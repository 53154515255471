import React from 'react';
import { AppProvider, SignInPage } from '@toolpad/core';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const providers = [{ id: 'credentials', name: 'Email and Password' }];

const signIn = async (provider, formData, navigate) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const email = formData.get('email');
      const password = formData.get('password');

      if (email === 'admin@example.com' && password === 'password') {

        const fakeToken = 'your-generated-jwt-token';
        
        localStorage.setItem('authToken', fakeToken);
        resolve();
        navigate('/');
      } else {
        alert('Invalid credentials');
        resolve();
      }
    }, 300);
  });
};

export default function Login() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <AppProvider theme={theme}>
      <SignInPage signIn={(provider, formData) => signIn(provider, formData, navigate)} providers={providers} />
    </AppProvider>
  );
}
