import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashboardLayoutBranding from './DashboardLayoutBranding';
import Login from './pages/Login';
import MediaBuyerAnalytics from './pages/MediaBuyerAnalytics';
import ProtectedRoute from './components/ProtectedRoute';
import AdvertiserAnalytics from './pages/AdvertiserAnalytics';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <DashboardLayoutBranding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/media-buyer-analytics"
          element={
            <ProtectedRoute>
              <MediaBuyerAnalytics />
            </ProtectedRoute>
          }
        />
                <Route
          path="/advertiser-analytics"
          element={
            <ProtectedRoute>
              <AdvertiserAnalytics />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}
