import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import lightLogo from './images/dark-logo.svg';
import darkLogo from './images/logo.svg';
import MediaBuyerAnalytics from './pages/MediaBuyerAnalytics';
import AdvertiserAnalytics from './pages/AdvertiserAnalytics';
import GlobalStyles from '@mui/material/GlobalStyles';

const inputGlobalStyles = <GlobalStyles
  styles={{
    '& main.MuiBox-root': {
      minWidth: '80%',
      boxSizing: 'border-box',
    },
  }}
/>

const NAVIGATION = [
  {
    segment: 'media-buyer-analytics',
    title: 'Media Buyer Analytics',
    icon: <PeopleIcon />,
  },
  {
    segment: 'advertiser-analytics',
    title: 'Advertiser Analytics',
    icon: <BarChartIcon />,
  },
];

const getTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
});

const AUTHENTICATION = {
  signIn: () => {},
  signOut: () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    window.location.href = '/login';
  },
};

const session = {
  user: {
    name: localStorage.getItem('username') || 'Anonymous',
    email: localStorage.getItem('email') || 'admin@example.com',
  },
};

export default function DashboardLayoutBranding() {
  const [pathname, setPathname] = useState('/media-buyer-analytics');
  const [currentLogo, setCurrentLogo] = useState(lightLogo);

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  const handleLogoSwitch = () => {
    setCurrentLogo((prevLogo) => (prevLogo === lightLogo ? darkLogo : lightLogo));
  };

  useEffect(() => {
    const themeSwitchButton = document.querySelector(
      'button[aria-label="Switch to dark mode"], button[aria-label="Switch to light mode"]'
    );

    if (themeSwitchButton) {
      themeSwitchButton.addEventListener('click', handleLogoSwitch);
    }

    return () => {
      if (themeSwitchButton) {
        themeSwitchButton.removeEventListener('click', handleLogoSwitch);
      }
    };
  }, []);

  const renderContent = () => {
    switch (pathname) {
      case '/media-buyer-analytics':
        return <MediaBuyerAnalytics />;
      case '/advertiser-analytics':
        return <AdvertiserAnalytics />;
      default:
        return <MediaBuyerAnalytics />;
    }
  };

  return (
    <AppProvider
      navigation={NAVIGATION}
      theme={getTheme}
      branding={{
        logo: <img src={currentLogo} alt="CRM2FTDS Logo" />,
        title: '',
      }}
      router={router}
      authentication={AUTHENTICATION}
      session={session}
    >
      {inputGlobalStyles}
      <DashboardLayout>
        <Box sx={{ p: 2 }}>
          {renderContent()}
        </Box>
      </DashboardLayout>
    </AppProvider>
  );
}
