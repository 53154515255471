import React, { useState, useMemo } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { processAffiliateData } from '../utils';
import { dataGridStyles } from '../styles';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const data = [
    {
        "traderID": 69511424,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "sextarius.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154361695",
        "ip": "31.161.144.18",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:49:24",
        "isSmartClick": "0",
        "email": "lamersmarco@gmail.com",
        "name": "Marco Lamers",
        "firstName": "Marco",
        "lastName": "Lamers",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12636",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276560736",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14301845",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69511271,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "remedilessness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154360871",
        "ip": "2a02:a420:490:3374:7443:8801:e235:bd95",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:48:18",
        "isSmartClick": "0",
        "email": "siereveldj@zeelandnet.nl",
        "name": "Johan Siereveld",
        "firstName": "Johan",
        "lastName": "Siereveld",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12635",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "new",
        "saleStatusMapped": "Not Handled",
        "clickID": "276560394",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14301819",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69511016,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Antwerp",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: flawlessdepered.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "154359812",
        "ip": "2a02:1808:87:f62f::1",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:46:20",
        "isSmartClick": "0",
        "email": "Pierrot@telenet.be",
        "name": "Pierrot Lancellotti",
        "firstName": "Pierrot",
        "lastName": "Lancellotti",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12634",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276559863",
        "countryID": 23,
        "cityID": 18,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14301780",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69510902,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Oosterhout",
        "custom": null,
        "custom1": null,
        "custom2": "waterlessness.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154359470",
        "ip": "82.173.104.220",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:45:48",
        "isSmartClick": "0",
        "email": "jabrobbasje@gmail.com",
        "name": "Jan Broeders",
        "firstName": "Jan",
        "lastName": "Broeders",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12633",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276559587",
        "countryID": 156,
        "cityID": 3987,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14301760",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69510033,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tilburg",
        "custom": null,
        "custom1": null,
        "custom2": "zomrao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154356234",
        "ip": "85.146.129.133",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:40:19",
        "isSmartClick": "0",
        "email": "johan.van.geloven@gmail.com",
        "name": "Johan van Geloven",
        "firstName": "Johan",
        "lastName": "van Geloven",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12632",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "OTP",
        "saleStatusMapped": null,
        "clickID": "276557826",
        "countryID": 156,
        "cityID": 235,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14301636",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69509849,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zomrao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154355791",
        "ip": "81.59.52.177",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:39:06",
        "isSmartClick": "0",
        "email": "aartvermeij@hotmail.com",
        "name": "Fany Violeta  Reto Zarate Vermeij ",
        "firstName": "Fany Violeta ",
        "lastName": "Reto Zarate Vermeij ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "aartvermeij@hotmail.com",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276557510",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69509472,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Arnhem",
        "custom": null,
        "custom1": null,
        "custom2": "mycomycete.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "154354342",
        "ip": "89.205.135.112",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:36:43",
        "isSmartClick": "0",
        "email": "nilsschilder2015@gmail.com",
        "name": "Nils Schilder",
        "firstName": "Nils",
        "lastName": "Schilder",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12631",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276556758",
        "countryID": 156,
        "cityID": 1365,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14301575",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69507086,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Sommelsdijk",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154345130",
        "ip": "2a02:a213:3041:7080:21a9:24db:70f9:271b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:21:14",
        "isSmartClick": "0",
        "email": "Annekedeslegte@gmail.com",
        "name": "Anneke De slegte",
        "firstName": "Anneke",
        "lastName": "De slegte",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12630",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276551819",
        "countryID": 156,
        "cityID": 6091,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14301217",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69504777,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "sextarius.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154337688",
        "ip": "109.38.133.80",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:07:41",
        "isSmartClick": "0",
        "email": "takkeung1979@hotmail.com",
        "name": "Tak Chau",
        "firstName": "Tak",
        "lastName": "Chau",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12629",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276547250",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14300871",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69503861,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Sint-Oedenrode",
        "custom": null,
        "custom1": null,
        "custom2": "lexniox.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154334777",
        "ip": "62.194.158.211",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 09:02:48",
        "isSmartClick": "0",
        "email": "vheuvenr@hotmail.com",
        "name": "Ron van Heuven",
        "firstName": "Ron",
        "lastName": "van Heuven",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12628",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276545461",
        "countryID": 156,
        "cityID": 24735,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14300740",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69502806,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "jesuitry.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154330871",
        "ip": "2a02:a449:37af:0:edf3:fab3:3484:3d84",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:56:34",
        "isSmartClick": "0",
        "email": "margreet.kohn@gmail.com",
        "name": "Margreet  Kohn",
        "firstName": "Margreet ",
        "lastName": "Kohn",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12627",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276543210",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14300598",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69501327,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hagestein",
        "custom": null,
        "custom1": null,
        "custom2": "rhodorhiza.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154325454",
        "ip": "2a02:a420:22a:eee4:60fd:d451:c445:6096",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:47:33",
        "isSmartClick": "0",
        "email": "lotimetz@gmail.com",
        "name": "Lourens  Metz",
        "firstName": "Lourens ",
        "lastName": "Metz",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12626",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276540337",
        "countryID": 156,
        "cityID": 47354,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14300422",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69501271,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "semiprotectorate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154325284",
        "ip": "217.103.7.223",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:47:21",
        "isSmartClick": "0",
        "email": "Mariusalfredjozefzoon@gmail.com",
        "name": "Marius Alfred Jozefzoon  Jozefzoon ",
        "firstName": "Marius Alfred Jozefzoon ",
        "lastName": "Jozefzoon ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "Mariusalfredjozefzoon@gmail.com",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276540273",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69498797,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Harderwijk",
        "custom": null,
        "custom1": null,
        "custom2": "sextarius.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154315497",
        "ip": "141.138.220.57",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:30:08",
        "isSmartClick": "0",
        "email": "Richard.smit@topper-verpakkingen.nl",
        "name": "Richard Smit",
        "firstName": "Richard",
        "lastName": "Smit",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12625",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276535230",
        "countryID": 156,
        "cityID": 7386,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299964",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69498727,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nibbixwoud",
        "custom": null,
        "custom1": null,
        "custom2": "sextarius.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154315349",
        "ip": "84.27.17.207",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:29:21",
        "isSmartClick": "0",
        "email": "fordmustangboss794@gmail.com",
        "name": "Jelle De Smit",
        "firstName": "Jelle",
        "lastName": "De Smit",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12624",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276535089",
        "countryID": 156,
        "cityID": 65764,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299937",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69498387,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Dokkum",
        "custom": null,
        "custom1": null,
        "custom2": "sextarius.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154313774",
        "ip": "2a02:a44e:7c79:1:a9dc:5d47:bee:5c2a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:26:24",
        "isSmartClick": "0",
        "email": "freddy_schur@hotmail.com",
        "name": "Frederikus .j.m.g Schür",
        "firstName": "Frederikus .j.m.g",
        "lastName": "Schür",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12623",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276534369",
        "countryID": 156,
        "cityID": 6662,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299864",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69497821,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Bruges",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: energyzersk.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "154311438",
        "ip": "88.82.37.107",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:21:51",
        "isSmartClick": "0",
        "email": "gabytoon@gmail.com",
        "name": "Antoine Van Kerckhove",
        "firstName": "Antoine",
        "lastName": "Van Kerckhove",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12622",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276533163",
        "countryID": 23,
        "cityID": 1779,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14299762",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69497653,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "sextarius.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154310773",
        "ip": "2a02:a420:500:516c:7599:c6b0:d4b0:5ba4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:20:46",
        "isSmartClick": "0",
        "email": "mehooi17@gmail.com",
        "name": "Angelo Hooi",
        "firstName": "Angelo",
        "lastName": "Hooi",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12621",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276532822",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299742",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69497282,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Vianen",
        "custom": null,
        "custom1": null,
        "custom2": "semimolecule.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154309531",
        "ip": "2a02:a420:460:d5ce:10a8:efff:fe8d:26d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:17:29",
        "isSmartClick": "0",
        "email": "pwgliu38@gmail.com",
        "name": "Wing-Geen Liu",
        "firstName": "Wing-Geen",
        "lastName": "Liu",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12620",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276532097",
        "countryID": 156,
        "cityID": 12737,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299668",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69496952,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "sextarius.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154308457",
        "ip": "109.38.133.123",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:15:05",
        "isSmartClick": "0",
        "email": "Wesleyfeenstra86@hotmail.com",
        "name": "Wesley  Feenstra ",
        "firstName": "Wesley ",
        "lastName": "Feenstra ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12619",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276531404",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299612",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69496774,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "lomviao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154307813",
        "ip": "188.65.191.60",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:13:52",
        "isSmartClick": "0",
        "email": "malepartis@gmail.com",
        "name": "Ilse Hoogenboom ",
        "firstName": "Ilse",
        "lastName": "Hoogenboom ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12618",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276531041",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-09 08:57:00",
        "depositID": "20415083",
        "conversionID": "1219390",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299574",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69496328,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "lomviao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154306361",
        "ip": "84.241.198.69",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:11:03",
        "isSmartClick": "0",
        "email": "Airin_b@hotmail.nl",
        "name": "Airin Baboeram ",
        "firstName": "Airin",
        "lastName": "Baboeram ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12617",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276530088",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299494",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69494929,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heesch",
        "custom": null,
        "custom1": null,
        "custom2": "vamnia.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154301656",
        "ip": "2a02:a461:f702:1:9ca1:325a:81fe:3a52",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 08:01:38",
        "isSmartClick": "0",
        "email": "Ru.cornelis@wxs.nl",
        "name": "Ruud Cornelis",
        "firstName": "Ruud",
        "lastName": "Cornelis",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12616",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276527250",
        "countryID": 156,
        "cityID": 1549,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14299205",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69491430,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Gouda",
        "custom": null,
        "custom1": null,
        "custom2": "foqza.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154285787",
        "ip": "2a02:a420:260:6445:4b4d:8285:a61e:3bfc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 07:24:49",
        "isSmartClick": "0",
        "email": "rolfgerritsen@hotmail.com",
        "name": "Rolf Gerritsen",
        "firstName": "Rolf",
        "lastName": "Gerritsen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12615",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276518708",
        "countryID": 156,
        "cityID": 2663,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14298639",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69480732,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zwolle",
        "custom": null,
        "custom1": null,
        "custom2": "mycomycete.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "154224485",
        "ip": "2001:1c06:1e0a:d700:fb8e:e8e1:6b3b:f766",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 05:23:57",
        "isSmartClick": "0",
        "email": "Jacqueline1952buijs@gmail.com",
        "name": "Jacqueline Buijs",
        "firstName": "Jacqueline",
        "lastName": "Buijs",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12614",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276493984",
        "countryID": 156,
        "cityID": 5049,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14297930",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69469299,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Helmond",
        "custom": null,
        "custom1": null,
        "custom2": "semiprotectorate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154171450",
        "ip": "213.10.62.150",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 02:42:15",
        "isSmartClick": "0",
        "email": "Wimvdheuvel64@gmail.com",
        "name": "Wim Van den heuvel",
        "firstName": "Wim",
        "lastName": "Van den heuvel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12613",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276470777",
        "countryID": 156,
        "cityID": 1558,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14297022",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69460853,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "teallite.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154140271",
        "ip": "89.205.130.50",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-09 00:24:20",
        "isSmartClick": "0",
        "email": "raoulsahebdin1@gmail.com",
        "name": "Raoul Sahebdin",
        "firstName": "Raoul",
        "lastName": "Sahebdin",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12612",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276458123",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14296728",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69456969,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Australia",
        "cityName": "Perth",
        "custom": null,
        "custom1": null,
        "custom2": "ctenolium.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154125461",
        "ip": "2001:8004:13c0:9fa6:d5cd:66a:d9aa:f245",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 21:46:50",
        "isSmartClick": "0",
        "email": "bartonvaneldik528@gmail.com",
        "name": "Barton  Van eldik ",
        "firstName": "Barton ",
        "lastName": "Van eldik ",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "0c85e895d1ed9d95bfaeab2141b3d46a",
        "leadType": "0",
        "countryCode": "AU",
        "saleStatus": "noanswer3",
        "saleStatusMapped": "No Answer",
        "clickID": "276449573",
        "countryID": 15,
        "cityID": 2,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Sonia Kruger is being sued by the Bank of Australia for comments she made on live broadcast",
        "leadRequestOfferName": "Immediate Edge (AU) Sonia Kruger_v1",
        "leadRequestOfferWebsite": "Immediate Edge (AU) Sonia Kruger_v1",
        "brokerAutologinClick": "14296326",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69455049,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "akmudar.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154118378",
        "ip": "2a02:a420:4b0:743c:25b0:e6ad:3cd4:8397",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 20:22:22",
        "isSmartClick": "0",
        "email": "mynilogistiek@gmail.com",
        "name": "Jeffrey Barrington",
        "firstName": "Jeffrey",
        "lastName": "Barrington",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12611",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276444869",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14296113",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69454142,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Oss",
        "custom": null,
        "custom1": null,
        "custom2": "semiprotectorate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154114569",
        "ip": "89.32.243.119",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 19:52:30",
        "isSmartClick": "0",
        "email": "Onyeugbohangus75@gmail.com",
        "name": "Angus  Onyeugboh ",
        "firstName": "Angus ",
        "lastName": "Onyeugboh ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12610",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276437002",
        "countryID": 156,
        "cityID": 1120,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14296001",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69453526,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Campbellton",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154111982",
        "ip": "2607:fea8:c459:fe70:c5f3:3078:7f95:a50a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 19:32:50",
        "isSmartClick": "0",
        "email": "lrtscooter2@gmail.com",
        "name": "William Brien",
        "firstName": "William",
        "lastName": "Brien",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "838cb8b429674d16ed63bd4b5dadfe66",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "callback",
        "saleStatusMapped": "Call again",
        "clickID": "276431974",
        "countryID": 40,
        "cityID": 4532,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14295916",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69453316,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Ospel",
        "custom": null,
        "custom1": null,
        "custom2": "interchaff.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154111220",
        "ip": "2a02:a420:24f:836d:a0dc:20bc:d4f8:8d51",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 19:25:40",
        "isSmartClick": "0",
        "email": "walter.valkenburg@hotmail.com",
        "name": "Peter Valkenburg ",
        "firstName": "Peter",
        "lastName": "Valkenburg ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12609",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276430202",
        "countryID": 156,
        "cityID": 34461,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14295881",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69448978,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Calgary",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154093679",
        "ip": "2604:3d09:2783:4200:a86c:d5d5:5989:b3de",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 18:01:54",
        "isSmartClick": "0",
        "email": "aghasurvey1@gmail.com",
        "name": "Agha Sherazee",
        "firstName": "Agha",
        "lastName": "Sherazee",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "aghasurvey1@gmail.com",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276405550",
        "countryID": 40,
        "cityID": 36,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69448091,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Kitchener",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154090442",
        "ip": "104.157.179.40",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 17:42:33",
        "isSmartClick": "0",
        "email": "Greco.f.1978@gmail.com",
        "name": "Frank  Greco ",
        "firstName": "Frank ",
        "lastName": "Greco ",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "e2bd09ce7ad0891519854e5fba383fed",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "hot",
        "saleStatusMapped": "Hot",
        "clickID": "276400122",
        "countryID": 40,
        "cityID": 1869,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14295114",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69446375,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Eindhoven",
        "custom": null,
        "custom1": null,
        "custom2": "semiprotectorate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154084497",
        "ip": "2a02:a453:6e44:0:9729:1fb1:e318:80e8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 17:26:17",
        "isSmartClick": "0",
        "email": "Marcdelfgou@gmail.com",
        "name": "Marc Delfgou ",
        "firstName": "Marc",
        "lastName": "Delfgou ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12607",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276391889",
        "countryID": 156,
        "cityID": 1279,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14294930",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69442581,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "semiprotectorate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154071698",
        "ip": "217.103.7.223",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 16:54:55",
        "isSmartClick": "0",
        "email": "mariusalfredjozefzoon@gmail.com",
        "name": "Marius Alfred  Jozefzoon ",
        "firstName": "Marius Alfred ",
        "lastName": "Jozefzoon ",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "527852",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "DVM",
        "saleStatusMapped": "Voicemail",
        "clickID": "276379766",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69442461,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "sacrilegiousness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154071177",
        "ip": "188.189.5.104",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 16:54:34",
        "isSmartClick": "0",
        "email": "chriskesev@gmail.com",
        "name": "Sevdie beraj",
        "firstName": "Sevdie",
        "lastName": "beraj",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12606",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276379678",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14294388",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69437164,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Lindsay",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154053861",
        "ip": "72.38.106.170",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 16:15:48",
        "isSmartClick": "0",
        "email": "Richardhynes7@gmail.com",
        "name": "Richard Hynes",
        "firstName": "Richard",
        "lastName": "Hynes",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "5fd52eccc4bfd947a191af3631e19a1b",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "shared",
        "saleStatusMapped": "Not interested",
        "clickID": "276363253",
        "countryID": 40,
        "cityID": 12254,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14293531",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69437107,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Emmeloord",
        "custom": null,
        "custom1": null,
        "custom2": "lemirao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154053742",
        "ip": "2a02:a453:b405:0:605b:ad3f:caa7:dd45",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 16:15:20",
        "isSmartClick": "0",
        "email": "bellesgerrit@gmail.com",
        "name": "Gerrit Belles",
        "firstName": "Gerrit",
        "lastName": "Belles",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12605",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276363107",
        "countryID": 156,
        "cityID": 6708,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Het onderzoek rond P Diddy gaat door, maar wat heeft de officiële diensten van Amerika ertoe aangezet om de artiest te gaan onderzoeken? Waarom is dit nieuws zo duister en verwarrend? De artiest zegt zelf dat de pesterijen en bedreigingen tegen hem door de autoriteiten begonnen na een interview in TheEllenShow, waar P Diddy per ongeluk het platform noemde waarop hij en zijn vrienden hun fortuin hebben gemaakt en dat iedereen de kans zou kunnen krijgen om dit “programma” te gebruiken.",
        "leadRequestOfferName": "AvaPro P_Diddy",
        "leadRequestOfferWebsite": "AvaPro P_Diddy",
        "brokerAutologinClick": "14293519",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69435668,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Spijkenisse",
        "custom": null,
        "custom1": null,
        "custom2": "albumenizer.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154049287",
        "ip": "2a02:a462:4442:1:8c0b:19ee:29e6:bc65",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 16:04:08",
        "isSmartClick": "0",
        "email": "g.m.hendriks@hetnet.nl",
        "name": "Gerard  Hendriks ",
        "firstName": "Gerard ",
        "lastName": "Hendriks ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12604",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276358552",
        "countryID": 156,
        "cityID": 1534,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14293314",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69433146,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hengelo",
        "custom": null,
        "custom1": null,
        "custom2": "semiprotectorate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154042064",
        "ip": "2001:1c06:1906:8e00:f5c4:9031:6cb2:5660",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 15:45:56",
        "isSmartClick": "0",
        "email": "hheideman46@gmail.com",
        "name": "Herman Heideman ",
        "firstName": "Herman",
        "lastName": "Heideman ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12603",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276350789",
        "countryID": 156,
        "cityID": 5887,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14293060",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69432301,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Victoria",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154039828",
        "ip": "2001:569:6fe9:a05d:2940:567c:21ec:1f85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 15:40:21",
        "isSmartClick": "0",
        "email": "JRRMGROUP@YAHOO.COM",
        "name": "James Morden",
        "firstName": "James",
        "lastName": "Morden",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "0b3b6b3553732f8657d92e99d533417b",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "hot",
        "saleStatusMapped": "Hot",
        "clickID": "276348275",
        "countryID": 40,
        "cityID": 565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14292923",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69431562,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "154037689",
        "ip": "82.173.131.103",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 15:36:12",
        "isSmartClick": "0",
        "email": "Raveenstra1968@gmail.com",
        "name": "Richard  Veenstra ",
        "firstName": "Richard ",
        "lastName": "Veenstra ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12602",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong number",
        "saleStatusMapped": "Wrong Info",
        "clickID": "276346254",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14292849",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69428409,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "unliquidating.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154028311",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 15:15:10",
        "isSmartClick": "0",
        "email": "test12043@gmail.com",
        "name": "test12043 test120432",
        "firstName": "test12043",
        "lastName": "test120432",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12601",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276336474",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14292405",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69427475,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Soest",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154025746",
        "ip": "2a02:a420:246:cf12:7c2b:65ff:fec1:a335",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 15:09:07",
        "isSmartClick": "0",
        "email": "arjanjunte1982@gmail.com",
        "name": "Arjan Junte",
        "firstName": "Arjan",
        "lastName": "Junte",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12600",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276333751",
        "countryID": 156,
        "cityID": 6712,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14292236",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69426488,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Riemst",
        "custom": null,
        "custom1": null,
        "custom2": "toothaching.com",
        "custom3": "Dutch",
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154023501",
        "ip": "84.195.242.87",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 15:03:06",
        "isSmartClick": "0",
        "email": "test000test@test.link",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12599",
        "leadType": "1",
        "countryCode": "BE",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276330835",
        "countryID": 23,
        "cityID": 7035,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14292053",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69425979,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "cocainism.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "154022145",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 15:00:24",
        "isSmartClick": "0",
        "email": "testtatteatettea@gmail.com",
        "name": "testtetts test",
        "firstName": "testtetts",
        "lastName": "test",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "527292",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276329296",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14291977",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69425782,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "choaty.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "154021621",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 14:59:15",
        "isSmartClick": "0",
        "email": "testetstestestdstvayd@gmail.com",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12598",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276328892",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14291950",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69425530,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "The Hague",
        "custom": null,
        "custom1": null,
        "custom2": "bipenniform.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "154020699",
        "ip": "84.107.19.133",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 14:57:50",
        "isSmartClick": "0",
        "email": "test0001test@test.link",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12597",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276328397",
        "countryID": 156,
        "cityID": 921,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14291922",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69424243,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Goes",
        "custom": null,
        "custom1": null,
        "custom2": "multiradial.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154016188",
        "ip": "62.45.221.15",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 14:50:23",
        "isSmartClick": "0",
        "email": "Wouterwillems19750@gmail.com",
        "name": "Wouter Willems",
        "firstName": "Wouter",
        "lastName": "Willems",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12596",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276325552",
        "countryID": 156,
        "cityID": 2033,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 20:08:00",
        "depositID": "20414367",
        "conversionID": "1219163",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIAL NOTICE: Tesla is launching their latest platform Meta Genesis to help families get richer Due to the global financial crisis, Tesla is launching a new project that promises to help families get richer.",
        "leadRequestOfferName": "Meta genesis FULL",
        "leadRequestOfferWebsite": "Meta genesis FULL",
        "brokerAutologinClick": "14291718",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69422923,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heerlen",
        "custom": null,
        "custom1": null,
        "custom2": "mercenarily.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "154011710",
        "ip": "95.98.93.232",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 14:42:42",
        "isSmartClick": "0",
        "email": "test76890876@gmail.test",
        "name": "test76890876 test76890876",
        "firstName": "test76890876",
        "lastName": "test76890876",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12595",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276323036",
        "countryID": 156,
        "cityID": 1425,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14291560",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69422580,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "albumenizer.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "154010285",
        "ip": "89.205.131.14",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 14:40:28",
        "isSmartClick": "0",
        "email": "e.pereira2@hotmail.com",
        "name": "Elisa  Pereira",
        "firstName": "Elisa ",
        "lastName": "Pereira",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12594",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276322192",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14291503",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69418441,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "imperformable.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153990532",
        "ip": "89.205.227.2",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 14:07:23",
        "isSmartClick": "0",
        "email": "nbelfor@cordaan.nl",
        "name": "Nancy Belfor",
        "firstName": "Nancy",
        "lastName": "Belfor",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12593",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276312442",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14290942",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69416156,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": " ",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153979798",
        "ip": "31.161.189.45",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:48:31",
        "isSmartClick": "0",
        "email": "Poqo74tp@hetnet.nl",
        "name": "Sebastiano Basso",
        "firstName": "Sebastiano",
        "lastName": "Basso",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12592",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276307211",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIAL NOTICE: Tesla is launching their latest platform Meta Genesis to help families get richer Due to the global financial crisis, Tesla is launching a new project that promises to help families get richer.",
        "leadRequestOfferName": "Meta genesis FULL",
        "leadRequestOfferWebsite": "Meta genesis FULL",
        "brokerAutologinClick": "14290635",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69414961,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Leiderdorp",
        "custom": null,
        "custom1": null,
        "custom2": "albumenizer.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153974210",
        "ip": "84.86.248.131",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:39:50",
        "isSmartClick": "0",
        "email": "Aagje.Nommensen@gmail.com",
        "name": "Aagje  Nommensen ",
        "firstName": "Aagje ",
        "lastName": "Nommensen ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12591",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276304317",
        "countryID": 156,
        "cityID": 9936,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14290479",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69414709,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hardenberg",
        "custom": null,
        "custom1": null,
        "custom2": "unrebuked.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153972682",
        "ip": "2a02:a45e:1298:0:3c57:9e7a:32c3:7247",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:37:28",
        "isSmartClick": "0",
        "email": "paquot17@hotmail.com",
        "name": "Tony Paquot",
        "firstName": "Tony",
        "lastName": "Paquot",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "paquot17@hotmail.com",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276303631",
        "countryID": 156,
        "cityID": 2435,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69412494,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Tielt",
        "custom": null,
        "custom1": null,
        "custom2": "sacrilegiousness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153963012",
        "ip": "2a02:a03f:e28e:8700:9c55:70a2:6c:2d91",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:21:52",
        "isSmartClick": "0",
        "email": "vlegelschristina@msn.com",
        "name": "Chistina Vlegels",
        "firstName": "Chistina",
        "lastName": "Vlegels",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12590",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276298387",
        "countryID": 23,
        "cityID": 2035,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14290181",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69411568,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heerhugowaard",
        "custom": null,
        "custom1": null,
        "custom2": "albumenizer.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153959121",
        "ip": "2a02:a454:3367:1:6149:8b68:4a60:1589",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:15:20",
        "isSmartClick": "0",
        "email": "w.schuijlenburg@gmail.com",
        "name": "wim schuijlenburg",
        "firstName": "wim",
        "lastName": "schuijlenburg",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12589",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276296266",
        "countryID": 156,
        "cityID": 6092,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14290074",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69411478,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Oosterhout",
        "custom": null,
        "custom1": null,
        "custom2": "unmotivatedness.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153958874",
        "ip": "2001:1c03:492f:b00:80b1:403b:5669:9c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:14:41",
        "isSmartClick": "0",
        "email": "holanbras@gmail.com",
        "name": "M.J.P.A. Scherders",
        "firstName": "M.J.P.A.",
        "lastName": "Scherders",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12588",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276296097",
        "countryID": 156,
        "cityID": 3987,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14290062",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69411282,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Son en Breugel",
        "custom": null,
        "custom1": null,
        "custom2": "lomviao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153958254",
        "ip": "143.178.5.131",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:13:20",
        "isSmartClick": "0",
        "email": "larsmeima89@gmail.com",
        "name": "Lars Meima",
        "firstName": "Lars",
        "lastName": "Meima",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12587",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276295771",
        "countryID": 156,
        "cityID": 24551,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14290045",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69410964,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "epalpate.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "153957064",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:11:51",
        "isSmartClick": "0",
        "email": "testesttesttttvedba@gmail.com",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "526712",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276295172",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69410613,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153955958",
        "ip": "2a01:cb1e:23:66fe:b746:60be:385f:d746",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 13:09:41",
        "isSmartClick": "0",
        "email": "carmagnat.f@gmail.com",
        "name": "Francoise CARMAGNAT",
        "firstName": "Francoise",
        "lastName": "CARMAGNAT",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "c6414ca7-cde7-4b0a-9385-2edce4099879",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "276294531",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69410586,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Liège",
        "custom": null,
        "custom1": null,
        "custom2": "agamogenetically.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "153955888",
        "ip": "149.154.250.86",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:09:35",
        "isSmartClick": "0",
        "email": "testtesttsetgsvbegswb123@gmail.com",
        "name": "test tetststest",
        "firstName": "test",
        "lastName": "tetststest",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "526692",
        "leadType": "1",
        "countryCode": "BE",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276294494",
        "countryID": 23,
        "cityID": 545,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14289995",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69409713,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Nice",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153953233",
        "ip": "109.24.151.124",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 13:04:33",
        "isSmartClick": "0",
        "email": "claudel.location@sfr.fr",
        "name": "Olivier Claudel",
        "firstName": "Olivier",
        "lastName": "Claudel",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "2de46865-a99d-4ce0-b8a7-f9a4ba71843b",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Call again",
        "saleStatusMapped": "Call again",
        "clickID": "276292790",
        "countryID": 75,
        "cityID": 1922,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14289917",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69409351,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "semiprotectorate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153952006",
        "ip": "2a02:f6d:88f6:0:5da5:257e:e22b:4c77",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 13:02:16",
        "isSmartClick": "0",
        "email": "perdamme@zeelamdnet.nl",
        "name": "Willem Vogel",
        "firstName": "Willem",
        "lastName": "Vogel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12586",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276292130",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14289877",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69406450,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Azille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153943033",
        "ip": "2a01:e0a:55f:b390:92cd:afc5:b091:f7f8",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 12:44:23",
        "isSmartClick": "0",
        "email": "vblerote@yahoo.fr",
        "name": "Bleriot epouse Touzeau",
        "firstName": "Bleriot epouse",
        "lastName": "Touzeau",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "vblerote@yahoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276286690",
        "countryID": 75,
        "cityID": 65466,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69405736,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "uncadenced.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153941116",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:39:51",
        "isSmartClick": "0",
        "email": "test76891123@gmail.test",
        "name": "test76891123 test76891123",
        "firstName": "test76891123",
        "lastName": "test76891123",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12585",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276285395",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14289533",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69404438,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zevenaar",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153937590",
        "ip": "77.161.210.200",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:31:32",
        "isSmartClick": "0",
        "email": "m.bourgondier@gmail.com",
        "name": "Marinus Cornelissen",
        "firstName": "Marinus",
        "lastName": "Cornelissen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12584",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276283039",
        "countryID": 156,
        "cityID": 6740,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14289406",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69404235,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amersfoort",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153937051",
        "ip": "109.37.128.122",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:30:25",
        "isSmartClick": "0",
        "email": "mrhooi@hotmail.com",
        "name": "Jerry  Hooi",
        "firstName": "Jerry ",
        "lastName": "Hooi",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12583",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276282642",
        "countryID": 156,
        "cityID": 1494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14289384",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69403652,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Oosterhout",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153935673",
        "ip": "2001:1c03:492f:b00:80b1:403b:5669:9c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:26:30",
        "isSmartClick": "0",
        "email": "anjalifes@gmail.com",
        "name": "Marjolein Scherders",
        "firstName": "Marjolein",
        "lastName": "Scherders",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12582",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276281625",
        "countryID": 156,
        "cityID": 3987,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14289305",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69402414,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heerhugowaard",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153932575",
        "ip": "87.212.76.235",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:17:57",
        "isSmartClick": "0",
        "email": "wimroos1952@gmail.com",
        "name": "Wlm Roos",
        "firstName": "Wlm",
        "lastName": "Roos",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12581",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276279255",
        "countryID": 156,
        "cityID": 6092,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 15:17:22",
        "depositID": "20414024",
        "conversionID": "1219006",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14289156",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69401607,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanvoxa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153930472",
        "ip": "2a02:a420:490:76ed:69bd:cd9:9e88:b50e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:12:57",
        "isSmartClick": "0",
        "email": "rzaalmink@hotmail.com",
        "name": "Rob Zaalmink",
        "firstName": "Rob",
        "lastName": "Zaalmink",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12580",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276277775",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14289091",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69400895,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Bristol",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153928700",
        "ip": "82.41.78.48",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 12:08:29",
        "isSmartClick": "0",
        "email": "margce@live.co.uk",
        "name": "Robert Bowman",
        "firstName": "Robert",
        "lastName": "Bowman",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "670520be4a92f941d304c773",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Low Quality",
        "saleStatusMapped": "No interest",
        "clickID": "276276522",
        "countryID": 233,
        "cityID": 693,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14289025",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69400772,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tilburg",
        "custom": null,
        "custom1": null,
        "custom2": "interchaff.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153928344",
        "ip": "85.145.19.235",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:07:38",
        "isSmartClick": "0",
        "email": "rita-richard@hotmail.com",
        "name": "Gens Hempenius",
        "firstName": "Gens",
        "lastName": "Hempenius",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12579",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276276299",
        "countryID": 156,
        "cityID": 235,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14289013",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69400455,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153927539",
        "ip": "2a04:cec0:c02c:acc5:4ea:daff:feb7:dd77",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 12:05:46",
        "isSmartClick": "0",
        "email": "mireilleboucley27@gmail.com",
        "name": "Mireille Boucley",
        "firstName": "Mireille",
        "lastName": "Boucley",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "mireilleboucley27@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276275731",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69400341,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153927359",
        "ip": "2a02:a420:4f1:299a:a928:a780:e114:969f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:04:59",
        "isSmartClick": "0",
        "email": "dianamanni114@gmail.com",
        "name": "Diana Manni",
        "firstName": "Diana",
        "lastName": "Manni",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12578",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276275559",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288960",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69400100,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153926769",
        "ip": "31.161.159.76",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 12:03:37",
        "isSmartClick": "0",
        "email": "Lalbostanli@hotmail.com",
        "name": "Levent Albostanli",
        "firstName": "Levent",
        "lastName": "Albostanli",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12577",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276275172",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69398995,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Emmen",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153923864",
        "ip": "2001:1c01:4009:4b00:e990:d3f1:6a93:b363",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:56:39",
        "isSmartClick": "0",
        "email": "harry.krul55@gmail.com",
        "name": "Harry Krul",
        "firstName": "Harry",
        "lastName": "Krul",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12576",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276273150",
        "countryID": 156,
        "cityID": 5877,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288818",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69398760,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "unmotivatedness.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153923283",
        "ip": "2a02:a472:ffe3:1:9035:59d4:f757:9502",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:55:11",
        "isSmartClick": "0",
        "email": "info@dva-international.com",
        "name": "Frans Vilder",
        "firstName": "Frans",
        "lastName": "Vilder",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12575",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276272726",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288793",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69398165,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Schijndel",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153921944",
        "ip": "2a02:a46c:a7b7:1:699f:ff7b:486e:4f39",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:51:27",
        "isSmartClick": "0",
        "email": "aanmarga5@gmail.com",
        "name": "Marga De Groot",
        "firstName": "Marga",
        "lastName": "De Groot",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12574",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276271715",
        "countryID": 156,
        "cityID": 13961,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 13:21:11",
        "depositID": "20414001",
        "conversionID": "1218880",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288736",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69397371,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "unmotivatedness.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153919941",
        "ip": "2a02:a450:1953:1:e586:d215:3c43:e6a5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:46:26",
        "isSmartClick": "0",
        "email": "chafikamraoui@hotmail.com",
        "name": "Chafik Amraoui",
        "firstName": "Chafik",
        "lastName": "Amraoui",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12573",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276270347",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288649",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69396576,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Zoutleeuw",
        "custom": null,
        "custom1": null,
        "custom2": "preadamitic.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153917342",
        "ip": "2a02:1810:b411:6800:d4bc:1b34:5559:6465",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:41:31",
        "isSmartClick": "0",
        "email": "Jappy.bruyneel@gmail.com",
        "name": "Jean Bruyneel",
        "firstName": "Jean",
        "lastName": "Bruyneel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12572",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276268796",
        "countryID": 23,
        "cityID": 25258,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14288561",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69395876,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Venray",
        "custom": null,
        "custom1": null,
        "custom2": "electrokinematics.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153915057",
        "ip": "2001:1c05:378e:8000:1db5:d63e:9137:f4f8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:37:29",
        "isSmartClick": "0",
        "email": "keescobussen@gmail.com",
        "name": "Cornelis  Cobussen ",
        "firstName": "Cornelis ",
        "lastName": "Cobussen ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12571",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276267469",
        "countryID": 156,
        "cityID": 6111,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288458",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69395568,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Turnhout",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153914323",
        "ip": "2a02:a03f:88fa:a100:e14d:d10:d500:bafd",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:35:51",
        "isSmartClick": "0",
        "email": "Schuurmanssven@gmail.com",
        "name": "Sven Schuurmans",
        "firstName": "Sven",
        "lastName": "Schuurmans",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12570",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276266924",
        "countryID": 23,
        "cityID": 745,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14288416",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69395337,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "sacrilegiousness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153913812",
        "ip": "188.188.241.233",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:34:19",
        "isSmartClick": "0",
        "email": "evyblendeman4@gmail.com",
        "name": "Evy Blendeman",
        "firstName": "Evy",
        "lastName": "Blendeman",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12569",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276266539",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14288388",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69395010,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanrova.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153913036",
        "ip": "84.241.199.185",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:32:14",
        "isSmartClick": "0",
        "email": "A.vandertoorn@outlook.com",
        "name": "Anthony Van Der Toorn",
        "firstName": "Anthony",
        "lastName": "Van Der Toorn",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12568",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276265959",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288342",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69394409,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Sint-Niklaas",
        "custom": null,
        "custom1": null,
        "custom2": "sacrilegiousness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153911562",
        "ip": "2a02:a03f:834a:9800:3215:d183:2397:b783",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:28:33",
        "isSmartClick": "0",
        "email": "anchris.de.saegher@skynet.be",
        "name": "Andre De saegher",
        "firstName": "Andre",
        "lastName": "De saegher",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12567",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong number",
        "saleStatusMapped": "Wrong Info",
        "clickID": "276264871",
        "countryID": 23,
        "cityID": 647,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14288266",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69394261,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "preadamitic.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153911257",
        "ip": "2a02:a020:88:9bd3:1:0:f5fe:2548",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:27:46",
        "isSmartClick": "0",
        "email": "hoefnagelsjan@hotmail.com",
        "name": "Jan Hoefnagels",
        "firstName": "Jan",
        "lastName": "Hoefnagels",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12566",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276264642",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14288241",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69393979,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanrova.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153910514",
        "ip": "89.205.225.165",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:26:19",
        "isSmartClick": "0",
        "email": "san6ers@gmail.com",
        "name": "sander van de velde",
        "firstName": "sander",
        "lastName": "van de velde",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12565",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276264197",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 12:19:10",
        "depositID": "20413925",
        "conversionID": "1218803",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288208",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69393702,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Schoonhoven",
        "custom": null,
        "custom1": null,
        "custom2": "rhizomorphoid.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153909856",
        "ip": "217.169.235.243",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:24:45",
        "isSmartClick": "0",
        "email": "Pieter@zaaijer.eu",
        "name": "Pieter Zaaijer",
        "firstName": "Pieter",
        "lastName": "Zaaijer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12564",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276263660",
        "countryID": 156,
        "cityID": 14272,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14288179",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69392425,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Strasbourg",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153906920",
        "ip": "88.187.14.77",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 11:17:18",
        "isSmartClick": "0",
        "email": "bouchta.ouafella@gmail.com",
        "name": "Bouchta Ouafella",
        "firstName": "Bouchta",
        "lastName": "Ouafella",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "0aeb597f-6ec9-406b-b668-37d7f3284560",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "276261432",
        "countryID": 75,
        "cityID": 1101,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14288027",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69392066,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanvoxa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153906104",
        "ip": "2a02:a420:410:a97a:cf67:e248:691e:f63a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:15:37",
        "isSmartClick": "0",
        "email": "W.j.drees1@gmail.com",
        "name": "Edwin  Drees",
        "firstName": "Edwin ",
        "lastName": "Drees",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12563",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276260772",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14287997",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69391831,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zutphen",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153905602",
        "ip": "2a02:a213:8801:6500:8185:9794:15e4:b08b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:14:06",
        "isSmartClick": "0",
        "email": "joopapem@gmail.com",
        "name": "Jopie  Bronsvoort",
        "firstName": "Jopie ",
        "lastName": "Bronsvoort",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12562",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276260348",
        "countryID": 156,
        "cityID": 8500,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14287960",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69391519,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "akmudar.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153904991",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 11:12:21",
        "isSmartClick": "0",
        "email": "test123fdufyuiquqoa@gmail.com",
        "name": "test123fdufyuiquqoa test123fdufyuiquqoa",
        "firstName": "test123fdufyuiquqoa",
        "lastName": "test123fdufyuiquqoa",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12561",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276259824",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14287929",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69391160,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153904056",
        "ip": "37.171.167.165",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 11:10:35",
        "isSmartClick": "0",
        "email": "berdan74@gmail.com",
        "name": "Daniele Guillaume gonin",
        "firstName": "Daniele",
        "lastName": "Guillaume gonin",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "27eabae6-36a4-4aa6-adcc-ed19bf41d36b",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Wrong Info",
        "saleStatusMapped": "Wrong Info",
        "clickID": "276259110",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14287900",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69389475,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153900458",
        "ip": "2a04:cec0:1200:8cd0:a11d:b709:caea:f466",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 11:02:12",
        "isSmartClick": "0",
        "email": "abderlek@gmail.com",
        "name": "Abderrahmane Lekkal",
        "firstName": "Abderrahmane",
        "lastName": "Lekkal",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "66a5e4f4-323e-4ab5-8a14-9a840b07b7b8",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "276256357",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69389363,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153900118",
        "ip": "2a01:cb01:862:b7c2:0:13:1c7f:5101",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 11:01:44",
        "isSmartClick": "0",
        "email": "olenadvoryanova@gmail.com",
        "name": "Dvoryanova Dvoryanova",
        "firstName": "Dvoryanova",
        "lastName": "Dvoryanova",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "3d6835ca-401a-432c-b4cd-3d536dddffc5",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276256111",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14287723",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69388913,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "interchaff.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153898990",
        "ip": "2a02:a420:4f0:73c9:8dda:3473:8526:4609",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:59:32",
        "isSmartClick": "0",
        "email": "stefanokkes@hotmail.com",
        "name": "Stefan Okkes",
        "firstName": "Stefan",
        "lastName": "Okkes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12560",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276255356",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14287674",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69388702,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tilburg",
        "custom": null,
        "custom1": null,
        "custom2": "zanvoxa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153898397",
        "ip": "2a02:a448:5015:1:bde4:a68d:478c:6bcf",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:58:22",
        "isSmartClick": "0",
        "email": "hout.v.d.t@outlook.com",
        "name": "Toine van den Hout",
        "firstName": "Toine",
        "lastName": "van den Hout",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12559",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276254989",
        "countryID": 156,
        "cityID": 235,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 12:02:27",
        "depositID": "20413902",
        "conversionID": "1218779",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14287645",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69387415,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Vuren",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153894908",
        "ip": "92.70.181.178",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:51:39",
        "isSmartClick": "0",
        "email": "Rvermeulen49@gmail.com",
        "name": "Laurens Vermeulen ",
        "firstName": "Laurens",
        "lastName": "Vermeulen ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12558",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276252555",
        "countryID": 156,
        "cityID": 36517,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14287508",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69386951,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "uncadenced.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153893786",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:49:07",
        "isSmartClick": "0",
        "email": "test6789087@gmail.test",
        "name": "test6789087 test6789087",
        "firstName": "test6789087",
        "lastName": "test6789087",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12557",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276251727",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14287425",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69386176,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Volendam",
        "custom": null,
        "custom1": null,
        "custom2": "equilibristat.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153890862",
        "ip": "92.67.123.185",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:44:45",
        "isSmartClick": "0",
        "email": "hvelding@home.nl",
        "name": "Herman Velding",
        "firstName": "Herman",
        "lastName": "Velding",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12556",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276250208",
        "countryID": 156,
        "cityID": 8655,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 15:18:37",
        "depositID": "20414279",
        "conversionID": "1219009",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14287317",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69385075,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Temse",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153887240",
        "ip": "2a02:a03f:8442:6701:7c9b:2ad9:3db9:edfc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:38:47",
        "isSmartClick": "0",
        "email": "yvanwillaert@hotmail.com",
        "name": "Yvan Willaert ",
        "firstName": "Yvan",
        "lastName": "Willaert ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12555",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276248162",
        "countryID": 23,
        "cityID": 7433,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14287175",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69385024,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "vamnia.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153886999",
        "ip": "89.205.130.226",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:38:32",
        "isSmartClick": "0",
        "email": "thijsheldens@gmail.com",
        "name": "Thijs  Heldens",
        "firstName": "Thijs ",
        "lastName": "Heldens",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12554",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276248068",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14287167",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69384130,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Caldicot",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153884005",
        "ip": "82.68.86.142",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 10:33:54",
        "isSmartClick": "0",
        "email": "admin@newquayweather.com",
        "name": "Philip Paskin",
        "firstName": "Philip",
        "lastName": "Paskin",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "67050a93c81644330a0cd093",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276246356",
        "countryID": 233,
        "cityID": 28839,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14287036",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69384052,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153883864",
        "ip": "78.245.198.242",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 10:33:32",
        "isSmartClick": "0",
        "email": "ermira1967@yahoo.fr",
        "name": "Ermira Haxhija",
        "firstName": "Ermira",
        "lastName": "Haxhija",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "3f39ef47-3655-4e89-b15e-b87e80427aee",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No money",
        "saleStatusMapped": "No Money",
        "clickID": "276246254",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14287012",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69383953,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Leguevin",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153883638",
        "ip": "2001:861:c85:bb00:711a:916e:6dac:b685",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 10:32:59",
        "isSmartClick": "0",
        "email": "nanas.cagire@gmail.com",
        "name": "Frederic Lasserre",
        "firstName": "Frederic",
        "lastName": "Lasserre",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "441b6031-3b4d-4879-9f3a-5dc837a5b24a",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Call again",
        "saleStatusMapped": "Call again",
        "clickID": "276246099",
        "countryID": 75,
        "cityID": 28113,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14286995",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69383409,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153881661",
        "ip": "77.205.21.35",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 10:30:00",
        "isSmartClick": "0",
        "email": "barhoumimihamed@gmail.com",
        "name": "Mohamed Barhoumi",
        "firstName": "Mohamed",
        "lastName": "Barhoumi",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "23094616-bd27-4623-88c3-cf692d749f7a",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "276245114",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69382131,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "imperformable.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153877483",
        "ip": "82.101.231.209",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:25:03",
        "isSmartClick": "0",
        "email": "rjschet@gmail.com",
        "name": "Ronald John Schet",
        "firstName": "Ronald John",
        "lastName": "Schet",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12553",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276243201",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14286755",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69381743,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ranizo.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153876371",
        "ip": "2001:4c3c:1302:fd00:4cef:f187:96cb:d85b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:23:28",
        "isSmartClick": "0",
        "email": "margrietsnellers63@hotmail.com",
        "name": "Margriet Snellers",
        "firstName": "Margriet",
        "lastName": "Snellers",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12552",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276242658",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14286705",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69381195,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153874804",
        "ip": "89.205.141.202",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:21:27",
        "isSmartClick": "0",
        "email": "janvanharen4@gmail.com",
        "name": "Jan Van Haren",
        "firstName": "Jan",
        "lastName": "Van Haren",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12551",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276241871",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14286642",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69380855,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Horssen",
        "custom": null,
        "custom1": null,
        "custom2": "ranizo.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153873844",
        "ip": "2a02:a46b:95b:1:d4a2:250a:fe55:d91c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:19:50",
        "isSmartClick": "0",
        "email": "vanhelvoirtjohn@gmail.com",
        "name": "Ajm Helvoirt",
        "firstName": "Ajm",
        "lastName": "Helvoirt",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12550",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276241353",
        "countryID": 156,
        "cityID": 39422,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14286593",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69380170,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Horst",
        "custom": null,
        "custom1": null,
        "custom2": "safeseniorcareretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153871767",
        "ip": "94.215.50.211",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:17:27",
        "isSmartClick": "0",
        "email": "gertiethijssen@gmail.com",
        "name": "Gertie Thijssen",
        "firstName": "Gertie",
        "lastName": "Thijssen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12549",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276240359",
        "countryID": 156,
        "cityID": 9411,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14286515",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69379997,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nijewier",
        "custom": null,
        "custom1": null,
        "custom2": "imperformable.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153871142",
        "ip": "212.52.252.234",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:16:46",
        "isSmartClick": "0",
        "email": "njansen54@gmail.com",
        "name": "Nico Jansen",
        "firstName": "Nico",
        "lastName": "Jansen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12548",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276240026",
        "countryID": 156,
        "cityID": 142003,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14286499",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69378973,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "waterlessness.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153868266",
        "ip": "109.36.155.27",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:12:01",
        "isSmartClick": "0",
        "email": "robbertroomer@hotmail.com",
        "name": "Robbert  Roomer",
        "firstName": "Robbert ",
        "lastName": "Roomer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12547",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276238494",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 11:32:00",
        "depositID": "20413831",
        "conversionID": "1218747",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14286338",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69378397,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Caluire-et-Cuire",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153866510",
        "ip": "88.174.127.57",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 10:08:55",
        "isSmartClick": "0",
        "email": "paulinebonhomme2@hotmail.com",
        "name": "Pauline Bonhomme",
        "firstName": "Pauline",
        "lastName": "Bonhomme",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "efb9cbf2-9f76-499d-a305-ec26d5049879",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276237548",
        "countryID": 75,
        "cityID": 3151,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14286263",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69378103,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zaandam",
        "custom": null,
        "custom1": null,
        "custom2": "zanvoxa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153865810",
        "ip": "31.151.214.26",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 10:07:23",
        "isSmartClick": "0",
        "email": "bestways@upcmail.nl",
        "name": "Steven  Best",
        "firstName": "Steven ",
        "lastName": "Best",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12546",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276237147",
        "countryID": 156,
        "cityID": 1566,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14286234",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69378032,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Rochefort-du-Gard",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153865557",
        "ip": "2a04:cec0:1204:b2d0:9512:a42f:d95e:a632",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 10:06:53",
        "isSmartClick": "0",
        "email": "fbmjeny@gmail.com",
        "name": "Beddar Beddarmartinez",
        "firstName": "Beddar",
        "lastName": "Beddarmartinez",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "3a09b497-c48a-4b2a-8722-e1f870f61a7e",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276236970",
        "countryID": 75,
        "cityID": 14493,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69376538,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Lambeth",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153861387",
        "ip": "2a00:23ee:2878:c45:a856:964f:947a:459c",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 09:58:24",
        "isSmartClick": "0",
        "email": "johnofarrell38@gmail.com",
        "name": "John Ofarrell",
        "firstName": "John",
        "lastName": "Ofarrell",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "670502412fc35a1ebc00ea33",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276234550",
        "countryID": 233,
        "cityID": 372,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14286036",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69376500,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Alphen aan den Rijn",
        "custom": null,
        "custom1": null,
        "custom2": "interchaff.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153861316",
        "ip": "2001:1c00:805:be00:508f:ca3c:e907:c5a5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:58:17",
        "isSmartClick": "0",
        "email": "janvanvliet_50@hotmail.com",
        "name": "Jan Van vliet",
        "firstName": "Jan",
        "lastName": "Van vliet",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12545",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276234523",
        "countryID": 156,
        "cityID": 1537,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14286024",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69376240,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Oosterhout",
        "custom": null,
        "custom1": null,
        "custom2": "mindforgepro.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "153860554",
        "ip": "2a02:a471:b8f5:1:7254:e855:402:28bd",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:56:55",
        "isSmartClick": "0",
        "email": "f.koffy71@gmail.com",
        "name": "Faleka Koffy",
        "firstName": "Faleka",
        "lastName": "Koffy",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12544",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276234074",
        "countryID": 156,
        "cityID": 3987,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14285990",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69375971,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Rhinau",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153859819",
        "ip": "2a02:842a:2a7f:a501:e597:bba5:be4a:7ddf",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 09:55:36",
        "isSmartClick": "0",
        "email": "ludivine_ky@hotmail.fr",
        "name": "Ky Ky",
        "firstName": "Ky",
        "lastName": "Ky",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "6a1a3ef7-68a3-4c40-9195-516486f91e20",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "276233641",
        "countryID": 75,
        "cityID": 21866,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69375501,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Ipswich",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153858806",
        "ip": "82.6.112.250",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 09:53:15",
        "isSmartClick": "0",
        "email": "leenadxb@hotmail.com",
        "name": "Leena kishanchand",
        "firstName": "Leena",
        "lastName": "kishanchand",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6705010d6c3fbe3e5602f8e3",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Low Quality",
        "saleStatusMapped": "No interest",
        "clickID": "276233006",
        "countryID": 233,
        "cityID": 2120,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14285936",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69375250,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Champigny-sur-Marne",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153858242",
        "ip": "62.35.89.34",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 09:52:13",
        "isSmartClick": "0",
        "email": "okoudesire7@gmail.com",
        "name": "Tohouri OKOU Desire",
        "firstName": "Tohouri",
        "lastName": "OKOU Desire",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "24f57683-4cc8-4dd9-a654-4a03e58f579f",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Wrong Info",
        "saleStatusMapped": "Wrong Info",
        "clickID": "276232634",
        "countryID": 75,
        "cityID": 4921,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69373230,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Barendrecht",
        "custom": null,
        "custom1": null,
        "custom2": "lomviao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153853875",
        "ip": "89.98.141.229",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:42:32",
        "isSmartClick": "0",
        "email": "yordanosredi@gmail.com",
        "name": "Yordanos  Redi ",
        "firstName": "Yordanos ",
        "lastName": "Redi ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12543",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276229744",
        "countryID": 156,
        "cityID": 6730,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14285684",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69370961,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Zoutleeuw",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153849130",
        "ip": "2a02:1810:b434:a600:317b:8e05:2931:1a1f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:31:54",
        "isSmartClick": "0",
        "email": "Raymaekersborgers@hotmail.ne",
        "name": "Ingrif Borgers",
        "firstName": "Ingrif",
        "lastName": "Borgers",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12542",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276226216",
        "countryID": 23,
        "cityID": 25258,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14285414",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69369532,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Coventry",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153845594",
        "ip": "89.242.153.127",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 09:24:09",
        "isSmartClick": "0",
        "email": "stuart.dibb@jmginsurance.co.uk",
        "name": "stuart dibb",
        "firstName": "stuart",
        "lastName": "dibb",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6704fa3a4e8f3ebeab0868d3",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276223992",
        "countryID": 233,
        "cityID": 1002,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14285230",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69369443,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Ghent",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153845394",
        "ip": "193.121.56.122",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:23:43",
        "isSmartClick": "0",
        "email": "jean.duysan@gmail.com",
        "name": "Jean Duysan",
        "firstName": "Jean",
        "lastName": "Duysan",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12541",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276223883",
        "countryID": 23,
        "cityID": 403,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14285215",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69369384,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amersfoort",
        "custom": null,
        "custom1": null,
        "custom2": "viscontal.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153845275",
        "ip": "2a02:a45c:fb85:0:fb4e:75a0:88dc:9262",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:23:25",
        "isSmartClick": "0",
        "email": "Luciennunes017@gmail.com",
        "name": "Lucien Nunes",
        "firstName": "Lucien",
        "lastName": "Nunes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12540",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276223812",
        "countryID": 156,
        "cityID": 1494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Joost Klein aan voor zijn opmerkingen in live tv-programma",
        "leadRequestOfferName": "Trade Avapro Joost Klein (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Joost Klein (NL)",
        "brokerAutologinClick": "14285206",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69369041,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Lokeren",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153844400",
        "ip": "87.64.85.176",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:21:49",
        "isSmartClick": "0",
        "email": "yalcin.ergun@hotmail.be",
        "name": "Ergun Yalcin",
        "firstName": "Ergun",
        "lastName": "Yalcin",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12539",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276223262",
        "countryID": 23,
        "cityID": 4276,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14285157",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69367741,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Zichem",
        "custom": null,
        "custom1": null,
        "custom2": "paunchful.com",
        "custom3": "Dutch",
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153841224",
        "ip": "2a02:a03f:8780:2d00:2856:7999:4643:cb04",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:16:14",
        "isSmartClick": "0",
        "email": "Calibraturbo20@gmail.com",
        "name": "Mario De oliveira ",
        "firstName": "Mario",
        "lastName": "De oliveira ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12538",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276221406",
        "countryID": 23,
        "cityID": 27293,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14285022",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69367264,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rhoon",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153840147",
        "ip": "62.45.107.210",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 09:14:04",
        "isSmartClick": "0",
        "email": "henvdelzem@gmail.com",
        "name": "Henriëtte ban den Elzen van de Ven",
        "firstName": "Henriëtte",
        "lastName": "ban den Elzen van de Ven",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12537",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276220628",
        "countryID": 156,
        "cityID": 6233,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14284961",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69365249,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Wigan",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153835233",
        "ip": "82.10.232.134",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 09:03:21",
        "isSmartClick": "0",
        "email": "susiepnicholson@uwclub.net",
        "name": "susie nicholson",
        "firstName": "susie",
        "lastName": "nicholson",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6704f55ab911ae7bc400b793",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276217469",
        "countryID": 233,
        "cityID": 2040,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14284589",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69364529,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Lier",
        "custom": null,
        "custom1": null,
        "custom2": "sacrilegiousness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153833212",
        "ip": "2a02:a03f:82ea:4900:e8af:60cc:e26:3f69",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:57:46",
        "isSmartClick": "0",
        "email": "simonne.devuyst@hotmail.com",
        "name": "Simonne Agnes Devuyst",
        "firstName": "Simonne Agnes",
        "lastName": "Devuyst",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12536",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276216190",
        "countryID": 23,
        "cityID": 3619,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 10:14:55",
        "depositID": "20413763",
        "conversionID": "1218645",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14284445",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69364094,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Stein",
        "custom": null,
        "custom1": null,
        "custom2": "interchaff.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153831926",
        "ip": "86.83.46.64",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:53:49",
        "isSmartClick": "0",
        "email": "w.stijnen@telfort.nl",
        "name": "Wil Stijnen",
        "firstName": "Wil",
        "lastName": "Stijnen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12535",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276215327",
        "countryID": 156,
        "cityID": 7113,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14284344",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69363445,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: movementdeperseds.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "153829929",
        "ip": "2a02:1808:180:c5ba::1",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:48:31",
        "isSmartClick": "0",
        "email": "senesael.joshi@gmail.com",
        "name": "joshi senesael",
        "firstName": "joshi",
        "lastName": "senesael",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12534",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276213992",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14284221",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69363217,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amersfoort",
        "custom": null,
        "custom1": null,
        "custom2": "tricarbimide.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153829163",
        "ip": "89.205.135.94",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:46:45",
        "isSmartClick": "0",
        "email": "tmottenhof@gmail.com",
        "name": "Toby Ottenhof ",
        "firstName": "Toby",
        "lastName": "Ottenhof ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12533",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276213535",
        "countryID": 156,
        "cityID": 1494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14284177",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69362926,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Pontoise",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153828209",
        "ip": "91.160.38.50",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 08:44:22",
        "isSmartClick": "0",
        "email": "madouda.delci@outook.ff",
        "name": "Madouda Delci",
        "firstName": "Madouda",
        "lastName": "Delci",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "madouda.delci@outook.ff",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276212952",
        "countryID": 75,
        "cityID": 26729,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69362497,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Soest",
        "custom": null,
        "custom1": null,
        "custom2": "remedilessness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153826797",
        "ip": "2a02:a420:238:513a:f910:cb6a:deb7:9b03",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:40:42",
        "isSmartClick": "0",
        "email": "Leovaders@gmail.com",
        "name": "Leo Vaders",
        "firstName": "Leo",
        "lastName": "Vaders",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12532",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276212060",
        "countryID": 156,
        "cityID": 6712,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14284064",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69362465,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Coevorden",
        "custom": null,
        "custom1": null,
        "custom2": "zanvoxa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153826723",
        "ip": "2a02:a447:a1a1:1:b13e:4d3e:e62f:6074",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:40:25",
        "isSmartClick": "0",
        "email": "frankprins13@gmail.com",
        "name": "Frank Prins",
        "firstName": "Frank",
        "lastName": "Prins",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12531",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276212005",
        "countryID": 156,
        "cityID": 20801,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14284053",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69361746,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: energyzersk.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "153824633",
        "ip": "194.78.58.41",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:33:59",
        "isSmartClick": "0",
        "email": "kellydebock79@gmail.com",
        "name": "kelly de bock",
        "firstName": "kelly",
        "lastName": "de bock",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12530",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276210602",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14283918",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69360325,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hoorn",
        "custom": null,
        "custom1": null,
        "custom2": "newmanism.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153820985",
        "ip": "94.208.250.70",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:19:26",
        "isSmartClick": "0",
        "email": "test111test@test.test",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12529",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276208009",
        "countryID": 156,
        "cityID": 3837,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69360158,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153820559",
        "ip": "2a01:e34:ecbf:4de0:2d3:4b90:acdd:f326",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 08:17:50",
        "isSmartClick": "0",
        "email": "somphet689@gmail.com",
        "name": "Somphet Ngonavichit",
        "firstName": "Somphet",
        "lastName": "Ngonavichit",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "b8407b53-080a-42d5-ba8a-81fd67807ea8",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "New",
        "saleStatusMapped": "Not Handled",
        "clickID": "276207695",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69359664,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "semiprotectorate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153819211",
        "ip": "109.36.224.212",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:14:21",
        "isSmartClick": "0",
        "email": "lilysomers54@gmail.com",
        "name": "Lily  Somers ",
        "firstName": "Lily ",
        "lastName": "Somers ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12528",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276206777",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69359441,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Maastricht",
        "custom": null,
        "custom1": null,
        "custom2": "mindforgepro.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "153818824",
        "ip": "2001:1c05:321f:8400:e981:7266:a315:2fdc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:13:19",
        "isSmartClick": "0",
        "email": "Hitz@ziggo.nl",
        "name": "Peter Hitz",
        "firstName": "Peter",
        "lastName": "Hitz",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12527",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276206484",
        "countryID": 156,
        "cityID": 6986,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14283410",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69358640,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Rennes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153816931",
        "ip": "2a02:8440:7143:d8fa:c480:34ff:feb3:a7ab",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 08:07:22",
        "isSmartClick": "0",
        "email": "elkadryhouria@gmail.com",
        "name": "Houria EL kadry",
        "firstName": "Houria",
        "lastName": "EL kadry",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "dfe757ad-7748-470e-ba45-88fda0ff12fb",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "New",
        "saleStatusMapped": "Not Handled",
        "clickID": "276205095",
        "countryID": 75,
        "cityID": 2952,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69358610,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Dordrecht",
        "custom": null,
        "custom1": null,
        "custom2": "leucocrate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153816865",
        "ip": "185.116.124.1",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:07:12",
        "isSmartClick": "0",
        "email": "emilvanleeuwen02@gmail.com",
        "name": "Emil van Leeuwen",
        "firstName": "Emil",
        "lastName": "van Leeuwen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12526",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276205060",
        "countryID": 156,
        "cityID": 5119,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14283206",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69357952,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "infrastapedial.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153815409",
        "ip": "89.205.227.18",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:03:21",
        "isSmartClick": "0",
        "email": "luikingafysio@gmail.com",
        "name": "Ja Luikinga ",
        "firstName": "Ja",
        "lastName": "Luikinga ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12525",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276203995",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 12:47:37",
        "depositID": "manual",
        "conversionID": "1218848",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14283045",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69357642,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rucphen",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153814468",
        "ip": "81.207.181.60",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:00:57",
        "isSmartClick": "0",
        "email": "biancateunissen66@outlook.com",
        "name": "Bianca Teunissen van Manen",
        "firstName": "Bianca",
        "lastName": "Teunissen van Manen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12524",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276203353",
        "countryID": 156,
        "cityID": 15865,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282942",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69357585,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "pituitousness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153814278",
        "ip": "109.38.144.10",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 08:00:14",
        "isSmartClick": "0",
        "email": "Marcoh@ziggo.nl",
        "name": "Marco Hatzmann",
        "firstName": "Marco",
        "lastName": "Hatzmann",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12523",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276203245",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 09:19:42",
        "depositID": "20413631",
        "conversionID": "1218554",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282926",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69357561,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Beuningen",
        "custom": null,
        "custom1": null,
        "custom2": "pituitousness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153814177",
        "ip": "2a02:a212:2900:7080:e1fe:7066:ae49:a5d6",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:59:47",
        "isSmartClick": "0",
        "email": "wim.leeflang@upcmail.nl",
        "name": "Wim Leeflang",
        "firstName": "Wim",
        "lastName": "Leeflang",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12522",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276203157",
        "countryID": 156,
        "cityID": 6685,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282916",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69357085,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Eindhoven",
        "custom": null,
        "custom1": null,
        "custom2": "pituitousness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153812947",
        "ip": "87.212.251.76",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:53:51",
        "isSmartClick": "0",
        "email": "bultbom@gmail.com",
        "name": "Frans Bult",
        "firstName": "Frans",
        "lastName": "Bult",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12521",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276202229",
        "countryID": 156,
        "cityID": 1279,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282813",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69356684,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153811814",
        "ip": "80.14.98.164",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 07:49:44",
        "isSmartClick": "0",
        "email": "raller-zarar@gmx.fr",
        "name": "elarbi lamloum",
        "firstName": "elarbi",
        "lastName": "lamloum",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "dc496312-e99c-44bd-bcef-bffbde3d8e99",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Call again",
        "saleStatusMapped": "Call again",
        "clickID": "276201466",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14282737",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69356562,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153811472",
        "ip": "2001:4c3c:6201:4300:81e3:8245:5723:6b8e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:48:06",
        "isSmartClick": "0",
        "email": "janenulla@caiway.nl",
        "name": "Jan Vroombout",
        "firstName": "Jan",
        "lastName": "Vroombout",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12520",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276201208",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282714",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69356476,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Alençon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153811237",
        "ip": "2a01:e0a:b33:b770:750d:7b08:518c:57b2",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-08 07:46:55",
        "isSmartClick": "0",
        "email": "gdiangouaya@gmail.com",
        "name": "DIANGOUAYA Gabriel",
        "firstName": "DIANGOUAYA",
        "lastName": "Gabriel",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "a3133596-0145-4441-81be-cb1f341c60a0",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "276201010",
        "countryID": 75,
        "cityID": 18824,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14282692",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69355930,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "rhizomorphoid.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153809647",
        "ip": "31.161.205.154",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:39:16",
        "isSmartClick": "0",
        "email": "Fikshet@gmail.com",
        "name": "Pascal Bongers",
        "firstName": "Pascal",
        "lastName": "Bongers",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12519",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276199763",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282581",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69355613,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Alphen aan den Rijn",
        "custom": null,
        "custom1": null,
        "custom2": "tricarbimide.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153808650",
        "ip": "188.89.202.175",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:34:32",
        "isSmartClick": "0",
        "email": "bert.enzerink@gmail.com",
        "name": "Bert Enzerink",
        "firstName": "Bert",
        "lastName": "Enzerink",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12518",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276199019",
        "countryID": 156,
        "cityID": 1537,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282529",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69355576,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "The Hague",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153808544",
        "ip": "2001:1c00:f16:c100:92b:584c:9921:c14",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:34:00",
        "isSmartClick": "0",
        "email": "Andjenie66@hotmail.com",
        "name": "An Sing",
        "firstName": "An",
        "lastName": "Sing",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12517",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276198932",
        "countryID": 156,
        "cityID": 921,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282524",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69354886,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Goes",
        "custom": null,
        "custom1": null,
        "custom2": "imperformable.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153806170",
        "ip": "2a02:a464:fef3:1:a05b:5f41:9d4a:8e01",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:23:24",
        "isSmartClick": "0",
        "email": "anitaleijs@gmail.com",
        "name": "Anita Leijs",
        "firstName": "Anita",
        "lastName": "Leijs",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12516",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276197178",
        "countryID": 156,
        "cityID": 2033,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 08:31:00",
        "depositID": "20413609",
        "conversionID": "1218483",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14282393",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69353959,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "d827d6b744",
        "affiliateID": "88048",
        "affiliateName": "Lexdg",
        "affiliateEmail": "lexdg@gmail2.com",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "dioptrically.com",
        "custom3": null,
        "custom4": "lexdg",
        "custom5": null,
        "leadRequestID": "153803460",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:11:06",
        "isSmartClick": "0",
        "email": "weklfklwefklklwe@gmail.com",
        "name": "fklewklfewkl fkewlkfwkl",
        "firstName": "fklewklfewkl",
        "lastName": "fkewlkfwkl",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12515",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276195293",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIALE EDITIE: Piet Adema heeft met succes het Meta Genesis platform voor Nederlandse burgers gelanceerd, dat al meer dan 12.000 deelnemers heeft geholpen om €80.000 te verdienen",
        "leadRequestOfferName": "Meta genesis Piet Adema",
        "leadRequestOfferWebsite": "Meta genesis Piet Adema",
        "brokerAutologinClick": "14282263",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69353603,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "lomviao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153802349",
        "ip": "143.178.64.238",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:05:44",
        "isSmartClick": "0",
        "email": "Mohamadhadouch10@gmail.com",
        "name": "Mohamad Hadouch",
        "firstName": "Mohamad",
        "lastName": "Hadouch",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12514",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276194527",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282206",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69353477,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Haarlem",
        "custom": null,
        "custom1": null,
        "custom2": "zomrao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153801933",
        "ip": "2a02:a457:7a7f:1:21bc:1b70:eb77:1c23",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:03:14",
        "isSmartClick": "0",
        "email": "A.h.pauwels@hotmail.com",
        "name": "Andre Pauwels",
        "firstName": "Andre",
        "lastName": "Pauwels",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12513",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276194273",
        "countryID": 156,
        "cityID": 494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282193",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69353285,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Leuven",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: flawlessdepered.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "153801362",
        "ip": "2a02:1811:d24:7e00:d980:fc2c:5edb:988c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 07:00:13",
        "isSmartClick": "0",
        "email": "ald.2002@yahoo.com",
        "name": "Loma ALjabery",
        "firstName": "Loma",
        "lastName": "ALjabery",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12512",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276193899",
        "countryID": 23,
        "cityID": 3675,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 09:20:32",
        "depositID": "20413623",
        "conversionID": "1218559",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14282156",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69352494,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153797846",
        "ip": "2a02:a213:323f:e800:d762:b0ce:222a:10d2",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 06:46:08",
        "isSmartClick": "0",
        "email": "waltervanballegooijen@gmail.com",
        "name": "Walter van Ballegooijen",
        "firstName": "Walter",
        "lastName": "van Ballegooijen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12511",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276191985",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14282081",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69347791,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zoetermeer",
        "custom": null,
        "custom1": null,
        "custom2": "zanrova.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153780840",
        "ip": "2a02:a451:cb5f:1:b867:f212:3a39:af3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 05:43:54",
        "isSmartClick": "0",
        "email": "mirandavanvliet.mvv@gmail.com",
        "name": "Miranda  van Vliet",
        "firstName": "Miranda ",
        "lastName": "van Vliet",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12510",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276182916",
        "countryID": 156,
        "cityID": 1565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14281837",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69345728,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Culiacán",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153774703",
        "ip": "200.68.184.21",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 05:20:53",
        "isSmartClick": "0",
        "email": "andreseguzman10@gmail.com",
        "name": "Andrés Eduardo Espinoza ",
        "firstName": "Andrés Eduardo",
        "lastName": "Espinoza ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "786017",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "276179314",
        "countryID": 143,
        "cityID": 1172,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14281744",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69340755,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Schiedam",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153749128",
        "ip": "62.45.64.237",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 04:24:06",
        "isSmartClick": "0",
        "email": "daen@treppon.nl",
        "name": "Daen Noppert",
        "firstName": "Daen",
        "lastName": "Noppert",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12509",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276170220",
        "countryID": 156,
        "cityID": 3781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14281600",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69339426,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Altotonga",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153742291",
        "ip": "2806:268:1401:7f8:21d1:e1dd:511f:92a5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 04:02:24",
        "isSmartClick": "0",
        "email": "garciangeldiego.72@gmail.com",
        "name": "Angel Diego Garcia",
        "firstName": "Angel",
        "lastName": "Diego Garcia",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785970",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "276167790",
        "countryID": 143,
        "cityID": 24717,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14281575",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69330112,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Iztapalapa",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153700347",
        "ip": "200.68.186.40",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 01:12:52",
        "isSmartClick": "0",
        "email": "alfredevaristou68@gmail.com",
        "name": "alfred Perez",
        "firstName": "alfred",
        "lastName": "Perez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785816",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276151854",
        "countryID": 143,
        "cityID": 3576,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14281326",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69329995,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Haarlem",
        "custom": null,
        "custom1": null,
        "custom2": "remissiveness.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153699703",
        "ip": "86.88.200.39",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 01:10:27",
        "isSmartClick": "0",
        "email": "Hannyhmal@gmail.com",
        "name": "Danny Smalburg",
        "firstName": "Danny",
        "lastName": "Smalburg",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12508",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276151632",
        "countryID": 156,
        "cityID": 494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14281322",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69328289,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Woerden",
        "custom": null,
        "custom1": null,
        "custom2": "albumenizer.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153689822",
        "ip": "77.167.95.227",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 00:42:12",
        "isSmartClick": "0",
        "email": "keesdekker1956@gmail.com",
        "name": "Kees Dekker ",
        "firstName": "Kees",
        "lastName": "Dekker ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12507",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276148928",
        "countryID": 156,
        "cityID": 6781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14281281",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69328024,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Zapopan",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153688422",
        "ip": "2806:2f0:5501:f8b9:5c3b:13cd:f530:dc0b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 00:38:40",
        "isSmartClick": "0",
        "email": "arimaygloss17@gmail.com",
        "name": "Maria Gloria  García ",
        "firstName": "Maria Gloria ",
        "lastName": "García ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785766",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276148559",
        "countryID": 143,
        "cityID": 4215,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14281273",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69327897,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153687603",
        "ip": "201.162.167.13",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 00:36:20",
        "isSmartClick": "0",
        "email": "Franciscovera.perez89@gmail.com",
        "name": "Francisco  Vera Pérez ",
        "firstName": "Francisco ",
        "lastName": "Vera Pérez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785761",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276148357",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14281271",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69326910,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Texcoco",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153680585",
        "ip": "2806:105e:1c:ae74:5877:d122:8030:5c28",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-08 00:15:40",
        "isSmartClick": "0",
        "email": "estradam91967@gmail.com",
        "name": "Miguel  Estrada",
        "firstName": "Miguel ",
        "lastName": "Estrada",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785727",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276146668",
        "countryID": 143,
        "cityID": 13081,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14281245",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69325986,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuautitlán Izcalli",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153674888",
        "ip": "201.145.52.199",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 23:55:58",
        "isSmartClick": "0",
        "email": "jportillo2025@gmail.com",
        "name": "Jorge Portillo",
        "firstName": "Jorge",
        "lastName": "Portillo",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jportillo2025@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276144833",
        "countryID": 143,
        "cityID": 3577,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69325711,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlaquepaque",
        "custom": null,
        "custom1": null,
        "custom2": "tubinares.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153673777",
        "ip": "2806:2f0:5001:dc9b:7cc2:f368:808:a389",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 23:50:19",
        "isSmartClick": "0",
        "email": "Rosales.sarabia.isa@gmail.com",
        "name": "Maria Rosales ",
        "firstName": "Maria",
        "lastName": "Rosales ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785698",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276144318",
        "countryID": 143,
        "cityID": 15180,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Mexico&#39;s National Bank sues Galilea Montijo for what she said on live TV. Everyone in Mexico should know the truth!",
        "leadRequestOfferName": "Bitcoineer Galilea Montijo-MX",
        "leadRequestOfferWebsite": "Bitcoineer Galilea Montijo-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69325247,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nunspeet",
        "custom": null,
        "custom1": null,
        "custom2": "mycomycete.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "153671833",
        "ip": "2a02:a213:9e81:780:2307:481c:f409:a7d2",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 23:41:26",
        "isSmartClick": "0",
        "email": "emorachid49@gmail.com",
        "name": "Rachid  Karniti ",
        "firstName": "Rachid ",
        "lastName": "Karniti ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12506",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276143468",
        "countryID": 156,
        "cityID": 2152,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14281183",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69325145,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mérida",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153671229",
        "ip": "200.68.172.182",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 23:39:31",
        "isSmartClick": "0",
        "email": "xavierpena8@gmail.com",
        "name": "Francisco Javier  Peña Llamas ",
        "firstName": "Francisco Javier ",
        "lastName": "Peña Llamas ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785687",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276143230",
        "countryID": 143,
        "cityID": 366,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69324386,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Middelharnis",
        "custom": null,
        "custom1": null,
        "custom2": "zomrao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153666633",
        "ip": "2a02:a44e:9e52:1:6554:afad:5620:677d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 23:22:44",
        "isSmartClick": "0",
        "email": "ckrgblok@gmail.com",
        "name": "Christiaan  Blok",
        "firstName": "Christiaan ",
        "lastName": "Blok",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12505",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276141553",
        "countryID": 156,
        "cityID": 6439,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14281150",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69323724,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Pachuca",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153664374",
        "ip": "2806:370:98bc:a930:e7a1:df54:ae7d:a41f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 23:05:46",
        "isSmartClick": "0",
        "email": "miguelangelmishi74@gmail.com",
        "name": "Miguel Ángel   Sánchez Cruz ",
        "firstName": "Miguel Ángel  ",
        "lastName": "Sánchez Cruz ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785641",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276140269",
        "countryID": 143,
        "cityID": 1187,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14281128",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69323111,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153662254",
        "ip": "201.166.178.102",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 22:49:18",
        "isSmartClick": "0",
        "email": "ibanp502@gmail.com",
        "name": "Iban  Perez",
        "firstName": "Iban ",
        "lastName": "Perez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785615",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276139185",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14281103",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69322505,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "jesuitry.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153660166",
        "ip": "2a02:a457:6ce0:1:2475:18f2:631d:4970",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 22:21:36",
        "isSmartClick": "0",
        "email": "wilvandooren@kpnmail.nl",
        "name": "Wil Van Dooren",
        "firstName": "Wil",
        "lastName": "Van Dooren",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12504",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276137889",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 11:31:00",
        "depositID": "20413798",
        "conversionID": "1218743",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14281043",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69321738,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Puebla City",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153657432",
        "ip": "200.68.170.11",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 21:47:22",
        "isSmartClick": "0",
        "email": "jeyne003@hotmail.com",
        "name": "JEYNE Lara Flores ",
        "firstName": "JEYNE",
        "lastName": "Lara Flores ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785520",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "276136263",
        "countryID": 143,
        "cityID": 329,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280983",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69321671,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153657200",
        "ip": "201.141.117.89",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 21:43:27",
        "isSmartClick": "0",
        "email": "veritogaray009@gmail.com",
        "name": "Veronica Vega ",
        "firstName": "Veronica",
        "lastName": "Vega ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785514",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276136108",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280973",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69321364,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153656311",
        "ip": "189.201.32.36",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 21:30:17",
        "isSmartClick": "0",
        "email": "julitojimenez099@gmail.com",
        "name": "Julio Cesar Jiménez ",
        "firstName": "Julio Cesar",
        "lastName": "Jiménez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785482",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276135492",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280934",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69321154,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "San Nicolás de los Garza",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153655453",
        "ip": "189.158.181.169",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 21:20:27",
        "isSmartClick": "0",
        "email": "medinacoronadoyolanda627@gmail.com",
        "name": "Yolanda  Medina coronado ",
        "firstName": "Yolanda ",
        "lastName": "Medina coronado ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785454",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276134994",
        "countryID": 143,
        "cityID": 8762,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280904",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69321025,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Celaya",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153654913",
        "ip": "187.187.196.155",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 21:15:23",
        "isSmartClick": "0",
        "email": "lopezbeto406@gmail.com",
        "name": "José Alberto   López Hernández ",
        "firstName": "José Alberto  ",
        "lastName": "López Hernández ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785434",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276134735",
        "countryID": 143,
        "cityID": 8940,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280885",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69320282,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153652529",
        "ip": "187.190.202.95",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 20:47:13",
        "isSmartClick": "0",
        "email": "mramoschuy@gmail.com",
        "name": "Chuy Mramos",
        "firstName": "Chuy",
        "lastName": "Mramos",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785349",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276133218",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280758",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69319896,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "General Escobedo",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153651188",
        "ip": "2806:109f:13:14a6:7d61:efe4:eba3:4800",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 20:30:12",
        "isSmartClick": "0",
        "email": "aracely821mty@gmail.com",
        "name": "Sanjuana Aracely Vazquez Gonzalez",
        "firstName": "Sanjuana Aracely",
        "lastName": "Vazquez Gonzalez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785303",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276132299",
        "countryID": 143,
        "cityID": 21933,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280691",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69319539,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Reynosa",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153650137",
        "ip": "2806:109f:1b:9610:2c8b:ef71:be78:b63a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 20:16:57",
        "isSmartClick": "0",
        "email": "molarconsuelo@gmail.com",
        "name": "Consuelo Molar",
        "firstName": "Consuelo",
        "lastName": "Molar",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785257",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276131518",
        "countryID": 143,
        "cityID": 8585,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280623",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69319167,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153649091",
        "ip": "2806:2f0:92e5:b4a4:185:8563:797:73a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 20:05:11",
        "isSmartClick": "0",
        "email": "samuelmejiabautista7@gmail.com",
        "name": "Samuel Mejia",
        "firstName": "Samuel",
        "lastName": "Mejia",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785185",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276130737",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280536",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69319155,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Juchitán de Zaragoza",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153649053",
        "ip": "177.241.93.210",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 20:04:45",
        "isSmartClick": "0",
        "email": "josemartines2257@gmail.com",
        "name": "Jose alberto  Martines olivar",
        "firstName": "Jose alberto ",
        "lastName": "Martines olivar",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785181",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276130714",
        "countryID": 143,
        "cityID": 3625,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280532",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318970,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mérida",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153648457",
        "ip": "2806:2f0:8041:cf84:d121:2910:ff0c:dde9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:58:52",
        "isSmartClick": "0",
        "email": "pallomo@hotmail.com",
        "name": "Roberto Antonio Palomares Diaz",
        "firstName": "Roberto Antonio",
        "lastName": "Palomares Diaz",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785132",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276130349",
        "countryID": 143,
        "cityID": 366,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280499",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318937,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "tubinares.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153648347",
        "ip": "201.131.132.126",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:57:40",
        "isSmartClick": "0",
        "email": "ingenieriaelectrica@siteur.gob.mx",
        "name": "Francisco Valenzuela Rodriguez",
        "firstName": "Francisco",
        "lastName": "Valenzuela Rodriguez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785110",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276130292",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Mexico&#39;s National Bank sues Galilea Montijo for what she said on live TV. Everyone in Mexico should know the truth!",
        "leadRequestOfferName": "Bitcoineer Galilea Montijo-MX",
        "leadRequestOfferWebsite": "Bitcoineer Galilea Montijo-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318850,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153647986",
        "ip": "2806:261:497:863:a150:2be8:3f5c:940b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:54:20",
        "isSmartClick": "0",
        "email": "Ventosjosr@gmail.com",
        "name": "Jose guadalupe  Landeros Ramirez ",
        "firstName": "Jose guadalupe ",
        "lastName": "Landeros Ramirez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "785058",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "276130088",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280471",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318474,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153646911",
        "ip": "2806:2f0:a4a0:c19a:f13:8500:27c3:a411",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:41:30",
        "isSmartClick": "0",
        "email": "cristhianmagallon00@gmail.com",
        "name": "SOFIA GIL GGONZALEz Gil Gonzalez ",
        "firstName": "SOFIA GIL GGONZALEz",
        "lastName": "Gil Gonzalez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784950",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276129325",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280360",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318405,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "San Luis Río Colorado",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153646634",
        "ip": "201.143.73.78",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:38:45",
        "isSmartClick": "0",
        "email": "hernandesjoe32@gmail.com",
        "name": "Alfredo Castro Alvarez",
        "firstName": "Alfredo",
        "lastName": "Castro Alvarez",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "hernandesjoe32@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276129134",
        "countryID": 143,
        "cityID": 1189,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318311,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Rincon de Romos",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153646297",
        "ip": "2806:2f0:61c1:f9dc:e1fe:57d9:d8aa:de85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:35:17",
        "isSmartClick": "0",
        "email": "bsantillanluevano@gmail.com",
        "name": "Brayan Bladimir  Santillan Luevano ",
        "firstName": "Brayan Bladimir ",
        "lastName": "Santillan Luevano ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784911",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "276128907",
        "countryID": 143,
        "cityID": 41245,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280330",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318291,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Lopez Mateos",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153646218",
        "ip": "2806:104e:25:84b8:cdcd:a89:3f0e:3b1b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:34:28",
        "isSmartClick": "0",
        "email": "jorgetapiarivas25@gmail.com",
        "name": "JORGE TAPIA RIVAS",
        "firstName": "JORGE",
        "lastName": "TAPIA RIVAS",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784907",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276128863",
        "countryID": 143,
        "cityID": 10069,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280326",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318287,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Iztapalapa",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153646202",
        "ip": "2806:106e:5:502b:1b5d:3fee:2a07:fc59",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:34:18",
        "isSmartClick": "0",
        "email": "karinapinky35@gmail.com",
        "name": "Elizabeth karina  Ponce Escamilla ",
        "firstName": "Elizabeth karina ",
        "lastName": "Ponce Escamilla ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784904",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276128853",
        "countryID": 143,
        "cityID": 3576,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280324",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69318227,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153646025",
        "ip": "189.201.85.149",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:32:50",
        "isSmartClick": "0",
        "email": "sergiotiradojuarez36@gmail.com",
        "name": "Sergio Tirado ",
        "firstName": "Sergio",
        "lastName": "Tirado ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784896",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276128749",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280313",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69317846,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Juárez",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153645000",
        "ip": "201.127.119.28",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:21:32",
        "isSmartClick": "0",
        "email": "javier.giner71@gmail.com",
        "name": "Javier arturo Giner yañez",
        "firstName": "Javier arturo",
        "lastName": "Giner yañez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784836",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "276127968",
        "countryID": 143,
        "cityID": 1364,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280210",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69317549,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Antwerp",
        "custom": null,
        "custom1": null,
        "custom2": "paunchful.com",
        "custom3": "Dutch",
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153644082",
        "ip": "91.182.154.169",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:11:21",
        "isSmartClick": "0",
        "email": "dhoremans@msn.com",
        "name": "Danny  Horemans",
        "firstName": "Danny ",
        "lastName": "Horemans",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12503",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276127325",
        "countryID": 23,
        "cityID": 18,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14280115",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69317501,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "San Pedro",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153643884",
        "ip": "200.68.140.70",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:09:57",
        "isSmartClick": "0",
        "email": "yiyidelhumeau2023@gmail.com",
        "name": "Jeanelle Delhumeau Islas",
        "firstName": "Jeanelle",
        "lastName": "Delhumeau Islas",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "195709",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "276127178",
        "countryID": 143,
        "cityID": 47201,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280106",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69317339,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153643424",
        "ip": "201.162.167.99",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 19:05:34",
        "isSmartClick": "0",
        "email": "Josealbertoalvarez@gmail.com",
        "name": "José Alvarez",
        "firstName": "José",
        "lastName": "Alvarez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784757",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276126797",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14280066",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316894,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuauhtemoc",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153642117",
        "ip": "201.162.169.193",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:52:30",
        "isSmartClick": "0",
        "email": "borracho7595@gmail.com",
        "name": "Cesar salvador  Hernández Ramírez ",
        "firstName": "Cesar salvador ",
        "lastName": "Hernández Ramírez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784699",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Unqualified",
        "saleStatusMapped": "Non Eligible",
        "clickID": "276125697",
        "countryID": 143,
        "cityID": 7743,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279966",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316836,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlaquepaque",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153641949",
        "ip": "2806:370:561a:b781:c5c9:2ea1:36f1:39e8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:51:05",
        "isSmartClick": "0",
        "email": "jairb7947@gmail.com",
        "name": "Jair  Bejar",
        "firstName": "Jair ",
        "lastName": "Bejar",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784691",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276125539",
        "countryID": 143,
        "cityID": 15180,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279948",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316637,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Orillia",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153641426",
        "ip": "208.124.255.194",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:46:01",
        "isSmartClick": "0",
        "email": "dahnahgag@gmail.com",
        "name": "Dahlia Nahimana",
        "firstName": "Dahlia",
        "lastName": "Nahimana",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "21e00f08540304ed86f0d7f0b3be22c2",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "shared3",
        "saleStatusMapped": "Not interested",
        "clickID": "276125068",
        "countryID": 40,
        "cityID": 5671,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14279899",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316573,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Zapopan",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153641272",
        "ip": "2806:102e:1e:8559:8856:30ea:c9fa:f70c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:43:47",
        "isSmartClick": "0",
        "email": "servicios.fiscales@yahoo.com.mx",
        "name": "Alberto Rivera Ayala",
        "firstName": "Alberto",
        "lastName": "Rivera Ayala",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784653",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276124892",
        "countryID": 143,
        "cityID": 4215,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279884",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316521,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153641132",
        "ip": "138.84.56.69",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:42:44",
        "isSmartClick": "0",
        "email": "yepeznahomi334@gmail.com",
        "name": "Lizeth Castro",
        "firstName": "Lizeth",
        "lastName": "Castro",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784646",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276124793",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279878",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316477,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153641022",
        "ip": "2806:107e:d:11d2:d961:c28c:d9b0:d17f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:41:38",
        "isSmartClick": "0",
        "email": "coriagomezm@gmail.com",
        "name": "María Lourdes  Coria Gómez ",
        "firstName": "María Lourdes ",
        "lastName": "Coria Gómez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784640",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276124683",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279869",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316182,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlaxcala City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153640306",
        "ip": "187.187.225.96",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:36:46",
        "isSmartClick": "0",
        "email": "mkopepe02092004@gmail.com",
        "name": "José Antonio  Mirón ",
        "firstName": "José Antonio ",
        "lastName": "Mirón ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784614",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276124073",
        "countryID": 143,
        "cityID": 879,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279829",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316184,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Iztapalapa",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153640307",
        "ip": "201.119.147.130",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:36:46",
        "isSmartClick": "0",
        "email": "ah6655380@hotmail.com",
        "name": "Alejandro Huerta",
        "firstName": "Alejandro",
        "lastName": "Huerta",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784615",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276124076",
        "countryID": 143,
        "cityID": 3576,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69316105,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Obregón",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153640116",
        "ip": "2806:2f0:21e1:fad3:cc30:49ec:77cd:1b25",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:35:53",
        "isSmartClick": "0",
        "email": "yesenialachica9@gmail.com",
        "name": "Yesenia Natali  Lachica Guerrero ",
        "firstName": "Yesenia Natali ",
        "lastName": "Lachica Guerrero ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784610",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276123937",
        "countryID": 143,
        "cityID": 6254,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279817",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69315820,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Reynosa",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153639336",
        "ip": "2806:2f0:4580:e7a2:407a:798f:fc54:ac31",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:32:56",
        "isSmartClick": "0",
        "email": "carolina.martinez2928@gmail.com",
        "name": "MARILYN CAROLINA MARTINEZ TELLO",
        "firstName": "MARILYN CAROLINA",
        "lastName": "MARTINEZ TELLO",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784595",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276123445",
        "countryID": 143,
        "cityID": 8585,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279777",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69314619,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Fresnillo",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153634967",
        "ip": "200.188.18.134",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:21:29",
        "isSmartClick": "0",
        "email": "samueljimenez1357@gmail.com",
        "name": "samuel Limatitla jimenez",
        "firstName": "samuel",
        "lastName": "Limatitla jimenez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784531",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276121019",
        "countryID": 143,
        "cityID": 3713,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279687",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69314123,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tijuana",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153632608",
        "ip": "2806:290:881e:1f7:e0ab:f336:34e9:ec4a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:17:52",
        "isSmartClick": "0",
        "email": "daeepole@gmail.com",
        "name": "Mak Diaz",
        "firstName": "Mak",
        "lastName": "Diaz",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784504",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276119810",
        "countryID": 143,
        "cityID": 1286,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279649",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69314089,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Linares",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153632425",
        "ip": "189.218.239.200",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:17:29",
        "isSmartClick": "0",
        "email": "ayalairmayolanda365@gmail.com",
        "name": "Irma Yolanda Ayala. Gordillo ",
        "firstName": "Irma Yolanda",
        "lastName": "Ayala. Gordillo ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ayalairmayolanda365@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276119748",
        "countryID": 143,
        "cityID": 65962,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69314017,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "San Nicolás de los Garza",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153632088",
        "ip": "2806:267:3424:872c:1569:d960:27ff:651a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:16:51",
        "isSmartClick": "0",
        "email": "normasotoaguirre@gmail.com",
        "name": "Norma Eugenia  Soto Aguirre ",
        "firstName": "Norma Eugenia ",
        "lastName": "Soto Aguirre ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784498",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276119530",
        "countryID": 143,
        "cityID": 8762,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279639",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69313583,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "San Luis Potosí City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153629888",
        "ip": "2806:2f0:64a0:f42f:4933:a4e7:306d:100e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:13:47",
        "isSmartClick": "0",
        "email": "chinacampos58@gmail.com",
        "name": "Aide Martinez ",
        "firstName": "Aide",
        "lastName": "Martinez ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "chinacampos58@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276118475",
        "countryID": 143,
        "cityID": 3565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69313288,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "overcull.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153628197",
        "ip": "81.19.216.252",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:12:05",
        "isSmartClick": "0",
        "email": "test0000@test.test",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12502",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276117726",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14279611",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69312739,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlaxcala City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153625657",
        "ip": "2806:10a6:1f:2c24:909e:2957:f4cb:73af",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:08:28",
        "isSmartClick": "0",
        "email": "Bofohuerta1@gmail.com",
        "name": "Adolfo  Huerta González ",
        "firstName": "Adolfo ",
        "lastName": "Huerta González ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784422",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276116437",
        "countryID": 143,
        "cityID": 879,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279574",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69312679,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Québec",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153625525",
        "ip": "199.243.187.172",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:08:17",
        "isSmartClick": "0",
        "email": "mlunlimitedwealth01@gmail.com",
        "name": "Yannick Leclerc",
        "firstName": "Yannick",
        "lastName": "Leclerc",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "5edf0d92e0fcefb235828620fc7bef23",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "shared3",
        "saleStatusMapped": "Not interested",
        "clickID": "276116372",
        "countryID": 40,
        "cityID": 728,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14279570",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69312524,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Iztapalapa",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153624945",
        "ip": "189.201.5.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:07:03",
        "isSmartClick": "0",
        "email": "vegarobindavid@gmail.com",
        "name": "David  Vega",
        "firstName": "David ",
        "lastName": "Vega",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784412",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276115983",
        "countryID": 143,
        "cityID": 3576,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279555",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69312367,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Toronto",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153624438",
        "ip": "142.116.5.151",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:06:12",
        "isSmartClick": "0",
        "email": "robsherman56@gmail.com",
        "name": "Rob Sherman",
        "firstName": "Rob",
        "lastName": "Sherman",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "561c453c76f7231af2ebc4296c8f1748",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "noanswer3",
        "saleStatusMapped": "No Answer",
        "clickID": "276115730",
        "countryID": 40,
        "cityID": 92,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14279545",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69311857,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153622438",
        "ip": "200.63.42.96",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:02:08",
        "isSmartClick": "0",
        "email": "Milpasjavier103@gmail.com",
        "name": "Javier Pineda",
        "firstName": "Javier",
        "lastName": "Pineda",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784373",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276114664",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279502",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69311814,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Torreon",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153622198",
        "ip": "187.244.57.201",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:01:40",
        "isSmartClick": "0",
        "email": "fane03@live.com.mx",
        "name": "Esteban  Alvarez",
        "firstName": "Esteban ",
        "lastName": "Alvarez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784369",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276114538",
        "countryID": 143,
        "cityID": 3551,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279498",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69311751,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tijuana",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153621910",
        "ip": "201.175.208.61",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:01:08",
        "isSmartClick": "0",
        "email": "jaimartinez302@gmail.com",
        "name": "Jaime Martinez Ramirez",
        "firstName": "Jaime",
        "lastName": "Martinez Ramirez",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jaimartinez302@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276114413",
        "countryID": 143,
        "cityID": 1286,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69311701,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tijuana",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153621705",
        "ip": "201.162.236.59",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 18:00:38",
        "isSmartClick": "0",
        "email": "manuelocamporoman@gmail.com",
        "name": "Manuel  Ocampo ronan",
        "firstName": "Manuel ",
        "lastName": "Ocampo ronan",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784363",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276114283",
        "countryID": 143,
        "cityID": 1286,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279487",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69310645,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Torreon",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153618271",
        "ip": "2806:267:1480:8144:b062:f1d5:365:d6bf",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:51:36",
        "isSmartClick": "0",
        "email": "aguilerajaneth94@gmail.com",
        "name": "Karla Janeth Alvarado Aguilera",
        "firstName": "Karla Janeth",
        "lastName": "Alvarado Aguilera",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784293",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276112188",
        "countryID": 143,
        "cityID": 3551,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279400",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69310499,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Calgary",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153617714",
        "ip": "2605:8d80:461:cbea:73d3:d1c5:6eb5:550d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:50:15",
        "isSmartClick": "0",
        "email": "ton.cynthia5@gmail.com",
        "name": "Cynthia Brennan",
        "firstName": "Cynthia",
        "lastName": "Brennan",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "2bbf9eb2bc14ee529e3a44b27780e0a5",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "callback",
        "saleStatusMapped": "Call again",
        "clickID": "276111877",
        "countryID": 40,
        "cityID": 36,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14279386",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69309539,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Chihuahua City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153615148",
        "ip": "2806:108e:15:b16:9918:5301:55e0:1fdc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:40:24",
        "isSmartClick": "0",
        "email": "joseescobar95745@gmail.com",
        "name": "Jose Alfredo  Escobar",
        "firstName": "Jose Alfredo ",
        "lastName": "Escobar",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784149",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276110085",
        "countryID": 143,
        "cityID": 3353,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279308",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69309477,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153614962",
        "ip": "201.131.129.3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:39:52",
        "isSmartClick": "0",
        "email": "zerimar642011@hotmail.com",
        "name": "Martín RAMIREZ ZEPEDA",
        "firstName": "Martín",
        "lastName": "RAMIREZ ZEPEDA",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784141",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276109975",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279297",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69309256,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153614334",
        "ip": "189.203.54.180",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:36:49",
        "isSmartClick": "0",
        "email": "juandede87@gmail.com",
        "name": "Juan de Dios  Suárez ",
        "firstName": "Juan de Dios ",
        "lastName": "Suárez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784116",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276109541",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279251",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69308623,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153612733",
        "ip": "201.162.227.214",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:29:12",
        "isSmartClick": "0",
        "email": "Mariaguadalupeisabeliuarezvazquez2@gmail.com",
        "name": "Isabel Suarez",
        "firstName": "Isabel",
        "lastName": "Suarez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784068",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276108345",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279172",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69308620,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Obregón",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153612730",
        "ip": "187.137.219.167",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:29:10",
        "isSmartClick": "0",
        "email": "delpardo10_02@hotmail.com",
        "name": "Marisol Del pardo",
        "firstName": "Marisol",
        "lastName": "Del pardo",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784067",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "276108343",
        "countryID": 143,
        "cityID": 6254,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279169",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69308470,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Arnhem",
        "custom": null,
        "custom1": null,
        "custom2": "quinquevalency.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153611982",
        "ip": "217.103.117.157",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:27:41",
        "isSmartClick": "0",
        "email": "ruud61kremer@gmail.com",
        "name": "Rudy Kremer",
        "firstName": "Rudy",
        "lastName": "Kremer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12501",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276108083",
        "countryID": 156,
        "cityID": 1365,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14279156",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69308422,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mérida",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153611768",
        "ip": "2806:370:826f:11b6:1:0:220b:7ea8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:26:56",
        "isSmartClick": "0",
        "email": "sanchemartinezfelipe373@gmail.com",
        "name": "Felipe  Sánchez Martínez ",
        "firstName": "Felipe ",
        "lastName": "Sánchez Martínez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "329822",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "276107937",
        "countryID": 143,
        "cityID": 366,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279145",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69308414,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tepic",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153611722",
        "ip": "2806:261:1481:8d76:517e:d983:f65b:40d1",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:26:52",
        "isSmartClick": "0",
        "email": "rosalvamorafigueroa@gmail.com",
        "name": "Rosalva  Mora",
        "firstName": "Rosalva ",
        "lastName": "Mora",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784048",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276107923",
        "countryID": 143,
        "cityID": 3571,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279142",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69308302,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "La Paz",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153611329",
        "ip": "2806:106e:2:5436:fcdd:7c2e:ff9f:6b78",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:26:01",
        "isSmartClick": "0",
        "email": "villamiljuan9@gmail.com",
        "name": "Juan manuel Villamil Castillo ",
        "firstName": "Juan manuel",
        "lastName": "Villamil Castillo ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784044",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276107745",
        "countryID": 143,
        "cityID": 2196,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279134",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69308219,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Xalapa",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153611120",
        "ip": "201.105.146.54",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:24:52",
        "isSmartClick": "0",
        "email": "yairogonzalez9@gmail.com",
        "name": "Jayro  Soto",
        "firstName": "Jayro ",
        "lastName": "Soto",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784036",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276107577",
        "countryID": 143,
        "cityID": 3552,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279121",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69308094,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Hermosillo",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153610752",
        "ip": "2806:263:c480:93f5:d1dd:50c7:ef23:f3d3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:23:30",
        "isSmartClick": "0",
        "email": "rigobertocampanacruz9@gmail.com",
        "name": "Rigoberto  Campaña",
        "firstName": "Rigoberto ",
        "lastName": "Campaña",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784026",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276107373",
        "countryID": 143,
        "cityID": 2595,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279107",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69307974,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tijuana",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153610409",
        "ip": "200.68.178.49",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:22:20",
        "isSmartClick": "0",
        "email": "ihyqe854@gmail.com",
        "name": "Israel  Hernández ",
        "firstName": "Israel ",
        "lastName": "Hernández ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784017",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276107183",
        "countryID": 143,
        "cityID": 1286,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279095",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69307853,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuernavaca",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153609864",
        "ip": "2806:104e:1b:1324:c52e:71fe:d772:d756",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:20:38",
        "isSmartClick": "0",
        "email": "angelicaabonza14@gmail.com",
        "name": "Angélica  Medina abonza",
        "firstName": "Angélica ",
        "lastName": "Medina abonza",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "784004",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276106895",
        "countryID": 143,
        "cityID": 8351,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279073",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69307579,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Chihuahua City",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153608822",
        "ip": "2806:370:313d:4ad4:c8a3:3da3:86ff:15d2",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:17:36",
        "isSmartClick": "0",
        "email": "franmon1951@gmail.com",
        "name": "Mireya  Ayal",
        "firstName": "Mireya ",
        "lastName": "Ayal",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783980",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276106415",
        "countryID": 143,
        "cityID": 3353,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14279041",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69307514,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Oud-Beijerland",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153608562",
        "ip": "77.251.106.71",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:16:52",
        "isSmartClick": "0",
        "email": "Eacvisser@hotmail.com",
        "name": "Arie Visser ",
        "firstName": "Arie",
        "lastName": "Visser ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12500",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276106320",
        "countryID": 156,
        "cityID": 6774,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14279038",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69306471,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Calgary",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153605084",
        "ip": "2605:b100:749:6e90:8541:3313:9510:ca0d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:04:35",
        "isSmartClick": "0",
        "email": "mantex@telus.net",
        "name": "Tom Guenther ",
        "firstName": "Tom",
        "lastName": "Guenther ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "mantex@telus.net",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276104277",
        "countryID": 40,
        "cityID": 36,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69306455,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153605032",
        "ip": "138.84.54.14",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:04:25",
        "isSmartClick": "0",
        "email": "ch4558648@gmail.com",
        "name": "Clemente Hernández ",
        "firstName": "Clemente",
        "lastName": "Hernández ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783878",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276104249",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278876",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69306420,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "San Luis Río Colorado",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153604888",
        "ip": "2806:250:205:bf01:f00b:ee3d:a6a4:a2da",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:04:08",
        "isSmartClick": "0",
        "email": "julioperez301422@gmail.com",
        "name": "Julio Perez",
        "firstName": "Julio",
        "lastName": "Perez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783875",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276104187",
        "countryID": 143,
        "cityID": 1189,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278870",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69306409,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Berlaar",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: flawlessdepered.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "153604836",
        "ip": "2a02:1808:1:23b4::1",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:04:01",
        "isSmartClick": "0",
        "email": "Joe.tattoo666@gmail.com",
        "name": "Joeri De backer",
        "firstName": "Joeri",
        "lastName": "De backer",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "525412",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "Recent_NA",
        "saleStatusMapped": null,
        "clickID": "276104143",
        "countryID": 23,
        "cityID": 22092,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14278868",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69306400,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Torreon",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153604819",
        "ip": "2806:2f0:3141:f272:501b:f398:12f2:ff21",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:03:56",
        "isSmartClick": "0",
        "email": "diazluna1509@gmail.com",
        "name": "Brenda Guadalupe  Diaz luna",
        "firstName": "Brenda Guadalupe ",
        "lastName": "Diaz luna",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783873",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276104142",
        "countryID": 143,
        "cityID": 3551,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278865",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69306251,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Querétaro City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153604366",
        "ip": "200.68.146.171",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:02:27",
        "isSmartClick": "0",
        "email": "zavala00m@gmail.com",
        "name": "Miguel  García Zavala ",
        "firstName": "Miguel ",
        "lastName": "García Zavala ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783863",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276103896",
        "countryID": 143,
        "cityID": 1250,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278847",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69306110,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153603903",
        "ip": "187.190.160.50",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 17:00:55",
        "isSmartClick": "0",
        "email": "vicameale@gmail.com",
        "name": "Alejandra Marisol Gutierrez Reyes",
        "firstName": "Alejandra Marisol",
        "lastName": "Gutierrez Reyes",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783854",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Unqualified",
        "saleStatusMapped": "Non Eligible",
        "clickID": "276103633",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278820",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69305567,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Minatitlán",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153602379",
        "ip": "2806:10ae:5:2596:c9e8:f8c4:a372:6dc0",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:54:47",
        "isSmartClick": "0",
        "email": "cruzjimenezcarlosmanuel7@gmail.com",
        "name": "Carlos Manuel Cruz Jiménez",
        "firstName": "Carlos Manuel",
        "lastName": "Cruz Jiménez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783804",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276102616",
        "countryID": 143,
        "cityID": 13040,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69305084,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Córdoba",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153600743",
        "ip": "2806:10a6:16:4c50:a94d:9c76:528b:cac8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:49:00",
        "isSmartClick": "0",
        "email": "rq570384@gmail.com",
        "name": "Rafael Quevedo",
        "firstName": "Rafael",
        "lastName": "Quevedo",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "rq570384@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276101656",
        "countryID": 143,
        "cityID": 10191,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69305086,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Hermosillo",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153600744",
        "ip": "189.197.66.66",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:48:56",
        "isSmartClick": "0",
        "email": "cmlm98.cl@gmail.com",
        "name": "carlos manuel lopez murrieta",
        "firstName": "carlos manuel",
        "lastName": "lopez murrieta",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783744",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276101651",
        "countryID": 143,
        "cityID": 2595,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278667",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69305059,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tuxtla Gutiérrez",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153600676",
        "ip": "2806:10ae:f:2393:c08c:7783:ef1b:8ff0",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:48:31",
        "isSmartClick": "0",
        "email": "ruizjavier0816@gmail.com",
        "name": "Carmen Javier Ruiz Navarro",
        "firstName": "Carmen Javier",
        "lastName": "Ruiz Navarro",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783738",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276101600",
        "countryID": 143,
        "cityID": 1266,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278659",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69304331,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Puebla City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153597922",
        "ip": "187.157.44.108",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:40:16",
        "isSmartClick": "0",
        "email": "mdcmagana.50@gmaol.com",
        "name": "MARIA DEL CARMEN MAGAÑA MAGAÑA",
        "firstName": "MARIA DEL CARMEN",
        "lastName": "MAGAÑA MAGAÑA",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783680",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276100214",
        "countryID": 143,
        "cityID": 329,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278568",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69303849,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Venlo",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153596378",
        "ip": "188.88.129.49",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:35:16",
        "isSmartClick": "0",
        "email": "rayatavakoli2000@gmail.com",
        "name": "Mojtaba Tavakoli",
        "firstName": "Mojtaba",
        "lastName": "Tavakoli",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12499",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276099474",
        "countryID": 156,
        "cityID": 629,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14278465",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69303442,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Nezahualcoyotl",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153594736",
        "ip": "200.63.43.134",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:30:20",
        "isSmartClick": "0",
        "email": "armandom22m@gmail.com",
        "name": "Armando  Hernández ",
        "firstName": "Armando ",
        "lastName": "Hernández ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783607",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276098720",
        "countryID": 143,
        "cityID": 7194,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278401",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69303375,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Boca del Rio",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153594390",
        "ip": "2806:10a6:12:b88:708b:78c6:8079:2323",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:29:31",
        "isSmartClick": "0",
        "email": "ro847084@gmail.com",
        "name": "Renan  Ortega ",
        "firstName": "Renan ",
        "lastName": "Ortega ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783601",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276098601",
        "countryID": 143,
        "cityID": 9624,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278391",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69303339,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Boekel",
        "custom": null,
        "custom1": null,
        "custom2": "zanrova.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153594125",
        "ip": "2a00:bba0:1128:2700:a18c:9c2b:14e7:d2f3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:29:13",
        "isSmartClick": "0",
        "email": "Gooijers@gooijers.nl",
        "name": "Ton Gooijers",
        "firstName": "Ton",
        "lastName": "Gooijers",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12498",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276098545",
        "countryID": 156,
        "cityID": 24540,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14278387",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69303244,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153593795",
        "ip": "86.92.6.220",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:27:59",
        "isSmartClick": "0",
        "email": "Zorroverkley@hotmail.nl",
        "name": "Zacharias  Verkleij",
        "firstName": "Zacharias ",
        "lastName": "Verkleij",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12497",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276098369",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14278373",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69302972,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153592501",
        "ip": "201.162.243.223",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:24:46",
        "isSmartClick": "0",
        "email": "ignacio89@hotmail.com",
        "name": "Ignacio Rojas Trujillo",
        "firstName": "Ignacio",
        "lastName": "Rojas Trujillo",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783565",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276097853",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278336",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69302545,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Wilrijk",
        "custom": null,
        "custom1": null,
        "custom2": "sacrilegiousness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153590790",
        "ip": "2a02:a03f:8495:f201:4c35:3cef:7792:e26",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:19:29",
        "isSmartClick": "0",
        "email": "Triarso-nelly@hotmail.com",
        "name": "nelly triarso",
        "firstName": "nelly",
        "lastName": "triarso",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12496",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276097189",
        "countryID": 23,
        "cityID": 562,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14278268",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69302184,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153589396",
        "ip": "189.203.54.113",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:15:29",
        "isSmartClick": "0",
        "email": "danielviguerasv.1872@gmail.com",
        "name": "Daniel  Vigueras ",
        "firstName": "Daniel ",
        "lastName": "Vigueras ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783492",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276096569",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278210",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69301983,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "San Luis Potosí City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153588652",
        "ip": "2806:2f0:64a0:f42f:4933:a4e7:306d:100e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:12:54",
        "isSmartClick": "0",
        "email": "chinacampos58@gmail.com",
        "name": "Aide  Martinez Campos",
        "firstName": "Aide ",
        "lastName": "Martinez Campos",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783479",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276096226",
        "countryID": 143,
        "cityID": 3565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278177",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69301934,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuauhtemoc",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153588501",
        "ip": "189.146.139.8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:12:14",
        "isSmartClick": "0",
        "email": "vpmath89@gmail.com",
        "name": "Viridiana  perez marquez ",
        "firstName": "Viridiana ",
        "lastName": "perez marquez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783470",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "276096134",
        "countryID": 143,
        "cityID": 7743,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278168",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69301661,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Zapopan",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153587442",
        "ip": "2806:310:108:87ac:8515:ad5e:fe5c:4464",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 16:08:07",
        "isSmartClick": "0",
        "email": "alejanbrorosas3456@gmail.com",
        "name": "Jesus santiago Rosas",
        "firstName": "Jesus santiago",
        "lastName": "Rosas",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783441",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "276095572",
        "countryID": 143,
        "cityID": 4215,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14278122",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69300745,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "León",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153583796",
        "ip": "2806:264:5487:14a7:de62:3b76:fc79:5772",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:56:09",
        "isSmartClick": "0",
        "email": "diegoarturohernandezsalas02@gmail.com",
        "name": "Diego Arturo  Hernández salas ",
        "firstName": "Diego Arturo ",
        "lastName": "Hernández salas ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "diegoarturohernandezsalas02@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276093763",
        "countryID": 143,
        "cityID": 3398,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69300618,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "d827d6b744",
        "affiliateID": "88048",
        "affiliateName": "Lexdg",
        "affiliateEmail": "lexdg@gmail2.com",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "guayroto.com",
        "custom3": null,
        "custom4": "lexdg",
        "custom5": null,
        "leadRequestID": "153583287",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:54:43",
        "isSmartClick": "0",
        "email": "testetste@wrtrgdwrterg4terg.com",
        "name": "tstetset testetstetes",
        "firstName": "tstetset",
        "lastName": "testetstetes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12495",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276093513",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIALE EDITIE: Renze Klamer heeft met succes het Meta Genesis platform voor Nederlandse burgers gelanceerd, dat al meer dan 12.000 deelnemers heeft geholpen om €80.000 te verdienen",
        "leadRequestOfferName": "Meta genesis Renze Klamer",
        "leadRequestOfferWebsite": "Meta genesis Renze Klamer",
        "brokerAutologinClick": "14277956",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69300095,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuernavaca",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153581349",
        "ip": "2806:2f0:9081:8e0f:a535:ada5:57af:350",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:49:01",
        "isSmartClick": "0",
        "email": "fsadurni@gmail.com",
        "name": "Francisco  Sadurni ",
        "firstName": "Francisco ",
        "lastName": "Sadurni ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783279",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276092587",
        "countryID": 143,
        "cityID": 8351,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14277848",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69299930,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mérida",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153580691",
        "ip": "2806:10be:9:2e8a:90da:9d86:6530:1821",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:46:58",
        "isSmartClick": "0",
        "email": "firazoqui82@yahoo.com",
        "name": "Felipe Irazoqui ",
        "firstName": "Felipe",
        "lastName": "Irazoqui ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783262",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276092275",
        "countryID": 143,
        "cityID": 366,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14277797",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69299601,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "albumenizer.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153579540",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:43:37",
        "isSmartClick": "0",
        "email": "testt789ooiuyt@gmail.test",
        "name": "testt789ooiuyt testt789ooiuyt",
        "firstName": "testt789ooiuyt",
        "lastName": "testt789ooiuyt",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12494",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276091710",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14277730",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69299431,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "sacrilegiousness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153578875",
        "ip": "178.51.94.187",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:42:05",
        "isSmartClick": "0",
        "email": "huseyinresat.ARslan1976@hotmail.com",
        "name": "Arslan Resat",
        "firstName": "Arslan",
        "lastName": "Resat",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12493",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276091420",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14277698",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69298986,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Los Mochis",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153576967",
        "ip": "189.195.138.182",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:37:02",
        "isSmartClick": "0",
        "email": "alexisburgos508@gmail.com",
        "name": "Alexis  Burgos Leon",
        "firstName": "Alexis ",
        "lastName": "Burgos Leon",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783136",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276090503",
        "countryID": 143,
        "cityID": 3707,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14277628",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69298595,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "tubinares.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153575925",
        "ip": "200.68.139.88",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:33:30",
        "isSmartClick": "0",
        "email": "marcostellechea60@gmail.com",
        "name": "MARCO ANTONIO TELLECHEA ENRÍQUEZ",
        "firstName": "MARCO ANTONIO",
        "lastName": "TELLECHEA ENRÍQUEZ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "783085",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "276089849",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Mexico&#39;s National Bank sues Galilea Montijo for what she said on live TV. Everyone in Mexico should know the truth!",
        "leadRequestOfferName": "Bitcoineer Galilea Montijo-MX",
        "leadRequestOfferWebsite": "Bitcoineer Galilea Montijo-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69298566,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "The Hague",
        "custom": null,
        "custom1": null,
        "custom2": "unsatisfiedness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153575841",
        "ip": "85.145.159.156",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:33:12",
        "isSmartClick": "0",
        "email": "jrwhite4y@gmail.com",
        "name": "John White",
        "firstName": "John",
        "lastName": "White",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12492",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276089797",
        "countryID": 156,
        "cityID": 921,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14277554",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69298093,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heerhugowaard",
        "custom": null,
        "custom1": null,
        "custom2": "interchaff.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153574211",
        "ip": "2a02:a465:f8c8:1:4cbc:f4e2:bf6b:7d46",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:28:21",
        "isSmartClick": "0",
        "email": "nolte9@xs4sall.nl",
        "name": "Jan Nolte ",
        "firstName": "Jan",
        "lastName": "Nolte ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12491",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276088839",
        "countryID": 156,
        "cityID": 6092,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14277483",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69297575,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Obregón",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153572534",
        "ip": "2806:1016:a:317:385a:b70b:f953:1173",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:23:54",
        "isSmartClick": "0",
        "email": "josecarlos.m47.jc@gmail.com",
        "name": "José Carlos  Miramontes ",
        "firstName": "José Carlos ",
        "lastName": "Miramontes ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "782992",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "FTD",
        "saleStatusMapped": "Converted",
        "clickID": "276087866",
        "countryID": 143,
        "cityID": 6254,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 06:52:00",
        "depositID": "782992",
        "conversionID": "1218414",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14277433",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69297523,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hardenberg",
        "custom": null,
        "custom1": null,
        "custom2": "interchaff.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153572380",
        "ip": "84.85.48.22",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:23:05",
        "isSmartClick": "0",
        "email": "paquot17@hotmail.com",
        "name": "Tony Paquot",
        "firstName": "Tony",
        "lastName": "Paquot",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "524812",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "NoAnswer",
        "saleStatusMapped": "No Answer",
        "clickID": "276087729",
        "countryID": 156,
        "cityID": 2435,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14277418",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69297384,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153571959",
        "ip": "200.63.46.41",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:22:01",
        "isSmartClick": "0",
        "email": "cristinapedrazar.27@gmail.com",
        "name": "Cristina Pedraza",
        "firstName": "Cristina",
        "lastName": "Pedraza",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "782977",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "276087519",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14277399",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69296881,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ecatepec",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153570415",
        "ip": "2806:2f0:a1c1:e3cf:a028:957c:b62a:248d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:17:21",
        "isSmartClick": "0",
        "email": "urielcaravarin4@gmail.com",
        "name": "Brandon Uriel  Cruz caravarin ",
        "firstName": "Brandon Uriel ",
        "lastName": "Cruz caravarin ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "782939",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276086585",
        "countryID": 143,
        "cityID": 17641,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14277348",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69296305,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "interchaff.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153568799",
        "ip": "2001:4c3c:104:400:d9a8:9e37:4764:59aa",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:11:51",
        "isSmartClick": "0",
        "email": "bloklandclifford@gmail.com",
        "name": "Clifford Blokland",
        "firstName": "Clifford",
        "lastName": "Blokland",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12490",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276085564",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-08 13:22:24",
        "depositID": "manual",
        "conversionID": "1218882",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14277253",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69295702,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Beuningen",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153567038",
        "ip": "2a02:a212:2901:a600:f3dd:aea5:886d:c7b4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:05:08",
        "isSmartClick": "0",
        "email": "lm.1961@hotmail.com",
        "name": "Leo Muller",
        "firstName": "Leo",
        "lastName": "Muller",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12489",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276084305",
        "countryID": 156,
        "cityID": 6685,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-07 15:55:00",
        "depositID": "20412824",
        "conversionID": "1218170",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14277154",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69295434,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "rhodorhiza.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153566340",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 15:01:47",
        "isSmartClick": "0",
        "email": "test67897867@gmail.com",
        "name": "test67897867 test67897867",
        "firstName": "test67897867",
        "lastName": "test67897867",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12488",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276083729",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69294280,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "d827d6b744",
        "affiliateID": "88048",
        "affiliateName": "Lexdg",
        "affiliateEmail": "lexdg@gmail2.com",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "restorationist.net",
        "custom3": null,
        "custom4": "lexdg",
        "custom5": null,
        "leadRequestID": "153563228",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 14:47:41",
        "isSmartClick": "0",
        "email": "test@gmail.test",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12487",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276081235",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIALE EDITIE: Piet Adema heeft met succes het Meta Genesis platform voor Nederlandse burgers gelanceerd, dat al meer dan 12.000 deelnemers heeft geholpen om €80.000 te verdienen",
        "leadRequestOfferName": "Meta genesis Piet Adema",
        "leadRequestOfferWebsite": "Meta genesis Piet Adema",
        "brokerAutologinClick": "14276878",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69294132,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "IJsselstein",
        "custom": null,
        "custom1": null,
        "custom2": "zanrova.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153562861",
        "ip": "2a02:a45a:c9e8:1:b944:ca23:d07c:8797",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 14:46:03",
        "isSmartClick": "0",
        "email": "jan.mirjana@hotmail.com",
        "name": "Jan Van den Berg ",
        "firstName": "Jan",
        "lastName": "Van den Berg ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12486",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276080918",
        "countryID": 156,
        "cityID": 1345,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14276859",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69292588,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "jesuitry.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153558548",
        "ip": "2a02:a44f:9721:1:5112:e565:6b26:af4c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 14:28:55",
        "isSmartClick": "0",
        "email": "jacquesvisser@hotmail.com",
        "name": "Jacques Visser",
        "firstName": "Jacques",
        "lastName": "Visser",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12485",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276077885",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14276635",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69291614,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "d827d6b744",
        "affiliateID": "88048",
        "affiliateName": "Lexdg",
        "affiliateEmail": "lexdg@gmail2.com",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "restorationist.net",
        "custom3": null,
        "custom4": "lexdg",
        "custom5": null,
        "leadRequestID": "153555846",
        "ip": "2a09:bac5:4e25:1478::20a:9a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 14:17:59",
        "isSmartClick": "0",
        "email": "testetsetest@efsadfesddfv.com",
        "name": "tetsetsete etstetsetes",
        "firstName": "tetsetsete",
        "lastName": "etstetsetes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12484",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276076084",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIALE EDITIE: Piet Adema heeft met succes het Meta Genesis platform voor Nederlandse burgers gelanceerd, dat al meer dan 12.000 deelnemers heeft geholpen om €80.000 te verdienen",
        "leadRequestOfferName": "Meta genesis Piet Adema",
        "leadRequestOfferWebsite": "Meta genesis Piet Adema",
        "brokerAutologinClick": "14276498",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69290854,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Goes",
        "custom": null,
        "custom1": null,
        "custom2": "quinquevalency.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153553403",
        "ip": "2a02:a46d:d12:0:dc58:336d:ffb:9d1d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 14:08:14",
        "isSmartClick": "0",
        "email": "cejo100252200249@outlook.com",
        "name": "Johan en Ceciel Asselman- Jonkheijm",
        "firstName": "Johan en Ceciel",
        "lastName": "Asselman- Jonkheijm",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12483",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276074564",
        "countryID": 156,
        "cityID": 2033,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-07 15:54:00",
        "depositID": "20412825",
        "conversionID": "1218171",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14276421",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69290734,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Winsum",
        "custom": null,
        "custom1": null,
        "custom2": "hydrometallurgically.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153553075",
        "ip": "84.105.156.107",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 14:06:47",
        "isSmartClick": "0",
        "email": "erikbianca@ziggo.nl",
        "name": "Erik Tuitman",
        "firstName": "Erik",
        "lastName": "Tuitman",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12482",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276074320",
        "countryID": 156,
        "cityID": 6702,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14276398",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69289072,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hilversum",
        "custom": null,
        "custom1": null,
        "custom2": "ranizo.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153548290",
        "ip": "2a02:a460:6271:0:c896:534:7efb:e6bb",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 13:47:27",
        "isSmartClick": "0",
        "email": "f.zuijdervliet@mediahuis.nl",
        "name": "Fred Zuijdervliet ",
        "firstName": "Fred",
        "lastName": "Zuijdervliet ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12481",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276071241",
        "countryID": 156,
        "cityID": 1556,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14276172",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69288901,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "'s-Hertogenbosch",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153547735",
        "ip": "84.35.86.226",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 13:45:03",
        "isSmartClick": "0",
        "email": "j.waterschoot3@upcmail.nl",
        "name": "Johan Waterschoot",
        "firstName": "Johan",
        "lastName": "Waterschoot",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12480",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276070906",
        "countryID": 156,
        "cityID": 2150,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14276147",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69287942,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanrova.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153541942",
        "ip": "109.36.236.32",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 13:34:19",
        "isSmartClick": "0",
        "email": "jozevdn@gmail.com",
        "name": "Jozé van de Nostrum",
        "firstName": "Jozé",
        "lastName": "van de Nostrum",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12479",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276068880",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14275994",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69286750,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Halfweg",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153536153",
        "ip": "38.153.122.134",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 13:22:34",
        "isSmartClick": "0",
        "email": "z.baltes@hotmail.com",
        "name": "Z. Reyes",
        "firstName": "Z.",
        "lastName": "Reyes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12478",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "276066469",
        "countryID": 156,
        "cityID": 15590,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14275831",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69284749,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanrova.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153526823",
        "ip": "89.205.133.9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 13:05:05",
        "isSmartClick": "0",
        "email": "peterfolkner@hotmail.com",
        "name": "Peter  Folkner",
        "firstName": "Peter ",
        "lastName": "Folkner",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12477",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276062662",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14275541",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69284385,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tilburg",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153525398",
        "ip": "2001:1c03:5b0e:b300:5827:a244:8aa3:944c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 13:02:27",
        "isSmartClick": "0",
        "email": "tonnystofmeel@gnail.com",
        "name": "Tonny Stofmeel",
        "firstName": "Tonny",
        "lastName": "Stofmeel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12476",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276062091",
        "countryID": 156,
        "cityID": 235,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14275488",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69283851,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heemstede",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153523371",
        "ip": "2001:1c04:912:c00:5dc0:d1a3:9583:ebb5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 12:57:28",
        "isSmartClick": "0",
        "email": "ericbosveld2@gmail.com",
        "name": "eric bosveld",
        "firstName": "eric",
        "lastName": "bosveld",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12475",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "276061144",
        "countryID": 156,
        "cityID": 6725,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-07 15:30:00",
        "depositID": "20412715",
        "conversionID": "1218148",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14275427",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69279813,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "mercenarily.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153509199",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 12:23:55",
        "isSmartClick": "0",
        "email": "test7678908765@gmail.test",
        "name": "test7678908765 test7678908765",
        "firstName": "test7678908765",
        "lastName": "test7678908765",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12474",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "276054188",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14274913",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69278277,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Manchester",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153504071",
        "ip": "2a00:23ee:2080:dc59:a488:f3ce:9af9:e366",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 12:09:26",
        "isSmartClick": "0",
        "email": "joe.albert.allen@gmail.com",
        "name": "Joe Allen",
        "firstName": "Joe",
        "lastName": "Allen",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6703cf775bc7d7ca4601c1e3",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276051600",
        "countryID": 233,
        "cityID": 452,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69277908,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Croydon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153502791",
        "ip": "82.132.228.7",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 12:06:19",
        "isSmartClick": "0",
        "email": "t_anwar60@yahoo.co.uk",
        "name": "TARIQ ANWAR",
        "firstName": "TARIQ",
        "lastName": "ANWAR",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6703cebcf1899dc33700ff93",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276051036",
        "countryID": 233,
        "cityID": 221,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14274650",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69277411,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Groningen",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153501216",
        "ip": "2001:1c01:4216:9e00:b1bc:3bd4:7e55:56b9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 12:02:04",
        "isSmartClick": "0",
        "email": "ronald.pen18@gmail.com",
        "name": "Ronald Pen",
        "firstName": "Ronald",
        "lastName": "Pen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12473",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276050200",
        "countryID": 156,
        "cityID": 1750,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14274567",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69276281,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanrova.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153497120",
        "ip": "2a02:a473:f787:1:7508:787b:6b9d:d8b9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 11:51:57",
        "isSmartClick": "0",
        "email": "shaike@hotmail.com",
        "name": "Romeo Ramkhelawan",
        "firstName": "Romeo",
        "lastName": "Ramkhelawan",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12472",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276048184",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14274424",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69276148,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Doetinchem",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153496502",
        "ip": "2a02:a450:4f19:1:10ad:594e:701f:eb79",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 11:50:59",
        "isSmartClick": "0",
        "email": "Blentinkmp@gmail.com",
        "name": "Fenneke  Steinvoort ",
        "firstName": "Fenneke ",
        "lastName": "Steinvoort ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12471",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276047900",
        "countryID": 156,
        "cityID": 125,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14274415",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69275023,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153492280",
        "ip": "148.252.145.240",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 11:39:15",
        "isSmartClick": "0",
        "email": "johnathan.leverton1998@gmail.com",
        "name": "Johnathan Leverton",
        "firstName": "Johnathan",
        "lastName": "Leverton",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6703c8640c14c3e3f70fb5e3",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Low Quality",
        "saleStatusMapped": "No interest",
        "clickID": "276045828",
        "countryID": 233,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14274249",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69275006,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Swindon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153492215",
        "ip": "77.95.179.67",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 11:39:09",
        "isSmartClick": "0",
        "email": "jskandola1970@gmail.com",
        "name": "Jarnail Kandola",
        "firstName": "Jarnail",
        "lastName": "Kandola",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6703c85e972f4a94d70ae8c3",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "276045807",
        "countryID": 233,
        "cityID": 599,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14274247",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69272067,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Scicli",
        "custom": "KMrZQ9bkLyVGYxql5n2WNBOnKGkKw7eJ1PogO6mBdajzvEAp3",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153481220",
        "ip": "185.155.122.123",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 11:13:15",
        "isSmartClick": "0",
        "email": "federicabistolfi1601@libero.it",
        "name": "Federica Bistolfi",
        "firstName": "Federica",
        "lastName": "Bistolfi",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "K9rbWxqOYkBgav0eJLGmz899LP628Zo5n1EdVPQ26743RAylj",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276040542",
        "countryID": 109,
        "cityID": 45418,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14273773",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69271178,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Harringay",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153478028",
        "ip": "82.36.114.211",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 11:06:00",
        "isSmartClick": "0",
        "email": "jcq.graham@gmail.com",
        "name": "Jacqui Graham",
        "firstName": "Jacqui",
        "lastName": "Graham",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6703c099f0179905f302eb73",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276038903",
        "countryID": 233,
        "cityID": 1321,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14273661",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69270456,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Bloemendaal",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153475534",
        "ip": "2001:1c04:480d:4700:255b:9cc8:8cb9:7b9a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 11:00:14",
        "isSmartClick": "0",
        "email": "ceesmgdevries@gmail.com",
        "name": "cees de vries",
        "firstName": "cees",
        "lastName": "de vries",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12470",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276037745",
        "countryID": 156,
        "cityID": 18995,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14273562",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69268875,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153471256",
        "ip": "185.69.145.132",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:51:38",
        "isSmartClick": "0",
        "email": "tracygleeson@btinternet.com",
        "name": "Tracy Gleeson",
        "firstName": "Tracy",
        "lastName": "Gleeson",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6703bd3b30abd12a9e0815b3",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "276035374",
        "countryID": 233,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69267891,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Padova",
        "custom": "0laZpgRjG6zAPk5KnYyvw2n1yzaWwoO9JmBWLd17qDV2e3b4x",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153468896",
        "ip": "87.8.173.190",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:46:21",
        "isSmartClick": "0",
        "email": "cantomarkino@live.it",
        "name": "Marco Cantini",
        "firstName": "Marco",
        "lastName": "Cantini",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "Wbg49ZG0xrJekl76MV5LzXYny1qO8Ya1QKDRnOoyAqBvdmpj2",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "denied registartion",
        "saleStatusMapped": null,
        "clickID": "276033908",
        "countryID": 109,
        "cityID": 4218,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14273375",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69267643,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Drunen",
        "custom": null,
        "custom1": null,
        "custom2": "jesuitry.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153468252",
        "ip": "2001:1c03:5a19:5200:a435:e223:5ca8:de74",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 10:45:12",
        "isSmartClick": "0",
        "email": "peterjeannev@gmail.com",
        "name": "Jeanne van de Ven",
        "firstName": "Jeanne",
        "lastName": "van de Ven",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12469",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276033527",
        "countryID": 156,
        "cityID": 2428,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14273340",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69266351,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153465215",
        "ip": "193.248.6.232",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:37:40",
        "isSmartClick": "0",
        "email": "cheungrc0512@gmail.com",
        "name": "Teroto Cheung",
        "firstName": "Teroto",
        "lastName": "Cheung",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "11c31a97-017b-4ffb-bc9e-a414b997f0c5",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "276031631",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14273223",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69266286,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Brescia",
        "custom": "GeODmZ0KY2WkjEB7RvVo8ZVo50mk8rpJzM4anAyP16g9d3Qx5",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153465065",
        "ip": "151.55.126.28",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:37:22",
        "isSmartClick": "0",
        "email": "iris195262@gmail.com",
        "name": "Innocenti Iris",
        "firstName": "Innocenti",
        "lastName": "Iris",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "rRYbBK9kgx7AWnOm0QVedX7GeQgJXEGp3Ja5lqPy2z4Loj6Dv",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276031557",
        "countryID": 109,
        "cityID": 1643,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69265373,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153462952",
        "ip": "2a04:cec0:1225:eeb:5041:80b5:da47:640b",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:32:38",
        "isSmartClick": "0",
        "email": "zanjiaking@gmail.com",
        "name": "Zanjia Bacchi",
        "firstName": "Zanjia",
        "lastName": "Bacchi",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "23b51659-253d-45c6-905f-1b0cce3b9263",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 2",
        "saleStatusMapped": null,
        "clickID": "276030183",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14273124",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69265267,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153462674",
        "ip": "2a02:8440:340d:7aba:dda3:d162:b11f:26c9",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:32:19",
        "isSmartClick": "0",
        "email": "tiennotjacques@free.fr",
        "name": "Tiennot Tiennot jacques",
        "firstName": "Tiennot",
        "lastName": "Tiennot jacques",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "49b32661-a92a-44b1-924f-ef281ac44c57",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Call back",
        "saleStatusMapped": "Call again",
        "clickID": "276030041",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14273117",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69265126,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153462411",
        "ip": "2a04:cec0:1044:33e3:e807:caff:fed2:1977",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:31:47",
        "isSmartClick": "0",
        "email": "francoisstefanucci10@gmail.com",
        "name": "francois stefanucci",
        "firstName": "francois",
        "lastName": "stefanucci",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "2dfc3da3-b8b2-4f52-99ce-a7df2f8cb9ae",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "276029843",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69263662,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Barnet",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153459371",
        "ip": "2a00:23c4:68ae:5901:cc1c:ff94:5f15:621",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:24:25",
        "isSmartClick": "0",
        "email": "Nimsbaby@gmail.com",
        "name": "Naheema Adeola",
        "firstName": "Naheema",
        "lastName": "Adeola",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6703b6dab893d6131801a083",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276027863",
        "countryID": 233,
        "cityID": 3567,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": "14272987",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69263465,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Monsano",
        "custom": "0laZpgRjG6zAPk5KnYyvw2n1yRb1woO9JmBWLd17qDV2e3b4x",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153458936",
        "ip": "37.182.154.201",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:23:30",
        "isSmartClick": "0",
        "email": "fdistefano500@gmail.com",
        "name": "Distefano Francesco",
        "firstName": "Distefano",
        "lastName": "Francesco",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "GoKgZe5RVY4DaLjpWMr9BNx4QbR7N7vnk6m231bP0dlQqEzJx",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276027608",
        "countryID": 109,
        "cityID": 29162,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14272969",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69262991,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153457996",
        "ip": "37.175.166.200",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:21:18",
        "isSmartClick": "0",
        "email": "marierosefm@gmail.com",
        "name": "MarieRose Fernandez Miret",
        "firstName": "MarieRose",
        "lastName": "Fernandez Miret",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "cab981b2-baa3-4819-995b-3dcad31d3148",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 2",
        "saleStatusMapped": null,
        "clickID": "276027015",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14272910",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69261086,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153454296",
        "ip": "37.174.135.77",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:12:45",
        "isSmartClick": "0",
        "email": "idir9736@gmail.com",
        "name": "Idir Ibouadilene",
        "firstName": "Idir",
        "lastName": "Ibouadilene",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "d11168d6-ef49-426d-919b-443a87b37fe3",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "276024596",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69260399,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Pescara",
        "custom": "zaO0gYqreDVQdyo5M4mpXQVQKLDO8R1n2Zl9GJB6KLEbjAxWv",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153452943",
        "ip": "91.254.168.145",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:09:32",
        "isSmartClick": "0",
        "email": "raggiodisole@gmail.com",
        "name": "Maria Ravelli",
        "firstName": "Maria",
        "lastName": "Ravelli",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "De3YvkOqlKgy71R6W9PVdNKamLVMXM42jQZBmo5Lpbz0EJAxa",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "denied registartion",
        "saleStatusMapped": null,
        "clickID": "276023664",
        "countryID": 109,
        "cityID": 1175,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14272685",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69259822,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Toulouse",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153451913",
        "ip": "92.88.170.212",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 10:06:56",
        "isSmartClick": "0",
        "email": "boualemhalima.malika@hmzi.com",
        "name": "Boualem halima malika",
        "firstName": "Boualem halima",
        "lastName": "malika",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "dcce5699-44ec-4bf4-9578-86f28a943fe4",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276022951",
        "countryID": 75,
        "cityID": 1655,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14272615",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69258130,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Cormeilles-en-Parisis",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153448486",
        "ip": "2a01:e0a:8db:f820:613c:2c78:dc39:f831",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:55:56",
        "isSmartClick": "0",
        "email": "mayosylla7@gmail.com",
        "name": "Mayo Sylla",
        "firstName": "Mayo",
        "lastName": "Sylla",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "399ee19c-a286-446e-8cac-082e19b35b99",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No money",
        "saleStatusMapped": "No Money",
        "clickID": "276020471",
        "countryID": 75,
        "cityID": 17395,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14272439",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69258023,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Villeneuve-Loubet",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153448239",
        "ip": "80.119.178.200",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:55:13",
        "isSmartClick": "0",
        "email": "vero.icart@hotmail.fr",
        "name": "Icart Veronique que",
        "firstName": "Icart",
        "lastName": "Veronique que",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "212a9df0-1249-465d-a400-d2a14f43f42b",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "276020319",
        "countryID": 75,
        "cityID": 14227,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69257674,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Australia",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ctenolium.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153447517",
        "ip": "2001:8004:1101:9181:8d3a:882a:a041:805",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 09:53:01",
        "isSmartClick": "0",
        "email": "Carltonrecce@gmail.com",
        "name": "Rhys Carlton",
        "firstName": "Rhys",
        "lastName": "Carlton",
        "brokerID": "21205",
        "brokerName": "Investment Peak",
        "customerID": "4-9937",
        "leadType": "0",
        "countryCode": "AU",
        "saleStatus": "Call again R",
        "saleStatusMapped": null,
        "clickID": "276019855",
        "countryID": 15,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-07 10:24:55",
        "depositID": "4-9937",
        "conversionID": "1217850",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Sonia Kruger is being sued by the Bank of Australia for comments she made on live broadcast",
        "leadRequestOfferName": "Immediate Edge (AU) Sonia Kruger_v1",
        "leadRequestOfferWebsite": "Immediate Edge (AU) Sonia Kruger_v1",
        "brokerAutologinClick": "14272379",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69257125,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153446382",
        "ip": "2a01:cb1e:11:49a5:a80e:adde:6495:6976",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:49:33",
        "isSmartClick": "0",
        "email": "marie-chantal.fraisse@orange.fr",
        "name": "MarieChantal FRAISSE",
        "firstName": "MarieChantal",
        "lastName": "FRAISSE",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "5290ba91-4a15-46de-bfc4-248581aa0f2f",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276018986",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69257102,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": null,
        "custom": "GeODmZ0KY2WkjEB7RvVo8ZVo5RYK8rpJzM4anAyP16g9d3Qx5",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153446343",
        "ip": "5.179.144.165",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:49:24",
        "isSmartClick": "0",
        "email": "dinalamonaca33@gmail.com",
        "name": "Dina Monaca",
        "firstName": "Dina",
        "lastName": "Monaca",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "eoml3DGrbMZE15R4Bg9AqXgJDR2VwL20P7xzV6QnWpOdkKvaj",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276018956",
        "countryID": 109,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14272318",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69256937,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153446066",
        "ip": "2a04:cec0:11ee:7608:0:32:b015:3001",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:48:32",
        "isSmartClick": "0",
        "email": "Sandrinebolmin@gmail.com",
        "name": "Sandrine Bolmin",
        "firstName": "Sandrine",
        "lastName": "Bolmin",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "Sandrinebolmin@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276018725",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69256875,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153445939",
        "ip": "78.243.6.252",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:48:07",
        "isSmartClick": "0",
        "email": "mamadoukametajocu@gmail.com",
        "name": "JOCU JOCU",
        "firstName": "JOCU",
        "lastName": "JOCU",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "7d038c16-715a-4686-ace7-c1fc7d7578a5",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "276018640",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14272288",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69256725,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Echt",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153445626",
        "ip": "2001:1c05:381:6600:41f2:54ce:2d3b:ee1e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 09:47:16",
        "isSmartClick": "0",
        "email": "sonjamuurman@planet.nl",
        "name": "Sonja Muurman",
        "firstName": "Sonja",
        "lastName": "Muurman",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12468",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276018415",
        "countryID": 156,
        "cityID": 9522,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14272273",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69256467,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Villejuif",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153445038",
        "ip": "2001:861:6880:160:e806:849e:82df:e56e",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:45:43",
        "isSmartClick": "0",
        "email": "fofanadiplo77@gmail.com",
        "name": "Bangaly Fofana",
        "firstName": "Bangaly",
        "lastName": "Fofana",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "913cdcc1-b876-4211-b1ab-7e90931df5c5",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "276017977",
        "countryID": 75,
        "cityID": 4642,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 1,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14272243",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69256458,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153445015",
        "ip": "2a04:cec0:100b:b789:d4ec:fcff:fe82:3c68",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:45:39",
        "isSmartClick": "0",
        "email": "lesbenissons@gmail.com",
        "name": "Palm Palm",
        "firstName": "Palm",
        "lastName": "Palm",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "c121e455-92f5-4ab9-947f-3dc64ca2b1ef",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "276017961",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14272247",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69256330,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": "Ellesmere Port",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153444763",
        "ip": "2a00:23c6:e81d:7901:3cbc:f249:7af4:5de2",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:45:09",
        "isSmartClick": "0",
        "email": "davidhamblet7@gmail.com",
        "name": "David HAMBLET",
        "firstName": "David",
        "lastName": "HAMBLET",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "6703ada75a6fe3bbbd01d973",
        "leadType": "0",
        "countryCode": "GB",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276017754",
        "countryID": 233,
        "cityID": 4419,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "AI Invest",
        "leadRequestOfferName": "AI Invest",
        "leadRequestOfferWebsite": "AI Invest",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69256225,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153444558",
        "ip": "37.166.141.85",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:44:44",
        "isSmartClick": "0",
        "email": "chantdo71@live.fr",
        "name": "chantal dorme",
        "firstName": "chantal",
        "lastName": "dorme",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "2a4ca8fe-1095-444d-b436-b7aa390a9a8d",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 2",
        "saleStatusMapped": null,
        "clickID": "276017660",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69255635,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Villeneuve-d'Ascq",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153443331",
        "ip": "2a04:cec0:1140:b91e:0:2a:1126:9601",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:41:09",
        "isSmartClick": "0",
        "email": "louraghhafid@gmail.frv",
        "name": "Hafid Louragh",
        "firstName": "Hafid",
        "lastName": "Louragh",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "louraghhafid@gmail.frv",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "276016794",
        "countryID": 75,
        "cityID": 1166,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69255236,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Wassenaar",
        "custom": null,
        "custom1": null,
        "custom2": "jesuitry.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "153442241",
        "ip": "84.27.92.50",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 09:38:57",
        "isSmartClick": "0",
        "email": "p.wijninga@casema.nl",
        "name": "Peter Wijninga",
        "firstName": "Peter",
        "lastName": "Wijninga",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12467",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "276015987",
        "countryID": 156,
        "cityID": 6715,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14272119",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69254010,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Palermo",
        "custom": "A1gWYeKrzdZb0jxPLDRJ8EGrKRGDwam95yv4q3VkoMl6Q2OnG",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153438712",
        "ip": "151.81.210.245",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:32:25",
        "isSmartClick": "0",
        "email": "loiudice5557@gmail.com",
        "name": "Alessandro Loiudice",
        "firstName": "Alessandro",
        "lastName": "Loiudice",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "zP1aOyYrQpVjeJ93EkbRg8GzZrYaXBDA45d7ZmK2G0o6WMLqv",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276013653",
        "countryID": 109,
        "cityID": 884,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14271992",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69253514,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Tours",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153437574",
        "ip": "2a01:cb00:8697:9200:a1f6:840b:3df7:ed19",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:29:29",
        "isSmartClick": "0",
        "email": "dgfvfdxgt@fhdh.com",
        "name": "Dhfuhc Efghh",
        "firstName": "Dhfuhc",
        "lastName": "Efghh",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "0ccb6c0b-a7e9-4e5d-a1ce-df72d6b57c55",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 2",
        "saleStatusMapped": null,
        "clickID": "276012832",
        "countryID": 75,
        "cityID": 11472,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14271918",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69252011,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Brescia",
        "custom": "R75WoEk3BdxleDMgYL1rwpmk5xB7XpKy4vGjZmbP0n6qV2JzQ",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153434227",
        "ip": "87.14.87.252",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:21:37",
        "isSmartClick": "0",
        "email": "conniemariaconcetta@gmail.com",
        "name": "Maria Pronesti",
        "firstName": "Maria",
        "lastName": "Pronesti",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "yAnrpJ0GPejzv7m5319QWNjk2zzEwE2Bqk4xMaVKRZ6dOYloL",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "denied registartion",
        "saleStatusMapped": null,
        "clickID": "276010489",
        "countryID": 109,
        "cityID": 1643,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14271766",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69250947,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Aalst",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153431848",
        "ip": "2a02:a03f:e3ad:7b00:c9b9:b4de:2118:62f9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 09:14:53",
        "isSmartClick": "0",
        "email": "johanvanbever7@gmail.com",
        "name": "Johan Van Bever",
        "firstName": "Johan",
        "lastName": "Van Bever",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12466",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "276008670",
        "countryID": 23,
        "cityID": 3142,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14271630",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69248062,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Milan",
        "custom": "P4MdpBEGY5Azq6gn0Ok1w5npyknGXD3QZob29VaJ7mlKvRjxy",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153425767",
        "ip": "151.36.151.78",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 09:00:07",
        "isSmartClick": "0",
        "email": "victormarchiori7@gmail.com",
        "name": "Victor Marchiori",
        "firstName": "Victor",
        "lastName": "Marchiori",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "1nBb0Y47KvjdWOAoxamkeX0Yy5qW8J6RG2QqElrpMZVyLzg95",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "276003995",
        "countryID": 109,
        "cityID": 309,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14271311",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69244391,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Rome",
        "custom": "p975o31ZreMWjE2J6KaAXl2152zqNqz4P0YkdbxGOnVmLQvyD",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "153418492",
        "ip": "31.191.9.41",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-07 08:45:13",
        "isSmartClick": "0",
        "email": "sokolowskaanna004@gmail.com",
        "name": "Anna Sokolowska",
        "firstName": "Anna",
        "lastName": "Sokolowska",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "Bp975o31ZreMWjE2J6KaAXlbr24VNqz4P0YkdbxGOnVmLQvyD",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275998502",
        "countryID": 109,
        "cityID": 146,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14270964",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69242177,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "vamnio.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153412598",
        "ip": "80.112.122.242",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 08:33:11",
        "isSmartClick": "0",
        "email": "Devriesrene30@Outlook.com",
        "name": "Rene de Vries",
        "firstName": "Rene",
        "lastName": "de Vries",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12465",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275994718",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14270685",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69241310,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Numansdorp",
        "custom": null,
        "custom1": null,
        "custom2": "vesnioa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153410065",
        "ip": "89.98.247.9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 08:27:40",
        "isSmartClick": "0",
        "email": "hannekebenard80@gmail.com",
        "name": "Hanneke  Benard ",
        "firstName": "Hanneke ",
        "lastName": "Benard ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12464",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275993072",
        "countryID": 156,
        "cityID": 6179,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14270565",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69236357,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amersfoort",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153397233",
        "ip": "109.37.128.92",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 08:00:18",
        "isSmartClick": "0",
        "email": "vankouwenelektro@outlook.com",
        "name": "Jeroen Van Kouwen",
        "firstName": "Jeroen",
        "lastName": "Van Kouwen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12463",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275984125",
        "countryID": 156,
        "cityID": 1494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14269839",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69231213,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zoetermeer",
        "custom": null,
        "custom1": null,
        "custom2": "zomria.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153380197",
        "ip": "2001:1c00:2f1e:3b00:fc7c:3a48:af58:2eaf",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 06:28:29",
        "isSmartClick": "0",
        "email": "Poelfive@gmail.com",
        "name": "Gerard van der Poel",
        "firstName": "Gerard",
        "lastName": "van der Poel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12462",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275971600",
        "countryID": 156,
        "cityID": 1565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14268968",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69225224,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Australia",
        "cityName": "Perth",
        "custom": null,
        "custom1": null,
        "custom2": "ctenolium.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153352850",
        "ip": "49.196.169.76",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 03:41:50",
        "isSmartClick": "0",
        "email": "siroe2020@gmail.com",
        "name": "Istael Robinson",
        "firstName": "Istael",
        "lastName": "Robinson",
        "brokerID": "21205",
        "brokerName": "Investment Peak",
        "customerID": "4-9900",
        "leadType": "0",
        "countryCode": "AU",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "275956001",
        "countryID": 15,
        "cityID": 2,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Sonia Kruger is being sued by the Bank of Australia for comments she made on live broadcast",
        "leadRequestOfferName": "Immediate Edge (AU) Sonia Kruger_v1",
        "leadRequestOfferWebsite": "Immediate Edge (AU) Sonia Kruger_v1",
        "brokerAutologinClick": "14268522",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69224485,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Australia",
        "cityName": "Yandina Creek",
        "custom": null,
        "custom1": null,
        "custom2": "ctenolium.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "153349388",
        "ip": "2001:8003:e1dd:3301:a810:2417:3838:395d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-07 03:24:16",
        "isSmartClick": "0",
        "email": "maintenancepete@gmail.com",
        "name": "Peter Anderson",
        "firstName": "Peter",
        "lastName": "Anderson",
        "brokerID": "21205",
        "brokerName": "Investment Peak",
        "customerID": "4-9899",
        "leadType": "0",
        "countryCode": "AU",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275954248",
        "countryID": 15,
        "cityID": 39187,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Sonia Kruger is being sued by the Bank of Australia for comments she made on live broadcast",
        "leadRequestOfferName": "Immediate Edge (AU) Sonia Kruger_v1",
        "leadRequestOfferWebsite": "Immediate Edge (AU) Sonia Kruger_v1",
        "brokerAutologinClick": "14268483",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69216658,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "tubinares.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153268979",
        "ip": "2806:2a0:c18:857e:2534:503:64df:d2a8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-06 22:48:53",
        "isSmartClick": "0",
        "email": "Nicomoreno@hotmail.com",
        "name": "Nico Moreno",
        "firstName": "Nico",
        "lastName": "Moreno",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "777858",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275933663",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Mexico&#39;s National Bank sues Galilea Montijo for what she said on live TV. Everyone in Mexico should know the truth!",
        "leadRequestOfferName": "Bitcoineer Galilea Montijo-MX",
        "leadRequestOfferWebsite": "Bitcoineer Galilea Montijo-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69215456,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Monterrey",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153255357",
        "ip": "2806:108e:17:4074:78b4:cfab:583:f5f3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-06 22:03:29",
        "isSmartClick": "0",
        "email": "tomasalejandro501221@gmail.com",
        "name": "J. Guadalupe Tomás Alejandro Gaona  Alejandro Gaona ",
        "firstName": "J. Guadalupe Tomás Alejandro Gaona ",
        "lastName": "Alejandro Gaona ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "tomasalejandro501221@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275930743",
        "countryID": 143,
        "cityID": 4787,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69215262,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Xalapa",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "153253892",
        "ip": "187.190.158.151",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-06 21:57:45",
        "isSmartClick": "0",
        "email": "Charli53@gmail.com",
        "name": "Carlos Mora mireles",
        "firstName": "Carlos",
        "lastName": "Mora mireles",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "498255",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "275930384",
        "countryID": 143,
        "cityID": 3552,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14268014",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69193591,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hengelo",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: warmseniorhaven.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153137278",
        "ip": "83.81.137.250",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-06 07:48:03",
        "isSmartClick": "0",
        "email": "p.hensema@ziggo.nl",
        "name": "Petra Hensema",
        "firstName": "Petra",
        "lastName": "Hensema",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12461",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275867617",
        "countryID": 156,
        "cityID": 5887,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14267116",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69193583,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "Offer: warmseniorhaven.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153137259",
        "ip": "2001:4c3c:1603:7500:e003:e3a2:cd40:64c9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-06 07:47:22",
        "isSmartClick": "0",
        "email": "aberkhout76@gmail.com",
        "name": "Arie Berkhout",
        "firstName": "Arie",
        "lastName": "Berkhout",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12460",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275867614",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14267114",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69187791,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: warmseniorhaven.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153100853",
        "ip": "84.241.195.242",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-05 19:52:33",
        "isSmartClick": "0",
        "email": "Lekkertjeslezen@gmail.com",
        "name": "Marcel  Wijnans",
        "firstName": "Marcel ",
        "lastName": "Wijnans",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12459",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275852560",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14266717",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69186328,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Lelystad",
        "custom": null,
        "custom1": null,
        "custom2": "onanistic.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "153094520",
        "ip": "92.65.242.210",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-05 19:14:54",
        "isSmartClick": "0",
        "email": "erickeckhorst@hotmail.com",
        "name": "Erick Eckhorst",
        "firstName": "Erick",
        "lastName": "Eckhorst",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12458",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275849457",
        "countryID": 156,
        "cityID": 4093,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14266696",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69180248,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "153060776",
        "ip": "188.88.12.167",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-05 16:24:02",
        "isSmartClick": "0",
        "email": "ivekbasgitara@outlook.com",
        "name": "ZELJKA PNJAK",
        "firstName": "ZELJKA",
        "lastName": "PNJAK",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12457",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275837153",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14266550",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69173716,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "153020868",
        "ip": "172.70.46.78",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-05 14:22:49",
        "isSmartClick": "0",
        "email": "Hwillem1530@gmail.com",
        "name": "Gerard V huigenbos",
        "firstName": "Gerard",
        "lastName": "V huigenbos",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12456",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275819415",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14266397",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69167059,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Deventer",
        "custom": null,
        "custom1": null,
        "custom2": "zomliox.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152990049",
        "ip": "213.10.182.246",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-05 10:29:52",
        "isSmartClick": "0",
        "email": "Rita.benji@home.nl",
        "name": "Rita Hansen",
        "firstName": "Rita",
        "lastName": "Hansen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12455",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275801533",
        "countryID": 156,
        "cityID": 1765,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14265959",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69161247,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Opmeer",
        "custom": null,
        "custom1": null,
        "custom2": "onanistic.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152964340",
        "ip": "213.233.195.233",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-05 06:55:15",
        "isSmartClick": "0",
        "email": "Frank_rustenburg@hotmail.com",
        "name": "Frank  Rusten",
        "firstName": "Frank ",
        "lastName": "Rusten",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12454",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275786330",
        "countryID": 156,
        "cityID": 6717,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14265367",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69161018,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexicali",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152963227",
        "ip": "2806:290:a81e:957:cd36:582a:5fc6:780e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-05 06:41:54",
        "isSmartClick": "0",
        "email": "erik26324@gmail.com",
        "name": "ERICK Guereña Figueroa",
        "firstName": "ERICK",
        "lastName": "Guereña Figueroa",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "777391",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275785743",
        "countryID": 143,
        "cityID": 1232,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14265330",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69156514,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tuxpam de Rodriguez Cano",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152931840",
        "ip": "2806:2f0:73a0:fec6:90b0:a22:4e63:6352",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 20:27:48",
        "isSmartClick": "0",
        "email": "Virgiliolara@hotmail.com",
        "name": "Virgilio Lara",
        "firstName": "Virgilio",
        "lastName": "Lara",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776965",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275765872",
        "countryID": 143,
        "cityID": 11650,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14264683",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69156236,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "4ad3bcd8de",
        "affiliateID": "71365",
        "affiliateName": "Office2",
        "affiliateEmail": "office2@gmail2.com",
        "countryName": "Canada",
        "cityName": "Calgary",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152930961",
        "ip": "2605:8d80:4e1:bf2d:6914:7ebb:3007:d47",
        "userAgent": "GuzzleHttp/6.5.5 curl/7.68.0 PHP/7.4.33",
        "platform": "Other",
        "browser": "curl",
        "signupDate": "2024-10-04 20:13:20",
        "isSmartClick": "0",
        "email": "made2ready@gmail.com",
        "name": "Hussien Mahmoud",
        "firstName": "Hussien",
        "lastName": "Mahmoud",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "67004c6045e8b87e0905b6b3",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "275762636",
        "countryID": 40,
        "cityID": 36,
        "affiliateManagerEmail": "eva.leads2ftds@gmail.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "age => 42\ncountry => Canada\nlang => EN",
        "leadRequestOfferName": "QUANTUM",
        "leadRequestOfferWebsite": "quantunn.info?utm_source=t",
        "brokerAutologinClick": "14264659",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69155959,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "unpartook.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152930126",
        "ip": "169.150.218.37",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 20:03:41",
        "isSmartClick": "0",
        "email": "testleadsghigy2@gmail.com",
        "name": "testleadsghigy2 testleadsghigy2",
        "firstName": "testleadsghigy2",
        "lastName": "testleadsghigy2",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "523252",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275760223",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14264636",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69155927,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152930051",
        "ip": "144.178.82.115",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 20:02:14",
        "isSmartClick": "0",
        "email": "Gudoherben10@gmail.com",
        "name": "Gudo Herben",
        "firstName": "Gudo",
        "lastName": "Herben",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12453",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275759908",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14264622",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69155609,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152928910",
        "ip": "2a02:a454:9cd4:1:db19:2954:a324:fc7a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 19:47:47",
        "isSmartClick": "0",
        "email": "gilian.de.jonge@gmail.com",
        "name": "Gilian De Jonge",
        "firstName": "Gilian",
        "lastName": "De Jonge",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12452",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "Reshuffle",
        "saleStatusMapped": "Reshuffle",
        "clickID": "275756527",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14264582",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69155115,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Chicontepec",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152926742",
        "ip": "45.188.127.50",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 19:30:06",
        "isSmartClick": "0",
        "email": "josecevero167@gmail.com",
        "name": "Jose  angelb Rivera cevero ",
        "firstName": "Jose  angelb",
        "lastName": "Rivera cevero ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776775",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275752161",
        "countryID": 143,
        "cityID": 48193,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14264533",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69154815,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152925459",
        "ip": "201.162.243.27",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 19:17:19",
        "isSmartClick": "0",
        "email": "sramirezvillagomez@gmail.com",
        "name": "Sergio Ramirez Villagomez",
        "firstName": "Sergio",
        "lastName": "Ramirez Villagomez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776708",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275749165",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14264494",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69154504,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "IJsselstein",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152924169",
        "ip": "85.223.33.28",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 19:02:35",
        "isSmartClick": "0",
        "email": "p.m.dorgelo@hotmail.com",
        "name": "Petra  Dorgelo-vd Sluis ",
        "firstName": "Petra ",
        "lastName": "Dorgelo-vd Sluis ",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "523162",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "NoAnswer",
        "saleStatusMapped": "No Answer",
        "clickID": "275745771",
        "countryID": 156,
        "cityID": 1345,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14264450",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69154476,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152924066",
        "ip": "109.38.148.124",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 19:01:34",
        "isSmartClick": "0",
        "email": "paulvdpk@gmail.com",
        "name": "Paul  van der Pouw Kraan",
        "firstName": "Paul ",
        "lastName": "van der Pouw Kraan",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "523152",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "Wronglanguage",
        "saleStatusMapped": "Language Issues",
        "clickID": "275745381",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69154164,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152922428",
        "ip": "94.109.54.23",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 18:45:02",
        "isSmartClick": "0",
        "email": "Tanosayoc@gmail.com",
        "name": "Gaëtan  Van der Brempt",
        "firstName": "Gaëtan ",
        "lastName": "Van der Brempt",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12450",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275741767",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14264400",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69153839,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "4ad3bcd8de",
        "affiliateID": "71365",
        "affiliateName": "Office2",
        "affiliateEmail": "office2@gmail2.com",
        "countryName": "Canada",
        "cityName": "Toronto",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152920836",
        "ip": "2605:8d80:6c2:7bc5:5d6a:539a:64e0:748c",
        "userAgent": "GuzzleHttp/6.5.5 curl/7.68.0 PHP/7.4.33",
        "platform": "Other",
        "browser": "curl",
        "signupDate": "2024-10-04 18:27:19",
        "isSmartClick": "0",
        "email": "ivand@gmail.com",
        "name": "Ivan Duriv",
        "firstName": "Ivan",
        "lastName": "Duriv",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ivand@gmail.com",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275737942",
        "countryID": 40,
        "cityID": 92,
        "affiliateManagerEmail": "eva.leads2ftds@gmail.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "age => 34\ncountry => Canada\nlang => EN",
        "leadRequestOfferName": "QUANTUM",
        "leadRequestOfferWebsite": "quantunn.info?utm_source=t",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69153662,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "4ad3bcd8de",
        "affiliateID": "71365",
        "affiliateName": "Office2",
        "affiliateEmail": "office2@gmail2.com",
        "countryName": "Canada",
        "cityName": "Red Deer",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152919859",
        "ip": "2604:3d08:ea89:2500:1498:5bc3:3225:ec9",
        "userAgent": "GuzzleHttp/6.5.5 curl/7.68.0 PHP/7.4.33",
        "platform": "Other",
        "browser": "curl",
        "signupDate": "2024-10-04 18:18:07",
        "isSmartClick": "0",
        "email": "patcocks1771@gmail.com",
        "name": "Pat Cocks",
        "firstName": "Pat",
        "lastName": "Cocks",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "670031610263155fff0a36b3",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275735832",
        "countryID": 40,
        "cityID": 1330,
        "affiliateManagerEmail": "eva.leads2ftds@gmail.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "age => 53\ncountry => Canada\nlang => EN",
        "leadRequestOfferName": "QUANTUM",
        "leadRequestOfferWebsite": "quantunn.info?utm_source=t",
        "brokerAutologinClick": "14264319",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69153659,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Hermosillo",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152919837",
        "ip": "2806:263:c482:8466:212a:dadb:1579:7110",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 18:17:48",
        "isSmartClick": "0",
        "email": "juancarlossalinas136@gmail.com",
        "name": "Juan Carlos  Salinas",
        "firstName": "Juan Carlos ",
        "lastName": "Salinas",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776421",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275735775",
        "countryID": 143,
        "cityID": 2595,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14264317",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69153488,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "De Meern",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152919001",
        "ip": "2a02:a470:65ab:1:d3f7:2339:8f0b:a629",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 18:08:58",
        "isSmartClick": "0",
        "email": "Benniemans75@gmail.com",
        "name": "Ben Mans",
        "firstName": "Ben",
        "lastName": "Mans",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12449",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275733703",
        "countryID": 156,
        "cityID": 7668,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14264271",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69153346,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "4ad3bcd8de",
        "affiliateID": "71365",
        "affiliateName": "Office2",
        "affiliateEmail": "office2@gmail2.com",
        "countryName": "Canada",
        "cityName": "Saint John",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152918393",
        "ip": "2607:fea8:d1a1:3000:c0fd:3e14:f9f3:aa21",
        "userAgent": "GuzzleHttp/6.5.5 curl/7.68.0 PHP/7.4.33",
        "platform": "Other",
        "browser": "curl",
        "signupDate": "2024-10-04 18:02:17",
        "isSmartClick": "0",
        "email": "devinlbeach0325@gmail.com",
        "name": "Devin Beach",
        "firstName": "Devin",
        "lastName": "Beach",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "67002da9e3ad3502ed068d73",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275732200",
        "countryID": 40,
        "cityID": 3561,
        "affiliateManagerEmail": "eva.leads2ftds@gmail.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "age => 27\ncountry => Canada\nlang => EN",
        "leadRequestOfferName": "QUANTUM",
        "leadRequestOfferWebsite": "quantunn.info?utm_source=t",
        "brokerAutologinClick": "14264236",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69153344,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152918389",
        "ip": "201.162.243.44",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 18:02:15",
        "isSmartClick": "0",
        "email": "Eusebio62.ea@gmail.com",
        "name": "Eusebio Alfaro",
        "firstName": "Eusebio",
        "lastName": "Alfaro",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776360",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275732190",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14264233",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69153286,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "zomliox.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152918075",
        "ip": "109.37.132.113",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 18:00:07",
        "isSmartClick": "0",
        "email": "kindplustalent@gmail.com",
        "name": "Christel Douwes",
        "firstName": "Christel",
        "lastName": "Douwes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12448",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275731679",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14264230",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69153005,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152916474",
        "ip": "84.241.202.202",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 17:45:37",
        "isSmartClick": "0",
        "email": "ahmetkeskin1881@gmail.com",
        "name": "Ahmet Keskin",
        "firstName": "Ahmet",
        "lastName": "Keskin",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12447",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275728457",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-07 08:30:00",
        "depositID": "20412057",
        "conversionID": "1217728",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 1,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14264161",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69152703,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Atotonilco el Alto",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152915230",
        "ip": "45.188.164.204",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 17:31:49",
        "isSmartClick": "0",
        "email": "benitoortega499@gmail.com",
        "name": "Benito  Ortega ",
        "firstName": "Benito ",
        "lastName": "Ortega ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776220",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275725355",
        "countryID": 143,
        "cityID": 17806,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14264111",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69152635,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Miguel Hidalgo",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152914882",
        "ip": "189.236.69.187",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 17:27:45",
        "isSmartClick": "0",
        "email": "meibisceledonio@gmail.com",
        "name": "María Josefina  CELEDONIO  ",
        "firstName": "María Josefina ",
        "lastName": "CELEDONIO  ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776200",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275724548",
        "countryID": 143,
        "cityID": 16472,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14264097",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69152633,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152914864",
        "ip": "201.141.107.135",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 17:27:31",
        "isSmartClick": "0",
        "email": "sergiormzr20@gmail.com",
        "name": "Sergio Ramirez",
        "firstName": "Sergio",
        "lastName": "Ramirez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776199",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275724500",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14264093",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69152072,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152912267",
        "ip": "187.202.46.94",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 17:05:05",
        "isSmartClick": "0",
        "email": "sanchezhernandezraul423@gmail.com",
        "name": "Raúl  Sánchez Hernández ",
        "firstName": "Raúl ",
        "lastName": "Sánchez Hernández ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776098",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "275719388",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263990",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69151915,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Saltillito",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152911716",
        "ip": "2806:267:8402:8d4c:a9f0:6f33:cce7:1089",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 17:02:04",
        "isSmartClick": "0",
        "email": "gabrielaana434@gmail.com",
        "name": "ANA GABRIELA CISNEROs Rangel Rangel",
        "firstName": "ANA GABRIELA",
        "lastName": "CISNEROs Rangel Rangel",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "776080",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275718626",
        "countryID": 143,
        "cityID": 53558,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263979",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69151812,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "4ad3bcd8de",
        "affiliateID": "71365",
        "affiliateName": "Office2",
        "affiliateEmail": "office2@gmail2.com",
        "countryName": "Canada",
        "cityName": "Harbour Breton",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152911256",
        "ip": "142.162.1.185",
        "userAgent": "GuzzleHttp/6.5.5 curl/7.68.0 PHP/7.4.33",
        "platform": "Other",
        "browser": "curl",
        "signupDate": "2024-10-04 17:00:00",
        "isSmartClick": "0",
        "email": "bobstrathie@hotmail.com",
        "name": "Robert Strathie",
        "firstName": "Robert",
        "lastName": "Strathie",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "67001f10e339127d380d6643",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275718098",
        "countryID": 40,
        "cityID": 7019,
        "affiliateManagerEmail": "eva.leads2ftds@gmail.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "age => 65\ncountry => Canada\nlang => FR",
        "leadRequestOfferName": "QUANTUM",
        "leadRequestOfferWebsite": "quantunn.info?utm_source=t",
        "brokerAutologinClick": "14263974",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69151522,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heerenveen",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152909999",
        "ip": "2a02:a460:e0d1:1:beca:9bb2:1fd6:e2a5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:55:13",
        "isSmartClick": "0",
        "email": "heer.altenburg@gmail.com",
        "name": "Hessel Altenburg",
        "firstName": "Hessel",
        "lastName": "Altenburg",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12446",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275716827",
        "countryID": 156,
        "cityID": 561,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263951",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69151465,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152909766",
        "ip": "2001:4c3c:1d00:7300:9581:b18a:9293:9607",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:54:23",
        "isSmartClick": "0",
        "email": "Cinbrouwer28@hotmail.com",
        "name": "Cinderella  Brouwer ",
        "firstName": "Cinderella ",
        "lastName": "Brouwer ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12445",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275716610",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263948",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69150770,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Deventer",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152906974",
        "ip": "62.166.220.17",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:42:12",
        "isSmartClick": "0",
        "email": "suzankorenblik@hotmail.com",
        "name": "Suzan  Korenblik ",
        "firstName": "Suzan ",
        "lastName": "Korenblik ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12444",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275713535",
        "countryID": 156,
        "cityID": 1765,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263884",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69150291,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Puerto Juárez",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152905119",
        "ip": "201.108.109.11",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:33:35",
        "isSmartClick": "0",
        "email": "mvz.laguna@gmail.com",
        "name": "María Rosa Laguna",
        "firstName": "María Rosa",
        "lastName": "Laguna",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775934",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275711367",
        "countryID": 143,
        "cityID": 104527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263829",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69150102,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Meppel",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152904464",
        "ip": "2a02:a445:617c:1:8412:c5be:85ac:1554",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:31:13",
        "isSmartClick": "0",
        "email": "reyer999@gmail.com",
        "name": "Reijer Schoonhoven",
        "firstName": "Reijer",
        "lastName": "Schoonhoven",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12443",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275710764",
        "countryID": 156,
        "cityID": 6214,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263809",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69150072,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Zapopan",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152904363",
        "ip": "187.213.191.120",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:30:48",
        "isSmartClick": "0",
        "email": "gmedinazavala64@gmail.com",
        "name": "Gabriel Medina Zavala",
        "firstName": "Gabriel",
        "lastName": "Medina Zavala",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775923",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275710562",
        "countryID": 143,
        "cityID": 4215,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263806",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149853,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Huimanguillo",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152903503",
        "ip": "200.76.31.196",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:27:05",
        "isSmartClick": "0",
        "email": "katerycastilloolan@gmail.com",
        "name": "Katery Castillo olan",
        "firstName": "Katery",
        "lastName": "Castillo olan",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775905",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275709480",
        "countryID": 143,
        "cityID": 49132,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263784",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149571,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Veracruz",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152902508",
        "ip": "2806:268:480:8eb:a123:b4f6:b547:e47b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:22:24",
        "isSmartClick": "0",
        "email": "gomezgomezjorge333@gmail.com",
        "name": "Jorge  Gomez ",
        "firstName": "Jorge ",
        "lastName": "Gomez ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "dc29d466-41dc-4e95-96cc-df24bbe6f4d2",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275708175",
        "countryID": 143,
        "cityID": 785,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263752",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149498,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Venray",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152902173",
        "ip": "2a02:a45a:5b57:0:cd50:926b:4cf3:1b3a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:21:06",
        "isSmartClick": "0",
        "email": "robnagy22.rn@gmail.com",
        "name": "Rob Nagy ",
        "firstName": "Rob",
        "lastName": "Nagy ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12442",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275707738",
        "countryID": 156,
        "cityID": 6111,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263740",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149446,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tijuana",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152902017",
        "ip": "201.131.198.19",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:20:23",
        "isSmartClick": "0",
        "email": "julianhernandezfernando@gmail.com",
        "name": "Jonathan  Hernández González ",
        "firstName": "Jonathan ",
        "lastName": "Hernández González ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775871",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "275707481",
        "countryID": 143,
        "cityID": 1286,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263735",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149403,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ecatepec",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152901852",
        "ip": "2806:105e:f:2b68:56c:a53:8bd5:32dc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:19:37",
        "isSmartClick": "0",
        "email": "mariaguadalupegarciahuerta3@gmail.com",
        "name": "Maria Guadalupe  García Huerta ",
        "firstName": "Maria Guadalupe ",
        "lastName": "García Huerta ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775866",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275707249",
        "countryID": 143,
        "cityID": 17641,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14263726",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149384,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Saltillo",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152901773",
        "ip": "187.141.246.154",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:18:56",
        "isSmartClick": "0",
        "email": "posuna@fordsaltillo.com",
        "name": "Pedro  Osuna",
        "firstName": "Pedro ",
        "lastName": "Osuna",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775865",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275707083",
        "countryID": 143,
        "cityID": 11263,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263719",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149288,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Iztapalapa",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152901387",
        "ip": "200.63.42.192",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:17:21",
        "isSmartClick": "0",
        "email": "am5702844@gmail.com",
        "name": "Alfredo Manuel  Martínez Sánchez ",
        "firstName": "Alfredo Manuel ",
        "lastName": "Martínez Sánchez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775854",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275706585",
        "countryID": 143,
        "cityID": 3576,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263698",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149110,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Loreto",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152900642",
        "ip": "177.124.85.4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:14:18",
        "isSmartClick": "0",
        "email": "sv0602063@gmail.com",
        "name": "Samuel Velazquez Rodriguez",
        "firstName": "Samuel",
        "lastName": "Velazquez Rodriguez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775834",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "275705736",
        "countryID": 143,
        "cityID": 34237,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263678",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69149049,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152900418",
        "ip": "189.201.49.93",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:12:56",
        "isSmartClick": "0",
        "email": "amdorojass03@gmail.com",
        "name": "Joseamado Rojas mares",
        "firstName": "Joseamado",
        "lastName": "Rojas mares",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775828",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275705422",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263668",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69148953,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Texcoco",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152900104",
        "ip": "2806:105e:1c:2ce3:3d45:27a6:f253:25b6",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:11:38",
        "isSmartClick": "0",
        "email": "michelruiz892@gmail.com",
        "name": "Michelle pamela  Rosales ruiz",
        "firstName": "Michelle pamela ",
        "lastName": "Rosales ruiz",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775815",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275705055",
        "countryID": 143,
        "cityID": 13081,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263660",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69148574,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Woerden",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152898697",
        "ip": "2001:1c02:1e17:700:d3ba:ce1c:7fcd:6098",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:06:12",
        "isSmartClick": "0",
        "email": "edwingresnigtharmelen@gmail.com",
        "name": "Edwin Gresnigt",
        "firstName": "Edwin",
        "lastName": "Gresnigt",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12441",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275703613",
        "countryID": 156,
        "cityID": 6781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263616",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69148277,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "León",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152897750",
        "ip": "2806:103e:23:598f:b417:dfe2:e50d:555",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 16:01:54",
        "isSmartClick": "0",
        "email": "ANTONIOMONZON.TUZITA@GMAIL.COM",
        "name": "Antonio Monzonperez",
        "firstName": "Antonio",
        "lastName": "Monzonperez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775766",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275702356",
        "countryID": 143,
        "cityID": 3398,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14263573",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69147816,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Meise",
        "custom": null,
        "custom1": null,
        "custom2": "satisfactoriness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152895889",
        "ip": "2a02:a03f:6c4a:d800:454b:b0c2:3071:e486",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:55:59",
        "isSmartClick": "0",
        "email": "p.hellinckx@sitecom.com",
        "name": "Pascal Hellinckx",
        "firstName": "Pascal",
        "lastName": "Hellinckx",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12440",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275700416",
        "countryID": 23,
        "cityID": 11050,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Major scandal: Immediate Edge refuses to comment on its employee Gert Verhulstt",
        "leadRequestOfferName": "Immediate Tine Embrechts",
        "leadRequestOfferWebsite": "Immediate Tine Embrechts",
        "brokerAutologinClick": "14263541",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69147797,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152895795",
        "ip": "189.201.57.3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:55:08",
        "isSmartClick": "0",
        "email": "fptiz1954@gmail.com",
        "name": "Filiberto Pasco Tisnado",
        "firstName": "Filiberto",
        "lastName": "Pasco Tisnado",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775706",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275700232",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14263536",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69147706,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Uruapan",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152895450",
        "ip": "2806:103e:7:9e99:2905:bef8:202:9f9e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:53:52",
        "isSmartClick": "0",
        "email": "fernandosabalza86@gmail.com",
        "name": "Fernando  Sabalza Farias ",
        "firstName": "Fernando ",
        "lastName": "Sabalza Farias ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775693",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "FTD",
        "saleStatusMapped": "Converted",
        "clickID": "275699860",
        "countryID": 143,
        "cityID": 3583,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-05 16:55:17",
        "depositID": "775693",
        "conversionID": "1217474",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263525",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69147621,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "mindforgepro.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152895169",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:52:34",
        "isSmartClick": "0",
        "email": "testestetes@sfsdfsdfsd.com",
        "name": "еуыеуеыеуtestetset etsetstetse",
        "firstName": "еуыеуеыеуtestetset",
        "lastName": "etsetstetse",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12439",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275699466",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263513",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69147364,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuauhtemoc",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152894210",
        "ip": "2806:107e:c:15b7:6559:30fe:ef46:b287",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:50:14",
        "isSmartClick": "0",
        "email": "bettycampa088@gmail.com",
        "name": "Marta  Campa ",
        "firstName": "Marta ",
        "lastName": "Campa ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775653",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "275698496",
        "countryID": 143,
        "cityID": 7743,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263495",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69147240,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Haarlem",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152893713",
        "ip": "81.59.123.5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:48:05",
        "isSmartClick": "0",
        "email": "Masif.rw@gmail.com",
        "name": "Roy Weijers ",
        "firstName": "Roy",
        "lastName": "Weijers ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12438",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275697916",
        "countryID": 156,
        "cityID": 494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263479",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69147191,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "mindforgepro.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152893617",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:47:29",
        "isSmartClick": "0",
        "email": "testette@esadfsfdv.com",
        "name": "etestetset tetsettset",
        "firstName": "etestetset",
        "lastName": "tetsettset",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12437",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275697753",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69147164,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zoetermeer",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152893550",
        "ip": "84.241.198.253",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:47:04",
        "isSmartClick": "0",
        "email": "bryan_1992@live.nl",
        "name": "Bryan Sommeling",
        "firstName": "Bryan",
        "lastName": "Sommeling",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12436",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275697605",
        "countryID": 156,
        "cityID": 1565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263469",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69146846,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152892452",
        "ip": "189.201.47.179",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:42:58",
        "isSmartClick": "0",
        "email": "ealvaro381@gmail.com",
        "name": "Alvaro Cervera Espinoza",
        "firstName": "Alvaro",
        "lastName": "Cervera Espinoza",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775592",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "275696193",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263442",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69146481,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Wijk bij Duurstede",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152891179",
        "ip": "2001:1c03:287:ef00:e1:3d2e:1863:79a1",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:39:05",
        "isSmartClick": "0",
        "email": "Betopues@gmail.com",
        "name": "Awj Beemer",
        "firstName": "Awj",
        "lastName": "Beemer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12435",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275694871",
        "countryID": 156,
        "cityID": 6711,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263406",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69145997,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Wervik",
        "custom": null,
        "custom1": null,
        "custom2": "unpetrify.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152889309",
        "ip": "2a02:a03f:e6b4:9c00:8cd4:49ef:d7cb:3b95",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:32:52",
        "isSmartClick": "0",
        "email": "yvdb@msn.com",
        "name": "Yannick  Vandenberghe ",
        "firstName": "Yannick ",
        "lastName": "Vandenberghe ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12434",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275692920",
        "countryID": 23,
        "cityID": 9485,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14263355",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69145733,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tampico",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152888391",
        "ip": "2806:109f:7:faf2:6ca6:621d:a1dd:bdfa",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:29:54",
        "isSmartClick": "0",
        "email": "jlpcib@hotmail.com",
        "name": "JOSE LUIS PADRON ",
        "firstName": "JOSE LUIS",
        "lastName": "PADRON ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775484",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275691958",
        "countryID": 143,
        "cityID": 2536,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263329",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69145305,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "The Hague",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152886992",
        "ip": "2a02:a472:2177:0:1442:5550:9830:62b2",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:25:12",
        "isSmartClick": "0",
        "email": "am4hout@gmail.com",
        "name": "Anne-Marie Vierhout",
        "firstName": "Anne-Marie",
        "lastName": "Vierhout",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12433",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275690323",
        "countryID": 156,
        "cityID": 921,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263269",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69145188,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Schiedam",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152886637",
        "ip": "2a02:a455:348a:1:8581:ffa:dc07:a51e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:23:19",
        "isSmartClick": "0",
        "email": "Franketman9@gmail.com",
        "name": "Frank Etman",
        "firstName": "Frank",
        "lastName": "Etman",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12432",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275689797",
        "countryID": 156,
        "cityID": 3781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14263250",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69144875,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Irapuato",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152885457",
        "ip": "2806:102e:b:fa24:e912:c434:86ae:a296",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:20:47",
        "isSmartClick": "0",
        "email": "lerma7076@gmail.com",
        "name": "Jose salud  Lerma aguilar",
        "firstName": "Jose salud ",
        "lastName": "Lerma aguilar",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "bad90f98-d568-40f1-8847-82181b75c0fa",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275688593",
        "countryID": 143,
        "cityID": 12022,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263223",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69144865,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Irapuato",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152885438",
        "ip": "2806:102e:b:fa24:e912:c434:86ae:a296",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:20:41",
        "isSmartClick": "0",
        "email": "lerma7076@gmail.com",
        "name": "Jose salud  Lerma aguilar",
        "firstName": "Jose salud ",
        "lastName": "Lerma aguilar",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775409",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275688577",
        "countryID": 143,
        "cityID": 12022,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69144839,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152885387",
        "ip": "201.141.18.181",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:20:22",
        "isSmartClick": "0",
        "email": "yolandagrande73@gmail.com",
        "name": "Yolanda Janett  Grande Alpizar ",
        "firstName": "Yolanda Janett ",
        "lastName": "Grande Alpizar ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775408",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275688500",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14263209",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69144830,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "San Luis Potosí City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152885370",
        "ip": "2001:1260:31e:837a:7056:3bfe:3692:415f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:20:17",
        "isSmartClick": "0",
        "email": "guadalupemoctezuma843@gmail.com",
        "name": "Ma.guadalupe Moctezuma  Covarrubias ",
        "firstName": "Ma.guadalupe Moctezuma ",
        "lastName": "Covarrubias ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775407",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "275688480",
        "countryID": 143,
        "cityID": 3565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263206",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69144328,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Morelia",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152883813",
        "ip": "2806:103e:2d:c7a8:297f:cdac:543b:6b33",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:15:46",
        "isSmartClick": "0",
        "email": "gobdulia580@gmail.com",
        "name": "Carlos bernardo Espino guillen",
        "firstName": "Carlos bernardo",
        "lastName": "Espino guillen",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775365",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275686733",
        "countryID": 143,
        "cityID": 3225,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263152",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69144219,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Benito Juarez",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152883521",
        "ip": "201.175.106.211",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:14:21",
        "isSmartClick": "0",
        "email": "gasparhdez64@imail.com",
        "name": "Gaspar  Hernandez",
        "firstName": "Gaspar ",
        "lastName": "Hernandez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775359",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275686347",
        "countryID": 143,
        "cityID": 3578,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14263137",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143988,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Aguascalientes",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152882929",
        "ip": "2806:103e:15:b8c:d6e3:68ed:a6af:c69a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:12:07",
        "isSmartClick": "0",
        "email": "martyalvarado182@gmail.com",
        "name": "martina renteria alvarado",
        "firstName": "martina",
        "lastName": "renteria alvarado",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775347",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275685605",
        "countryID": 143,
        "cityID": 1267,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263102",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143908,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152882723",
        "ip": "2806:370:7268:4689:4114:b79e:c5ac:5838",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:11:10",
        "isSmartClick": "0",
        "email": "j.omaravilag2810@gmail.com",
        "name": "Jorge Omar Ávila García ",
        "firstName": "Jorge Omar",
        "lastName": "Ávila García ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775340",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275685248",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263087",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143741,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Nezahualcoyotl",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152882130",
        "ip": "2806:107e:21:c558:e876:9373:d070:b122",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:09:53",
        "isSmartClick": "0",
        "email": "paulinoestrada256@gmail.com",
        "name": "Antonio Estrada Arias",
        "firstName": "Antonio",
        "lastName": "Estrada Arias",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775334",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Closed",
        "saleStatusMapped": null,
        "clickID": "275684693",
        "countryID": 143,
        "cityID": 7194,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263074",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143644,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Heroica Zitacuaro",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152881908",
        "ip": "2806:265:1400:f9d:614a:7444:4894:15b5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:08:40",
        "isSmartClick": "0",
        "email": "gdilangiogarcia2@gmail.com",
        "name": "Dilan giovani Garcia cuevas",
        "firstName": "Dilan giovani",
        "lastName": "Garcia cuevas",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775322",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275684316",
        "countryID": 143,
        "cityID": 12398,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263053",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143596,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152881810",
        "ip": "138.84.62.40",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:08:07",
        "isSmartClick": "0",
        "email": "gerardogarciamendez213@gmail.com",
        "name": "Gerardo  Garcia ",
        "firstName": "Gerardo ",
        "lastName": "Garcia ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775318",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275684156",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263051",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143521,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152881611",
        "ip": "200.68.138.12",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:07:14",
        "isSmartClick": "0",
        "email": "chejosecastillo15@gmail.com",
        "name": "Jose Marcos Castillo Hernandez ",
        "firstName": "Jose Marcos",
        "lastName": "Castillo Hernandez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775316",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275683823",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263036",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143395,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Puebla City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152881242",
        "ip": "2806:10a6:26:ebcd:41bb:27d5:aebf:eb97",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:06:13",
        "isSmartClick": "0",
        "email": "vanes0682@gmail.com",
        "name": "Vanessa  Ramirez ",
        "firstName": "Vanessa ",
        "lastName": "Ramirez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775307",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275683450",
        "countryID": 143,
        "cityID": 329,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263031",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143285,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Jiutepec",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152880950",
        "ip": "2806:2f0:a4c1:f80a:b864:3bf5:8e1:e334",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:04:53",
        "isSmartClick": "0",
        "email": "chaparramartinez1994@gmail.com",
        "name": "Lourdes  Martínez ",
        "firstName": "Lourdes ",
        "lastName": "Martínez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "775300",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275682956",
        "countryID": 143,
        "cityID": 16158,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14263017",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69143127,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zovto.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "152880566",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:03:20",
        "isSmartClick": "0",
        "email": "test9087ytop@gmail.com",
        "name": "test9087ytop test9087ytop",
        "firstName": "test9087ytop",
        "lastName": "test9087ytop",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12431",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275682486",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14262999",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69142827,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zovto.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "152879766",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 15:00:21",
        "isSmartClick": "0",
        "email": "testuio9p09oiyut@gmail.test",
        "name": "testuio9p09oiyut testuio9p09oiyut",
        "firstName": "testuio9p09oiyut",
        "lastName": "testuio9p09oiyut",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12430",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275681429",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14262968",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69141345,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Alkmaar",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152875792",
        "ip": "84.82.44.248",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 14:45:56",
        "isSmartClick": "0",
        "email": "Bas@cortol.nl",
        "name": "Bas Dekker",
        "firstName": "Bas",
        "lastName": "Dekker",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12429",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275678240",
        "countryID": 156,
        "cityID": 1885,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14262822",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69140446,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zwolle",
        "custom": null,
        "custom1": null,
        "custom2": "vamnio.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152873379",
        "ip": "2a02:a45b:4dde:1:9119:4338:3ca2:c3da",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 14:37:27",
        "isSmartClick": "0",
        "email": "jolandavandervegt1982@gmail.com",
        "name": "Jolanda  vegt",
        "firstName": "Jolanda ",
        "lastName": "vegt",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12428",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275676588",
        "countryID": 156,
        "cityID": 5049,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14262709",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69140053,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Musselkanaal",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152872299",
        "ip": "2a02:a45a:dff0:0:fdd0:8ace:5a3a:810f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 14:34:03",
        "isSmartClick": "0",
        "email": "chrishuiberts@hotmail.com",
        "name": "Chris Huiberts",
        "firstName": "Chris",
        "lastName": "Huiberts",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12427",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275675821",
        "countryID": 156,
        "cityID": 1968,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-04 16:16:15",
        "depositID": "20411384",
        "conversionID": "1217275",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14262671",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69137505,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152864530",
        "ip": "131.196.255.59",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 14:07:33",
        "isSmartClick": "0",
        "email": "test73192@gmail.com",
        "name": "test789087yt trywury7",
        "firstName": "test789087yt",
        "lastName": "trywury7",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "774961",
        "leadType": "1",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275670837",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14262346",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69136212,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Schiedam",
        "custom": null,
        "custom1": null,
        "custom2": "deammonation.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152859817",
        "ip": "62.45.116.28",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 13:54:13",
        "isSmartClick": "0",
        "email": "gusvanbeek@gmail.com",
        "name": "Gijs van Beek",
        "firstName": "Gijs",
        "lastName": "van Beek",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12426",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275668390",
        "countryID": 156,
        "cityID": 3781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIAL NOTICE: RTL Late Night launches their newest platform Orionis Prime to help families become wealthier.",
        "leadRequestOfferName": "Orionis Prime - Humberto Tan ",
        "leadRequestOfferWebsite": "Orionis Prime - Humberto Tan ",
        "brokerAutologinClick": "14262184",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69133510,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Tours",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152850438",
        "ip": "128.78.112.156",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 13:28:03",
        "isSmartClick": "0",
        "email": "patochedu37@gmail.com",
        "name": "Patrice Desbourdes",
        "firstName": "Patrice",
        "lastName": "Desbourdes",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffed65316b3",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Slip Away S",
        "saleStatusMapped": null,
        "clickID": "275663226",
        "countryID": 75,
        "cityID": 11472,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69132964,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152848452",
        "ip": "77.205.22.243",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 13:23:02",
        "isSmartClick": "0",
        "email": "kechadpeinture@gmail.com",
        "name": "Amar Kechad",
        "firstName": "Amar",
        "lastName": "Kechad",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffec38bdcc5",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer 3 S",
        "saleStatusMapped": null,
        "clickID": "275662159",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69132321,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "4ad3bcd8de",
        "affiliateID": "71365",
        "affiliateName": "Office2",
        "affiliateEmail": "office2@gmail2.com",
        "countryName": "Canada",
        "cityName": "Ottawa",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152846260",
        "ip": "2001:56b:9fe1:5fd2:0:64:52fb:3c01",
        "userAgent": "GuzzleHttp/6.5.5 curl/7.68.0 PHP/7.4.33",
        "platform": "Other",
        "browser": "curl",
        "signupDate": "2024-10-04 13:16:27",
        "isSmartClick": "0",
        "email": "stephenwhiteley484@gmail.com",
        "name": "Stephen Whiteley",
        "firstName": "Stephen",
        "lastName": "Whiteley",
        "brokerID": "18340",
        "brokerName": "Promoted",
        "customerID": "66ffeaad4b70b2dcf307d853",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275660848",
        "countryID": 40,
        "cityID": 805,
        "affiliateManagerEmail": "eva.leads2ftds@gmail.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "age => 66\ncountry => Canada\nlang => EN",
        "leadRequestOfferName": "QUANTUM",
        "leadRequestOfferWebsite": "quantunn.info?utm_source=t",
        "brokerAutologinClick": "14261848",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69131670,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zaltbommel",
        "custom": null,
        "custom1": null,
        "custom2": "zomria.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152844123",
        "ip": "84.80.127.42",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 13:10:43",
        "isSmartClick": "0",
        "email": "h.a.olree@gmail.com",
        "name": "Henk Olree",
        "firstName": "Henk",
        "lastName": "Olree",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12425",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275659649",
        "countryID": 156,
        "cityID": 12645,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14261766",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69131426,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zaandam",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152843232",
        "ip": "85.149.165.142",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 13:08:18",
        "isSmartClick": "0",
        "email": "Dennisgulen@gmail.com",
        "name": "Dennis Gulen",
        "firstName": "Dennis",
        "lastName": "Gulen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12424",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275659233",
        "countryID": 156,
        "cityID": 1566,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14261742",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69130525,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152840194",
        "ip": "37.168.118.147",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 13:00:03",
        "isSmartClick": "0",
        "email": "poucinette51100@gmail.com",
        "name": "Jessica Debaisieux",
        "firstName": "Jessica",
        "lastName": "Debaisieux",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffe6d57bcab",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer 3 S",
        "saleStatusMapped": null,
        "clickID": "275657480",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69129780,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Gannat",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152837713",
        "ip": "89.84.197.150",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 12:52:03",
        "isSmartClick": "0",
        "email": "carinedecrayssac15@gmail.com",
        "name": "Carine Chadefaux",
        "firstName": "Carine",
        "lastName": "Chadefaux",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffe4f5af48a",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Slip Away S",
        "saleStatusMapped": null,
        "clickID": "275656003",
        "countryID": 75,
        "cityID": 26296,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69129431,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Mernel",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152836525",
        "ip": "92.162.192.17",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 12:48:02",
        "isSmartClick": "0",
        "email": "l.s35370@hotmail.com",
        "name": "Loic Saliou",
        "firstName": "Loic",
        "lastName": "Saliou",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffe40444a0e",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer 3 S",
        "saleStatusMapped": null,
        "clickID": "275655274",
        "countryID": 75,
        "cityID": 59924,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69127471,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Saint-Michel-sur-Orge",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152830160",
        "ip": "88.180.124.202",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 12:29:02",
        "isSmartClick": "0",
        "email": "jeremy.dunand@hotmail.fr",
        "name": "Jeremy Dunand",
        "firstName": "Jeremy",
        "lastName": "Dunand",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffdf903a5dd",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hung Up S",
        "saleStatusMapped": null,
        "clickID": "275651490",
        "countryID": 75,
        "cityID": 9442,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69127239,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Antwerp",
        "custom": null,
        "custom1": null,
        "custom2": "unpetrify.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152829347",
        "ip": "2a02:1810:3e16:6500:7433:d3b1:dd78:1473",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 12:26:11",
        "isSmartClick": "0",
        "email": "Tom.laenens1@telenet.be",
        "name": "Sandy Laeremans",
        "firstName": "Sandy",
        "lastName": "Laeremans",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12423",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275651006",
        "countryID": 23,
        "cityID": 18,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14261270",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69126231,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Saint-Girons",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152826217",
        "ip": "176.137.79.72",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 12:16:55",
        "isSmartClick": "0",
        "email": "sylv1du09@gmail.com",
        "name": "Sylvain Amiour",
        "firstName": "Sylvain",
        "lastName": "Amiour",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffdcb9a1c93",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer 2 S",
        "saleStatusMapped": null,
        "clickID": "275649032",
        "countryID": 75,
        "cityID": 25418,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69124004,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zwijndrecht",
        "custom": null,
        "custom1": null,
        "custom2": "onanistic.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152818799",
        "ip": "2a02:a213:28c5:6700:c06e:5be9:d8f8:673d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 11:56:27",
        "isSmartClick": "0",
        "email": "skippy9764@hotmail.com",
        "name": "Ron van Beek",
        "firstName": "Ron",
        "lastName": "van Beek",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12422",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong number",
        "saleStatusMapped": "Wrong Info",
        "clickID": "275644930",
        "countryID": 156,
        "cityID": 6718,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14260965",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69123026,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Cannes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152815415",
        "ip": "88.163.31.213",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 11:47:45",
        "isSmartClick": "0",
        "email": "murielle.piparo@yahoo.fr",
        "name": "Maria Piparo",
        "firstName": "Maria",
        "lastName": "Piparo",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffd5e34188a",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hung Up S",
        "saleStatusMapped": null,
        "clickID": "275643081",
        "countryID": 75,
        "cityID": 4094,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69122567,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Alblasserdam",
        "custom": null,
        "custom1": null,
        "custom2": "unsatisfiedness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152814074",
        "ip": "109.36.153.200",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 11:44:16",
        "isSmartClick": "0",
        "email": "franktusek@gmail.com",
        "name": "Frank Tusek",
        "firstName": "Frank",
        "lastName": "Tusek",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12421",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275642265",
        "countryID": 156,
        "cityID": 1719,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14260816",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69122122,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Lorient",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152812754",
        "ip": "90.38.13.168",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 11:41:02",
        "isSmartClick": "0",
        "email": "daniel.kathelyn@orange.fr",
        "name": "Kathelyn Daniel",
        "firstName": "Kathelyn",
        "lastName": "Daniel",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffd4503fd2f",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer 3 S",
        "saleStatusMapped": null,
        "clickID": "275641409",
        "countryID": 75,
        "cityID": 8043,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69121497,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Woerden",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152810861",
        "ip": "2a02:a46b:4de9:1:2cdf:f839:c1eb:a42c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 11:35:42",
        "isSmartClick": "0",
        "email": "a.graveland2@kpnplanet.nl",
        "name": "Otto Graveland",
        "firstName": "Otto",
        "lastName": "Graveland",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12420",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "Reshuffle",
        "saleStatusMapped": "Reshuffle",
        "clickID": "275640201",
        "countryID": 156,
        "cityID": 6781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14260714",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69119743,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "unpetrify.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152805292",
        "ip": "94.108.6.107",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 11:17:20",
        "isSmartClick": "0",
        "email": "Versppierre@hotmail.com",
        "name": "Pierre Verspecht",
        "firstName": "Pierre",
        "lastName": "Verspecht",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12419",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275636448",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14260496",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69119068,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152802243",
        "ip": "2a02:8429:4d8d:2801:c8c5:dad3:7f8c:a884",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 11:09:02",
        "isSmartClick": "0",
        "email": "richardcaramanolis@icloud.com",
        "name": "Michel Caramanolis",
        "firstName": "Michel",
        "lastName": "Caramanolis",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffccd065453",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No Answer 3 S",
        "saleStatusMapped": null,
        "clickID": "275634229",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69117499,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152796315",
        "ip": "146.70.194.8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 10:56:02",
        "isSmartClick": "0",
        "email": "uuuiisidorov@gmail.com",
        "name": "Petr Sidorov",
        "firstName": "Petr",
        "lastName": "Sidorov",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffc9c513d3d",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Wrong number S",
        "saleStatusMapped": null,
        "clickID": "275630629",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69112645,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152781031",
        "ip": "141.101.76.60",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 10:21:17",
        "isSmartClick": "0",
        "email": "Defrutgers@gmail.com",
        "name": "Danny  Rutgers ",
        "firstName": "Danny ",
        "lastName": "Rutgers ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12418",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275619994",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-04 10:57:26",
        "depositID": "20411038",
        "conversionID": "1217040",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14259769",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69111611,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Charleville-Mézières",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152777488",
        "ip": "90.109.210.12",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 10:11:02",
        "isSmartClick": "0",
        "email": "laurelouise.massart@laposte.net",
        "name": "Laure Massart",
        "firstName": "Laure",
        "lastName": "Massart",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffbf3837e3d",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hung Up S",
        "saleStatusMapped": null,
        "clickID": "275617622",
        "countryID": 75,
        "cityID": 19184,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69111586,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Flushing",
        "custom": null,
        "custom1": null,
        "custom2": "vesnioa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152777426",
        "ip": "62.45.223.153",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 10:10:45",
        "isSmartClick": "0",
        "email": "murene9@gmail.com",
        "name": "Anton Goes",
        "firstName": "Anton",
        "lastName": "Goes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12417",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275617586",
        "countryID": 156,
        "cityID": 1081,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14259617",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69109749,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Hasselt",
        "custom": null,
        "custom1": null,
        "custom2": "sacrilegiousness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152771452",
        "ip": "2a02:1810:9535:3f00:5cc4:3842:190c:a7e2",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 09:54:10",
        "isSmartClick": "0",
        "email": "thomasdedroog36@gmail.com",
        "name": "Thomas Dedroog",
        "firstName": "Thomas",
        "lastName": "Dedroog",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12416",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275613700",
        "countryID": 23,
        "cityID": 840,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14259372",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69107861,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Ingre",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152765971",
        "ip": "176.168.50.51",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 09:41:02",
        "isSmartClick": "0",
        "email": "boussaadanadia24@gmail.com",
        "name": "NADIA Boussaada",
        "firstName": "NADIA",
        "lastName": "Boussaada",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffb8300931f",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hung Up S",
        "saleStatusMapped": null,
        "clickID": "275610344",
        "countryID": 75,
        "cityID": 36941,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69106781,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Aalsmeer",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152762982",
        "ip": "2a02:a420:25f:249b:45ee:e34:d644:e91c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 09:33:31",
        "isSmartClick": "0",
        "email": "jjpruissen@gmail.com",
        "name": "Jos Pruissen",
        "firstName": "Jos",
        "lastName": "Pruissen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12415",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275608409",
        "countryID": 156,
        "cityID": 1761,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14259016",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69106714,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "565627a4cd",
        "affiliateID": "67016",
        "affiliateName": "Eva",
        "affiliateEmail": "eva.leads2ftds@gmail.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152762811",
        "ip": "37.252.225.132",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 09:33:02",
        "isSmartClick": "0",
        "email": "piguet@club-internet.frr",
        "name": "Hubert PIGUET",
        "firstName": "Hubert",
        "lastName": "PIGUET",
        "brokerID": "24191",
        "brokerName": "1X",
        "customerID": "66ffb6509f07a",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hung Up S",
        "saleStatusMapped": null,
        "clickID": "275608286",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French",
        "localeCode": "fr",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69105034,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Lummen",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152758364",
        "ip": "84.192.45.193",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 09:24:23",
        "isSmartClick": "0",
        "email": "test123asdhqwuqwqbe@gmail.com",
        "name": "test123asdhqwuqwqbe test123asdhqwuqwqbe",
        "firstName": "test123asdhqwuqwqbe",
        "lastName": "test123asdhqwuqwqbe",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12414",
        "leadType": "1",
        "countryCode": "BE",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275605325",
        "countryID": 23,
        "cityID": 4777,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14258843",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69103721,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Marcinelle",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152755365",
        "ip": "193.190.64.17",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 09:18:03",
        "isSmartClick": "0",
        "email": "testtestftvschabc@gmail.com",
        "name": "tetadtasd teatdvad",
        "firstName": "tetadtasd",
        "lastName": "teatdvad",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12413",
        "leadType": "1",
        "countryCode": "BE",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275603253",
        "countryID": 23,
        "cityID": 8868,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14258739",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69102353,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152752028",
        "ip": "178.51.32.95",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 09:12:11",
        "isSmartClick": "0",
        "email": "dcwolfje@gmail.com",
        "name": "Frank De Cock",
        "firstName": "Frank",
        "lastName": "De Cock",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12412",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275601153",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-04 10:58:01",
        "depositID": "20411015",
        "conversionID": "1217041",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14258615",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69100224,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Australia",
        "cityName": "Perth",
        "custom": null,
        "custom1": null,
        "custom2": "ctenolium.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "152746851",
        "ip": "120.16.31.98",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 08:59:39",
        "isSmartClick": "0",
        "email": "nodrag64@gmail.com",
        "name": "Julie Lambert",
        "firstName": "Julie",
        "lastName": "Lambert",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "c9d1821feaacd2a424267612bef76bda",
        "leadType": "0",
        "countryCode": "AU",
        "saleStatus": "shared",
        "saleStatusMapped": "Not interested",
        "clickID": "275597509",
        "countryID": 15,
        "cityID": 2,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Sonia Kruger is being sued by the Bank of Australia for comments she made on live broadcast",
        "leadRequestOfferName": "Immediate Edge (AU) Sonia Kruger_v1",
        "leadRequestOfferWebsite": "Immediate Edge (AU) Sonia Kruger_v1",
        "brokerAutologinClick": "14258395",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69099394,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152743856",
        "ip": "2a02:a45f:f4c8:1:1d6f:370d:9260:c3b4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 08:48:14",
        "isSmartClick": "0",
        "email": "j.s.flier@ziggo.nl",
        "name": "Joop Flier",
        "firstName": "Joop",
        "lastName": "Flier",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12411",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275595535",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14258160",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69098277,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Lelystad",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152740924",
        "ip": "87.208.135.190",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 08:38:38",
        "isSmartClick": "0",
        "email": "kennethmoria@hotmail.com",
        "name": "Kenneth Moria",
        "firstName": "Kenneth",
        "lastName": "Moria",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12410",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275593458",
        "countryID": 156,
        "cityID": 4093,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14257953",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69098009,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Mol",
        "custom": null,
        "custom1": null,
        "custom2": "hexacosane.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152740043",
        "ip": "2a02:a03f:8889:1c00:7508:7eaf:e7b6:12a5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 08:35:59",
        "isSmartClick": "0",
        "email": "Kennyvermant82@gmail.com",
        "name": "Kenny Vermant",
        "firstName": "Kenny",
        "lastName": "Vermant",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12409",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275592839",
        "countryID": 23,
        "cityID": 3228,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14257903",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69096174,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152734121",
        "ip": "172.71.182.60",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 08:16:38",
        "isSmartClick": "0",
        "email": "mjpljc@gmail.com",
        "name": "Maja Polojac",
        "firstName": "Maja",
        "lastName": "Polojac",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12408",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275588747",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14257397",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69096150,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Ede",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152734025",
        "ip": "89.205.140.238",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 08:16:25",
        "isSmartClick": "0",
        "email": "boefje.adriaensen@gmail.com",
        "name": "Jeroen Adriaensen",
        "firstName": "Jeroen",
        "lastName": "Adriaensen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12407",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275588690",
        "countryID": 156,
        "cityID": 1309,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14257392",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69094438,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Eindhoven",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152729039",
        "ip": "89.106.163.122",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 08:00:21",
        "isSmartClick": "0",
        "email": "Koekieco@gmail.com",
        "name": "Gerard Coenders",
        "firstName": "Gerard",
        "lastName": "Coenders",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12406",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275585265",
        "countryID": 156,
        "cityID": 1279,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256942",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69093852,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Saint-Etienne",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152726875",
        "ip": "176.190.39.152",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:55:33",
        "isSmartClick": "0",
        "email": "couassou99@gmail.com",
        "name": "Deborah Simon",
        "firstName": "Deborah",
        "lastName": "Simon",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "27eefa48-44d4-4d7c-ba24-3271c20d9af6",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "275583482",
        "countryID": 75,
        "cityID": 3919,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69093747,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Toulouse",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152726420",
        "ip": "92.88.170.78",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:53:57",
        "isSmartClick": "0",
        "email": "heude.jacques46@gmail.com",
        "name": "Jacques Heude",
        "firstName": "Jacques",
        "lastName": "Heude",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "a2563d51-bf1b-44ea-ae17-6ae5f75f9f60",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "275583109",
        "countryID": 75,
        "cityID": 1655,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14256864",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69093549,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152725806",
        "ip": "2a04:cec0:11cd:c1e4:0:4c:e73a:9401",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:50:47",
        "isSmartClick": "0",
        "email": "damien0067@yahoo.fr",
        "name": "Damien Hoffmann",
        "firstName": "Damien",
        "lastName": "Hoffmann",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "damien0067@yahoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275582688",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69093544,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152725784",
        "ip": "2a02:a464:8180:0:7da4:b1d0:6796:ad6d",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 07:50:42",
        "isSmartClick": "0",
        "email": "Peutoz_cliff@hotmail.com",
        "name": "Cliff Poeth",
        "firstName": "Cliff",
        "lastName": "Poeth",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12405",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275582679",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-04 08:48:01",
        "depositID": "20410873",
        "conversionID": "1216910",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256817",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69093499,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Lelystad",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152725642",
        "ip": "89.98.255.170",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 07:50:08",
        "isSmartClick": "0",
        "email": "sandra.berserik@gmail.com",
        "name": "Sandra Berserik",
        "firstName": "Sandra",
        "lastName": "Berserik",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12404",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275582589",
        "countryID": 156,
        "cityID": 4093,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256806",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69093297,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zeist",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152725000",
        "ip": "2a02:a420:22a:4c49:a4a:3213:c910:8152",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 07:46:15",
        "isSmartClick": "0",
        "email": "dirkjankuijpers@hotmail.com",
        "name": "Dirk-Jan Kuijpers",
        "firstName": "Dirk-Jan",
        "lastName": "Kuijpers",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12403",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275582122",
        "countryID": 156,
        "cityID": 2020,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256758",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69093204,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Peyrolles-en-Provence",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152724253",
        "ip": "2a01:e0a:a35:c5c0:75a4:f6a0:97b5:5f50",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:45:05",
        "isSmartClick": "0",
        "email": "vgene@hotmail.fr",
        "name": "genevieve vernhes",
        "firstName": "genevieve",
        "lastName": "vernhes",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "c12b7165-c32a-4a8d-a4fa-0c6e9cdcb5b5",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Wrong number",
        "saleStatusMapped": "Wrong Info",
        "clickID": "275581504",
        "countryID": 75,
        "cityID": 20467,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69093199,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Amancy",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152724237",
        "ip": "2001:861:2441:bc90:a8bc:4e00:3812:6c77",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:45:00",
        "isSmartClick": "0",
        "email": "mb5455nk@gmail.com",
        "name": "MarieBeatrice GUIBERT",
        "firstName": "MarieBeatrice",
        "lastName": "GUIBERT",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "fa483bcf-744f-408d-8aa3-f982d8d468eb",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "275581487",
        "countryID": 75,
        "cityID": 8400,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14256735",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69092749,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Bodegraven",
        "custom": null,
        "custom1": null,
        "custom2": "excudate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "152721961",
        "ip": "84.241.201.68",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 07:37:24",
        "isSmartClick": "0",
        "email": "dinonl@gmail.com",
        "name": "Elvedin Hodzic",
        "firstName": "Elvedin",
        "lastName": "Hodzic",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12402",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275579608",
        "countryID": 156,
        "cityID": 1938,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14256640",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69092602,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Groningen",
        "custom": null,
        "custom1": null,
        "custom2": "vesnioa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152721428",
        "ip": "2a02:a44d:bf36:1:f1ff:97b8:bc55:3a3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 07:35:15",
        "isSmartClick": "0",
        "email": "Ronvanderploeg01@gmail.com",
        "name": "Ron Van der Ploeg",
        "firstName": "Ron",
        "lastName": "Van der Ploeg",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12401",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275579232",
        "countryID": 156,
        "cityID": 1750,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-04 08:52:30",
        "depositID": "20410954",
        "conversionID": "1216931",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256606",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69091566,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152718709",
        "ip": "37.252.225.132",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:27:21",
        "isSmartClick": "0",
        "email": "piguet@club-internet.frr",
        "name": "Hubert PIGUET",
        "firstName": "Hubert",
        "lastName": "PIGUET",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "piguet@club-internet.frr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275577432",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69091095,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Groningen",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152717147",
        "ip": "85.145.38.254",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 07:21:14",
        "isSmartClick": "0",
        "email": "andreswab@outlook.com",
        "name": "André Swabedissen",
        "firstName": "André",
        "lastName": "Swabedissen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12400",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275576236",
        "countryID": 156,
        "cityID": 1750,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256455",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69090293,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152714571",
        "ip": "77.205.22.33",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:13:34",
        "isSmartClick": "0",
        "email": "delmas.monique@outlook.fr",
        "name": "Delmas Monique",
        "firstName": "Delmas",
        "lastName": "Monique",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "c7e07663-164b-4f6e-8621-402afdfcf381",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 3",
        "saleStatusMapped": null,
        "clickID": "275574699",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14256373",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69089490,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Morangis",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152712190",
        "ip": "2a01:e0a:3c3:4f50:b5d4:8e25:8a14:637b",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:07:51",
        "isSmartClick": "0",
        "email": "boze1964@gmail.com",
        "name": "Nebojsa Mirkovic",
        "firstName": "Nebojsa",
        "lastName": "Mirkovic",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "0d9924c1-d113-4f27-9934-4e9ab80931ca",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "275573400",
        "countryID": 75,
        "cityID": 8313,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69089063,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152711151",
        "ip": "37.166.116.147",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 07:02:33",
        "isSmartClick": "0",
        "email": "dxailly2@gmail.com",
        "name": "Didier Xailly",
        "firstName": "Didier",
        "lastName": "Xailly",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "636c7390-9b1d-49a4-8ba2-ae47af5b7367",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275572627",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-04 08:45:19",
        "depositID": "636c7390-9b1d-49a4-8ba2-ae47af5b7367",
        "conversionID": "1216892",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69088813,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152710513",
        "ip": "2a01:cb1e:11:34e5:b4b6:9da4:321f:71f3",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 06:59:28",
        "isSmartClick": "0",
        "email": "la.maevanne@gmail.com",
        "name": "Marie BIGNAND",
        "firstName": "Marie",
        "lastName": "BIGNAND",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "la.maevanne@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275572121",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69088482,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heesch",
        "custom": null,
        "custom1": null,
        "custom2": "vesnioa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152709397",
        "ip": "2a00:bba0:111e:b200:8c58:1fb6:a7d8:288b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:54:44",
        "isSmartClick": "0",
        "email": "goossenstimmerwerken@gmail.com",
        "name": "Mark Goossens",
        "firstName": "Mark",
        "lastName": "Goossens",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12399",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275571394",
        "countryID": 156,
        "cityID": 1549,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256218",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69088271,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Bornerbroek",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152708462",
        "ip": "84.84.214.172",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:51:50",
        "isSmartClick": "0",
        "email": "shirley_sjaam@hotmail.com",
        "name": "Mila Gowrising ",
        "firstName": "Mila",
        "lastName": "Gowrising ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12398",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275571024",
        "countryID": 156,
        "cityID": 28291,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256191",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69087829,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152706428",
        "ip": "77.205.22.243",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 06:46:13",
        "isSmartClick": "0",
        "email": "kechadpeinture@gmail.com",
        "name": "Amar Kechad",
        "firstName": "Amar",
        "lastName": "Kechad",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "kechadpeinture@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275570229",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69087746,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Villejuif",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152705948",
        "ip": "86.247.122.208",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 06:45:26",
        "isSmartClick": "0",
        "email": "Zk9493@hotmail.fr",
        "name": "Fateh Boustila",
        "firstName": "Fateh",
        "lastName": "Boustila",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "Zk9493@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275570065",
        "countryID": 75,
        "cityID": 4642,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69087282,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nistelrode",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152703659",
        "ip": "84.247.45.165",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:39:17",
        "isSmartClick": "0",
        "email": "plukginny@hotmail.com",
        "name": "Ginny Pluk",
        "firstName": "Ginny",
        "lastName": "Pluk",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12397",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275569185",
        "countryID": 156,
        "cityID": 40774,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256133",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69087103,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Tours",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152702945",
        "ip": "128.78.112.156",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 06:36:57",
        "isSmartClick": "0",
        "email": "patochedu37@gmail.com",
        "name": "Patrice Desbourdes",
        "firstName": "Patrice",
        "lastName": "Desbourdes",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "patochedu37@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275568838",
        "countryID": 75,
        "cityID": 11472,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69086761,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152701540",
        "ip": "172.71.102.29",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:32:25",
        "isSmartClick": "0",
        "email": "pietje_17-03@hotmail.com",
        "name": "Piet VanOs",
        "firstName": "Piet",
        "lastName": "VanOs",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12396",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275568181",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256101",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69086756,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Malden",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152701522",
        "ip": "31.137.24.21",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:32:19",
        "isSmartClick": "0",
        "email": "Cap_74@msn.com",
        "name": "Casper Lenten",
        "firstName": "Casper",
        "lastName": "Lenten",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12395",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275568171",
        "countryID": 156,
        "cityID": 6778,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256099",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69086678,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lyon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152700804",
        "ip": "78.201.113.30",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 06:31:09",
        "isSmartClick": "0",
        "email": "verriereedith@hotmail.fr",
        "name": "Edith VERRIERE",
        "firstName": "Edith",
        "lastName": "VERRIERE",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "verriereedith@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275567991",
        "countryID": 75,
        "cityID": 898,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69086298,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Naaldwijk",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152698731",
        "ip": "2a02:a442:42da:1:f6d3:bfe3:4e3:ec12",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:25:32",
        "isSmartClick": "0",
        "email": "hwjansz@hotmail.com",
        "name": "Harald Jansz",
        "firstName": "Harald",
        "lastName": "Jansz",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12394",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275567168",
        "countryID": 156,
        "cityID": 1564,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256058",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69086282,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Cannes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152698645",
        "ip": "88.163.31.213",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 06:25:23",
        "isSmartClick": "0",
        "email": "murielle.piparo@yahoo.fr",
        "name": "Maria Piparo",
        "firstName": "Maria",
        "lastName": "Piparo",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "murielle.piparo@yahoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275567139",
        "countryID": 75,
        "cityID": 4094,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69085933,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Duiven",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152696513",
        "ip": "2a02:a212:24c0:d300:c9e6:5c35:f6dd:3c0",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:19:28",
        "isSmartClick": "0",
        "email": "jeroen.jong@gmail.com",
        "name": "Jeroen De Jong",
        "firstName": "Jeroen",
        "lastName": "De Jong",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12393",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275566290",
        "countryID": 156,
        "cityID": 2678,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14256030",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69085909,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152696364",
        "ip": "172.70.47.159",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:19:12",
        "isSmartClick": "0",
        "email": "lexke1958@gmail.com",
        "name": "Alex Steenman",
        "firstName": "Alex",
        "lastName": "Steenman",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12392",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275566231",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14256025",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69085569,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lamorlaye",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152694229",
        "ip": "88.183.146.209",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 06:13:33",
        "isSmartClick": "0",
        "email": "henri.grz@outlook.fr",
        "name": "Henri Grzybinski",
        "firstName": "Henri",
        "lastName": "Grzybinski",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "henri.grz@outlook.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275565425",
        "countryID": 75,
        "cityID": 28250,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69085426,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152693555",
        "ip": "172.71.182.63",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 06:11:28",
        "isSmartClick": "0",
        "email": "test123nlsadfyqweiqw@gmail.com",
        "name": "test123nlsadfyqweiqw test123nlsadfyqweiqw",
        "firstName": "test123nlsadfyqweiqw",
        "lastName": "test123nlsadfyqweiqw",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12391",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275565169",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14256000",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69084932,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152691648",
        "ip": "37.168.118.147",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 06:05:53",
        "isSmartClick": "0",
        "email": "poucinette51100@gmail.com",
        "name": "Jessica Debaisieux",
        "firstName": "Jessica",
        "lastName": "Debaisieux",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "poucinette51100@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275564248",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69084428,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Annoeullin",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152689328",
        "ip": "37.65.38.25",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:59:30",
        "isSmartClick": "0",
        "email": "romualdponthieu09@gmail.com",
        "name": "Romuald Ponthieu",
        "firstName": "Romuald",
        "lastName": "Ponthieu",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "romualdponthieu09@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275563336",
        "countryID": 75,
        "cityID": 21862,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69083924,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Escalquens",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152687201",
        "ip": "88.160.50.181",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:53:50",
        "isSmartClick": "0",
        "email": "ocoeurdelame8@gmaill.com",
        "name": "Sylvie Tissedre",
        "firstName": "Sylvie",
        "lastName": "Tissedre",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ocoeurdelame8@gmaill.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275562535",
        "countryID": 75,
        "cityID": 39577,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69083666,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Rilly-la-Montagne",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152686012",
        "ip": "88.124.219.1",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:50:25",
        "isSmartClick": "0",
        "email": "severinemarmottemartin5@gmail.com",
        "name": "severine marmotte",
        "firstName": "severine",
        "lastName": "marmotte",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "severinemarmottemartin5@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275562052",
        "countryID": 75,
        "cityID": 85593,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69083646,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Doetinchem",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152685796",
        "ip": "2a02:a213:9a42:2900:5544:4fb2:ab0f:5587",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-04 05:50:05",
        "isSmartClick": "0",
        "email": "tomsteghuis@gmail.com",
        "name": "Tom Steghuis",
        "firstName": "Tom",
        "lastName": "Steghuis",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12390",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275561994",
        "countryID": 156,
        "cityID": 125,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14255908",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69083455,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152684689",
        "ip": "2a01:cb01:102c:e3c8:0:5f:30f3:a201",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:46:56",
        "isSmartClick": "0",
        "email": "quillecdidier@gmail.com",
        "name": "Didier Quillec",
        "firstName": "Didier",
        "lastName": "Quillec",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "quillecdidier@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275561559",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69083186,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152683630",
        "ip": "37.165.95.254",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:43:26",
        "isSmartClick": "0",
        "email": "martinbertr@gmail.com",
        "name": "Bertrand Martin",
        "firstName": "Bertrand",
        "lastName": "Martin",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "martinbertr@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275561086",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69083142,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Labouheyre",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152683423",
        "ip": "176.141.212.233",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:42:36",
        "isSmartClick": "0",
        "email": "sylvielpr@gmail.com",
        "name": "Sylvie Lepere",
        "firstName": "Sylvie",
        "lastName": "Lepere",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "sylvielpr@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275560955",
        "countryID": 75,
        "cityID": 43284,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69082842,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Nantes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152682155",
        "ip": "79.95.87.157",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:38:51",
        "isSmartClick": "0",
        "email": "yoann.lebrun34@gmail.com",
        "name": "yoann lebrun",
        "firstName": "yoann",
        "lastName": "lebrun",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "yoann.lebrun34@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275560373",
        "countryID": 75,
        "cityID": 510,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69082807,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Prevessin-Moens",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152682037",
        "ip": "86.200.123.60",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:38:34",
        "isSmartClick": "0",
        "email": "sofilayna@gmail.com",
        "name": "Sofi Zeqiri",
        "firstName": "Sofi",
        "lastName": "Zeqiri",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "sofilayna@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275560321",
        "countryID": 75,
        "cityID": 30061,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69082522,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Ingre",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152680801",
        "ip": "176.168.50.51",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:35:03",
        "isSmartClick": "0",
        "email": "boussaadanadia24@gmail.com",
        "name": "NADIA Boussaada",
        "firstName": "NADIA",
        "lastName": "Boussaada",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "boussaadanadia24@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275559828",
        "countryID": 75,
        "cityID": 36941,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69082271,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Limoges",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152679391",
        "ip": "86.205.72.182",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:31:38",
        "isSmartClick": "0",
        "email": "ferdinand.julien87@gmail.com",
        "name": "Julien Ferdinand",
        "firstName": "Julien",
        "lastName": "Ferdinand",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ferdinand.julien87@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275559307",
        "countryID": 75,
        "cityID": 12651,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69081943,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Romans-sur-Isère",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152678084",
        "ip": "86.221.166.86",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:27:22",
        "isSmartClick": "0",
        "email": "kamelsalhi955@gmail.com",
        "name": "Kamel Salhi",
        "firstName": "Kamel",
        "lastName": "Salhi",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "kamelsalhi955@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275558714",
        "countryID": 75,
        "cityID": 1205,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69081719,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152676781",
        "ip": "92.184.112.125",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:24:21",
        "isSmartClick": "0",
        "email": "jloris06@gmail.com",
        "name": "Lorris Joseph",
        "firstName": "Lorris",
        "lastName": "Joseph",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jloris06@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275558323",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69081546,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152675931",
        "ip": "37.67.156.139",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:22:08",
        "isSmartClick": "0",
        "email": "locif.tizouiar@yahoo.com",
        "name": "Locif Tizouiar",
        "firstName": "Locif",
        "lastName": "Tizouiar",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "locif.tizouiar@yahoo.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275558026",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69081437,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Charleville-Mézières",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152675439",
        "ip": "90.109.210.12",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:20:54",
        "isSmartClick": "0",
        "email": "laurelouise.massart@laposte.net",
        "name": "Laure Massart",
        "firstName": "Laure",
        "lastName": "Massart",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "laurelouise.massart@laposte.net",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275557843",
        "countryID": 75,
        "cityID": 19184,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69080763,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152672607",
        "ip": "92.184.119.114",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:12:07",
        "isSmartClick": "0",
        "email": "marieorhon22@gmail.com",
        "name": "Marie Guillope",
        "firstName": "Marie",
        "lastName": "Guillope",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "marieorhon22@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275556730",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69080614,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152672005",
        "ip": "80.214.74.42",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:09:30",
        "isSmartClick": "0",
        "email": "aguilbaud24@yahoo.com",
        "name": "Anais Guilbaud",
        "firstName": "Anais",
        "lastName": "Guilbaud",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "aguilbaud24@yahoo.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275556464",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69079959,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152668819",
        "ip": "89.86.75.129",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 05:00:29",
        "isSmartClick": "0",
        "email": "abruchon71@gmail.com",
        "name": "Angelique Bruchon",
        "firstName": "Angelique",
        "lastName": "Bruchon",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "abruchon71@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275555325",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69078365,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Tourville-sur-Arques",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152662677",
        "ip": "90.108.48.189",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 04:43:16",
        "isSmartClick": "0",
        "email": "raissaleloup1108@gmail.com",
        "name": "Raissa Leloup",
        "firstName": "Raissa",
        "lastName": "Leloup",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "raissaleloup1108@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275552740",
        "countryID": 75,
        "cityID": 100606,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69078195,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Montauban",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152661733",
        "ip": "86.249.166.121",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 04:40:35",
        "isSmartClick": "0",
        "email": "marjorie-tournier@laposte.net",
        "name": "Marjorie Marceau",
        "firstName": "Marjorie",
        "lastName": "Marceau",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "marjorie-tournier@laposte.net",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275552336",
        "countryID": 75,
        "cityID": 7541,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69078142,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Juillac",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152661125",
        "ip": "88.166.169.150",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 04:39:27",
        "isSmartClick": "0",
        "email": "s.steve19350@gmail.com",
        "name": "steve Spadavecchia",
        "firstName": "steve",
        "lastName": "Spadavecchia",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "s.steve19350@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275552190",
        "countryID": 75,
        "cityID": 74918,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69077595,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Rennes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152655071",
        "ip": "81.53.94.84",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 04:27:39",
        "isSmartClick": "0",
        "email": "meyeraurelie01@gmail.com",
        "name": "Aurelie Meyet",
        "firstName": "Aurelie",
        "lastName": "Meyet",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "meyeraurelie01@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275550763",
        "countryID": 75,
        "cityID": 2952,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69077313,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152652425",
        "ip": "92.184.96.112",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 04:22:30",
        "isSmartClick": "0",
        "email": "laurent.will12@gmail.com",
        "name": "Laurent Will",
        "firstName": "Laurent",
        "lastName": "Will",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "laurent.will12@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275550115",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69076967,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152649073",
        "ip": "93.23.15.116",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 04:16:13",
        "isSmartClick": "0",
        "email": "vidiapass@gmail.com",
        "name": "Vidia Nowbuth",
        "firstName": "Vidia",
        "lastName": "Nowbuth",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "vidiapass@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275549316",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69076940,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152648848",
        "ip": "87.88.164.115",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 04:15:36",
        "isSmartClick": "0",
        "email": "dianeduong33300@gmail.com",
        "name": "Diane Duong",
        "firstName": "Diane",
        "lastName": "Duong",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "dianeduong33300@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275549238",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69076493,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Fleury-Merogis",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152645577",
        "ip": "88.170.18.2",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 04:08:10",
        "isSmartClick": "0",
        "email": "segeveber@aol.com",
        "name": "Serge Veber",
        "firstName": "Serge",
        "lastName": "Veber",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "segeveber@aol.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275548404",
        "countryID": 75,
        "cityID": 30299,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69074936,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Mernel",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152633776",
        "ip": "92.162.192.17",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 03:38:27",
        "isSmartClick": "0",
        "email": "l.s35370@hotmail.com",
        "name": "Loic Saliou",
        "firstName": "Loic",
        "lastName": "Saliou",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "l.s35370@hotmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275545304",
        "countryID": 75,
        "cityID": 59924,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69074089,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152627615",
        "ip": "92.184.119.138",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 03:21:28",
        "isSmartClick": "0",
        "email": "mikapol@yahoo.fr",
        "name": "Mickael Polvent",
        "firstName": "Mickael",
        "lastName": "Polvent",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "mikapol@yahoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275543612",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69073697,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Saint-Michel-sur-Orge",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152625188",
        "ip": "88.180.124.202",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 03:14:00",
        "isSmartClick": "0",
        "email": "jeremy.dunand@hotmail.fr",
        "name": "Jeremy Dunand",
        "firstName": "Jeremy",
        "lastName": "Dunand",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jeremy.dunand@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275542909",
        "countryID": 75,
        "cityID": 9442,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69073291,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Diemeringen",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152622554",
        "ip": "90.126.101.203",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 03:07:29",
        "isSmartClick": "0",
        "email": "moritz.g.muller@gmail.com",
        "name": "Maurice Muller",
        "firstName": "Maurice",
        "lastName": "Muller",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "moritz.g.muller@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275542218",
        "countryID": 75,
        "cityID": 41632,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69073248,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Toulouse",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152622359",
        "ip": "92.88.171.240",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 03:07:11",
        "isSmartClick": "0",
        "email": "stephane.lebricquir@sfr.fr",
        "name": "Stephane Le Bricquir",
        "firstName": "Stephane",
        "lastName": "Le Bricquir",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "stephane.lebricquir@sfr.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275542167",
        "countryID": 75,
        "cityID": 1655,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69073014,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152621103",
        "ip": "37.166.13.240",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 03:02:36",
        "isSmartClick": "0",
        "email": "luvuma.sofia@gmail.com",
        "name": "Sofia Luvuma",
        "firstName": "Sofia",
        "lastName": "Luvuma",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "luvuma.sofia@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275541728",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69072321,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Gannat",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152617355",
        "ip": "89.84.197.150",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 02:51:43",
        "isSmartClick": "0",
        "email": "carinedecrayssac15@gmail.com",
        "name": "Carine Chadefaux",
        "firstName": "Carine",
        "lastName": "Chadefaux",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "carinedecrayssac15@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275540592",
        "countryID": 75,
        "cityID": 26296,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69072020,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Brie-Comte-Robert",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152615778",
        "ip": "88.124.41.45",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 02:46:58",
        "isSmartClick": "0",
        "email": "pilotefafe@hotmail.fr",
        "name": "Fernando Freitas da cunha",
        "firstName": "Fernando",
        "lastName": "Freitas da cunha",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "pilotefafe@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275540086",
        "countryID": 75,
        "cityID": 24893,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69071897,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Limoges",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152615177",
        "ip": "128.78.9.62",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 02:44:14",
        "isSmartClick": "0",
        "email": "emmanuelbegnis@orange.fr",
        "name": "Emmanuel Begnis",
        "firstName": "Emmanuel",
        "lastName": "Begnis",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "emmanuelbegnis@orange.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275539869",
        "countryID": 75,
        "cityID": 12651,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69070606,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152608461",
        "ip": "37.166.162.187",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 02:23:47",
        "isSmartClick": "0",
        "email": "larriere.virginie@wanadoo.fr",
        "name": "Virginie Larriere",
        "firstName": "Virginie",
        "lastName": "Larriere",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "larriere.virginie@wanadoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275537629",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69070190,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Thiberville",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152605723",
        "ip": "88.163.158.33",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 02:17:08",
        "isSmartClick": "0",
        "email": "sonia-lerat89@hotmail.fr",
        "name": "Sonia Lerat",
        "firstName": "Sonia",
        "lastName": "Lerat",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "sonia-lerat89@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275536889",
        "countryID": 75,
        "cityID": 27471,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69069769,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Saint-Girons",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152603205",
        "ip": "176.137.79.72",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 02:09:39",
        "isSmartClick": "0",
        "email": "sylv1du09@gmail.com",
        "name": "Sylvain Amiour",
        "firstName": "Sylvain",
        "lastName": "Amiour",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "sylv1du09@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275536131",
        "countryID": 75,
        "cityID": 25418,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69069592,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152601992",
        "ip": "92.184.104.185",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 02:06:48",
        "isSmartClick": "0",
        "email": "stephane.dezempte@gmail.com",
        "name": "Stephane Dezempte",
        "firstName": "Stephane",
        "lastName": "Dezempte",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "stephane.dezempte@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275535823",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69068127,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152581665",
        "ip": "37.174.147.100",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 01:29:03",
        "isSmartClick": "0",
        "email": "bhoucine425@gmail.com",
        "name": "Houcine Bouzidi",
        "firstName": "Houcine",
        "lastName": "Bouzidi",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "bhoucine425@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275531355",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69066558,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Auch",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152565646",
        "ip": "2001:861:9040:4400:a10f:61df:8b9d:6057",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 00:58:57",
        "isSmartClick": "0",
        "email": "bourvil09000@hotmail.fr",
        "name": "Laurent Clabaux",
        "firstName": "Laurent",
        "lastName": "Clabaux",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "bourvil09000@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275527642",
        "countryID": 75,
        "cityID": 4768,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69065399,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152554658",
        "ip": "37.165.0.174",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 00:34:44",
        "isSmartClick": "0",
        "email": "nourouali510@gmail.com",
        "name": "Abdou Ali Nourou",
        "firstName": "Abdou",
        "lastName": "Ali Nourou",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "nourouali510@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275524941",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69064557,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Aulnay",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152548165",
        "ip": "86.239.43.137",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 00:18:32",
        "isSmartClick": "0",
        "email": "angie230987@hotmail.fr",
        "name": "Angie Gaborieau",
        "firstName": "Angie",
        "lastName": "Gaborieau",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "angie230987@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275523164",
        "countryID": 75,
        "cityID": 10321,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69063674,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Reims",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152543952",
        "ip": "79.84.240.135",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-04 00:06:26",
        "isSmartClick": "0",
        "email": "jeanphilippejumeaux150@gmail.com",
        "name": "jeanphilippe jumeaux",
        "firstName": "jeanphilippe",
        "lastName": "jumeaux",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jeanphilippejumeaux150@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275521780",
        "countryID": 75,
        "cityID": 1919,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69062322,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152537835",
        "ip": "192.141.246.146",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 23:09:07",
        "isSmartClick": "0",
        "email": "primitivo9231@gmail.com",
        "name": "Primitivo  Yerena Castro ",
        "firstName": "Primitivo ",
        "lastName": "Yerena Castro ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "773475",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "FTD",
        "saleStatusMapped": "Converted",
        "clickID": "275518517",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-05 16:54:40",
        "depositID": "773475",
        "conversionID": "1217475",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69061532,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152534323",
        "ip": "200.68.167.61",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 22:21:54",
        "isSmartClick": "0",
        "email": "gr0707435@gmail.com",
        "name": "Eileen Zaizar",
        "firstName": "Eileen",
        "lastName": "Zaizar",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "773422",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275515902",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69061482,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "El Salto",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152534163",
        "ip": "2806:103e:14:2b6e:9b1a:cd8b:375c:e0dc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 22:19:16",
        "isSmartClick": "0",
        "email": "fcobos725@gmail.com",
        "name": "Francis Cobos",
        "firstName": "Francis",
        "lastName": "Cobos",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "773419",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275515749",
        "countryID": 143,
        "cityID": 58280,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69061168,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152533081",
        "ip": "2806:103e:19:6f6f:48dd:9197:9131:cbf9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 22:04:06",
        "isSmartClick": "0",
        "email": "dominguezgileduardo34@gmail.com",
        "name": "Eduardo Dominguez",
        "firstName": "Eduardo",
        "lastName": "Dominguez",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "604867",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "275514849",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69060829,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Benito Juarez",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152531574",
        "ip": "2806:2f0:a440:1698:cd39:fd9:d098:cf88",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 21:47:59",
        "isSmartClick": "0",
        "email": "contrerasruizsaul72@gmail.com",
        "name": "Saul Isaías  Contreras Ruiz ",
        "firstName": "Saul Isaías ",
        "lastName": "Contreras Ruiz ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "773387",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275513769",
        "countryID": 143,
        "cityID": 15867,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69060768,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "onanistic.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152531347",
        "ip": "95.96.1.12",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 21:44:46",
        "isSmartClick": "0",
        "email": "rmdriesten@gmail.com",
        "name": "Reina Driesten",
        "firstName": "Reina",
        "lastName": "Driesten",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12389",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275513555",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14254572",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69060546,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zoetermeer",
        "custom": null,
        "custom1": null,
        "custom2": "onanistic.com",
        "custom3": null,
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152530419",
        "ip": "86.86.169.31",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 21:34:07",
        "isSmartClick": "0",
        "email": "Pierre_Kirindongo@hotmail.com",
        "name": "Pierre  Kirindongo",
        "firstName": "Pierre ",
        "lastName": "Kirindongo",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12388",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275512832",
        "countryID": 156,
        "cityID": 1565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Katja Schuurman had spijt dat ze de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen Chantal Janzen per ongeluk haar geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro Katja Schuurman (NL)",
        "leadRequestOfferWebsite": "Trade Avapro Katja Schuurman (NL)",
        "brokerAutologinClick": "14254542",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69060529,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Morelia",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152530371",
        "ip": "2806:103e:2d:631a:35e9:26c7:ce37:afa3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 21:33:15",
        "isSmartClick": "0",
        "email": "oscar.guillermo.hd@gmail.com",
        "name": "Oscar Guillermo  Hernández Ortiz ",
        "firstName": "Oscar Guillermo ",
        "lastName": "Hernández Ortiz ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "773359",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275512780",
        "countryID": 143,
        "cityID": 3225,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69060332,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuautitlán Izcalli",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152529497",
        "ip": "2806:105e:17:abf:c0ad:d315:d2ca:1b76",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 21:22:31",
        "isSmartClick": "0",
        "email": "buyopipol@gmail.com",
        "name": "Eulogio Felipe  Delgadillo alva ",
        "firstName": "Eulogio Felipe ",
        "lastName": "Delgadillo alva ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "773345",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275512066",
        "countryID": 143,
        "cityID": 3577,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69060113,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlalnepantla",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152528480",
        "ip": "200.63.43.140",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 21:12:08",
        "isSmartClick": "0",
        "email": "marcoantoniomondragonrodriges@gmail.com",
        "name": "Marco Antonio  Mondragon Rodriguez ",
        "firstName": "Marco Antonio ",
        "lastName": "Mondragon Rodriguez ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "773328",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Unqualified",
        "saleStatusMapped": "Non Eligible",
        "clickID": "275511310",
        "countryID": 143,
        "cityID": 10440,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69059221,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Bunschoten",
        "custom": null,
        "custom1": null,
        "custom2": "infrastapedial.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "152524749",
        "ip": "80.114.228.188",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 20:33:12",
        "isSmartClick": "0",
        "email": "test123nlsdfhyuweuyq@gmail.com",
        "name": "test123nlsdfhyuweuyq test123nlsdfhyuweuyq",
        "firstName": "test123nlsdfhyuweuyq",
        "lastName": "test123nlsdfhyuweuyq",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12387",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275507802",
        "countryID": 156,
        "cityID": 1136,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14254369",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69058793,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152522922",
        "ip": "172.71.102.54",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 20:15:31",
        "isSmartClick": "0",
        "email": "Franksiroen@gmail.com",
        "name": "Frank  Siroen ",
        "firstName": "Frank ",
        "lastName": "Siroen ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12386",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275505842",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14254316",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69058452,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tuxpam de Rodriguez Cano",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152521635",
        "ip": "187.251.139.2",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 20:02:18",
        "isSmartClick": "0",
        "email": "bramonantonio@gmail.com",
        "name": "Antonio Barrios",
        "firstName": "Antonio",
        "lastName": "Barrios",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "773157",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275504484",
        "countryID": 143,
        "cityID": 11650,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14254245",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69058137,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Haarlem",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152520473",
        "ip": "2a02:a442:8e93:1:dba1:b720:2a63:3d74",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:51:06",
        "isSmartClick": "0",
        "email": "Willemoverzee9@gmail.com",
        "name": "Willem Stoker",
        "firstName": "Willem",
        "lastName": "Stoker",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12385",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275503056",
        "countryID": 156,
        "cityID": 494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14254211",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69057969,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152519814",
        "ip": "201.175.181.93",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:44:53",
        "isSmartClick": "0",
        "email": "ayalairmayolanda365@gmail.com",
        "name": "Irma yolanda Ayala Gordillo ",
        "firstName": "Irma yolanda",
        "lastName": "Ayala Gordillo ",
        "brokerID": "26519",
        "brokerName": "Ventury",
        "customerID": "573849",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reopen",
        "saleStatusMapped": null,
        "clickID": "275501129",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69057949,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152519747",
        "ip": "189.144.198.241",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:44:02",
        "isSmartClick": "0",
        "email": "martinianomarquez769@gmail.com",
        "name": "MARTINIANO MARQUEZ ORTIZ",
        "firstName": "MARTINIANO",
        "lastName": "MARQUEZ ORTIZ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "1e6b23b2-20e7-4c85-8206-dcd848831200",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Potential_Low",
        "saleStatusMapped": null,
        "clickID": "275500887",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Mexico&#39;s National Bank sues Galilea Montijo for what she said on live TV. Everyone in Mexico should know the truth!",
        "leadRequestOfferName": "Bitcoineer Galilea Montijo-MX",
        "leadRequestOfferWebsite": "Bitcoineer Galilea Montijo-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69057774,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlaquepaque",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152519129",
        "ip": "177.249.172.211",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:37:02",
        "isSmartClick": "0",
        "email": "maricelabernaltorres@gmail.com",
        "name": "Maricela  Bernal Torres ",
        "firstName": "Maricela ",
        "lastName": "Bernal Torres ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "9cc56eff-b8ed-4e40-8365-549777657a80",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275498757",
        "countryID": 143,
        "cityID": 15180,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69057478,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152518261",
        "ip": "189.203.63.158",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:29:02",
        "isSmartClick": "0",
        "email": "fhugovalgal@gmail.com",
        "name": "Fernando hugo  Valenzuela Galván ",
        "firstName": "Fernando hugo ",
        "lastName": "Valenzuela Galván ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "c9e912e6-914a-4758-8482-7bec1872d5b7",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reassign",
        "saleStatusMapped": "Call again",
        "clickID": "275496224",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69057190,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152517359",
        "ip": "131.196.255.59",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:21:11",
        "isSmartClick": "0",
        "email": "testleadst89y@gmail.com",
        "name": "testleadst89y testleadst89y",
        "firstName": "testleadst89y",
        "lastName": "testleadst89y",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "58721ea9-1181-4394-98b9-ecd8e6deda1b",
        "leadType": "1",
        "countryCode": "MX",
        "saleStatus": "Trash",
        "saleStatusMapped": "Canceled",
        "clickID": "275493691",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69056960,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mérida",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152516788",
        "ip": "190.123.12.154",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:17:02",
        "isSmartClick": "0",
        "email": "juanbojorquezrebolledo@gmail.com",
        "name": "Juan José Bojoquez solis",
        "firstName": "Juan José",
        "lastName": "Bojoquez solis",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "33fd05ae-d7dd-4590-92e2-6133637eb7ff",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reassign",
        "saleStatusMapped": "Call again",
        "clickID": "275492221",
        "countryID": 143,
        "cityID": 366,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69056674,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152516049",
        "ip": "2605:59c0:42ef:6010:40a5:e2fd:b9cf:3089",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:12:01",
        "isSmartClick": "0",
        "email": "jesusroberto@gmail.com",
        "name": "Jesus gabriel Montes carrillo",
        "firstName": "Jesus gabriel",
        "lastName": "Montes carrillo",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "9854f3c1-f4cc-41d8-acac-4bd46b72516c",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275490511",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14254031",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69056300,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlalnepantla",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152514495",
        "ip": "2806:370:983d:3f38:ed71:b4d5:2aeb:2047",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:04:55",
        "isSmartClick": "0",
        "email": "heriber.sam@gmail.com",
        "name": "Heriberto Lorenzo Sánchez ",
        "firstName": "Heriberto Lorenzo",
        "lastName": "Sánchez ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "fdedb8f6-6756-4af6-a398-ef29460c9689",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275487922",
        "countryID": 143,
        "cityID": 10440,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14254003",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69056130,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Klazienaveen",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152513862",
        "ip": "2001:1c01:4121:f500:d4b9:9a94:d143:d841",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:01:51",
        "isSmartClick": "0",
        "email": "erichaikens@home.nl",
        "name": "Eric Haikens",
        "firstName": "Eric",
        "lastName": "Haikens",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12384",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275486836",
        "countryID": 156,
        "cityID": 2480,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14253992",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69056090,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152513335",
        "ip": "2806:102e:22:2570:41b2:3eb9:192d:1c31",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 19:00:23",
        "isSmartClick": "0",
        "email": "ariasparra59@gmail.com",
        "name": "Alfonso  Arias Parra ariasparra59@gmail.com",
        "firstName": "Alfonso ",
        "lastName": "Arias Parra ariasparra59@gmail.com",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "57b0f332-e7dc-4a12-a8e9-947b9dc721ed",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275486337",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14253989",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69055875,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlalnepantla",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152511884",
        "ip": "189.203.51.94",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:55:55",
        "isSmartClick": "0",
        "email": "Chismosos@gmail.com",
        "name": "Chismosos Chismosos",
        "firstName": "Chismosos",
        "lastName": "Chismosos",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "63f2b83a-2a3c-452b-84f6-b6ae13599c91",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Wrong_Info",
        "saleStatusMapped": null,
        "clickID": "275484852",
        "countryID": 143,
        "cityID": 10440,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14253978",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69055802,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152511535",
        "ip": "172.71.103.133",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:54:18",
        "isSmartClick": "0",
        "email": "Sandergrootnibbelink@gmail.com",
        "name": "Sander Groot Nibbelink ",
        "firstName": "Sander",
        "lastName": "Groot Nibbelink ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12383",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275484379",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14253976",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69055208,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152508103",
        "ip": "189.203.63.4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:42:02",
        "isSmartClick": "0",
        "email": "luisandresmorales56@gmail.com",
        "name": "Luis Andres Morales Guadarrama",
        "firstName": "Luis Andres",
        "lastName": "Morales Guadarrama",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "693ea68c-541b-45b2-90d7-dd8b3a6065d3",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275480480",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69054845,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152506996",
        "ip": "172.71.102.163",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:34:37",
        "isSmartClick": "0",
        "email": "tom-deboer@outlook.com",
        "name": "Tom de Boer",
        "firstName": "Tom",
        "lastName": "de Boer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12382",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275478451",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14253901",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69054797,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152506821",
        "ip": "172.71.103.80",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:33:20",
        "isSmartClick": "0",
        "email": "stankeizers@hotmail.com",
        "name": "Stan Keizers",
        "firstName": "Stan",
        "lastName": "Keizers",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12381",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275478104",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14253895",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69054691,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152506485",
        "ip": "172.71.183.26",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:31:14",
        "isSmartClick": "0",
        "email": "mathenanne@gmail.com",
        "name": "Mathe Nanne",
        "firstName": "Mathe",
        "lastName": "Nanne",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12380",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275477523",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14253882",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69054394,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152505736",
        "ip": "172.71.102.156",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:26:39",
        "isSmartClick": "0",
        "email": "A.aderdouch@hotmail.com",
        "name": "Naim  Hamidan ",
        "firstName": "Naim ",
        "lastName": "Hamidan ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12379",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275476155",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14253854",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69053836,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152504051",
        "ip": "172.71.99.67",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:17:09",
        "isSmartClick": "0",
        "email": "Jvangeel1961@gmail.com",
        "name": "Jan van Geel",
        "firstName": "Jan",
        "lastName": "van Geel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12378",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275473295",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14253791",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69053306,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Iguala de la Independencia",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152502501",
        "ip": "2806:104e:4:ef2:a1a1:97f:6b39:c673",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:07:45",
        "isSmartClick": "0",
        "email": "helsinki1983@live.com.mx",
        "name": "Eduardo Munguia",
        "firstName": "Eduardo",
        "lastName": "Munguia",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "18d350c5-b5ba-498e-ab92-69ab30e37f10",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275470557",
        "countryID": 143,
        "cityID": 9165,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253730",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69052957,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "vamnia.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152501655",
        "ip": "2001:4c3c:1302:1200:61b5:ea02:101c:b4f3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 18:01:41",
        "isSmartClick": "0",
        "email": "b.veldstra09@gmail.com",
        "name": "Bert Veldstra",
        "firstName": "Bert",
        "lastName": "Veldstra",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12377",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275468900",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-04 08:47:17",
        "depositID": "20410802",
        "conversionID": "1216907",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14253699",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69052422,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Veracruz",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152500104",
        "ip": "189.192.91.59",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 17:51:13",
        "isSmartClick": "0",
        "email": "ancianos839@gmail.com",
        "name": "Rodolfo García Martinez",
        "firstName": "Rodolfo",
        "lastName": "García Martinez",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ancianos839@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275465857",
        "countryID": 143,
        "cityID": 785,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69052316,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152499781",
        "ip": "109.38.149.201",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 17:49:11",
        "isSmartClick": "0",
        "email": "he.bijker@ziggo.nl",
        "name": "Erik  Bijker",
        "firstName": "Erik ",
        "lastName": "Bijker",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12376",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275465242",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14253606",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69052086,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "skillnestle.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": "WP",
        "leadRequestID": "152498985",
        "ip": "172.71.99.203",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 17:45:51",
        "isSmartClick": "0",
        "email": "remco.de.jager@hotmail.com",
        "name": "remco de jager",
        "firstName": "remco",
        "lastName": "de jager",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12375",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275464168",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "André Rieu had er spijt van dat hij de waarheid had onthuld. Maar het was al te laat. Het schandaal brak uit tijdens een live-uitzending toen André Rieu per ongeluk zijn geheim onthulde in het programma.",
        "leadRequestOfferName": "Trade Avapro André Rieu (NL)",
        "leadRequestOfferWebsite": "Trade Avapro André Rieu (NL)",
        "brokerAutologinClick": "14253583",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69051137,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zwaagdijk-Oost",
        "custom": null,
        "custom1": null,
        "custom2": "mormaordom.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152495424",
        "ip": "195.241.10.224",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 17:29:17",
        "isSmartClick": "0",
        "email": "michelciere24@msn.com",
        "name": "Michel Ciere",
        "firstName": "Michel",
        "lastName": "Ciere",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12374",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275459082",
        "countryID": 156,
        "cityID": 56676,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14253477",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69050767,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152493800",
        "ip": "189.203.29.11",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 17:23:07",
        "isSmartClick": "0",
        "email": "Pelorkasexample@gmail.com",
        "name": "Hector  Cardenas ",
        "firstName": "Hector ",
        "lastName": "Cardenas ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "acd22d20-cc15-4cc0-94b7-8b7177a23ffd",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275457099",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253429",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69050545,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalajara",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152492929",
        "ip": "177.241.49.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 17:18:13",
        "isSmartClick": "0",
        "email": "arturocarrilo1970@hotmail.com",
        "name": "Cesar Arturo Sánchez Carrillo",
        "firstName": "Cesar Arturo",
        "lastName": "Sánchez Carrillo",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "d537fdce-4243-4401-ac10-1ed7debbd398",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275455652",
        "countryID": 143,
        "cityID": 755,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253398",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69050262,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152492048",
        "ip": "2806:105e:1d:72ea:565:687b:f3d0:1005",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 17:13:15",
        "isSmartClick": "0",
        "email": "Susanitaarjon@gmail.com",
        "name": "Brayan Benitez",
        "firstName": "Brayan",
        "lastName": "Benitez",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "6283fb01-5059-438e-8ae2-35b2800615de",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275454136",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253345",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69050252,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152491995",
        "ip": "192.141.247.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 17:12:56",
        "isSmartClick": "0",
        "email": "joxxeibarra@gmail.com",
        "name": "Josefina  Ibarra ",
        "firstName": "Josefina ",
        "lastName": "Ibarra ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "7ff7fd14-63e8-4237-9cd5-6fc741658f4d",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275454052",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14253338",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69049536,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Santa Ana Nextlalpan",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152489550",
        "ip": "201.157.46.30",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:57:01",
        "isSmartClick": "0",
        "email": "mejiavictorgabriel774@gmail.com",
        "name": "Victor Gabriel  Mejia ",
        "firstName": "Victor Gabriel ",
        "lastName": "Mejia ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "db881864-39be-45f1-ac58-e06a3476100b",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275449521",
        "countryID": 143,
        "cityID": 40786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253242",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69049425,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Pachuca",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152489161",
        "ip": "2806:105e:8:7ded:ceb1:8cd5:8e63:9a79",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:54:39",
        "isSmartClick": "0",
        "email": "duvacapitanachi@gmail.com",
        "name": "Liliana  Duarte ",
        "firstName": "Liliana ",
        "lastName": "Duarte ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "bf180681-b368-410c-84d6-27c86d570d96",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275448825",
        "countryID": 143,
        "cityID": 1187,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253217",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69049379,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Coyoacán",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152489039",
        "ip": "2806:2f0:9fa0:4b1e:45f4:ecf8:b1aa:7aa0",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:53:48",
        "isSmartClick": "0",
        "email": "vaniaresendiz2708@gmail.com",
        "name": "Vania Resendiz",
        "firstName": "Vania",
        "lastName": "Resendiz",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "9c783a55-43c8-4f7f-828b-142b14f437d6",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Reassign",
        "saleStatusMapped": "Call again",
        "clickID": "275448596",
        "countryID": 143,
        "cityID": 7267,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253210",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69049035,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mérida",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152487832",
        "ip": "201.175.218.73",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:48:20",
        "isSmartClick": "0",
        "email": "andres.alejo.jimenez.1974@gmail.com",
        "name": "Andres Alejo jimenez",
        "firstName": "Andres",
        "lastName": "Alejo jimenez",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "dc7379c0-c574-43f5-adf5-6c3ad31b66ae",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275446834",
        "countryID": 143,
        "cityID": 366,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69048775,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152487043",
        "ip": "200.68.173.142",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:44:39",
        "isSmartClick": "0",
        "email": "leonguerrego@gmail.com",
        "name": "Leonel  Guerrero martinez",
        "firstName": "Leonel ",
        "lastName": "Guerrero martinez",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "e73dfd97-0d89-40b1-b292-d2a00e0efcb7",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275445549",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253153",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69048582,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuauhtemoc",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152486396",
        "ip": "189.203.54.23",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:39:51",
        "isSmartClick": "0",
        "email": "abelmanuelxk11@gmail.com",
        "name": "Abel  Manuel ",
        "firstName": "Abel ",
        "lastName": "Manuel ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "87d25055-2951-445f-a1ce-9a8ebe005c5d",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275444133",
        "countryID": 143,
        "cityID": 7743,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253115",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69048227,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Puebla City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152484908",
        "ip": "189.156.240.146",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:30:01",
        "isSmartClick": "0",
        "email": "victpsusano@gmail.com",
        "name": "Víctor  Pérez ",
        "firstName": "Víctor ",
        "lastName": "Pérez ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "de604356-44c1-4bf8-8a9d-d28f609c04fe",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275441322",
        "countryID": 143,
        "cityID": 329,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14253044",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047894,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152483748",
        "ip": "201.141.23.212",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:21:48",
        "isSmartClick": "0",
        "email": "javierespana780@gmail.com",
        "name": "Javier España",
        "firstName": "Javier",
        "lastName": "España",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "f3ba74e9-c47b-40ae-a573-70c5ef65d3a3",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275438909",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14252982",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047824,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Irapuato",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152483451",
        "ip": "2806:2f0:60e1:ea61:3cf2:fba2:a79f:f514",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:19:50",
        "isSmartClick": "0",
        "email": "vegab7986@gmail.com",
        "name": "Bryan Alfredo  Vega Luna ",
        "firstName": "Bryan Alfredo ",
        "lastName": "Vega Luna ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "a89a30ec-ae63-4c34-89d1-e1a498035298",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275438268",
        "countryID": 143,
        "cityID": 12022,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14252963",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047632,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuauhtemoc",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152482622",
        "ip": "201.162.161.194",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:14:15",
        "isSmartClick": "0",
        "email": "santiago_cota_a@hotmail.com",
        "name": "Santiago Alfonso  Cota Algandar ",
        "firstName": "Santiago Alfonso ",
        "lastName": "Cota Algandar ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "c732611c-9bff-46e4-923c-315fd88ec299",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275436611",
        "countryID": 143,
        "cityID": 7743,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14252917",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047615,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Chihuahua City",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152482547",
        "ip": "2806:370:316b:4336:358c:99a9:917a:b06",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:13:40",
        "isSmartClick": "0",
        "email": "polancoguillermo68@gmail.com",
        "name": "Guillermo Polanco",
        "firstName": "Guillermo",
        "lastName": "Polanco",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "70bfc409-28c2-491f-9812-daa9124b2bf4",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275436383",
        "countryID": 143,
        "cityID": 3353,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14252913",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047546,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Zapopan",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152482196",
        "ip": "2806:310:128:9068:6d31:16d0:9a41:97a6",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:11:41",
        "isSmartClick": "0",
        "email": "jhh333gabi@gmail.com",
        "name": "Julián  Hernández ",
        "firstName": "Julián ",
        "lastName": "Hernández ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "5b0ecf02-8a67-4bff-abd5-ae9e61ff4259",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275435732",
        "countryID": 143,
        "cityID": 4215,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14252894",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047503,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152481996",
        "ip": "200.63.44.7",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:10:36",
        "isSmartClick": "0",
        "email": "ecardenas2100@gmail.com",
        "name": "Edgar Jesus  Cardenas Terrazas ",
        "firstName": "Edgar Jesus ",
        "lastName": "Cardenas Terrazas ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "48171297-60a1-4fce-938f-3d03ad15ef9b",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275435353",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14252883",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047474,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Centro Municipality",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152481862",
        "ip": "187.187.194.181",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:09:49",
        "isSmartClick": "0",
        "email": "sandyjimenez642@gmail.com",
        "name": "Sandra Alejandra Aguilar Jiménez",
        "firstName": "Sandra Alejandra",
        "lastName": "Aguilar Jiménez",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "714a87a0-02fa-4419-8652-c9015f7dd725",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Interest",
        "saleStatusMapped": null,
        "clickID": "275435047",
        "countryID": 143,
        "cityID": 127223,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14252877",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047409,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Juárez",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152481728",
        "ip": "2806:2f0:33a0:e116:659f:eda3:e112:18e9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:08:59",
        "isSmartClick": "0",
        "email": "guillerminasuarez307@gmail.com",
        "name": "Guillermina  Suárez Ramírez ",
        "firstName": "Guillermina ",
        "lastName": "Suárez Ramírez ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "e55a9678-20d0-4c98-a691-575000568a2b",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275434808",
        "countryID": 143,
        "cityID": 1364,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14252865",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047406,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tepic",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152481716",
        "ip": "2806:261:1401:8140:fd1a:5942:18bf:77b3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:08:45",
        "isSmartClick": "0",
        "email": "carloscedano2704@gmail.com",
        "name": "Carlos de Jesús  Tirado Domínguez ",
        "firstName": "Carlos de Jesús ",
        "lastName": "Tirado Domínguez ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "8aacc3be-4d0b-4c0d-8784-baa5c765c5e5",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275434754",
        "countryID": 143,
        "cityID": 3571,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14252858",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047383,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "remedilessness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152481661",
        "ip": "109.38.239.207",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:08:30",
        "isSmartClick": "0",
        "email": "groenewold4@hotmail.com",
        "name": "Lambertus Groenewold",
        "firstName": "Lambertus",
        "lastName": "Groenewold",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12373",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275434664",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14252850",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047215,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuernavaca",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152481281",
        "ip": "201.160.157.19",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:06:14",
        "isSmartClick": "0",
        "email": "jgrgarciarojas@gmail.com",
        "name": "Jonathan  García rojas",
        "firstName": "Jonathan ",
        "lastName": "García rojas",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "fb5a6e23-d39c-4727-ab57-7bb9f16fcd6c",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No_Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275433932",
        "countryID": 143,
        "cityID": 8351,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14252827",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69047140,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Guadalupe",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152481044",
        "ip": "2806:109f:17:1a5d:d83:da49:1df5:59a5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 16:04:48",
        "isSmartClick": "0",
        "email": "jesuscarrascogutierrez10@gmail.com",
        "name": "Jesús  Carrasco Gutiérrez ",
        "firstName": "Jesús ",
        "lastName": "Carrasco Gutiérrez ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jesuscarrascogutierrez10@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275433449",
        "countryID": 143,
        "cityID": 12294,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69046860,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tijuana",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152480178",
        "ip": "200.68.148.179",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:59:21",
        "isSmartClick": "0",
        "email": "cristofervillacio29@gmail.com",
        "name": "Cristofer Villavicencio ",
        "firstName": "Cristofer",
        "lastName": "Villavicencio ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "f0541241-072a-4bb0-8013-65319c47987c",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275431849",
        "countryID": 143,
        "cityID": 1286,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14252776",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69046767,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "mormaordom.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152479859",
        "ip": "95.98.249.135",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:57:18",
        "isSmartClick": "0",
        "email": "damiendurinck76@gmail.com",
        "name": "Damien Durinck",
        "firstName": "Damien",
        "lastName": "Durinck",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12372",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275431244",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14252740",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69046721,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tultitlan de Mariano Escobedo",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152479745",
        "ip": "2806:265:3404:bc1:f802:265b:49f8:4c16",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:56:51",
        "isSmartClick": "0",
        "email": "jacr70_ja67@yahoo.com.mx",
        "name": "Jesús Antonio Martínez López",
        "firstName": "Jesús Antonio",
        "lastName": "Martínez López",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "7af9c2be-57e2-48f2-a80b-f7acb6f6602d",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275431066",
        "countryID": 143,
        "cityID": 11930,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14252736",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69046479,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "unluffed.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152478602",
        "ip": "2a02:f6f:633:0:406:ef2a:f0fe:7f3f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:53:35",
        "isSmartClick": "0",
        "email": "lenie.den.boer@gmail.com",
        "name": "Lenie den Boer",
        "firstName": "Lenie",
        "lastName": "den Boer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12371",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275429629",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14252711",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69046318,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Iztacalco",
        "custom": null,
        "custom1": null,
        "custom2": "semiamplexicaul.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152477997",
        "ip": "200.68.186.223",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:51:41",
        "isSmartClick": "0",
        "email": "robelagu24@gmail.com",
        "name": "Roberto Laguna Villalba",
        "firstName": "Roberto",
        "lastName": "Laguna Villalba",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "3ac9c50b-3ec3-4713-9ddc-cd123b9ede64",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275428878",
        "countryID": 143,
        "cityID": 14191,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14252678",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69045983,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152476584",
        "ip": "2806:2f0:91a0:7985:9b:7b2b:5ba1:c14b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:46:57",
        "isSmartClick": "0",
        "email": "nreyesortiz@gmail.com",
        "name": "NORMA ALICIA REYES ORTIZ",
        "firstName": "NORMA ALICIA",
        "lastName": "REYES ORTIZ",
        "brokerID": "22933",
        "brokerName": "KIV",
        "customerID": "3da95d54-1ad9-476b-9b19-42e32b0f0538",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275426960",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14252621",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69045486,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "deammonation.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152474994",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:41:54",
        "isSmartClick": "0",
        "email": "test67890p12@gmail.test",
        "name": "test67890p12 test67890p12",
        "firstName": "test67890p12",
        "lastName": "test67890p12",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12370",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275425009",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIAL NOTICE: RTL Late Night launches their newest platform Orionis Prime to help families become wealthier.",
        "leadRequestOfferName": "Orionis Prime - Humberto Tan ",
        "leadRequestOfferWebsite": "Orionis Prime - Humberto Tan ",
        "brokerAutologinClick": "14252560",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69044974,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Naaldwijk",
        "custom": null,
        "custom1": null,
        "custom2": "uncadenced.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152473562",
        "ip": "2a02:a443:ab3f:1:c1be:89e1:2fab:7ff4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:36:26",
        "isSmartClick": "0",
        "email": "thhendriks@kpnmail.nl",
        "name": "Th.A. Hendriks",
        "firstName": "Th.A.",
        "lastName": "Hendriks",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12369",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275422377",
        "countryID": 156,
        "cityID": 1564,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIAL NOTICE: RTL Late Night launches their newest platform Orionis Prime to help families become wealthier.",
        "leadRequestOfferName": "Orionis Prime - Humberto Tan ",
        "leadRequestOfferWebsite": "Orionis Prime - Humberto Tan ",
        "brokerAutologinClick": "14252462",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69044899,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Riemst",
        "custom": null,
        "custom1": null,
        "custom2": "perfumeress.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152473246",
        "ip": "2a02:a03f:8daf:dc01:64a0:c15d:3634:ebae",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:36:04",
        "isSmartClick": "0",
        "email": "Dirixnancy@gmail.com",
        "name": "Nancy Dirix",
        "firstName": "Nancy",
        "lastName": "Dirix",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12368",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275421861",
        "countryID": 23,
        "cityID": 7035,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Major scandal: Immediate Edge refuses to comment on its employee Gert Verhulstt",
        "leadRequestOfferName": "Immediate Tine Embrechts",
        "leadRequestOfferWebsite": "Immediate Tine Embrechts",
        "brokerAutologinClick": "14252459",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69044861,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152473158",
        "ip": "131.196.255.59",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:35:28",
        "isSmartClick": "0",
        "email": "testyui9876@gmail.com",
        "name": "testyui9876 testyui9876",
        "firstName": "testyui9876",
        "lastName": "testyui9876",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "testyui9876@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275420903",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69043844,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Apeldoorn",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152469631",
        "ip": "2a02:a44d:2216:1:a9b7:27ec:460c:7a61",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:23:10",
        "isSmartClick": "0",
        "email": "hlgroot@planet.nl",
        "name": "Henk  de Groot",
        "firstName": "Henk ",
        "lastName": "de Groot",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12367",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275415885",
        "countryID": 156,
        "cityID": 1555,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14252255",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69042613,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Lansdowne",
        "custom": null,
        "custom1": null,
        "custom2": "predecease.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152465813",
        "ip": "98.124.4.161",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:11:14",
        "isSmartClick": "0",
        "email": "test00001@test011.test",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "6b1d4e6d61fa93f1f5fe61b0949c28ba",
        "leadType": "1",
        "countryCode": "CA",
        "saleStatus": "test_lead",
        "saleStatusMapped": "Test",
        "clickID": "275410663",
        "countryID": 40,
        "cityID": 40373,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14252079",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69042386,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Airdrie",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152464979",
        "ip": "137.186.253.201",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:08:13",
        "isSmartClick": "0",
        "email": "test@test0001.test",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "test@test0001.test",
        "leadType": "0",
        "countryCode": "CA",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275409275",
        "countryID": 40,
        "cityID": 4948,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Dragons&#39; Den Canada makes Canadian rich with oil! The episode may not be broadcast - the broadcaster is furious",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69041813,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Canada",
        "cityName": "Airdrie",
        "custom": null,
        "custom1": null,
        "custom2": "noncapitulation.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152463604",
        "ip": "137.186.253.201",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 15:02:48",
        "isSmartClick": "0",
        "email": "test22277@test.test",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "17250",
        "brokerName": "TraderIdeal",
        "customerID": "83e7640732e12ece64ff7ff87caaa882",
        "leadType": "1",
        "countryCode": "CA",
        "saleStatus": "test_lead",
        "saleStatusMapped": "Test",
        "clickID": "275406979",
        "countryID": 40,
        "cityID": 4948,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Het onderzoek rond P Diddy gaat door, maar wat heeft de officiële diensten van Amerika ertoe aangezet om de artiest te gaan onderzoeken? Waarom is dit nieuws zo duister en verwarrend? De artiest zegt zelf dat de pesterijen en bedreigingen tegen hem door de autoriteiten begonnen na een interview in TheEllenShow, waar P Diddy per ongeluk het platform noemde waarop hij en zijn vrienden hun fortuin hebben gemaakt en dat iedereen de kans zou kunnen krijgen om dit “programma” te gebruiken.",
        "leadRequestOfferName": "Dragons CA",
        "leadRequestOfferWebsite": "Dragons CA",
        "brokerAutologinClick": "14251943",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69040995,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Vlijmen",
        "custom": null,
        "custom1": null,
        "custom2": "quinquevalency.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152460741",
        "ip": "84.35.95.193",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 14:54:37",
        "isSmartClick": "0",
        "email": "Martin-wolthuis@live.nl",
        "name": "Martin  Wolthuis ",
        "firstName": "Martin ",
        "lastName": "Wolthuis ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12366",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275404411",
        "countryID": 156,
        "cityID": 6452,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14251822",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69036241,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152445865",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 14:07:41",
        "isSmartClick": "0",
        "email": "test123dsvuweiuiuq@gmail.com",
        "name": "test123dsvuweiuiuq test123dsvuweiuiuq",
        "firstName": "test123dsvuweiuiuq",
        "lastName": "test123dsvuweiuiuq",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12365",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275393416",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14251118",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69034941,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "mormaordom.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152441736",
        "ip": "84.241.194.237",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 13:55:16",
        "isSmartClick": "0",
        "email": "Jimmejohan@gmail.com",
        "name": "Jimme Terpstra",
        "firstName": "Jimme",
        "lastName": "Terpstra",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12364",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275390291",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14250938",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69034040,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nijmegen",
        "custom": null,
        "custom1": null,
        "custom2": "tricarbimide.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152438814",
        "ip": "82.170.135.113",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 13:44:31",
        "isSmartClick": "0",
        "email": "mudo1970@hotmail.com",
        "name": "Maarten Udo",
        "firstName": "Maarten",
        "lastName": "Udo",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12363",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275388043",
        "countryID": 156,
        "cityID": 1328,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14250762",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69033843,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zoetermeer",
        "custom": null,
        "custom1": null,
        "custom2": "hereditarianism.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152438270",
        "ip": "87.209.177.129",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 13:42:36",
        "isSmartClick": "0",
        "email": "dazaconsultancy@gmail.com",
        "name": "Dajanand Ramparichan",
        "firstName": "Dajanand",
        "lastName": "Ramparichan",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12362",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275387621",
        "countryID": 156,
        "cityID": 1565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14250724",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69032378,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "unpetrify.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152433420",
        "ip": "94.109.146.107",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 13:28:23",
        "isSmartClick": "0",
        "email": "aissatimohamed9@gmail.com",
        "name": "Mohamed Aissati",
        "firstName": "Mohamed",
        "lastName": "Aissati",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "aissatimohamed9@gmail.com",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275383983",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "3",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69031137,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zwolle",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152429556",
        "ip": "2a02:a46c:4045:1:cc12:415f:5ca3:8da9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 13:17:50",
        "isSmartClick": "0",
        "email": "gertkroes@live.nl",
        "name": "gert kroes",
        "firstName": "gert",
        "lastName": "kroes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12361",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275381454",
        "countryID": 156,
        "cityID": 5049,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14250413",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69028808,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Antwerp",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152422865",
        "ip": "2a02:1810:2815:5300:e072:2d9:c482:57",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 12:54:58",
        "isSmartClick": "0",
        "email": "Kacper.kowalski17@vp.pl",
        "name": "Kacper  Kowalski",
        "firstName": "Kacper ",
        "lastName": "Kowalski",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12360",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275376630",
        "countryID": 23,
        "cityID": 18,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14250074",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69027687,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152418546",
        "ip": "109.139.84.205",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 12:43:13",
        "isSmartClick": "0",
        "email": "testetstedvwgd@gmail.com",
        "name": "teststtw testetstets",
        "firstName": "teststtw",
        "lastName": "testetstets",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12359",
        "leadType": "1",
        "countryCode": "BE",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275374417",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14249909",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69026787,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "lemirao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152415098",
        "ip": "185.107.80.87",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 12:33:53",
        "isSmartClick": "0",
        "email": "testtest@te.stt",
        "name": "testholy2 testteholy",
        "firstName": "testholy2",
        "lastName": "testteholy",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12358",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275372692",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Het onderzoek rond P Diddy gaat door, maar wat heeft de officiële diensten van Amerika ertoe aangezet om de artiest te gaan onderzoeken? Waarom is dit nieuws zo duister en verwarrend? De artiest zegt zelf dat de pesterijen en bedreigingen tegen hem door de autoriteiten begonnen na een interview in TheEllenShow, waar P Diddy per ongeluk het platform noemde waarop hij en zijn vrienden hun fortuin hebben gemaakt en dat iedereen de kans zou kunnen krijgen om dit “programma” te gebruiken.",
        "leadRequestOfferName": "AvaPro P_Diddy",
        "leadRequestOfferWebsite": "AvaPro P_Diddy",
        "brokerAutologinClick": "14249782",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69026599,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "vexrio.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152414333",
        "ip": "185.107.80.87",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 12:31:58",
        "isSmartClick": "0",
        "email": "testtest@te.st",
        "name": "testholy testholly",
        "firstName": "testholy",
        "lastName": "testholly",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12357",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275372348",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Het onderzoek rond P Diddy gaat door, maar wat heeft de officiële diensten van Amerika ertoe aangezet om de artiest te gaan onderzoeken? Waarom is dit nieuws zo duister en verwarrend? De artiest zegt zelf dat de pesterijen en bedreigingen tegen hem door de autoriteiten begonnen na een interview in TheEllenShow, waar P Diddy per ongeluk het platform noemde waarop hij en zijn vrienden hun fortuin hebben gemaakt en dat iedereen de kans zou kunnen krijgen om dit “programma” te gebruiken.",
        "leadRequestOfferName": "AvaPro P_Diddy",
        "leadRequestOfferWebsite": "AvaPro P_Diddy",
        "brokerAutologinClick": "14249757",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69026021,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Catania",
        "custom": "qWxPprDaYJnQ2gZBELzOwqkAmAeb8V4vkedG795l3m0AojM1K",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152412115",
        "ip": "93.147.249.182",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 12:26:24",
        "isSmartClick": "0",
        "email": "luiginone@blu.it",
        "name": "luigi di pasquale",
        "firstName": "luigi",
        "lastName": "di pasquale",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "rRYbBK9kgx7AWnOm0QVedX7GAJqAXEGp3Ja5lqPy2z4Loj6Dv",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275371216",
        "countryID": 109,
        "cityID": 1468,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "BTC Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14249696",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69025785,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Veenendaal",
        "custom": null,
        "custom1": null,
        "custom2": "unluffed.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152411224",
        "ip": "84.84.91.60",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 12:24:13",
        "isSmartClick": "0",
        "email": "robbedoes60@gmail.com",
        "name": "Rob Drabbe",
        "firstName": "Rob",
        "lastName": "Drabbe",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12356",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275370758",
        "countryID": 156,
        "cityID": 2504,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14249664",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69025145,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "zestva.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152408605",
        "ip": "2001:4c3c:f204:3d00:7830:db2c:26b8:e2c3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 12:17:03",
        "isSmartClick": "0",
        "email": "woudstragerke@gmail.com",
        "name": "Gerke Woudstra",
        "firstName": "Gerke",
        "lastName": "Woudstra",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12355",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275369479",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14249571",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69024129,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "unpetrify.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152404958",
        "ip": "94.109.54.23",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 12:06:23",
        "isSmartClick": "0",
        "email": "Sergevandenberghe73@gmail.com",
        "name": "Serge  Vandenberghe",
        "firstName": "Serge ",
        "lastName": "Vandenberghe",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12354",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275367536",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14249413",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69023457,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hoogezand",
        "custom": null,
        "custom1": null,
        "custom2": "vamnia.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152402361",
        "ip": "2001:1c01:36c7:4300:dfb:99b2:62ef:fca7",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 12:00:42",
        "isSmartClick": "0",
        "email": "Hennybontsema@gmail.com",
        "name": "Henny Bontsema ",
        "firstName": "Henny",
        "lastName": "Bontsema ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12353",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275366298",
        "countryID": 156,
        "cityID": 6337,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14249337",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69022923,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "mormaordom.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152399962",
        "ip": "2001:4c3c:e01:3a00:a18c:b277:86c4:77dd",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:55:19",
        "isSmartClick": "0",
        "email": "Janvanginkel@gmail.com",
        "name": "Jan van Ginkel",
        "firstName": "Jan",
        "lastName": "van Ginkel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12352",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275365209",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14249266",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69022841,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Rome",
        "custom": "y17LZdl5QDqbW6vJP4ORwWVRA3DZNgB9YjpxaGrEon23zmAeK",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152399542",
        "ip": "151.31.16.163",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 11:54:00",
        "isSmartClick": "0",
        "email": "santina.1978angotti@gmail.com",
        "name": "Anna Pedrotti",
        "firstName": "Anna",
        "lastName": "Pedrotti",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "ZGMbE63Op4y0lxodPjD1KNLOq57v87rgv2aRL59JkBYQnVWqm",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275365018",
        "countryID": 109,
        "cityID": 146,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "BTC Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14249255",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69021492,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nijkerk",
        "custom": null,
        "custom1": null,
        "custom2": "dilleniaceae.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152394604",
        "ip": "2a02:a420:40:509f:2:1:3650:7797",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:40:58",
        "isSmartClick": "0",
        "email": "henrydevijfde@gmail.com",
        "name": "Cees Henry",
        "firstName": "Cees",
        "lastName": "Henry",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12351",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275362363",
        "countryID": 156,
        "cityID": 16985,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-03 12:47:37",
        "depositID": "20409686",
        "conversionID": "1216442",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14249053",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69020620,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Poperinge",
        "custom": null,
        "custom1": null,
        "custom2": "unpetrify.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152391704",
        "ip": "2a02:a03f:e73c:8401:84e:a3d:8434:ebf7",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:32:50",
        "isSmartClick": "0",
        "email": "tonny.six@hotmail.com",
        "name": "Tonny Six",
        "firstName": "Tonny",
        "lastName": "Six",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12350",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275360669",
        "countryID": 23,
        "cityID": 4666,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14248913",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69019688,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rosmalen",
        "custom": null,
        "custom1": null,
        "custom2": "pituitousness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152388215",
        "ip": "84.241.203.193",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:24:15",
        "isSmartClick": "0",
        "email": "richardmulder2@hotmail.com",
        "name": "Richard Mulder",
        "firstName": "Richard",
        "lastName": "Mulder",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12349",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275358891",
        "countryID": 156,
        "cityID": 6722,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14248798",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69019305,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Florence",
        "custom": "maJKkVRQnyM9oYLpzOGZ8dWMDKVrXBbqDW4Agd7e0v256r31E",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152386659",
        "ip": "82.49.65.68",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 11:21:05",
        "isSmartClick": "0",
        "email": "meranceko@gmail.com",
        "name": "Merance Kongaha",
        "firstName": "Merance",
        "lastName": "Kongaha",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "K9rbWxqOYkBgav0eJLGmz899y9Zr8Zo5n1EdVPQ26743RAylj",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Hang Up",
        "saleStatusMapped": "Hang Up",
        "clickID": "275358149",
        "countryID": 109,
        "cityID": 2393,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "BTC Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14248760",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69018942,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152385434",
        "ip": "84.241.202.9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:17:10",
        "isSmartClick": "0",
        "email": "Elvira_vd_blom@msn.com",
        "name": "Elvira  Van der Blom",
        "firstName": "Elvira ",
        "lastName": "Van der Blom",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12348",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275357524",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14248706",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69018223,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152382723",
        "ip": "2a02:a45b:916b:0:a679:ce72:c0bc:f86f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:11:03",
        "isSmartClick": "0",
        "email": "Rickydew1987@gmail.com",
        "name": "Dewki  Bisnoeperkash ",
        "firstName": "Dewki ",
        "lastName": "Bisnoeperkash ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12347",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275356093",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14248619",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69017733,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Breda",
        "custom": null,
        "custom1": null,
        "custom2": "jesuitry.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152380845",
        "ip": "87.210.209.72",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:06:25",
        "isSmartClick": "0",
        "email": "amasderoo@gmail.com",
        "name": "Anna De Roo",
        "firstName": "Anna",
        "lastName": "De Roo",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12346",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275355149",
        "countryID": 156,
        "cityID": 1526,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-03 13:14:26",
        "depositID": "20409739",
        "conversionID": "1216466",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14248557",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69017531,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "vamnio.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152380076",
        "ip": "77.251.201.165",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:04:53",
        "isSmartClick": "0",
        "email": "p.flaman1@gmail.com",
        "name": "Peter Flaman",
        "firstName": "Peter",
        "lastName": "Flaman",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12345",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275354749",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14248530",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69017500,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Lecce",
        "custom": "oKgZe5RVY4DaLjpWMr9BNxmaDDY3w7vnk6m231bP0dlQqEzJx",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152379996",
        "ip": "95.250.249.157",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 11:04:34",
        "isSmartClick": "0",
        "email": "uyaguariana70@gmail.com",
        "name": "Marisol Uyaguari",
        "firstName": "Marisol",
        "lastName": "Uyaguari",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "Wbg49ZG0xrJekl76MV5LzXYn57P18Ya1QKDRnOoyAqBvdmpj2",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "wrong person",
        "saleStatusMapped": null,
        "clickID": "275354680",
        "countryID": 109,
        "cityID": 6350,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "BTC Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14248526",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69017397,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Eindhoven",
        "custom": null,
        "custom1": null,
        "custom2": "unluffed.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152379709",
        "ip": "62.163.52.79",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 11:03:36",
        "isSmartClick": "0",
        "email": "johankruizinga@live.nl",
        "name": "Johan Kruizinga",
        "firstName": "Johan",
        "lastName": "Kruizinga",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12344",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275354473",
        "countryID": 156,
        "cityID": 1279,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14248509",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69016462,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "unluffed.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152375936",
        "ip": "2a02:a420:23a:56e9:7e32:2c41:2297:ed1a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 10:56:23",
        "isSmartClick": "0",
        "email": "hrczegers@gmail.com",
        "name": "Herman Zegers",
        "firstName": "Herman",
        "lastName": "Zegers",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12343",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275352687",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14248392",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69015053,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Omegna",
        "custom": "GMbE63Op4y0lxodPjD1KNLRqJa6zX7rgv2aRL59JkBYQnVWqm",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152370033",
        "ip": "82.58.53.237",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 10:45:01",
        "isSmartClick": "0",
        "email": "albertinoizzo74@gmail.com",
        "name": "Alberto Izzo",
        "firstName": "Alberto",
        "lastName": "Izzo",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "WmD9Vn74AqapgQYO6BjPywP4Kr9ew0ve5ELGMZxdo1bRKzr2J",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Newer answe",
        "saleStatusMapped": null,
        "clickID": "275349942",
        "countryID": 109,
        "cityID": 45445,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "BTC Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69014519,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Valkenburg",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152368390",
        "ip": "2001:1c05:1405:2900:f844:edbf:ed87:72ac",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 10:39:21",
        "isSmartClick": "0",
        "email": "leejonardo@gmail.com",
        "name": "Frank Storm",
        "firstName": "Frank",
        "lastName": "Storm",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12342",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275348792",
        "countryID": 156,
        "cityID": 16808,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-03 14:35:00",
        "depositID": "20409833",
        "conversionID": "1216548",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 1,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14248061",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69014134,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Trento",
        "custom": "e3YvkOqlKgy71R6W9PVdNKe71GJDXM42jQZBmo5Lpbz0EJAxa",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152367231",
        "ip": "79.31.35.106",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 10:35:05",
        "isSmartClick": "0",
        "email": "luirom@live.it",
        "name": "Luigi Romano",
        "firstName": "Luigi",
        "lastName": "Romano",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "qWxEM7Zz4OdRVP5Lorn6lwoz5kQyNeakbgAmDv3KBQGyj920p",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Newer answe",
        "saleStatusMapped": null,
        "clickID": "275347941",
        "countryID": 109,
        "cityID": 10852,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14247987",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69013424,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Kontich",
        "custom": null,
        "custom1": null,
        "custom2": "satisfactoriness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152364907",
        "ip": "2a02:1810:4f2e:4900:ccba:cd77:eb0f:b89b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 10:26:26",
        "isSmartClick": "0",
        "email": "aissatimohamed9@gmail.com",
        "name": "Mohamed Aissati",
        "firstName": "Mohamed",
        "lastName": "Aissati",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "520812",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "Wronglanguage",
        "saleStatusMapped": "Language Issues",
        "clickID": "275346260",
        "countryID": 23,
        "cityID": 5587,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "2",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Major scandal: Immediate Edge refuses to comment on its employee Gert Verhulstt",
        "leadRequestOfferName": "Immediate Tine Embrechts",
        "leadRequestOfferWebsite": "Immediate Tine Embrechts",
        "brokerAutologinClick": "14247856",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69012613,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Genoa",
        "custom": "mD9Vn74AqapgQYO6BjPywPVJAZae80ve5ELGMZxdo1bRKzr2J",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152362232",
        "ip": "79.20.124.156",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 10:20:23",
        "isSmartClick": "0",
        "email": "dariovignola6@gmail.com",
        "name": "Dario Vignola",
        "firstName": "Dario",
        "lastName": "Vignola",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "OqEeLW7BgKkovmRZ0Y3lVwRdKGPZ8rJPGb5QAMpd14jD6y92z",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275344531",
        "countryID": 109,
        "cityID": 1332,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14247745",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69010699,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Eindhoven",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152356450",
        "ip": "31.20.232.237",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 10:03:19",
        "isSmartClick": "0",
        "email": "solissa.ipj@hotmail.com",
        "name": "Izaäc  Solissa ",
        "firstName": "Izaäc ",
        "lastName": "Solissa ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12341",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275340472",
        "countryID": 156,
        "cityID": 1279,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69008759,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": null,
        "custom": "0laZpgRjG6zAPk5KnYyvw2n19JJpwoO9JmBWLd17qDV2e3b4x",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152350470",
        "ip": "185.121.102.84",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 09:45:47",
        "isSmartClick": "0",
        "email": "Lucagolf05@gmail.com",
        "name": "Giovanni Lunardon",
        "firstName": "Giovanni",
        "lastName": "Lunardon",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "L4Rk0j9ndJa5VWxDYrmgewA0KqaZNGbvpol6EZA3zOyKM271Q",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "low potential",
        "saleStatusMapped": "Low Potentional",
        "clickID": "275336495",
        "countryID": 109,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14248856",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69007580,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152346393",
        "ip": "94.109.120.247",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 09:32:14",
        "isSmartClick": "0",
        "email": "terrakahfir96@gmail.com",
        "name": "Abdelkader  Terrak",
        "firstName": "Abdelkader ",
        "lastName": "Terrak",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12340",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275333763",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14246816",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69007293,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nijmegen",
        "custom": null,
        "custom1": null,
        "custom2": "vamnia.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152345460",
        "ip": "2a02:a212:2bc1:6880:d89d:59ae:937d:6366",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 09:29:33",
        "isSmartClick": "0",
        "email": "patrizia.eind@icloud.com",
        "name": "Eleonore Eind-Geenen",
        "firstName": "Eleonore",
        "lastName": "Eind-Geenen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12339",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275333085",
        "countryID": 156,
        "cityID": 1328,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14246757",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69006149,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Reusel",
        "custom": null,
        "custom1": null,
        "custom2": "unsatisfiedness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152340090",
        "ip": "45.82.184.194",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 09:18:37",
        "isSmartClick": "0",
        "email": "hansterpstra@protonmail.com",
        "name": "Hans Terpstra",
        "firstName": "Hans",
        "lastName": "Terpstra",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12338",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275330417",
        "countryID": 156,
        "cityID": 31934,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14246495",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69005263,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Sint-Niklaas",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152335036",
        "ip": "2a02:1812:2c14:4e00:6192:8c6a:2dc1:85ea",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 09:11:01",
        "isSmartClick": "0",
        "email": "Betonboringenkris@telenet.be",
        "name": "Kris  Van der Borght ",
        "firstName": "Kris ",
        "lastName": "Van der Borght ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12337",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275328294",
        "countryID": 23,
        "cityID": 647,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14246316",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69004503,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Genval",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152331258",
        "ip": "2a02:a020:7:6030:1:0:db51:fe68",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 09:04:32",
        "isSmartClick": "0",
        "email": "padmooreandrew@gmail.com",
        "name": "Andrew Padmoore",
        "firstName": "Andrew",
        "lastName": "Padmoore",
        "brokerID": "25403",
        "brokerName": "Iconix",
        "customerID": "d11cbe3d973f7a5dc037e3f4c36cdbd2",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "No Answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275326513",
        "countryID": 23,
        "cityID": 39886,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14246131",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69004379,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "La Spezia",
        "custom": "9rbWxqOYkBgav0eJLGmz89djQJRAXZo5n1EdVPQ26743RAylj",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152330716",
        "ip": "87.9.123.64",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 09:03:29",
        "isSmartClick": "0",
        "email": "irmicipasquale78@gmail.com",
        "name": "Pasquale Irmici",
        "firstName": "Pasquale",
        "lastName": "Irmici",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "EamzpxjMKrbP7o4G5v2LJNnR5az9NBR3glDZ6qyeA1WOkV9dn",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275326313",
        "countryID": 109,
        "cityID": 18690,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": "14246109",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69003724,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152327855",
        "ip": "2a02:a020:8:889a:1:0:dcb4:eb78",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:58:49",
        "isSmartClick": "0",
        "email": "clairevandenhoeke@gmail.com",
        "name": "claire VANDENHOEKE",
        "firstName": "claire",
        "lastName": "VANDENHOEKE",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12336",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275325075",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14245979",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69003569,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Genval",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152327416",
        "ip": "2a02:a020:7:6030:1:0:db51:fe68",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:57:43",
        "isSmartClick": "0",
        "email": "padmooreandrew@gmail.com",
        "name": "Andrew Padmoore",
        "firstName": "Andrew",
        "lastName": "Padmoore",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12335",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong number",
        "saleStatusMapped": "Wrong Info",
        "clickID": "275324752",
        "countryID": 23,
        "cityID": 39886,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14245959",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69002848,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Rapallo",
        "custom": "vld6gM5PyLKeDQoz0brGwaY6px7WwxBVAmRaJZnOq9Y1jp3WE",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152324055",
        "ip": "79.24.187.155",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 08:51:59",
        "isSmartClick": "0",
        "email": "arsabatano@gmail.com",
        "name": "Sabatano Vittorio",
        "firstName": "Sabatano",
        "lastName": "Vittorio",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "vaQ2RbpjOxJGmgZYk3MArNMVKejJw5yV4EWDqnd069LPle7zB",
        "leadType": "0",
        "countryCode": "IT",
        "saleStatus": "Call back",
        "saleStatusMapped": "Call again",
        "clickID": "275323175",
        "countryID": 109,
        "cityID": 25205,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Bitcoin Profit",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69002722,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hoorn",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152323527",
        "ip": "2001:1c04:3038:6f00:a415:1c0b:abf2:1385",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:51:09",
        "isSmartClick": "0",
        "email": "m.woud@quicknet.nl",
        "name": "Peter  Fruchtl ",
        "firstName": "Peter ",
        "lastName": "Fruchtl ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12334",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275322971",
        "countryID": 156,
        "cityID": 3837,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-03 11:09:19",
        "depositID": "20409582",
        "conversionID": "1216378",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14245806",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 69002008,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Ysselsteyn",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152320194",
        "ip": "2a00:bba0:1101:1f00:6002:ed88:5621:bdb4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:44:19",
        "isSmartClick": "0",
        "email": "josvandekerkhof@hotmail.com",
        "name": "Jos van de Kerkhof",
        "firstName": "Jos",
        "lastName": "van de Kerkhof",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12333",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275321384",
        "countryID": 156,
        "cityID": 62745,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14245636",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69001319,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Gouda",
        "custom": null,
        "custom1": null,
        "custom2": "tricarbimide.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152317560",
        "ip": "2a02:a450:cfd4:0:5d8c:b700:3434:afdc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:37:58",
        "isSmartClick": "0",
        "email": "ppda@telfort.nl",
        "name": "Pcm Forsthovel",
        "firstName": "Pcm",
        "lastName": "Forsthovel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12332",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275319963",
        "countryID": 156,
        "cityID": 2663,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14245489",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69000893,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152315666",
        "ip": "178.51.18.112",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:34:46",
        "isSmartClick": "0",
        "email": "Kenny.eelen@hotmail.com",
        "name": "Kennt Eelen",
        "firstName": "Kennt",
        "lastName": "Eelen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12331",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275319075",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14245406",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 69000412,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Deurne",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152313423",
        "ip": "81.83.155.185",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:30:45",
        "isSmartClick": "0",
        "email": "vermeirelucas1998@gmail.com",
        "name": "Lucas Vermeire",
        "firstName": "Lucas",
        "lastName": "Vermeire",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12330",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275318050",
        "countryID": 23,
        "cityID": 1123,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14245340",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68999864,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Willebroek",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152310776",
        "ip": "2a02:1808:5:df48::1",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:25:49",
        "isSmartClick": "0",
        "email": "kevinannaert1994@gmail.com",
        "name": "Kevin Annaert",
        "firstName": "Kevin",
        "lastName": "Annaert",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12329",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275316851",
        "countryID": 23,
        "cityID": 35,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14245236",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68999492,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "152308946",
        "ip": "2a02:a420:234:8893:80cd:9d43:fbc4:283e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 08:22:26",
        "isSmartClick": "0",
        "email": "Henkwolters35@gmail.com",
        "name": "Henk Wolters",
        "firstName": "Henk",
        "lastName": "Wolters",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12328",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275316086",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14245137",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68997938,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152301845",
        "ip": "37.169.150.18",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 08:10:24",
        "isSmartClick": "0",
        "email": "aminata.camara0184@gmail.com",
        "name": "Aminata Camara",
        "firstName": "Aminata",
        "lastName": "Camara",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "ae5d75da-5921-4f1c-ae30-c9559ceec2c5",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No money",
        "saleStatusMapped": "No Money",
        "clickID": "275312943",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14244841",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68997750,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152300789",
        "ip": "2a04:cec0:11d2:6094:e8e3:92b8:4488:7b30",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 08:09:05",
        "isSmartClick": "0",
        "email": "ayakouame8531148@yahoo.com",
        "name": "PATRICIA KOUAME",
        "firstName": "PATRICIA",
        "lastName": "KOUAME",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "839bc185-489e-44ff-a726-184823118f08",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "275312615",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68996737,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152295847",
        "ip": "2a01:cb01:830:750e:68f8:da08:1f14:9469",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 08:01:49",
        "isSmartClick": "0",
        "email": "jean.pouch@orange.fr",
        "name": "Jean Pierre POUCH",
        "firstName": "Jean Pierre",
        "lastName": "POUCH",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "0dddded3-f6b4-4a54-b8aa-f93ec5ca179e",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 3",
        "saleStatusMapped": null,
        "clickID": "275310724",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14244604",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68996540,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "6a824d6136",
        "affiliateID": "84357",
        "affiliateName": "NativeOffers",
        "affiliateEmail": "NativeOffersSLintegration@mail.com",
        "countryName": "Italy",
        "cityName": "Catania",
        "custom": "4Rk0j9ndJa5VWxDYrmgewAjY42bMXGbvpol6EZA3zOyKM271Q",
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152294732",
        "ip": "212.171.118.134",
        "userAgent": "Link",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:59:48",
        "isSmartClick": "0",
        "email": "fakeemail18300773@test.com",
        "name": "Lukav Sergeevv",
        "firstName": "Lukav",
        "lastName": "Sergeevv",
        "brokerID": "24029",
        "brokerName": "Cryptoline",
        "customerID": "W6YQqkAr7GjBElZx30MgOXmjGvGB89ez5oapRPdKV24nyvbLJ",
        "leadType": "1",
        "countryCode": "IT",
        "saleStatus": "FTD",
        "saleStatusMapped": "Converted",
        "clickID": "275310269",
        "countryID": 109,
        "cityID": 1468,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-03 09:07:22",
        "depositID": "1216283",
        "conversionID": "1216298",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "---",
        "leadRequestOfferName": "Eni",
        "leadRequestOfferWebsite": null,
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68996304,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Fontainebleau",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152292941",
        "ip": "2a01:cb08:6dd:200:fb91:dc3:ccac:6777",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:56:57",
        "isSmartClick": "0",
        "email": "pacou.chat@orange.fr",
        "name": "Pascale Chatillon",
        "firstName": "Pascale",
        "lastName": "Chatillon",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "0e9f665e-7114-400f-b25e-22a2640d18a1",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Call back",
        "saleStatusMapped": "Call again",
        "clickID": "275309694",
        "countryID": 75,
        "cityID": 24159,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 1,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14244523",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68995863,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152290556",
        "ip": "37.169.66.249",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:51:54",
        "isSmartClick": "0",
        "email": "bolidenumero1@gmailco.com.com",
        "name": "Dowaduttsing DOOBARY",
        "firstName": "Dowaduttsing",
        "lastName": "DOOBARY",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "bb724dd9-04e1-4ae7-bfda-b288802c4fbf",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Didn`t register",
        "saleStatusMapped": null,
        "clickID": "275308655",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68995858,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Montauban",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152290533",
        "ip": "2a02:8428:7dc7:7d01:256e:58f2:db40:74cd",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:51:52",
        "isSmartClick": "0",
        "email": "nougayredemoniqueg3@imain.com",
        "name": "Monique NougAyrede",
        "firstName": "Monique",
        "lastName": "NougAyrede",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "2841af5a-1562-4fc2-9352-d6685fb5b1a1",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Low potential",
        "saleStatusMapped": "Low Potentional",
        "clickID": "275308649",
        "countryID": 75,
        "cityID": 7541,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68995736,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Makkum",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152289917",
        "ip": "141.224.208.98",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 07:51:08",
        "isSmartClick": "0",
        "email": "Froukjebakker@live.nl",
        "name": "Froukje Bakker",
        "firstName": "Froukje",
        "lastName": "Bakker",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12327",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275308421",
        "countryID": 156,
        "cityID": 45659,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-03 13:50:00",
        "depositID": "20409813",
        "conversionID": "1216520",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14244413",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68995368,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152288465",
        "ip": "77.205.21.171",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:47:10",
        "isSmartClick": "0",
        "email": "christinenicolas13360@gmail.com",
        "name": "Christine nicolas",
        "firstName": "Christine",
        "lastName": "nicolas",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "4a8d7079-aecd-43b4-8beb-f3b71b195ebe",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275307660",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14244356",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68995336,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Douai",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152288330",
        "ip": "2a01:e0a:516:63c0:9d83:d3a:8f89:161a",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:46:43",
        "isSmartClick": "0",
        "email": "patrick59552@outlook.fr",
        "name": "Patrick Adamski",
        "firstName": "Patrick",
        "lastName": "Adamski",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "1926a3d0-b9b9-4540-a23e-e940a2b93166",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "275307586",
        "countryID": 75,
        "cityID": 23752,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68995114,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152287537",
        "ip": "37.169.17.186",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:44:19",
        "isSmartClick": "0",
        "email": "hadidja.toumbou@gmail.com",
        "name": "Hadidja TOUMBOU",
        "firstName": "Hadidja",
        "lastName": "TOUMBOU",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "b13067ff-71c3-4ed9-b2d6-8b0ce6756774",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275307151",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14244316",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68994203,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Troyes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152284166",
        "ip": "2a01:cb0d:60d:2a00:481f:b337:f857:8276",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:35:52",
        "isSmartClick": "0",
        "email": "massonremy52@gmail.com",
        "name": "Remy Masson",
        "firstName": "Remy",
        "lastName": "Masson",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "54be318a-215c-4a02-8b90-836e26279a83",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "275305354",
        "countryID": 75,
        "cityID": 3775,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14244235",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68993814,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Meppel",
        "custom": null,
        "custom1": null,
        "custom2": "valzria.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152282841",
        "ip": "84.26.107.247",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 07:31:49",
        "isSmartClick": "0",
        "email": "willem-klomp@live.nl",
        "name": "Willem Klomp",
        "firstName": "Willem",
        "lastName": "Klomp",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12326",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275304597",
        "countryID": 156,
        "cityID": 6214,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14244179",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68993503,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152281815",
        "ip": "2a02:8429:d533:fd01:c8ed:c573:4563:deba",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:28:38",
        "isSmartClick": "0",
        "email": "bertnardflores.4725@gmail.com",
        "name": "Bernard Flores",
        "firstName": "Bernard",
        "lastName": "Flores",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "37505487-70e2-44c1-92c4-635f1fb5642c",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "275304043",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14244133",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68993410,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152281549",
        "ip": "2a01:cb09:e03c:f11c:0:b:b26c:f401",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:27:47",
        "isSmartClick": "0",
        "email": "sauniergerald@orange.fr",
        "name": "Gerald Saunier",
        "firstName": "Gerald",
        "lastName": "Saunier",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "78115e34-861f-45c6-9de9-5a58c1ce179b",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275303879",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68992125,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Kapellen",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152277207",
        "ip": "2a02:1810:c33:9f00:35cf:1aa5:bfc6:b8c0",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 07:15:49",
        "isSmartClick": "0",
        "email": "fabien.liegeois@gmail.com",
        "name": "Fabien Liégeois",
        "firstName": "Fabien",
        "lastName": "Liégeois",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12325",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275301358",
        "countryID": 23,
        "cityID": 6825,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14243997",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68991611,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152275807",
        "ip": "2a01:e0a:aa4:7370:a49d:8031:836a:fdcc",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:11:08",
        "isSmartClick": "0",
        "email": "mabcont@yahoo.fr",
        "name": "Mabinty CONTE",
        "firstName": "Mabinty",
        "lastName": "CONTE",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "e6d8010e-9f36-491c-85e9-9a40b3a10254",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "No money",
        "saleStatusMapped": "No Money",
        "clickID": "275300515",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14243945",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68991534,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Breda",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152275442",
        "ip": "2001:1c03:4114:e100:2fff:6e28:9bed:d990",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 07:09:59",
        "isSmartClick": "0",
        "email": "Ayyoub14on@hotmail.com",
        "name": "Ayyoub Nachchat ",
        "firstName": "Ayyoub",
        "lastName": "Nachchat ",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "520322",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "Recent_NA",
        "saleStatusMapped": null,
        "clickID": "275300304",
        "countryID": 156,
        "cityID": 1526,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14243933",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68991530,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Saint-Priest",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152275435",
        "ip": "2001:861:3608:22f0:ca46:7a92:7759:ce85",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:09:53",
        "isSmartClick": "0",
        "email": "manindidier38@gmail.com",
        "name": "Manin Manin",
        "firstName": "Manin",
        "lastName": "Manin",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "78912245-7051-4b4f-b681-ae60ca57382e",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275300302",
        "countryID": 75,
        "cityID": 94474,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68991294,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Antony",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152274612",
        "ip": "77.133.249.137",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 07:05:13",
        "isSmartClick": "0",
        "email": "kristin05@yahoo.com",
        "name": "Chandler Kristin",
        "firstName": "Chandler",
        "lastName": "Kristin",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "22b11f15-6397-49d9-a9b7-32edf4e82cf9",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Wrong number",
        "saleStatusMapped": "Wrong Info",
        "clickID": "275299672",
        "countryID": 75,
        "cityID": 4714,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14243872",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68989522,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152268543",
        "ip": "146.70.194.8",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 06:39:47",
        "isSmartClick": "0",
        "email": "uuuiisidorov@gmail.com",
        "name": "Petr Sidorov",
        "firstName": "Petr",
        "lastName": "Sidorov",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "uuuiisidorov@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275296181",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68989225,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Angers",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152267651",
        "ip": "91.165.56.2",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 06:36:14",
        "isSmartClick": "0",
        "email": "b.f.famille@gmail.com",
        "name": "Franck biguet",
        "firstName": "Franck",
        "lastName": "biguet",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "b.f.famille@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275295711",
        "countryID": 75,
        "cityID": 4757,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68988727,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152266100",
        "ip": "80.215.108.102",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 06:28:58",
        "isSmartClick": "0",
        "email": "chaudronmathieu@hotmail.fr",
        "name": "Mathieu Chaudron",
        "firstName": "Mathieu",
        "lastName": "Chaudron",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "chaudronmathieu@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275294815",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68988152,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Pontivy",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152263910",
        "ip": "176.141.189.223",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 06:20:49",
        "isSmartClick": "0",
        "email": "celineprz16@gmail.com",
        "name": "Celine Grandyot",
        "firstName": "Celine",
        "lastName": "Grandyot",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "celineprz16@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275293779",
        "countryID": 75,
        "cityID": 19230,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68988119,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Merchtem",
        "custom": null,
        "custom1": null,
        "custom2": "satisfactoriness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152263744",
        "ip": "2a02:1811:2508:5d00:dcfa:1142:9356:b360",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-03 06:19:55",
        "isSmartClick": "0",
        "email": "anndebolle123@gmail.com",
        "name": "Ann  De Bolle",
        "firstName": "Ann ",
        "lastName": "De Bolle",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12324",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275293710",
        "countryID": 23,
        "cityID": 10872,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Major scandal: Immediate Edge refuses to comment on its employee Gert Verhulstt",
        "leadRequestOfferName": "Immediate Tine Embrechts",
        "leadRequestOfferWebsite": "Immediate Tine Embrechts",
        "brokerAutologinClick": "14243620",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68987966,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152262809",
        "ip": "92.184.110.89",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 06:17:12",
        "isSmartClick": "0",
        "email": "bertrandfouillat@hotmail.fr",
        "name": "BERTRAND FOUILLAT",
        "firstName": "BERTRAND",
        "lastName": "FOUILLAT",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "bertrandfouillat@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275293397",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68987487,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152261108",
        "ip": "2a02:8429:4d8d:2801:c8c5:dad3:7f8c:a884",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 06:11:38",
        "isSmartClick": "0",
        "email": "richardcaramanolis@icloud.com",
        "name": "Michel Caramanolis",
        "firstName": "Michel",
        "lastName": "Caramanolis",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "richardcaramanolis@icloud.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275292711",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68987140,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152259659",
        "ip": "176.140.192.93",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 06:08:37",
        "isSmartClick": "0",
        "email": "jenny90945@hotmail.com",
        "name": "Annabel Bonet",
        "firstName": "Annabel",
        "lastName": "Bonet",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jenny90945@hotmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275292235",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68986309,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152256045",
        "ip": "2a01:cb1e:1c:f3e3:0:53:bc64:8b01",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 06:01:19",
        "isSmartClick": "0",
        "email": "amccardiga@gmail.com",
        "name": "Alexandre Cardiga",
        "firstName": "Alexandre",
        "lastName": "Cardiga",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "amccardiga@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275291151",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68985555,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Canisy",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152252070",
        "ip": "88.166.11.227",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:51:53",
        "isSmartClick": "0",
        "email": "audelambert88.al@gmail.com",
        "name": "Aude Lambert",
        "firstName": "Aude",
        "lastName": "Lambert",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "audelambert88.al@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275289911",
        "countryID": 75,
        "cityID": 50750,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68985323,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Champagnole",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152249889",
        "ip": "176.133.251.72",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:48:18",
        "isSmartClick": "0",
        "email": "louvard54@gmail.com",
        "name": "Monique Louvard",
        "firstName": "Monique",
        "lastName": "Louvard",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "louvard54@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275289328",
        "countryID": 75,
        "cityID": 4890,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68984181,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Châtellerault",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152242247",
        "ip": "86.233.52.176",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:34:17",
        "isSmartClick": "0",
        "email": "liloudu86@hotmail.com",
        "name": "Cecile Duchatel",
        "firstName": "Cecile",
        "lastName": "Duchatel",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "liloudu86@hotmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275287224",
        "countryID": 75,
        "cityID": 4153,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68983518,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Trelon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152240294",
        "ip": "88.126.237.105",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:28:40",
        "isSmartClick": "0",
        "email": "sonspirater@gmail.com",
        "name": "Kris Olejniczak",
        "firstName": "Kris",
        "lastName": "Olejniczak",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "sonspirater@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275286354",
        "countryID": 75,
        "cityID": 23687,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68982716,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lorient",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152238131",
        "ip": "90.38.13.168",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:22:41",
        "isSmartClick": "0",
        "email": "daniel.kathelyn@orange.fr",
        "name": "Kathelyn Daniel",
        "firstName": "Kathelyn",
        "lastName": "Daniel",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "daniel.kathelyn@orange.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275285364",
        "countryID": 75,
        "cityID": 8043,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68982296,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Bordeaux",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152236577",
        "ip": "79.90.222.214",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:16:51",
        "isSmartClick": "0",
        "email": "celine.charrie@orange.fr",
        "name": "Celine Charrie",
        "firstName": "Celine",
        "lastName": "Charrie",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "celine.charrie@orange.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275284733",
        "countryID": 75,
        "cityID": 1012,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68982206,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152236254",
        "ip": "37.166.212.69",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:15:58",
        "isSmartClick": "0",
        "email": "houdaselmi947@gmail.com",
        "name": "Houda Selmi",
        "firstName": "Houda",
        "lastName": "Selmi",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "houdaselmi947@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275284584",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68982197,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152236202",
        "ip": "77.205.21.182",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:15:31",
        "isSmartClick": "0",
        "email": "sam3dl6@gmail.com",
        "name": "Daniel Lewandowski",
        "firstName": "Daniel",
        "lastName": "Lewandowski",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "sam3dl6@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275284558",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68982041,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152235581",
        "ip": "77.205.117.51",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:12:17",
        "isSmartClick": "0",
        "email": "titou.boucharessas27@gmail.com",
        "name": "Pierre Boucharessas",
        "firstName": "Pierre",
        "lastName": "Boucharessas",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "titou.boucharessas27@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275284328",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68981363,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Nice",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152232908",
        "ip": "81.65.160.127",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 05:02:51",
        "isSmartClick": "0",
        "email": "fifou05190@gmail.com",
        "name": "Florent Nicolas",
        "firstName": "Florent",
        "lastName": "Nicolas",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "fifou05190@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275283350",
        "countryID": 75,
        "cityID": 1922,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68981117,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Issoire",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152231870",
        "ip": "91.174.198.183",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 04:58:23",
        "isSmartClick": "0",
        "email": "jeangilles.marc@gmail.com",
        "name": "marc jeangilles",
        "firstName": "marc",
        "lastName": "jeangilles",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jeangilles.marc@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275282988",
        "countryID": 75,
        "cityID": 22100,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68980339,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lyon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152228518",
        "ip": "80.214.146.52",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 04:46:23",
        "isSmartClick": "0",
        "email": "bubasow99@gmail.com",
        "name": "Boubacar Thiam",
        "firstName": "Boubacar",
        "lastName": "Thiam",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "bubasow99@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275281853",
        "countryID": 75,
        "cityID": 898,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68979805,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Veretz",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152226226",
        "ip": "128.78.114.29",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 04:38:32",
        "isSmartClick": "0",
        "email": "annareisinho57@gmail.com",
        "name": "anna reisinho",
        "firstName": "anna",
        "lastName": "reisinho",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "annareisinho57@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275281095",
        "countryID": 75,
        "cityID": 41503,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68977732,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152217946",
        "ip": "37.165.184.218",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 04:09:37",
        "isSmartClick": "0",
        "email": "gaetan89500@gmail.com",
        "name": "Gaetan Savre",
        "firstName": "Gaetan",
        "lastName": "Savre",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "gaetan89500@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275278138",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68977595,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Le Marillais",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152217483",
        "ip": "88.172.157.89",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 04:07:24",
        "isSmartClick": "0",
        "email": "tyty3513@hotmail.fr",
        "name": "Betty Passier",
        "firstName": "Betty",
        "lastName": "Passier",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "tyty3513@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275277922",
        "countryID": 75,
        "cityID": 138440,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68977482,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Croissy-sur-Seine",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152217130",
        "ip": "89.35.100.56",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 04:06:15",
        "isSmartClick": "0",
        "email": "philip44210@gmail.com",
        "name": "Philippe Roulleau",
        "firstName": "Philippe",
        "lastName": "Roulleau",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "philip44210@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275277779",
        "countryID": 75,
        "cityID": 45934,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68975491,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Strasbourg",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152210368",
        "ip": "77.130.249.1",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 03:39:51",
        "isSmartClick": "0",
        "email": "beauvergergwendal@hotmail.fr",
        "name": "gwendal beauverger",
        "firstName": "gwendal",
        "lastName": "beauverger",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "beauvergergwendal@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275274889",
        "countryID": 75,
        "cityID": 1101,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68975179,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Béziers",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152209340",
        "ip": "176.165.198.216",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 03:35:55",
        "isSmartClick": "0",
        "email": "gaetano-deligny@hotmail.fr",
        "name": "Gaetano Deligny",
        "firstName": "Gaetano",
        "lastName": "Deligny",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "gaetano-deligny@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275274461",
        "countryID": 75,
        "cityID": 7630,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68974205,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152205993",
        "ip": "176.169.243.67",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 03:16:43",
        "isSmartClick": "0",
        "email": "vincentdavid732@gmail.com",
        "name": "Vincent David",
        "firstName": "Vincent",
        "lastName": "David",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "vincentdavid732@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275272983",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68973245,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Antony",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152202025",
        "ip": "77.133.249.98",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 02:34:39",
        "isSmartClick": "0",
        "email": "commentdesgenspeuventtombersurunpiegeaussinul@tamere.com",
        "name": "Wesh Escroc",
        "firstName": "Wesh",
        "lastName": "Escroc",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "commentdesgenspeuventtombersurunpiegeaussinul@tamere.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275270766",
        "countryID": 75,
        "cityID": 4714,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68973002,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152200907",
        "ip": "176.183.129.235",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 02:23:57",
        "isSmartClick": "0",
        "email": "trmalek@live.fr",
        "name": "Abdelmalek Troudi",
        "firstName": "Abdelmalek",
        "lastName": "Troudi",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "trmalek@live.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275270222",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68972234,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Laxou",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152197101",
        "ip": "37.67.94.142",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 01:48:04",
        "isSmartClick": "0",
        "email": "boulettom194@gmail.com",
        "name": "Tom Boulet",
        "firstName": "Tom",
        "lastName": "Boulet",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "boulettom194@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275268363",
        "countryID": 75,
        "cityID": 33939,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68970719,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Saint-Jean-Lasseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152189970",
        "ip": "88.167.160.131",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 01:20:35",
        "isSmartClick": "0",
        "email": "Anais100116@gmail.com",
        "name": "Anais Forasiepi",
        "firstName": "Anais",
        "lastName": "Forasiepi",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "Anais100116@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275265774",
        "countryID": 75,
        "cityID": 142507,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68970582,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152189107",
        "ip": "92.184.100.45",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 01:18:41",
        "isSmartClick": "0",
        "email": "Sogononk@gmail.com",
        "name": "Sogonon Traore",
        "firstName": "Sogonon",
        "lastName": "Traore",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "Sogononk@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275265563",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68970497,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Cuinchy",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152188634",
        "ip": "88.137.183.43",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 01:17:31",
        "isSmartClick": "0",
        "email": "kevinkayden59@gmail.com",
        "name": "kevin durand",
        "firstName": "kevin",
        "lastName": "durand",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "kevinkayden59@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275265429",
        "countryID": 75,
        "cityID": 82294,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68969420,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lyon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152183529",
        "ip": "81.185.171.31",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 01:03:46",
        "isSmartClick": "0",
        "email": "moisediatta42000@gmail.com",
        "name": "Moise Diatta Ndiaye",
        "firstName": "Moise",
        "lastName": "Diatta Ndiaye",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "moisediatta42000@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275263734",
        "countryID": 75,
        "cityID": 898,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68969080,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Ploermel",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152181495",
        "ip": "2001:861:2ce3:5590:9a01:df52:e4d3:96db",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:59:03",
        "isSmartClick": "0",
        "email": "tissier.vale@outlook.fr",
        "name": "Valerie Joffre",
        "firstName": "Valerie",
        "lastName": "Joffre",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "tissier.vale@outlook.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275263133",
        "countryID": 75,
        "cityID": 20475,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68968843,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152179771",
        "ip": "92.184.123.151",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:55:26",
        "isSmartClick": "0",
        "email": "aureliebourgain18@gmail.com",
        "name": "Aurelie Bourgain",
        "firstName": "Aurelie",
        "lastName": "Bourgain",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "aureliebourgain18@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275262688",
        "countryID": 75,
        "cityID": 1673,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68968226,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lyon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152175160",
        "ip": "78.198.127.102",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:44:23",
        "isSmartClick": "0",
        "email": "jofoudil@free.fr",
        "name": "Jowhara Foudil",
        "firstName": "Jowhara",
        "lastName": "Foudil",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jofoudil@free.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275261534",
        "countryID": 75,
        "cityID": 898,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68967778,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152172073",
        "ip": "91.205.43.130",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:36:56",
        "isSmartClick": "0",
        "email": "titi28965@gmail.com",
        "name": "Thierry bandrou",
        "firstName": "Thierry",
        "lastName": "bandrou",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "titi28965@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275260731",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68967529,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152169762",
        "ip": "78.243.37.243",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:33:43",
        "isSmartClick": "0",
        "email": "pierro.bastin@gmail.com",
        "name": "Pierre BASTIEN",
        "firstName": "Pierre",
        "lastName": "BASTIEN",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "pierro.bastin@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275260249",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68967253,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Saint-Denis",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152168659",
        "ip": "80.239.186.161",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:30:15",
        "isSmartClick": "0",
        "email": "solennportais@orange.fr",
        "name": "Solenn Portais",
        "firstName": "Solenn",
        "lastName": "Portais",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "solennportais@orange.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275259863",
        "countryID": 75,
        "cityID": 4685,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68965805,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Hautmont",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152160322",
        "ip": "88.166.241.59",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:08:58",
        "isSmartClick": "0",
        "email": "aurelievouloir88@gmail.com",
        "name": "Aurelie Vouloir",
        "firstName": "Aurelie",
        "lastName": "Vouloir",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "aurelievouloir88@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275257625",
        "countryID": 75,
        "cityID": 19433,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68965599,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Roubaix",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152159726",
        "ip": "2001:861:34c4:1cd0:6311:2225:c381:cfbc",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:06:31",
        "isSmartClick": "0",
        "email": "demazierechristiane@gmail.com",
        "name": "christiane DEMAZIERE",
        "firstName": "christiane",
        "lastName": "DEMAZIERE",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "demazierechristiane@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275257352",
        "countryID": 75,
        "cityID": 5618,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68965534,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Montauban",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152159612",
        "ip": "2a01:e0a:1dc:3650:9902:4e30:aacf:3bf3",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-03 00:05:56",
        "isSmartClick": "0",
        "email": "hochetmarylene@yahoo.fr",
        "name": "MARIEHELENE deSOUZA",
        "firstName": "MARIEHELENE",
        "lastName": "deSOUZA",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "hochetmarylene@yahoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275257263",
        "countryID": 75,
        "cityID": 7541,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68964659,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152157699",
        "ip": "78.243.37.243",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 23:54:41",
        "isSmartClick": "0",
        "email": "pierro.bastien@gmail.com",
        "name": "Pierre BASTIEN",
        "firstName": "Pierre",
        "lastName": "BASTIEN",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "pierro.bastien@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275256052",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68963810,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Rouen",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152154668",
        "ip": "62.35.7.210",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 23:32:01",
        "isSmartClick": "0",
        "email": "ludivine76160@live.fr",
        "name": "Ludivine Aubert",
        "firstName": "Ludivine",
        "lastName": "Aubert",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ludivine76160@live.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275254681",
        "countryID": 75,
        "cityID": 8217,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68963364,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152153038",
        "ip": "176.161.225.47",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 23:20:15",
        "isSmartClick": "0",
        "email": "bernautdany2712@gmail.com",
        "name": "daniel bernaut",
        "firstName": "daniel",
        "lastName": "bernaut",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "bernautdany2712@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275253946",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68963127,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Epone",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152152493",
        "ip": "88.173.216.238",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 23:15:41",
        "isSmartClick": "0",
        "email": "nawelnouiga6@gmail.com",
        "name": "Nawal Nouiga",
        "firstName": "Nawal",
        "lastName": "Nouiga",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "nawelnouiga6@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275253609",
        "countryID": 75,
        "cityID": 42454,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68963080,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152152397",
        "ip": "92.184.119.112",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 23:15:03",
        "isSmartClick": "0",
        "email": "penelopeparis2023@gmail.com",
        "name": "Francine GALTIER",
        "firstName": "Francine",
        "lastName": "GALTIER",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "penelopeparis2023@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275253540",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68962814,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Brive-la-Gaillarde",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152151669",
        "ip": "37.65.112.191",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 23:05:55",
        "isSmartClick": "0",
        "email": "ArnaudAlex24@gmail.fr",
        "name": "MarieFrance Charbonneau",
        "firstName": "MarieFrance",
        "lastName": "Charbonneau",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ArnaudAlex24@gmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275253032",
        "countryID": 75,
        "cityID": 17389,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68962063,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: energyzersk.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152149752",
        "ip": "178.51.18.128",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 22:52:39",
        "isSmartClick": "0",
        "email": "dimi.kimpe@gmail.com",
        "name": "Dimitry  KIMPE ",
        "firstName": "Dimitry ",
        "lastName": "KIMPE ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12323",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer 2",
        "saleStatusMapped": "No Answer",
        "clickID": "275251795",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14242451",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68961879,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "La Roche-de-Glun",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152149167",
        "ip": "88.178.246.162",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:49:03",
        "isSmartClick": "0",
        "email": "dejoiebonheur@gmail.com",
        "name": "Mc Demolder",
        "firstName": "Mc",
        "lastName": "Demolder",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "dejoiebonheur@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275251440",
        "countryID": 75,
        "cityID": 23867,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68960943,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Vannes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152146520",
        "ip": "128.79.22.15",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:33:35",
        "isSmartClick": "0",
        "email": "chrismour56@gmail.com",
        "name": "Christophe Moureau",
        "firstName": "Christophe",
        "lastName": "Moureau",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "chrismour56@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275249814",
        "countryID": 75,
        "cityID": 9461,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68960873,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152146345",
        "ip": "78.246.17.144",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:32:26",
        "isSmartClick": "0",
        "email": "mohamdinassardine@gmail.com",
        "name": "Nassardine Mohamdi",
        "firstName": "Nassardine",
        "lastName": "Mohamdi",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "mohamdinassardine@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275249712",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68960711,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Beaumont",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152145951",
        "ip": "2a01:e0a:e2b:1030:a164:37ca:ea54:7439",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:30:11",
        "isSmartClick": "0",
        "email": "mantana63@laposte.net",
        "name": "Marc lacquement",
        "firstName": "Marc",
        "lastName": "lacquement",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "mantana63@laposte.net",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275249463",
        "countryID": 75,
        "cityID": 33930,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68960675,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Nevers",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152145867",
        "ip": "94.238.138.12",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:29:45",
        "isSmartClick": "0",
        "email": "simongressot@gmail.com",
        "name": "Simon Gressot",
        "firstName": "Simon",
        "lastName": "Gressot",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "simongressot@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275249413",
        "countryID": 75,
        "cityID": 14409,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68960674,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lyon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152145863",
        "ip": "81.185.166.211",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:29:44",
        "isSmartClick": "0",
        "email": "dj1danjack@gmail.com",
        "name": "danieljacky PAULME",
        "firstName": "danieljacky",
        "lastName": "PAULME",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "dj1danjack@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275249410",
        "countryID": 75,
        "cityID": 898,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68959951,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Villeurbanne",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152142998",
        "ip": "90.52.107.201",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:15:22",
        "isSmartClick": "0",
        "email": "jldie801@gmail.com",
        "name": "Ngoran Jean Luc Die",
        "firstName": "Ngoran Jean Luc",
        "lastName": "Die",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jldie801@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275248042",
        "countryID": 75,
        "cityID": 7652,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68959787,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152141870",
        "ip": "81.65.134.80",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:12:10",
        "isSmartClick": "0",
        "email": "guerri.sabrina@icloud.fr",
        "name": "Sabrina Guerri",
        "firstName": "Sabrina",
        "lastName": "Guerri",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "guerri.sabrina@icloud.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275247680",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68959337,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152139432",
        "ip": "37.170.229.241",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 22:01:42",
        "isSmartClick": "0",
        "email": "ybenyahia576@gmail.com",
        "name": "Yamina Benyahia",
        "firstName": "Yamina",
        "lastName": "Benyahia",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ybenyahia576@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275246512",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68959239,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152138993",
        "ip": "37.166.170.17",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 21:59:06",
        "isSmartClick": "0",
        "email": "cagnoli.eric@gmail.com",
        "name": "Eric Cagnoli",
        "firstName": "Eric",
        "lastName": "Cagnoli",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "cagnoli.eric@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275246262",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68958521,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Bousse",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152135056",
        "ip": "83.194.87.241",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 21:44:14",
        "isSmartClick": "0",
        "email": "anyone910@hotmail.fr",
        "name": "Celine Saujon",
        "firstName": "Celine",
        "lastName": "Saujon",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "anyone910@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275244512",
        "countryID": 75,
        "cityID": 41117,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68958510,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Waalwijk",
        "custom": null,
        "custom1": null,
        "custom2": "hydrocholecystis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152134966",
        "ip": "2a02:a462:dd3:0:ff93:3dbe:8aaf:2e50",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 21:44:02",
        "isSmartClick": "0",
        "email": "Patrickvoorde@hotmail.nl",
        "name": "Patrick  Ten voorde ",
        "firstName": "Patrick ",
        "lastName": "Ten voorde ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12322",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "call back",
        "saleStatusMapped": "Call again",
        "clickID": "275244458",
        "countryID": 156,
        "cityID": 5350,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14242326",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68957756,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152129450",
        "ip": "2a01:cb09:d03c:a4a6:0:33:9880:d901",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 21:26:56",
        "isSmartClick": "0",
        "email": "smain.benabdeslam@gmail.com",
        "name": "Smain Benabdeslam",
        "firstName": "Smain",
        "lastName": "Benabdeslam",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "smain.benabdeslam@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275242555",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68956967,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152124848",
        "ip": "89.86.76.202",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 21:12:21",
        "isSmartClick": "0",
        "email": "myred7250@gmail.com",
        "name": "Edward Martin",
        "firstName": "Edward",
        "lastName": "Martin",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "myred7250@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275240660",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68956829,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Annecy",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152124048",
        "ip": "88.138.212.236",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 21:09:35",
        "isSmartClick": "0",
        "email": "cyrillegrillet@icloud.com",
        "name": "Cyrille Grillet",
        "firstName": "Cyrille",
        "lastName": "Grillet",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "cyrillegrillet@icloud.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275240307",
        "countryID": 75,
        "cityID": 17881,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68956827,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Pontoy",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152124023",
        "ip": "90.7.79.124",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 21:09:15",
        "isSmartClick": "0",
        "email": "sarah.papalia57@gmail.com",
        "name": "Sarah Gresta",
        "firstName": "Sarah",
        "lastName": "Gresta",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "sarah.papalia57@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275240288",
        "countryID": 75,
        "cityID": 134939,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68956532,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152122355",
        "ip": "37.165.2.45",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 21:04:22",
        "isSmartClick": "0",
        "email": "g.demirtchian@gmail.com",
        "name": "Gregory Demirtchian",
        "firstName": "Gregory",
        "lastName": "Demirtchian",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "g.demirtchian@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275239557",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68956326,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152121110",
        "ip": "2a01:cb1e:5e:1112:0:3c:512:f301",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:59:47",
        "isSmartClick": "0",
        "email": "marquet.nicolas4@gmail.com",
        "name": "Nicolas MARQUET",
        "firstName": "Nicolas",
        "lastName": "MARQUET",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "marquet.nicolas4@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275238888",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68956208,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Sanguinet",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152120657",
        "ip": "90.61.240.233",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:56:26",
        "isSmartClick": "0",
        "email": "angeleeste@live.fr",
        "name": "Angele Budinsky",
        "firstName": "Angele",
        "lastName": "Budinsky",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "angeleeste@live.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275238537",
        "countryID": 75,
        "cityID": 31354,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68956058,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Mittainville",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152120194",
        "ip": "2a01:e0a:20b:cc80:764e:dbeb:902c:1d80",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:51:53",
        "isSmartClick": "0",
        "email": "aurelien.bourgeot@gmail.com",
        "name": "Aurelien Bourgeot",
        "firstName": "Aurelien",
        "lastName": "Bourgeot",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "aurelien.bourgeot@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275238007",
        "countryID": 75,
        "cityID": 101689,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68955257,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152117516",
        "ip": "85.169.156.44",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:32:27",
        "isSmartClick": "0",
        "email": "moons20@hotmail.fr",
        "name": "Mounia Zegrar",
        "firstName": "Mounia",
        "lastName": "Zegrar",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "moons20@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275235737",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68955130,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Roeulx",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152117111",
        "ip": "2a01:cb0d:a021:a289:5c6d:8259:221b:d660",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:28:09",
        "isSmartClick": "0",
        "email": "martinguillaume61@gmail.com",
        "name": "Guillaume Martin",
        "firstName": "Guillaume",
        "lastName": "Martin",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "martinguillaume61@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275235287",
        "countryID": 75,
        "cityID": 24871,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68954865,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Mayenne",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152116131",
        "ip": "90.40.66.25",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:20:23",
        "isSmartClick": "0",
        "email": "icigui@yahoo.fr",
        "name": "Guillaume Ragot",
        "firstName": "Guillaume",
        "lastName": "Ragot",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "icigui@yahoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275234345",
        "countryID": 75,
        "cityID": 19396,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68954773,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "satisfactoriness.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152115809",
        "ip": "178.51.69.33",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 20:17:36",
        "isSmartClick": "0",
        "email": "r.fares74@hotmail.com",
        "name": "Rachid  Fares ",
        "firstName": "Rachid ",
        "lastName": "Fares ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12321",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275234041",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Major scandal: Immediate Edge refuses to comment on its employee Gert Verhulstt",
        "leadRequestOfferName": "Immediate Tine Embrechts",
        "leadRequestOfferWebsite": "Immediate Tine Embrechts",
        "brokerAutologinClick": "14242034",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68954568,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Anet",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152114940",
        "ip": "2a02:8429:b220:ea01:e80c:2ce2:8c95:3c13",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:12:47",
        "isSmartClick": "0",
        "email": "doc.27@hotmail.fr",
        "name": "Sebastien Buche",
        "firstName": "Sebastien",
        "lastName": "Buche",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "doc.27@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275233360",
        "countryID": 75,
        "cityID": 29575,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68954556,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152114915",
        "ip": "37.174.12.18",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:12:42",
        "isSmartClick": "0",
        "email": "valerieescolano94@gmail.com",
        "name": "Valerie ESCOLANO",
        "firstName": "Valerie",
        "lastName": "ESCOLANO",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "valerieescolano94@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275233351",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68954284,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Reims",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152113832",
        "ip": "90.109.205.87",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 20:06:24",
        "isSmartClick": "0",
        "email": "venonjonathan2@gmail.com",
        "name": "Jonathan Venon",
        "firstName": "Jonathan",
        "lastName": "Venon",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "venonjonathan2@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275232529",
        "countryID": 75,
        "cityID": 1919,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68954228,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zwolle",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: objectative.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152113576",
        "ip": "2a02:a440:967e:0:4c6a:2037:dc89:3b40",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 20:05:00",
        "isSmartClick": "0",
        "email": "Jo.jansen56@kpnmail.nl",
        "name": "Jo Jansen",
        "firstName": "Jo",
        "lastName": "Jansen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12320",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275232302",
        "countryID": 156,
        "cityID": 5049,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14241988",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68953701,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Elbeuf",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152111623",
        "ip": "2a01:e0a:5bf:f40:ecf2:214b:ec7e:173d",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:51:21",
        "isSmartClick": "0",
        "email": "Ingrid.egot@laposte.net",
        "name": "Ingrid Egot",
        "firstName": "Ingrid",
        "lastName": "Egot",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "Ingrid.egot@laposte.net",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275230603",
        "countryID": 75,
        "cityID": 20651,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68953632,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "L'Haÿ-les-Roses",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152111379",
        "ip": "86.238.243.42",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:49:58",
        "isSmartClick": "0",
        "email": "jcdepecker@gmail.com",
        "name": "DEPECKER JEAN CHARLES",
        "firstName": "DEPECKER",
        "lastName": "JEAN CHARLES",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jcdepecker@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275230409",
        "countryID": 75,
        "cityID": 3937,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68953457,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Les Mees",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152110360",
        "ip": "88.172.19.93",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:44:58",
        "isSmartClick": "0",
        "email": "didierlyons@outlook.fr",
        "name": "Didier Lyons",
        "firstName": "Didier",
        "lastName": "Lyons",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "didierlyons@outlook.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275229843",
        "countryID": 75,
        "cityID": 27608,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68953176,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152109006",
        "ip": "2a01:e0a:994:e390:7856:cad0:1e65:2360",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:37:34",
        "isSmartClick": "0",
        "email": "alexanderbourrelly@gmail.com",
        "name": "Alexandre bourrelly",
        "firstName": "Alexandre",
        "lastName": "bourrelly",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "alexanderbourrelly@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275228859",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68952932,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Alençon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152107811",
        "ip": "88.172.206.221",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:31:17",
        "isSmartClick": "0",
        "email": "gdiangouaya@gmail.com",
        "name": "Gabriel DIANGOUAYA",
        "firstName": "Gabriel",
        "lastName": "DIANGOUAYA",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "gdiangouaya@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275227529",
        "countryID": 75,
        "cityID": 18824,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68952800,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Ajaccio",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152106993",
        "ip": "86.204.116.67",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:24:05",
        "isSmartClick": "0",
        "email": "salinijeanette@gmail.com",
        "name": "Juanita Salini",
        "firstName": "Juanita",
        "lastName": "Salini",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "salinijeanette@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275225430",
        "countryID": 75,
        "cityID": 8231,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68952680,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152106528",
        "ip": "2a02:8440:3311:f085:4458:f5ff:fe10:b07b",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:19:09",
        "isSmartClick": "0",
        "email": "ced26dufour@gmail.com",
        "name": "Cedric Dufour",
        "firstName": "Cedric",
        "lastName": "Dufour",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ced26dufour@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275224069",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68952521,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Chalon-sur-Saône",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152105614",
        "ip": "89.86.120.166",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:11:36",
        "isSmartClick": "0",
        "email": "benkhalamohamed@gmail.com",
        "name": "Mohamed Benkhala",
        "firstName": "Mohamed",
        "lastName": "Benkhala",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "benkhalamohamed@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275221942",
        "countryID": 75,
        "cityID": 8004,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68952286,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Villejuif",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152104529",
        "ip": "91.170.233.231",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:02:21",
        "isSmartClick": "0",
        "email": "maryse.jeangilles13@gmail.com",
        "name": "Maryse Jean gilles",
        "firstName": "Maryse",
        "lastName": "Jean gilles",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "maryse.jeangilles13@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275219490",
        "countryID": 75,
        "cityID": 4642,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68952242,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Chalon-sur-Saône",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152104347",
        "ip": "89.86.120.166",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:00:29",
        "isSmartClick": "0",
        "email": "layren7189@gmail.com",
        "name": "Abdelhafid Amara",
        "firstName": "Abdelhafid",
        "lastName": "Amara",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "layren7189@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275219000",
        "countryID": 75,
        "cityID": 8004,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68952234,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Champigny-sur-Marne",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152104308",
        "ip": "2a02:8440:a11b:98d:0:35:b708:d501",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 19:00:07",
        "isSmartClick": "0",
        "email": "machigouane@gmail.com",
        "name": "Laziz GHOUAT",
        "firstName": "Laziz",
        "lastName": "GHOUAT",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "machigouane@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275218925",
        "countryID": 75,
        "cityID": 4921,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68952016,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152103460",
        "ip": "78.243.51.73",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 18:52:13",
        "isSmartClick": "0",
        "email": "a.prousseville@hotmail.fr",
        "name": "Philippe Paillart",
        "firstName": "Philippe",
        "lastName": "Paillart",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "a.prousseville@hotmail.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275216862",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68951959,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "antifungin.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152103265",
        "ip": "2a02:a020:540:6608:5bbd:4f27:e5a9:ffd9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 18:50:05",
        "isSmartClick": "0",
        "email": "Fredericapril155@gmail.com",
        "name": "Frédéric April",
        "firstName": "Frédéric",
        "lastName": "April",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12319",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275216287",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14241693",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68951874,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152102878",
        "ip": "78.243.51.73",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 18:46:25",
        "isSmartClick": "0",
        "email": "paillartjuliette@gmail.com",
        "name": "Julie Paillart",
        "firstName": "Julie",
        "lastName": "Paillart",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "paillartjuliette@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275215370",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68951733,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152102320",
        "ip": "2a01:cb0a:9021:9eec:d37:6b91:6abe:618",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 18:41:09",
        "isSmartClick": "0",
        "email": "gmb16@live.fr",
        "name": "Guy Benteo",
        "firstName": "Guy",
        "lastName": "Benteo",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "gmb16@live.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275214017",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68951315,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152100657",
        "ip": "78.243.195.125",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 18:24:51",
        "isSmartClick": "0",
        "email": "joseselgas51@gmail.com",
        "name": "Jose Fernandes",
        "firstName": "Jose",
        "lastName": "Fernandes",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "joseselgas51@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275210013",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68951179,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marnay",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152100163",
        "ip": "2a01:cb10:8be4:3900:b136:b436:be23:615f",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 18:19:43",
        "isSmartClick": "0",
        "email": "daniel.roy871@orange.fr",
        "name": "Roy Roy",
        "firstName": "Roy",
        "lastName": "Roy",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "daniel.roy871@orange.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275208719",
        "countryID": 75,
        "cityID": 32569,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68950819,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152098848",
        "ip": "77.128.124.172",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 18:09:28",
        "isSmartClick": "0",
        "email": "keraounzab@gmail.com",
        "name": "Melkheir Ounzab",
        "firstName": "Melkheir",
        "lastName": "Ounzab",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "keraounzab@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275206008",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68950686,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152098448",
        "ip": "2a02:8429:d533:fd01:c8ed:c573:4563:deba",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 18:06:01",
        "isSmartClick": "0",
        "email": "bertnardflores.4725@gmail.com",
        "name": "Bernard Flores",
        "firstName": "Bernard",
        "lastName": "Flores",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "bertnardflores.4725@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275205088",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68950443,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152097444",
        "ip": "2a01:cb0a:9020:c5d8:f515:eb2d:7509:b601",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 17:57:45",
        "isSmartClick": "0",
        "email": "juliette.charles.91@icloud.com",
        "name": "Juliette Charle Juliette",
        "firstName": "Juliette Charle",
        "lastName": "Juliette",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "juliette.charles.91@icloud.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275203135",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68949804,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Toulouse",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152095197",
        "ip": "2a01:e34:ec25:230:e0d1:8ce2:b0ce:6170",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 17:39:55",
        "isSmartClick": "0",
        "email": "mihailangelov808@gmail.com",
        "name": "Mihail Angelov",
        "firstName": "Mihail",
        "lastName": "Angelov",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "mihailangelov808@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275198597",
        "countryID": 75,
        "cityID": 1655,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68949457,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Vannes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152094085",
        "ip": "2a01:cb08:835c:1100:70ba:dbae:d901:74b7",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 17:31:26",
        "isSmartClick": "0",
        "email": "7ursulines@gmail.com",
        "name": "Philippe Le Formal",
        "firstName": "Philippe",
        "lastName": "Le Formal",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "7ursulines@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275196458",
        "countryID": 75,
        "cityID": 9461,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68949037,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152092714",
        "ip": "37.170.234.4",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 17:21:56",
        "isSmartClick": "0",
        "email": "bellayp62@gmail.com",
        "name": "Patrice Bellay",
        "firstName": "Patrice",
        "lastName": "Bellay",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "bellayp62@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275194019",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68948730,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Schiedam",
        "custom": null,
        "custom1": null,
        "custom2": "valzria.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152091587",
        "ip": "62.45.43.43",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 17:16:10",
        "isSmartClick": "0",
        "email": "Aris.dheer@gmail.com",
        "name": "Aris Jan De  Heer ",
        "firstName": "Aris Jan",
        "lastName": "De  Heer ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12318",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275192397",
        "countryID": 156,
        "cityID": 3781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14241133",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68948381,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152090204",
        "ip": "2a01:cb06:b804:5084:25dd:bc73:2ff2:4b97",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 17:08:45",
        "isSmartClick": "0",
        "email": "teriitehautaputu@orange.fr",
        "name": "TAPUTU TERIITEHAU",
        "firstName": "TAPUTU",
        "lastName": "TERIITEHAU",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "teriitehautaputu@orange.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275190452",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68947873,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Nogent-sur-Seine",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152088521",
        "ip": "2a01:e0a:955:26c0:a346:f84f:5ab4:96a6",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:59:02",
        "isSmartClick": "0",
        "email": "anniechaboteau1960@gmail.com",
        "name": "Annie Chaboteau",
        "firstName": "Annie",
        "lastName": "Chaboteau",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "anniechaboteau1960@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275187728",
        "countryID": 75,
        "cityID": 31403,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68947737,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Nantes",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152088100",
        "ip": "2a02:8440:7154:c85:a0d6:611e:972a:5174",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:56:19",
        "isSmartClick": "0",
        "email": "mouctarami@gmail.com",
        "name": "Almamy Mouctar DIAKITE",
        "firstName": "Almamy Mouctar",
        "lastName": "DIAKITE",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "mouctarami@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275186935",
        "countryID": 75,
        "cityID": 510,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68947324,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Gergy",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152086621",
        "ip": "90.33.7.109",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:47:58",
        "isSmartClick": "0",
        "email": "jean-pul.lhomme@wanadoo.fr",
        "name": "JeanPau Lhomme",
        "firstName": "JeanPau",
        "lastName": "Lhomme",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "jean-pul.lhomme@wanadoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275184246",
        "countryID": 75,
        "cityID": 23148,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68946866,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Le Mans",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152085137",
        "ip": "2a01:cb05:823d:4d00:c066:b83b:59af:274c",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:39:06",
        "isSmartClick": "0",
        "email": "hunaultjocelyne@email.fr",
        "name": "Jocelyne Hunault",
        "firstName": "Jocelyne",
        "lastName": "Hunault",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "hunaultjocelyne@email.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275181370",
        "countryID": 75,
        "cityID": 4472,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68946851,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152085080",
        "ip": "2a01:e34:ecbf:4de0:4eaf:5183:cce5:dfeb",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:38:50",
        "isSmartClick": "0",
        "email": "somphet689@gmail.com",
        "name": "Sompheto Ngonavichit",
        "firstName": "Sompheto",
        "lastName": "Ngonavichit",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "somphet689@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275181291",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68946832,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Chelles",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152085011",
        "ip": "2001:861:4902:9c50:4cc8:6b9e:9bb6:8ce5",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:38:26",
        "isSmartClick": "0",
        "email": "trexayirevopoulos@yahoo.com",
        "name": "Rachida DATI",
        "firstName": "Rachida",
        "lastName": "DATI",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "trexayirevopoulos@yahoo.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275181182",
        "countryID": 75,
        "cityID": 3510,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68946812,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Castets",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152084947",
        "ip": "2a01:e0a:ade:c670:8878:73e5:2ea0:1188",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:38:01",
        "isSmartClick": "0",
        "email": "kathy.leleu@hotmail.com",
        "name": "Catherine PAGERON",
        "firstName": "Catherine",
        "lastName": "PAGERON",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "kathy.leleu@hotmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275181060",
        "countryID": 75,
        "cityID": 26621,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68946525,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Henin-Beaumont",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152084002",
        "ip": "79.83.254.235",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:32:21",
        "isSmartClick": "0",
        "email": "ari.stide@sfr.fr",
        "name": "Aristide Mezieche",
        "firstName": "Aristide",
        "lastName": "Mezieche",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "ari.stide@sfr.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275179294",
        "countryID": 75,
        "cityID": 19375,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68946512,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152083976",
        "ip": "2a04:cec0:11bb:e548:0:3d:20ef:9101",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:32:10",
        "isSmartClick": "0",
        "email": "armandalain@yahoo.fr",
        "name": "Alain armand",
        "firstName": "Alain",
        "lastName": "armand",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "armandalain@yahoo.fr",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275179239",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68946459,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "dilleniaceae.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152083805",
        "ip": "87.211.88.11",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 16:31:15",
        "isSmartClick": "0",
        "email": "andrethomas373@gmail.com",
        "name": "André Thomas",
        "firstName": "André",
        "lastName": "Thomas",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12317",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275178961",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14240709",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68946136,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Rillieux-la-Pape",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152082756",
        "ip": "79.90.184.123",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:25:14",
        "isSmartClick": "0",
        "email": "oliveiraeuclides565@gmail.com",
        "name": "Euclides Oliveira",
        "firstName": "Euclides",
        "lastName": "Oliveira",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "oliveiraeuclides565@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275176970",
        "countryID": 75,
        "cityID": 9482,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68945546,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Toulon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152080619",
        "ip": "2a04:cec0:11ac:9e5f:0:2f:b887:e401",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:14:41",
        "isSmartClick": "0",
        "email": "grverte@gmail.com",
        "name": "Mohamed Boussakouk",
        "firstName": "Mohamed",
        "lastName": "Boussakouk",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "grverte@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275173132",
        "countryID": 75,
        "cityID": 1034,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68944693,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152078079",
        "ip": "37.174.7.84",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 16:00:41",
        "isSmartClick": "0",
        "email": "narongfillatraud@gmail.com",
        "name": "Narong Fillatraud",
        "firstName": "Narong",
        "lastName": "Fillatraud",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "narongfillatraud@gmail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275168203",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68944334,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "hereditarianism.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152076815",
        "ip": "2a02:a468:5925:0:1472:48b5:eff9:1735",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 15:55:45",
        "isSmartClick": "0",
        "email": "johnstroes@outlook.com",
        "name": "John Stroes",
        "firstName": "John",
        "lastName": "Stroes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12316",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275166248",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14240294",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68940975,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuernavaca",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152067505",
        "ip": "2806:104e:1b:a665:290b:daae:ca05:8650",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 15:25:32",
        "isSmartClick": "0",
        "email": "gabyk1407@gmail.com",
        "name": "Gabriela Franco",
        "firstName": "Gabriela",
        "lastName": "Franco",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "gabyk1407@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275153224",
        "countryID": 143,
        "cityID": 8351,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68940109,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152065062",
        "ip": "189.203.63.4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 15:18:32",
        "isSmartClick": "0",
        "email": "luisandresmorales56@gmail.com",
        "name": "Luis Andres Morales Guadarrama",
        "firstName": "Luis Andres",
        "lastName": "Morales Guadarrama",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "luisandresmorales56@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275150160",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68940085,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mérida",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152065014",
        "ip": "190.123.12.154",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 15:18:25",
        "isSmartClick": "0",
        "email": "juanbojorquezrebolledo@gmail.com",
        "name": "Juan José Bojoquez solis",
        "firstName": "Juan José",
        "lastName": "Bojoquez solis",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "juanbojorquezrebolledo@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275150097",
        "countryID": 143,
        "cityID": 366,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68939806,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Ede",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: objectative.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152064305",
        "ip": "89.205.140.5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 15:16:33",
        "isSmartClick": "0",
        "email": "Bekor-stuc@hotmail.com",
        "name": "Benny Kortboyer",
        "firstName": "Benny",
        "lastName": "Kortboyer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12315",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275149323",
        "countryID": 156,
        "cityID": 1309,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14239565",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68938938,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152061790",
        "ip": "189.217.103.97",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 15:09:46",
        "isSmartClick": "0",
        "email": "vianeyauroracruzleon4@gmail.com",
        "name": "Vianey Aurora Cruz Leon",
        "firstName": "Vianey Aurora",
        "lastName": "Cruz Leon",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "f0b8d6b79078b16ede9265452f8920bc",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275146024",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68937167,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Antony",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152056886",
        "ip": "77.133.250.105",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 14:55:01",
        "isSmartClick": "0",
        "email": "francisdavid@gmail.com",
        "name": "David Monette",
        "firstName": "David",
        "lastName": "Monette",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "73867eba-a288-4d9a-adb8-0a3ece6158af",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Wrong number",
        "saleStatusMapped": "Wrong Info",
        "clickID": "275140378",
        "countryID": 75,
        "cityID": 4714,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14239115",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68936995,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Helmond",
        "custom": null,
        "custom1": null,
        "custom2": "dilleniaceae.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152056456",
        "ip": "2a02:a465:7180:0:f904:99da:a8b8:6bdf",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 14:54:15",
        "isSmartClick": "0",
        "email": "gsamanoudi@gmail.com",
        "name": "Gamal Samanoudi ",
        "firstName": "Gamal",
        "lastName": "Samanoudi ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12314",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275140221",
        "countryID": 156,
        "cityID": 1558,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14239088",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68936567,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Ridderkerk",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152054571",
        "ip": "2a02:a213:5c3:e080:a03b:90b4:8402:d8ae",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 14:52:19",
        "isSmartClick": "0",
        "email": "denouden04@gmail.com",
        "name": "michel den ouden",
        "firstName": "michel",
        "lastName": "den ouden",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12313",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275139022",
        "countryID": 156,
        "cityID": 2570,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14239027",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68935932,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152052775",
        "ip": "2a02:a420:418:efb7:2e83:6c06:9d42:d495",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 14:48:34",
        "isSmartClick": "0",
        "email": "Omicheneau@gmail.com",
        "name": "Olivier  Micheneau ",
        "firstName": "Olivier ",
        "lastName": "Micheneau ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12312",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275137618",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14238930",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68934370,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152048666",
        "ip": "176.170.23.68",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 14:36:17",
        "isSmartClick": "0",
        "email": "nathaliefleury47220@gmail.com",
        "name": "Nathalie Fleury",
        "firstName": "Nathalie",
        "lastName": "Fleury",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "aa59b233-9032-4f35-a74a-2d030269e747",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 3",
        "saleStatusMapped": null,
        "clickID": "275133966",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14238666",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68931970,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "mormaordom.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152042160",
        "ip": "82.168.3.66",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 14:17:37",
        "isSmartClick": "0",
        "email": "Versluijs.m@telfort.nl",
        "name": "Marcel  Versluijs ",
        "firstName": "Marcel ",
        "lastName": "Versluijs ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12311",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275128995",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14238248",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68931374,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Lyon",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152040551",
        "ip": "81.185.168.113",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 14:12:34",
        "isSmartClick": "0",
        "email": "alyendolou@gmail.com",
        "name": "Derdour Neyla",
        "firstName": "Derdour",
        "lastName": "Neyla",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "ae266468-58d0-4b24-aef9-8fdf0e07b353",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Low potential",
        "saleStatusMapped": "Low Potentional",
        "clickID": "275127646",
        "countryID": 75,
        "cityID": 898,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14238112",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68930871,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Henin-Beaumont",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152039132",
        "ip": "2a04:cec0:1145:318:e0dd:f236:d843:3e76",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 14:08:07",
        "isSmartClick": "0",
        "email": "gilles.viez80@gemail.com",
        "name": "Viez Viez",
        "firstName": "Viez",
        "lastName": "Viez",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "gilles.viez80@gemail.com",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "275126286",
        "countryID": 75,
        "cityID": 19375,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68929016,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Marseille",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152033524",
        "ip": "77.205.21.137",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 13:55:23",
        "isSmartClick": "0",
        "email": "sandrine.buisseret1@gmail.com",
        "name": "Sandrine Buisseret",
        "firstName": "Sandrine",
        "lastName": "Buisseret",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "8d34fa71-e2c4-45de-81c1-e4ed07062e06",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Callback",
        "saleStatusMapped": "Call again",
        "clickID": "275122634",
        "countryID": 75,
        "cityID": 3938,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-02 14:52:06",
        "depositID": "8d34fa71-e2c4-45de-81c1-e4ed07062e06",
        "conversionID": "1215885",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14237624",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68926987,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152027260",
        "ip": "37.165.199.206",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 13:39:32",
        "isSmartClick": "0",
        "email": "jupanpan2000@yahoo.fr",
        "name": "Julien Pandelon",
        "firstName": "Julien",
        "lastName": "Pandelon",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "b600d076-690c-4c0a-99fe-98438876ac8e",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "275118819",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14237284",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68925114,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Aramits",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152022112",
        "ip": "90.30.145.104",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 13:26:13",
        "isSmartClick": "0",
        "email": "sheylasoraya1709@gmail.com",
        "name": "Sheyla Belazzouz",
        "firstName": "Sheyla",
        "lastName": "Belazzouz",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "6abea8a1-9027-460a-8013-5243b082fc25",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 2",
        "saleStatusMapped": null,
        "clickID": "275115196",
        "countryID": 75,
        "cityID": 35344,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14237029",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68924754,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Kortemark",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "152021122",
        "ip": "2a02:a03f:e19c:3e00:14b4:961:98e3:dfd6",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 13:23:27",
        "isSmartClick": "0",
        "email": "christ.vermeulen1@gmail.com",
        "name": "CHRIST VERMEULEN",
        "firstName": "CHRIST",
        "lastName": "VERMEULEN",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12310",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275114517",
        "countryID": 23,
        "cityID": 6869,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-02 14:57:53",
        "depositID": "20408301",
        "conversionID": "1215886",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14236956",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68924540,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Thionville",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152020567",
        "ip": "91.161.164.85",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 13:22:14",
        "isSmartClick": "0",
        "email": "daasdaas42@gmail.com",
        "name": "Farida Daas",
        "firstName": "Farida",
        "lastName": "Daas",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "d4a3ae59-7569-4402-abae-508c77e0e363",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275114041",
        "countryID": 75,
        "cityID": 13262,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68924098,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Paris",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152019018",
        "ip": "92.184.107.235",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 13:18:44",
        "isSmartClick": "0",
        "email": "lucrece.chuette@gmail.com",
        "name": "Lucrece Chuette",
        "firstName": "Lucrece",
        "lastName": "Chuette",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "764a5d84-5928-4c73-846d-5aedfe350677",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Wrong info",
        "saleStatusMapped": "Wrong Info",
        "clickID": "275113234",
        "countryID": 75,
        "cityID": 530,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14236858",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68923794,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Houten",
        "custom": null,
        "custom1": null,
        "custom2": "hereditarianism.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152018166",
        "ip": "2a02:a443:35ac:1:deab:6ea0:4d4f:bda6",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 13:16:37",
        "isSmartClick": "0",
        "email": "RvGooswilligen@oro.nl",
        "name": "Rene  Gooswilligen",
        "firstName": "Rene ",
        "lastName": "Gooswilligen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12309",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275112675",
        "countryID": 156,
        "cityID": 1669,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14236798",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68922798,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152014723",
        "ip": "37.168.77.141",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 13:09:53",
        "isSmartClick": "0",
        "email": "jonval.morgane@gmail.com",
        "name": "Jonval Morgane",
        "firstName": "Jonval",
        "lastName": "Morgane",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "21b615ee-8c99-4902-9f69-3525838c393b",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Na 1",
        "saleStatusMapped": null,
        "clickID": "275110629",
        "countryID": 75,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14236608",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68922791,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Haarlem",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152014713",
        "ip": "77.249.8.137",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 13:09:49",
        "isSmartClick": "0",
        "email": "Adrie.john@outlook.com",
        "name": "John Zuurendonk",
        "firstName": "John",
        "lastName": "Zuurendonk",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12308",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275110615",
        "countryID": 156,
        "cityID": 494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14236597",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68920840,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rozenburg",
        "custom": null,
        "custom1": null,
        "custom2": "jesuitry.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "152008160",
        "ip": "62.194.89.227",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 12:55:11",
        "isSmartClick": "0",
        "email": "nicodevries100@outlook.com",
        "name": "Nico De Vries",
        "firstName": "Nico",
        "lastName": "De Vries",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12307",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275106985",
        "countryID": 156,
        "cityID": 3864,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14236207",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68920650,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "52c3033fc2",
        "affiliateID": "85016",
        "affiliateName": "MediaKings",
        "affiliateEmail": "mediakings@gmail2.com",
        "countryName": "France",
        "cityName": "Ecques",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "152007500",
        "ip": "176.163.104.13",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-02 12:53:18",
        "isSmartClick": "0",
        "email": "dydydup59@gmail.com",
        "name": "dylandugmailcom Dupriez",
        "firstName": "dylandugmailcom",
        "lastName": "Dupriez",
        "brokerID": "24903",
        "brokerName": "California",
        "customerID": "e3f8a32e-4319-43c0-a816-7f1dd8289c3c",
        "leadType": "0",
        "countryCode": "FR",
        "saleStatus": "Hungupp",
        "saleStatusMapped": null,
        "clickID": "275106601",
        "countryID": 75,
        "cityID": 22396,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "French (France)",
        "localeCode": "fr_FR",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Immediate Ai",
        "leadRequestOfferName": "Immediate Ai",
        "leadRequestOfferWebsite": "Immediate Ai",
        "brokerAutologinClick": "14236171",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68918670,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "permocarboniferous.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "152001971",
        "ip": "38.180.131.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 12:38:11",
        "isSmartClick": "0",
        "email": "test876789@gmail.com",
        "name": "test876789 test876789",
        "firstName": "test876789",
        "lastName": "test876789",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12306",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275102854",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "SPECIAL NOTICE: RTL Late Night launches their newest platform Orionis Prime to help families become wealthier.",
        "leadRequestOfferName": "Orionis Prime - Humberto Tan ",
        "leadRequestOfferWebsite": "Orionis Prime - Humberto Tan ",
        "brokerAutologinClick": "14235876",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68918655,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Sliedrecht",
        "custom": null,
        "custom1": null,
        "custom2": "zestva.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "152001873",
        "ip": "86.95.165.103",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 12:38:02",
        "isSmartClick": "0",
        "email": "gbvolker@gmail.com",
        "name": "Geurt Volker",
        "firstName": "Geurt",
        "lastName": "Volker",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12305",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275102797",
        "countryID": 156,
        "cityID": 89858,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-02 14:16:01",
        "depositID": "20408242",
        "conversionID": "1215825",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14235873",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68914168,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "The Hague",
        "custom": null,
        "custom1": null,
        "custom2": "hydrocholecystis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151989522",
        "ip": "77.160.58.190",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 12:00:22",
        "isSmartClick": "0",
        "email": "papasambasimal@gmail.com",
        "name": "Papa Simal",
        "firstName": "Papa",
        "lastName": "Simal",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12304",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275093699",
        "countryID": 156,
        "cityID": 921,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14235202",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68912596,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hilversum",
        "custom": null,
        "custom1": null,
        "custom2": "dilleniaceae.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151984677",
        "ip": "81.207.176.76",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 11:47:46",
        "isSmartClick": "0",
        "email": "se.tjoa@gmail.com",
        "name": "Sandra Tjoa",
        "firstName": "Sandra",
        "lastName": "Tjoa",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12303",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275090193",
        "countryID": 156,
        "cityID": 1556,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14235001",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68910700,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151978523",
        "ip": "89.205.227.70",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 11:33:40",
        "isSmartClick": "0",
        "email": "ourdream45@hotmail.com",
        "name": "Eric Mol",
        "firstName": "Eric",
        "lastName": "Mol",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12302",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275086463",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14234779",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68908848,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Leiden",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151973126",
        "ip": "2001:1c00:334:bd00:449a:4a37:e53e:51d0",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 11:21:43",
        "isSmartClick": "0",
        "email": "Bartdol20@gmail.com",
        "name": "Bart Dol",
        "firstName": "Bart",
        "lastName": "Dol",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12301",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275083060",
        "countryID": 156,
        "cityID": 1541,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14234530",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68907174,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: objectative.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151968342",
        "ip": "109.38.138.224",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 11:09:40",
        "isSmartClick": "0",
        "email": "h.krijger@hotmail.com",
        "name": "Hans Krijger ",
        "firstName": "Hans",
        "lastName": "Krijger ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12300",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275079778",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14234306",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68905448,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Winschoten",
        "custom": null,
        "custom1": null,
        "custom2": "jesuitry.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151962024",
        "ip": "2a02:a455:1e8:1:752a:c723:63fb:da17",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 10:58:04",
        "isSmartClick": "0",
        "email": "hansyvonne1938@gmail.com",
        "name": "Hans Groenewegen",
        "firstName": "Hans",
        "lastName": "Groenewegen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12299",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275076722",
        "countryID": 156,
        "cityID": 13997,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-02 14:58:34",
        "depositID": "20408378",
        "conversionID": "1215887",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14234097",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68905123,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Meterik",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: objectative.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151960786",
        "ip": "217.100.171.142",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 10:55:53",
        "isSmartClick": "0",
        "email": "Robjans@hotmail.com",
        "name": "Rob Jans",
        "firstName": "Rob",
        "lastName": "Jans",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12298",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275076043",
        "countryID": 156,
        "cityID": 9809,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14234052",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68905101,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151960724",
        "ip": "2a02:a45c:a38e:1:c5fb:4fb4:9e43:715b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 10:55:42",
        "isSmartClick": "0",
        "email": "reggus@hotmail.nl",
        "name": "Regilio  Felixdaal ",
        "firstName": "Regilio ",
        "lastName": "Felixdaal ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12297",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275076004",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14234049",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68900767,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "geochrony.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151947016",
        "ip": "84.241.207.32",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 10:15:29",
        "isSmartClick": "0",
        "email": "w.bendt@hotmail.com",
        "name": "W  Bendt",
        "firstName": "W ",
        "lastName": "Bendt",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12296",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275066609",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-02 11:17:06",
        "depositID": "20407958",
        "conversionID": "1215533",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14233236",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68900479,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "hereditarianism.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151946284",
        "ip": "2a02:a420:60:50b4:d053:fee:33ec:fe91",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 10:11:52",
        "isSmartClick": "0",
        "email": "jeandhorion@hotmail.com",
        "name": "Jean Horion",
        "firstName": "Jean",
        "lastName": "Horion",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12295",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275065992",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68898540,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "tranquilretirementhome.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151940019",
        "ip": "188.206.102.41",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 09:48:34",
        "isSmartClick": "0",
        "email": "aligunduz58@live.nl",
        "name": "Ali Gunduz ",
        "firstName": "Ali",
        "lastName": "Gunduz ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12294",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275061296",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Carice van Houten regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Carice van Houten accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14232546",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68898458,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Merelbeke",
        "custom": null,
        "custom1": null,
        "custom2": "tartarish.com",
        "custom3": "Dutch",
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151939778",
        "ip": "213.119.100.27",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 09:47:24",
        "isSmartClick": "0",
        "email": "test1@test.site",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12293",
        "leadType": "1",
        "countryCode": "BE",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275061083",
        "countryID": 23,
        "cityID": 6858,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14232528",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68898270,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Alphen aan den Rijn",
        "custom": null,
        "custom1": null,
        "custom2": "brachyuranic.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151939140",
        "ip": "62.250.198.194",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 09:45:28",
        "isSmartClick": "0",
        "email": "ajpn22@gmail.com",
        "name": "Arjan Nagtegaal",
        "firstName": "Arjan",
        "lastName": "Nagtegaal",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12292",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275060624",
        "countryID": 156,
        "cityID": 1537,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14232469",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68897369,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Almere Stad",
        "custom": null,
        "custom1": null,
        "custom2": "pituitousness.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151936455",
        "ip": "143.178.246.92",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 09:34:43",
        "isSmartClick": "0",
        "email": "test@test.link",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12291",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275058470",
        "countryID": 156,
        "cityID": 1524,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14232185",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68895815,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "151932649",
        "ip": "94.109.144.205",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 09:21:24",
        "isSmartClick": "0",
        "email": "aissatimohamed9@gmail.com",
        "name": "Mohamed Aissati",
        "firstName": "Mohamed",
        "lastName": "Aissati",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12290",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "275055436",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14231810",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68893097,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151924723",
        "ip": "2a02:a470:c49e:1:d0e2:be06:74a:64aa",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 08:54:11",
        "isSmartClick": "0",
        "email": "Ladejobi.cole4@live.com",
        "name": "Reuben  Cole",
        "firstName": "Reuben ",
        "lastName": "Cole",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12289",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "275049385",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14230937",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68890275,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: objectative.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151916439",
        "ip": "31.201.219.177",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 08:22:36",
        "isSmartClick": "0",
        "email": "a.st.laaroussi@gmail.com",
        "name": "achmed Stitou laaroussi",
        "firstName": "achmed",
        "lastName": "Stitou laaroussi",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12288",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275043126",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-02 10:07:42",
        "depositID": "20407882",
        "conversionID": "1215380",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14230076",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68889755,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "151915090",
        "ip": "2a02:a020:5c5:3b06:5839:cf05:104f:f96",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 08:17:18",
        "isSmartClick": "0",
        "email": "cliff.m1996@gmail.com",
        "name": "cliff merschout",
        "firstName": "cliff",
        "lastName": "merschout",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12287",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275042068",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14229898",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68888401,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Anderlecht",
        "custom": null,
        "custom1": null,
        "custom2": "ringinged.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "151911745",
        "ip": "2a02:a020:5c2:fd4d:d1e9:1ab5:fe36:dcbb",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 08:07:59",
        "isSmartClick": "0",
        "email": "Bartvandevelde1@live.be",
        "name": "Bart Van de velde",
        "firstName": "Bart",
        "lastName": "Van de velde",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12286",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275039629",
        "countryID": 23,
        "cityID": 924,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14229605",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68887350,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zestva.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151909178",
        "ip": "89.205.129.45",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 08:00:02",
        "isSmartClick": "0",
        "email": "aartvermeij@hotmail.com",
        "name": "Aart Daniel Vermeij",
        "firstName": "Aart Daniel",
        "lastName": "Vermeij",
        "brokerID": "23883",
        "brokerName": "SmartAds CPA",
        "customerID": "519202",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "Recent_NA",
        "saleStatusMapped": null,
        "clickID": "275037626",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14229347",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68886234,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tilburg",
        "custom": null,
        "custom1": null,
        "custom2": "hedenbergite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151905780",
        "ip": "83.80.179.85",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 07:46:16",
        "isSmartClick": "0",
        "email": "test00000t@test.com",
        "name": "test test",
        "firstName": "test",
        "lastName": "test",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12285",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "275035162",
        "countryID": 156,
        "cityID": 235,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14229074",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68885355,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: objectative.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151903314",
        "ip": "109.36.133.119",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 07:35:15",
        "isSmartClick": "0",
        "email": "zekirotterdam1990@gmail.com",
        "name": "Murat Gezer",
        "firstName": "Murat",
        "lastName": "Gezer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12284",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275033058",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14228863",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68884824,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Australia",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "ctenolium.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151901575",
        "ip": "2407:e400:a029:4601:dfe:2bed:821e:4600",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 07:27:30",
        "isSmartClick": "0",
        "email": "pagek0663@gmail.com",
        "name": "Kerry  Page",
        "firstName": "Kerry ",
        "lastName": "Page",
        "brokerID": "21205",
        "brokerName": "Investment Peak",
        "customerID": "4-9865",
        "leadType": "0",
        "countryCode": "AU",
        "saleStatus": "Not Interested",
        "saleStatusMapped": "No interest",
        "clickID": "275031575",
        "countryID": 15,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Sonia Kruger is being sued by the Bank of Australia for comments she made on live broadcast",
        "leadRequestOfferName": "Immediate Edge (AU) Sonia Kruger_v1",
        "leadRequestOfferWebsite": "Immediate Edge (AU) Sonia Kruger_v1",
        "brokerAutologinClick": "14228732",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68884786,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Eindhoven",
        "custom": null,
        "custom1": null,
        "custom2": "tranquilretirementhome.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151901426",
        "ip": "2a02:a450:caf9:0:34a7:c1d4:5782:de18",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 07:26:53",
        "isSmartClick": "0",
        "email": "Info@masterplast.nl",
        "name": "Willem Bool",
        "firstName": "Willem",
        "lastName": "Bool",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12283",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "275031440",
        "countryID": 156,
        "cityID": 1279,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-02 09:17:00",
        "depositID": "20407785",
        "conversionID": "1215275",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Carice van Houten regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Carice van Houten accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14228725",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68884119,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Australia",
        "cityName": "Perth",
        "custom": null,
        "custom1": null,
        "custom2": "ctenolium.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151898505",
        "ip": "130.95.40.101",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 07:15:43",
        "isSmartClick": "0",
        "email": "test3292@gmail.com",
        "name": "test3292 test329221",
        "firstName": "test3292",
        "lastName": "test329221",
        "brokerID": "21205",
        "brokerName": "Investment Peak",
        "customerID": "4-9861",
        "leadType": "1",
        "countryCode": "AU",
        "saleStatus": "Test lead",
        "saleStatusMapped": "Test",
        "clickID": "275029128",
        "countryID": 15,
        "cityID": 2,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Sonia Kruger is being sued by the Bank of Australia for comments she made on live broadcast",
        "leadRequestOfferName": "Immediate Edge (AU) Sonia Kruger_v1",
        "leadRequestOfferWebsite": "Immediate Edge (AU) Sonia Kruger_v1",
        "brokerAutologinClick": "14228574",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68882790,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Roosendaal",
        "custom": null,
        "custom1": null,
        "custom2": "vamnia.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151893632",
        "ip": "2a02:a465:1925:1:90bf:73d1:f77a:d31e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 06:57:25",
        "isSmartClick": "0",
        "email": "hanskooiman57@gmail.com",
        "name": "Johannes Kooiman",
        "firstName": "Johannes",
        "lastName": "Kooiman",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12282",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275026243",
        "countryID": 156,
        "cityID": 3008,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14228376",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68882021,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amstelveen",
        "custom": null,
        "custom1": null,
        "custom2": "valzria.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151890842",
        "ip": "31.201.113.247",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 06:44:41",
        "isSmartClick": "0",
        "email": "stamsonja@outlook.com",
        "name": "Sonja Stam",
        "firstName": "Sonja",
        "lastName": "Stam",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12281",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275024721",
        "countryID": 156,
        "cityID": 6142,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14228272",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68881522,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Emmeloord",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151887602",
        "ip": "2a02:a44d:4376:1:743b:caa2:e540:995a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 06:36:00",
        "isSmartClick": "0",
        "email": "gerda.vandelageweg74@gmail.com",
        "name": "Gerda Fransen",
        "firstName": "Gerda",
        "lastName": "Fransen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12280",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275023701",
        "countryID": 156,
        "cityID": 6708,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14228214",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68880118,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Duiven",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151881204",
        "ip": "2a02:a212:24c1:3a00:dc7a:a7a7:9a57:bd42",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 06:15:12",
        "isSmartClick": "0",
        "email": "peter.de.reus@gmail.com",
        "name": "Peter De Reus",
        "firstName": "Peter",
        "lastName": "De Reus",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12279",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "Reshuffle",
        "saleStatusMapped": "Reshuffle",
        "clickID": "275020876",
        "countryID": 156,
        "cityID": 2678,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14228101",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68877434,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151868308",
        "ip": "188.65.190.39",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 05:28:16",
        "isSmartClick": "0",
        "email": "adsl447280@gmail.com",
        "name": "Odeke Götte",
        "firstName": "Odeke",
        "lastName": "Götte",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12278",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275015317",
        "countryID": 156,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14227989",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68873915,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151850578",
        "ip": "77.172.118.56",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 04:34:40",
        "isSmartClick": "0",
        "email": "k.willmes@hotmail.com",
        "name": "Karin Willmes",
        "firstName": "Karin",
        "lastName": "Willmes",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12277",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "275008805",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14227861",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68865144,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Antwerp",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: movementdeperseds.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "151811720",
        "ip": "2a02:1808:2:3756::1",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 01:38:55",
        "isSmartClick": "0",
        "email": "Joe.tattoo666@gmail.com",
        "name": "Joeri De Backer",
        "firstName": "Joeri",
        "lastName": "De Backer",
        "brokerID": "21205",
        "brokerName": "Investment Peak",
        "customerID": "4-9832",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "Reassign",
        "saleStatusMapped": "Call again",
        "clickID": "274992040",
        "countryID": 23,
        "cityID": 18,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14227431",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68863667,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "oxalamide.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151803113",
        "ip": "189.203.63.158",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-02 00:03:42",
        "isSmartClick": "0",
        "email": "fhugovalgal@gmail.com",
        "name": "Fernando hugo  Valenzuela Galván ",
        "firstName": "Fernando hugo ",
        "lastName": "Valenzuela Galván ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "fhugovalgal@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "274987517",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68863033,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlaquepaque",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151800337",
        "ip": "177.249.172.211",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 23:15:14",
        "isSmartClick": "0",
        "email": "maricelabernaltorres@gmail.com",
        "name": "Maricela  Bernal Torres ",
        "firstName": "Maricela ",
        "lastName": "Bernal Torres ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "maricelabernaltorres@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "274985706",
        "countryID": 143,
        "cityID": 15180,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68862479,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": "tubinares.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151797688",
        "ip": "189.144.198.241",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 22:39:43",
        "isSmartClick": "0",
        "email": "martinianomarquez769@gmail.com",
        "name": "MARTINIANO MARQUEZ ORTIZ",
        "firstName": "MARTINIANO",
        "lastName": "MARQUEZ ORTIZ",
        "brokerID": "9687",
        "brokerName": "Lead Capture",
        "customerID": "martinianomarquez769@gmail.com",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": null,
        "saleStatusMapped": null,
        "clickID": "274984011",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "1",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Mexico&#39;s National Bank sues Galilea Montijo for what she said on live TV. Everyone in Mexico should know the truth!",
        "leadRequestOfferName": "Bitcoineer Galilea Montijo-MX",
        "leadRequestOfferWebsite": "Bitcoineer Galilea Montijo-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68860748,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Nezahualcoyotl",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151789941",
        "ip": "2806:2f0:a120:fd65:99a9:bf70:3cc5:d1ab",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 21:06:12",
        "isSmartClick": "0",
        "email": "almarazcg@gmail.com",
        "name": "Gabriela  Almaraz Calvillo ",
        "firstName": "Gabriela ",
        "lastName": "Almaraz Calvillo ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "1f6582ed3a44e2d87ef5e5ae200669cd",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274978061",
        "countryID": 143,
        "cityID": 7194,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14226536",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68860136,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Veracruz",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151787974",
        "ip": "189.192.86.17",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 20:45:06",
        "isSmartClick": "0",
        "email": "nazarioaleman414@gmail.com",
        "name": "Nazario Alemán Santos",
        "firstName": "Nazario",
        "lastName": "Alemán Santos",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "584814da4b9fddb1d22930afcd48f0ae",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274976297",
        "countryID": 143,
        "cityID": 785,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14226431",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68859808,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151786651",
        "ip": "200.68.141.71",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 20:31:39",
        "isSmartClick": "0",
        "email": "gomezyoselinkarolina@gmail.com",
        "name": "María esther Mirejes becerra",
        "firstName": "María esther",
        "lastName": "Mirejes becerra",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "6a6df292877742e37e17c03263fff6e1",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274975328",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68859396,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "El Salto",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151785034",
        "ip": "187.139.171.65",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 20:18:27",
        "isSmartClick": "0",
        "email": "Marcoscangrejo249@gmail.com",
        "name": "Marco antonio  Gonzalez alvarado",
        "firstName": "Marco antonio ",
        "lastName": "Gonzalez alvarado",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "84fb6801a59bf66eb99e3314fa8c0531",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274974241",
        "countryID": 143,
        "cityID": 58280,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14226329",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68858727,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zwolle",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151782718",
        "ip": "82.174.185.45",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 19:57:49",
        "isSmartClick": "0",
        "email": "arendjan.kleine@gmail.com",
        "name": "Arend Kleine",
        "firstName": "Arend",
        "lastName": "Kleine",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12276",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274971774",
        "countryID": 156,
        "cityID": 5049,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14226262",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68857859,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151780013",
        "ip": "200.68.165.45",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 19:34:27",
        "isSmartClick": "0",
        "email": "ar3335872@gmail.com",
        "name": "Aurelia rodriguez Hernández",
        "firstName": "Aurelia",
        "lastName": "rodriguez Hernández",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "3a825d14b4098208d670dd0eb27563fb",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274964173",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68856240,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151775329",
        "ip": "2806:2a0:1512:8d42:281c:5a0:fa69:8e10",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 18:49:40",
        "isSmartClick": "0",
        "email": "edgarmayacubillas4@gmail.com",
        "name": "Edgar Maya",
        "firstName": "Edgar",
        "lastName": "Maya",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "34157d72d0d7bfd4cb872fab2be35bef",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Call again",
        "saleStatusMapped": "Call again",
        "clickID": "274950294",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14225956",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68856206,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Juárez",
        "custom": null,
        "custom1": null,
        "custom2": "apathistical.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151775253",
        "ip": "2806:1016:d:5f8b:2120:5810:e17a:5f70",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 18:48:54",
        "isSmartClick": "0",
        "email": "cynthiaglez2812@gmail.com",
        "name": "Cynthia  González ",
        "firstName": "Cynthia ",
        "lastName": "González ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "ad1618f3655808290e03a5bcf17c9595",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274950109",
        "countryID": 143,
        "cityID": 1364,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68855704,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlahuac",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151773782",
        "ip": "2806:106e:1a:1ead:dbce:8aa:46fa:d200",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 18:35:36",
        "isSmartClick": "0",
        "email": "vicmansf58@gmail.com",
        "name": "VICTOR MANUEL SERRALDE FLORES",
        "firstName": "VICTOR MANUEL",
        "lastName": "SERRALDE FLORES",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "3775b53ab9fd3c9371a030de69153433",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274946418",
        "countryID": 143,
        "cityID": 12770,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14225864",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68855688,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Almere Stad",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151773734",
        "ip": "82.172.17.155",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 18:34:58",
        "isSmartClick": "0",
        "email": "gerrithooijenga1979@gmail.com",
        "name": "Gerrit Hooijenga ",
        "firstName": "Gerrit",
        "lastName": "Hooijenga ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12275",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274946291",
        "countryID": 156,
        "cityID": 1524,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14225860",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68855431,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "La Paz",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "151773072",
        "ip": "2806:101e:7:91d6:6170:309d:3e72:16a8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 18:28:10",
        "isSmartClick": "0",
        "email": "pabeljm@gmail.com",
        "name": "Juan manuel Palos beltran",
        "firstName": "Juan manuel",
        "lastName": "Palos beltran",
        "brokerID": "17920",
        "brokerName": "AvisuAds",
        "customerID": "408b0180b3cb0c52640eb5c59ffdc85d",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274944490",
        "countryID": 143,
        "cityID": 2196,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68855373,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Ostend",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: movementdeperseds.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "151772886",
        "ip": "2a02:a03f:e83f:4201:85d9:fff2:e3b9:fb01",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 18:26:08",
        "isSmartClick": "0",
        "email": "Tamaralagrou47@gmail.com",
        "name": "Tamara Lagrou",
        "firstName": "Tamara",
        "lastName": "Lagrou",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12274",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274943993",
        "countryID": 23,
        "cityID": 3925,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14225789",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68855267,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuautitlán Izcalli",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "151772612",
        "ip": "201.121.170.107",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 18:23:15",
        "isSmartClick": "0",
        "email": "Canito_234@gmail.com",
        "name": "Vallanse A la verga",
        "firstName": "Vallanse",
        "lastName": "A la verga",
        "brokerID": "17920",
        "brokerName": "AvisuAds",
        "customerID": "eb2925b1cea56639c01927107bbc8c98",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274943204",
        "countryID": 143,
        "cityID": 3577,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68854753,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Jiutepec",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151771015",
        "ip": "2806:104e:23:ae4f:2c08:65ff:ab67:7185",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 18:09:19",
        "isSmartClick": "0",
        "email": "cazarezantonio835@gmail.com",
        "name": "Antonio  Cazarez ",
        "firstName": "Antonio ",
        "lastName": "Cazarez ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "b5d857a78b1b537b00b955cc1fbaa532",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274939784",
        "countryID": 143,
        "cityID": 16158,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14225664",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68853794,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Cuautitlán Izcalli",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151768150",
        "ip": "201.121.192.9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 17:43:43",
        "isSmartClick": "0",
        "email": "crr5478@yahoo.com.mx",
        "name": "Carlos Romero",
        "firstName": "Carlos",
        "lastName": "Romero",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "23d6b123289fbb046b9702d5a0f69946",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274933051",
        "countryID": 143,
        "cityID": 3577,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68853435,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tlacolula de Matamoros",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151766819",
        "ip": "38.194.237.22",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 17:34:11",
        "isSmartClick": "0",
        "email": "mh2109631@gmail.com",
        "name": "María José Hernández Botello",
        "firstName": "María José",
        "lastName": "Hernández Botello",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "4b8ca8e83d844d1b27c9e28a9a3d08c8",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274930477",
        "countryID": 143,
        "cityID": 65489,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14225408",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68852381,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151763721",
        "ip": "201.166.191.38",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 17:08:51",
        "isSmartClick": "0",
        "email": "ernestomoralesrabeelo@gmail.com",
        "name": "Ernesto  Morales ",
        "firstName": "Ernesto ",
        "lastName": "Morales ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "0cc8d56db65f431aaca1b9a2182e45f2",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274924064",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14225233",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68851578,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Schiedam",
        "custom": null,
        "custom1": null,
        "custom2": "lovingseniorcomforthomes.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151761332",
        "ip": "188.88.74.35",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 16:50:21",
        "isSmartClick": "0",
        "email": "teststt@te.st",
        "name": "testt testtt",
        "firstName": "testt",
        "lastName": "testtt",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12273",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274919166",
        "countryID": 156,
        "cityID": 3781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Het onderzoek rond P Diddy gaat door, maar wat heeft de officiële diensten van Amerika ertoe aangezet om de artiest te gaan onderzoeken? Waarom is dit nieuws zo duister en verwarrend? De artiest zegt zelf dat de pesterijen en bedreigingen tegen hem door de autoriteiten begonnen na een interview in TheEllenShow, waar P Diddy per ongeluk het platform noemde waarop hij en zijn vrienden hun fortuin hebben gemaakt en dat iedereen de kans zou kunnen krijgen om dit “programma” te gebruiken.",
        "leadRequestOfferName": "AvaPro P_Diddy",
        "leadRequestOfferWebsite": "AvaPro P_Diddy",
        "brokerAutologinClick": "14225056",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68851281,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Poza Rica",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151760205",
        "ip": "2806:10a6:10:2cf1:105e:b5ad:d039:782a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 16:43:27",
        "isSmartClick": "0",
        "email": "fciascalvillo@hotmail.com",
        "name": "MIGUEL ANGEL FRANCISCO  CALVILLO HERNANDEZ",
        "firstName": "MIGUEL ANGEL FRANCISCO ",
        "lastName": "CALVILLO HERNANDEZ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "886f2355ef458832346ff123b216182a",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274917095",
        "countryID": 143,
        "cityID": 140910,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14225000",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68850508,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Gustavo Adolfo Madero",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151757594",
        "ip": "187.190.146.154",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 16:26:27",
        "isSmartClick": "0",
        "email": "gustavoadolforochp@outlook.com",
        "name": "Gustavo Rocha peñaloza Rocha Peñaloza",
        "firstName": "Gustavo Rocha peñaloza",
        "lastName": "Rocha Peñaloza",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "de8a945b892249f361607497c7a5cf67",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274912216",
        "countryID": 143,
        "cityID": 7786,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14224871",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68850465,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Antwerp",
        "custom": null,
        "custom1": null,
        "custom2": "cerambycid.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "151757477",
        "ip": "2a02:1810:2923:4300:e784:c957:4d2a:b8e3",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 16:26:01",
        "isSmartClick": "0",
        "email": "Joe.tattoo666@gmail.com",
        "name": "Joeri De Backet",
        "firstName": "Joeri",
        "lastName": "De Backet",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12272",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "274912110",
        "countryID": 23,
        "cityID": 18,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-01 20:21:22",
        "depositID": "20407028",
        "conversionID": "1215001",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14224866",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68850444,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Coatzacoalcos",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "151757365",
        "ip": "187.171.65.140",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 16:25:13",
        "isSmartClick": "0",
        "email": "elmo6917@gmail.com",
        "name": "Juan Carlos Bravo sanchez",
        "firstName": "Juan Carlos",
        "lastName": "Bravo sanchez",
        "brokerID": "17920",
        "brokerName": "AvisuAds",
        "customerID": "5e7c2b234461362e880abf554efe856f",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274911864",
        "countryID": 143,
        "cityID": 12557,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68850438,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "151757349",
        "ip": "2806:2f0:93a0:a2d7:71ee:a8fb:1867:9670",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 16:25:11",
        "isSmartClick": "0",
        "email": "d.o.rehaobregon@hotmail.com",
        "name": "Daniel Obregón ",
        "firstName": "Daniel",
        "lastName": "Obregón ",
        "brokerID": "17920",
        "brokerName": "AvisuAds",
        "customerID": "0953e2c104c0aa15c0373bf88964b0fb",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not potential",
        "saleStatusMapped": null,
        "clickID": "274911863",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68850379,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Xalapa",
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "151757088",
        "ip": "201.108.22.139",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 16:24:10",
        "isSmartClick": "0",
        "email": "fiscalterceralinde@gmail.comail.com",
        "name": "Iskander M M ",
        "firstName": "Iskander",
        "lastName": "M M ",
        "brokerID": "17920",
        "brokerName": "AvisuAds",
        "customerID": "fe8463e15d1c9a8bf271ef68209e9fa4",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Wrong info",
        "saleStatusMapped": "Wrong Info",
        "clickID": "274911555",
        "countryID": 143,
        "cityID": 3552,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "Spanish",
        "localeCode": "es",
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68849992,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Córdoba",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151755619",
        "ip": "2806:10a6:24:7d65:ac2e:b9dd:769b:1a06",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 16:16:38",
        "isSmartClick": "0",
        "email": "Saupzalez@outlook.com",
        "name": "Saul Perez",
        "firstName": "Saul",
        "lastName": "Perez",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "e8184296012888131056b62fda4772b4",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274909300",
        "countryID": 143,
        "cityID": 10191,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68848563,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nieuwegein",
        "custom": null,
        "custom1": null,
        "custom2": "pneumonolithiasis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151751295",
        "ip": "2001:1c02:3308:6c00:c1c8:c06f:ff32:f09f",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:51:18",
        "isSmartClick": "0",
        "email": "robert.deen1@gmail.com",
        "name": "Robert  Deen",
        "firstName": "Robert ",
        "lastName": "Deen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12271",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274901616",
        "countryID": 156,
        "cityID": 1894,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14224420",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68848466,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ciudad Juárez",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151751043",
        "ip": "2806:1016:c:91f3:c199:535c:5fba:8f58",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:50:26",
        "isSmartClick": "0",
        "email": "cuau040275@gmail.com",
        "name": "Cuauhtémoc  Gutiérrez ",
        "firstName": "Cuauhtémoc ",
        "lastName": "Gutiérrez ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "2d3f590f752d40b024f386caf274f416",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274901204",
        "countryID": 143,
        "cityID": 1364,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14224394",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68848389,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "General Escobedo",
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151750789",
        "ip": "2806:109f:13:3de1:4d1b:6f44:7920:d2a9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:49:14",
        "isSmartClick": "0",
        "email": "fllorente.esi@gmail.com",
        "name": "Francisco llorente",
        "firstName": "Francisco",
        "lastName": "llorente",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "52d6c555dba823ce991e2291204dd9e2",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274900816",
        "countryID": 143,
        "cityID": 21933,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14224357",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68848382,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Naaldwijk",
        "custom": null,
        "custom1": null,
        "custom2": "warmgoldenyearsretreathomes.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151750756",
        "ip": "185.177.126.134",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:49:08",
        "isSmartClick": "0",
        "email": "testholy@te.st",
        "name": "testholy testholy",
        "firstName": "testholy",
        "lastName": "testholy",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12270",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274900858",
        "countryID": 156,
        "cityID": 1564,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Het onderzoek rond P Diddy gaat door, maar wat heeft de officiële diensten van Amerika ertoe aangezet om de artiest te gaan onderzoeken? Waarom is dit nieuws zo duister en verwarrend? De artiest zegt zelf dat de pesterijen en bedreigingen tegen hem door de autoriteiten begonnen na een interview in TheEllenShow, waar P Diddy per ongeluk het platform noemde waarop hij en zijn vrienden hun fortuin hebben gemaakt en dat iedereen de kans zou kunnen krijgen om dit “programma” te gebruiken.",
        "leadRequestOfferName": "AvaPro P_Diddy",
        "leadRequestOfferWebsite": "AvaPro P_Diddy",
        "brokerAutologinClick": "14224356",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68847275,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Schiedam",
        "custom": null,
        "custom1": null,
        "custom2": "homeochromatism.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151746532",
        "ip": "188.90.4.148",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:38:33",
        "isSmartClick": "0",
        "email": "ingridvanrossen@gmail.com",
        "name": "Ingrid  van Rossen",
        "firstName": "Ingrid ",
        "lastName": "van Rossen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12269",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274896922",
        "countryID": 156,
        "cityID": 3781,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14224073",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68847116,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Puerto Vallarta",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151745871",
        "ip": "189.177.6.185",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:37:00",
        "isSmartClick": "0",
        "email": "waguirre@outlook.es",
        "name": "Wenceslao Aguirre",
        "firstName": "Wenceslao",
        "lastName": "Aguirre",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "269af75449dd6df437a5be278520f0ad",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274896258",
        "countryID": 143,
        "cityID": 825,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14224044",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68845584,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Hasselt",
        "custom": null,
        "custom1": null,
        "custom2": "antifungin.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151739658",
        "ip": "84.192.114.57",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:21:13",
        "isSmartClick": "0",
        "email": "frank.tritsmans@telenet.be",
        "name": "Frank Tritsmans",
        "firstName": "Frank",
        "lastName": "Tritsmans",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12268",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274890476",
        "countryID": 23,
        "cityID": 840,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14223746",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68845385,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": "lymphoblastosis.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151738956",
        "ip": "201.141.117.106",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:19:30",
        "isSmartClick": "0",
        "email": "pinedavillarruel@gmail.com",
        "name": "Alejandro David Pineda Villarruel",
        "firstName": "Alejandro David",
        "lastName": "Pineda Villarruel",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "18ca98ee2711e1a4e0edf4efc8364792",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274889682",
        "countryID": 143,
        "cityID": null,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Exclusive discovery: Claudia Sheinbaum , investing in oil with Oil Profit. Shocking details of success!.",
        "leadRequestOfferName": "Claudia Sheinbaum МХ Oil Profit",
        "leadRequestOfferWebsite": "Claudia Sheinbaum МХ Oil Profit",
        "brokerAutologinClick": "14223691",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68844521,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "mormaordom.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151735588",
        "ip": "89.205.142.123",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:12:08",
        "isSmartClick": "0",
        "email": "aartvermeij@hotmail.com",
        "name": "Aart Daniel Vermeij",
        "firstName": "Aart Daniel",
        "lastName": "Vermeij",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12267",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274886727",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14223531",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68844337,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Xochimilco",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151734791",
        "ip": "189.245.10.220",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:10:48",
        "isSmartClick": "0",
        "email": "carchaval190209@gmail.com",
        "name": "Carlos Chávez Valdés  Chávez ",
        "firstName": "Carlos Chávez Valdés ",
        "lastName": "Chávez ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "11a791193fd530acbe13b78496d66bd0",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "Not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274885996",
        "countryID": 143,
        "cityID": 5355,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68844257,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zuidhorn",
        "custom": null,
        "custom1": null,
        "custom2": "hydrocholecystis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151734582",
        "ip": "2001:1c01:46c2:aa00:1c8c:fdf2:c1d8:b34e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:10:01",
        "isSmartClick": "0",
        "email": "Kamps3@hotmail.com",
        "name": "Jan Kamps",
        "firstName": "Jan",
        "lastName": "Kamps",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12266",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274885761",
        "countryID": 156,
        "cityID": 2021,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14223496",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68844207,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Ecatepec",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151734267",
        "ip": "189.189.78.144",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:09:33",
        "isSmartClick": "0",
        "email": "elsarugamamartinez9@gmail.com",
        "name": "Elsa Rugama",
        "firstName": "Elsa",
        "lastName": "Rugama",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "3ddbe3c805896c4193bc78f19658d362",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274885514",
        "countryID": 143,
        "cityID": 17641,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14223488",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68844145,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Tuxtla Gutiérrez",
        "custom": null,
        "custom1": null,
        "custom2": "ismailite.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151733975",
        "ip": "2806:2f0:82a0:feda:8846:57e8:9bf6:f4b7",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:09:11",
        "isSmartClick": "0",
        "email": "ceciliahernandezduran204@gmail.com",
        "name": "Cecilia Hernández Duran ",
        "firstName": "Cecilia",
        "lastName": "Hernández Duran ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "4fe1ae168cc78b95b15c1c19412814da",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274885349",
        "countryID": 143,
        "cityID": 1266,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14223472",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68844094,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Mexico",
        "cityName": "Mexico City",
        "custom": null,
        "custom1": null,
        "custom2": "erepsin.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151733784",
        "ip": "138.84.45.90",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 15:08:35",
        "isSmartClick": "0",
        "email": "linoaguilar9o@ouhook.com",
        "name": "Lino  Aguilar ",
        "firstName": "Lino ",
        "lastName": "Aguilar ",
        "brokerID": "19482",
        "brokerName": "AvisuAds CRG",
        "customerID": "09f0acf6a31e22ab9a44eb42a80578cf",
        "leadType": "0",
        "countryCode": "MX",
        "saleStatus": "No answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274885143",
        "countryID": 143,
        "cityID": 622,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Thanks to Pemex Oil Profit™, you now have the opportunity to earn from",
        "leadRequestOfferName": "Oil Profit Quiz-MX",
        "leadRequestOfferWebsite": "Oil Profit Quiz-MX",
        "brokerAutologinClick": "14223460",
        "brokerAutologinClickJavascriptEnabled": 1,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68842974,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Barneveld",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151728933",
        "ip": "86.89.214.150",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 14:59:08",
        "isSmartClick": "0",
        "email": "test123sdhgwhriuqeowqnl@gmail.com",
        "name": "test123sdhgwhriuqeowqnl test123sdhgwhriuqeowqnl",
        "firstName": "test123sdhgwhriuqeowqnl",
        "lastName": "test123sdhgwhriuqeowqnl",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12265",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274881181",
        "countryID": 156,
        "cityID": 7233,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14223271",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68841418,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151724287",
        "ip": "193.172.190.86",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 14:47:36",
        "isSmartClick": "0",
        "email": "reggie286@gmail.com",
        "name": "Reggie Mitchell",
        "firstName": "Reggie",
        "lastName": "Mitchell",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12264",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274877899",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14223048",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68840203,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "The Hague",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151720421",
        "ip": "2a02:a45e:3559:1:4528:91d5:11f1:b856",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 14:37:58",
        "isSmartClick": "0",
        "email": "nicojungschlager@live.nl",
        "name": "Nico Jungschlager ",
        "firstName": "Nico",
        "lastName": "Jungschlager ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12263",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274875305",
        "countryID": 156,
        "cityID": 921,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68840056,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Helmond",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: objectative.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151720032",
        "ip": "80.57.140.139",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 14:36:29",
        "isSmartClick": "0",
        "email": "gregoire729@gmail.com",
        "name": "Rob Gregoire",
        "firstName": "Rob",
        "lastName": "Gregoire",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12262",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274874998",
        "countryID": 156,
        "cityID": 1558,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14222690",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68836989,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Eeklo",
        "custom": null,
        "custom1": null,
        "custom2": "antifungin.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151707169",
        "ip": "178.117.153.129",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 14:07:52",
        "isSmartClick": "0",
        "email": "tests678908765@gmail.com",
        "name": "tests678908765 tests678908765",
        "firstName": "tests678908765",
        "lastName": "tests678908765",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12261",
        "leadType": "1",
        "countryCode": "BE",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274867786",
        "countryID": 23,
        "cityID": 12877,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14221872",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68836743,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tiel",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151706161",
        "ip": "84.85.3.164",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 14:05:57",
        "isSmartClick": "0",
        "email": "cmanuhutu@live.nl",
        "name": "Costavins Manuhutu",
        "firstName": "Costavins",
        "lastName": "Manuhutu",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12260",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274867125",
        "countryID": 156,
        "cityID": 2288,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14221832",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68836413,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Middelburg",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151704892",
        "ip": "217.63.65.169",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 14:03:01",
        "isSmartClick": "0",
        "email": "willyblokland@gmail.com",
        "name": "willy blokland",
        "firstName": "willy",
        "lastName": "blokland",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12259",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274866428",
        "countryID": 156,
        "cityID": 14448,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14221762",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68836251,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Lunteren",
        "custom": null,
        "custom1": null,
        "custom2": "pneumonolithiasis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151704342",
        "ip": "212.45.48.142",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 14:01:38",
        "isSmartClick": "0",
        "email": "janvanharn66@gmail.com",
        "name": "Jan Van Harn",
        "firstName": "Jan",
        "lastName": "Van Harn",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12258",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274866086",
        "countryID": 156,
        "cityID": 2550,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14221736",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68835260,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Nieuwe Pekela",
        "custom": null,
        "custom1": null,
        "custom2": "mormaordom.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151700214",
        "ip": "84.24.88.221",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 13:54:01",
        "isSmartClick": "0",
        "email": "horus130@gmail.com",
        "name": "YA Van den Hemel",
        "firstName": "YA",
        "lastName": "Van den Hemel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12257",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274863691",
        "countryID": 156,
        "cityID": 29829,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14221586",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68833993,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Soest",
        "custom": null,
        "custom1": null,
        "custom2": "vamnia.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151694593",
        "ip": "84.241.205.144",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 13:42:03",
        "isSmartClick": "0",
        "email": "harrieverkuijlen01@outlook.com",
        "name": "Harrie Verkuijlen",
        "firstName": "Harrie",
        "lastName": "Verkuijlen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12256",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274860761",
        "countryID": 156,
        "cityID": 6712,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14221458",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68833682,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Eindhoven",
        "custom": null,
        "custom1": null,
        "custom2": "hereditarianism.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151693385",
        "ip": "77.167.119.222",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 13:39:17",
        "isSmartClick": "0",
        "email": "crime43eyes@msn.com",
        "name": "Theo Korsten",
        "firstName": "Theo",
        "lastName": "Korsten",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12255",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274860090",
        "countryID": 156,
        "cityID": 1279,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14221403",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68828458,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Westergeest",
        "custom": null,
        "custom1": null,
        "custom2": "zomrao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151672449",
        "ip": "212.52.236.63",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:55:06",
        "isSmartClick": "0",
        "email": "jdeboer1948@hotmail.com",
        "name": "Jan De Boer",
        "firstName": "Jan",
        "lastName": "De Boer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12254",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274849350",
        "countryID": 156,
        "cityID": 63927,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14220681",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68828114,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tilburg",
        "custom": null,
        "custom1": null,
        "custom2": "mormaordom.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151671425",
        "ip": "2a02:a463:e98d:1:c146:26f0:3014:5cf7",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:51:33",
        "isSmartClick": "0",
        "email": "Jpm.maas1955@gmail.com",
        "name": "Jan Maas",
        "firstName": "Jan",
        "lastName": "Maas",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12253",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274848757",
        "countryID": 156,
        "cityID": 235,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14220633",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68826207,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Spijkenisse",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151665223",
        "ip": "2a02:a450:b1ed:1:5fd7:9554:d6dd:cbd9",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:34:14",
        "isSmartClick": "0",
        "email": "bensos.58@gmail.com",
        "name": "Olaf Bens",
        "firstName": "Olaf",
        "lastName": "Bens",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12252",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274845204",
        "countryID": 156,
        "cityID": 1534,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14220418",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68825978,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rijswijk",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151664350",
        "ip": "2001:1c00:251e:ed00:a4a9:b6dd:c01d:41ff",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:32:10",
        "isSmartClick": "0",
        "email": "maximus07_94@hotmail.com",
        "name": "Peter Pieplenbosch",
        "firstName": "Peter",
        "lastName": "Pieplenbosch",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12251",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274844771",
        "countryID": 156,
        "cityID": 1529,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14220387",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68824749,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amstelveen",
        "custom": null,
        "custom1": null,
        "custom2": "homeochromatism.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151660550",
        "ip": "87.209.244.229",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:21:17",
        "isSmartClick": "0",
        "email": "lerenloslaten@gmail.com",
        "name": "Jan Marc van Keulen",
        "firstName": "Jan Marc",
        "lastName": "van Keulen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12250",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274842434",
        "countryID": 156,
        "cityID": 6142,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14220258",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68824134,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tilburg",
        "custom": null,
        "custom1": null,
        "custom2": "hereditarianism.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151658096",
        "ip": "77.174.76.208",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:15:20",
        "isSmartClick": "0",
        "email": "megafish56@gmail.com",
        "name": "Frank Melger",
        "firstName": "Frank",
        "lastName": "Melger",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12249",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274841177",
        "countryID": 156,
        "cityID": 235,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14220189",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68823307,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "intermercurial.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151654330",
        "ip": "169.150.218.88",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:07:40",
        "isSmartClick": "0",
        "email": "test2124021@gmail.com",
        "name": "test2124021 test21240211",
        "firstName": "test2124021",
        "lastName": "test21240211",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12248",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274839474",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14220077",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68823073,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "polymorphean.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151653408",
        "ip": "209.198.140.186",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:05:50",
        "isSmartClick": "0",
        "email": "test4567891@gmail.com",
        "name": "test456789 test4567891",
        "firstName": "test456789",
        "lastName": "test4567891",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12247",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274839025",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14220058",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68822935,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Gorinchem",
        "custom": null,
        "custom1": null,
        "custom2": "coracobrachial.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151652729",
        "ip": "2001:1c03:100f:b300:5cfe:aadd:8fd0:3371",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:04:26",
        "isSmartClick": "0",
        "email": "krantenjan@hotmail.com",
        "name": "Jan Versteeg",
        "firstName": "Jan",
        "lastName": "Versteeg",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12246",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274838762",
        "countryID": 156,
        "cityID": 4435,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Nederlandse bank klaagt Nicolette van Dam aan voor haar opmerkingen in live tv-programma Nicolette van Dam had spijt dat ze de waarheid had onthuld. Maar het was te laat. Het schandaal barstte los tijdens een live-uitzending toen Nicolette per ongeluk een familiegeheim onthulde in het programma.",
        "leadRequestOfferName": "AvaPro Nicolette&BasSmit",
        "leadRequestOfferWebsite": "AvaPro Nicolette&BasSmit",
        "brokerAutologinClick": "14220030",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68822788,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Lewedorp",
        "custom": null,
        "custom1": null,
        "custom2": "wrathiness.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151652034",
        "ip": "62.238.56.118",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:03:12",
        "isSmartClick": "0",
        "email": "test210231231@gmail.com",
        "name": "test210231231 test210231231",
        "firstName": "test210231231",
        "lastName": "test210231231",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12245",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274838474",
        "countryID": 156,
        "cityID": 34375,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14220014",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68822592,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Lewedorp",
        "custom": null,
        "custom1": null,
        "custom2": "leucocrate.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151651084",
        "ip": "62.238.56.118",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 12:01:28",
        "isSmartClick": "0",
        "email": "test39294523@gmail.com",
        "name": "test39294523 test39294523",
        "firstName": "test39294523",
        "lastName": "test39294523",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12244",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274838064",
        "countryID": 156,
        "cityID": 34375,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14219991",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68822397,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "sejugous.com",
        "custom3": null,
        "custom4": "Kir",
        "custom5": null,
        "leadRequestID": "151650221",
        "ip": "185.107.56.136",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:59:51",
        "isSmartClick": "0",
        "email": "test202323@gmail.com",
        "name": "test202323 test2023231",
        "firstName": "test202323",
        "lastName": "test2023231",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12243",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274837666",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "De Nederlandsche Bank klaagt Robert ten Brink aan voor zijn goed advies, waarin hij onthult hoe elke Nederlander echt rijk kan worden",
        "leadRequestOfferName": "Gas 5.5 Folex",
        "leadRequestOfferWebsite": "Gas 5.5 Folex",
        "brokerAutologinClick": "14219962",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68821604,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Venlo",
        "custom": null,
        "custom1": null,
        "custom2": "ranizo.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151645656",
        "ip": "2001:1c05:121:a300:b0f5:6dd8:6fbd:8fcd",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:52:34",
        "isSmartClick": "0",
        "email": "boots.hermien@gmail.com",
        "name": "Hermien Boots",
        "firstName": "Hermien",
        "lastName": "Boots",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12242",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274835888",
        "countryID": 156,
        "cityID": 629,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219865",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68821204,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Sneek",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: safeseniorretreats.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151643617",
        "ip": "2a02:a45b:c634:1:8c36:47ec:6b2e:d61c",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:48:59",
        "isSmartClick": "0",
        "email": "Jenlwonink@outlook.com",
        "name": "Lilian  Wonink-Koops",
        "firstName": "Lilian ",
        "lastName": "Wonink-Koops",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12241",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "274835076",
        "countryID": 156,
        "cityID": 2361,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-01 13:10:40",
        "depositID": "20406532",
        "conversionID": "1214746",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219820",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68821126,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Gouda",
        "custom": null,
        "custom1": null,
        "custom2": "zomria.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151643363",
        "ip": "109.232.41.219",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:48:12",
        "isSmartClick": "0",
        "email": "Nijhuiszuid@gmail.com",
        "name": "Prof. dr. J. G. Nijhuis",
        "firstName": "Prof. dr. J. G.",
        "lastName": "Nijhuis",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12240",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274834931",
        "countryID": 156,
        "cityID": 2663,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219800",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68820557,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Gennep",
        "custom": null,
        "custom1": null,
        "custom2": "pneumonolithiasis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151641090",
        "ip": "94.209.50.221",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:43:38",
        "isSmartClick": "0",
        "email": "Mvweerelt@ziggo.nl",
        "name": "Marcel Van weerelt",
        "firstName": "Marcel",
        "lastName": "Van weerelt",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12239",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274833854",
        "countryID": 156,
        "cityID": 30478,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219746",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68819883,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "homeochromatism.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151638279",
        "ip": "109.38.236.112",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:38:13",
        "isSmartClick": "0",
        "email": "Freek.1958@outlook.com",
        "name": "Freek  AB",
        "firstName": "Freek ",
        "lastName": "AB",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12238",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274832578",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219674",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68819533,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Utrecht",
        "custom": null,
        "custom1": null,
        "custom2": "pneumonolithiasis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151637022",
        "ip": "2a02:a420:4b0:4953:c18f:61b1:dbe9:ebf4",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:34:52",
        "isSmartClick": "0",
        "email": "Info@dus-enkhuizen.nl",
        "name": "Roe Denuil ",
        "firstName": "Roe",
        "lastName": "Denuil ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12237",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274831886",
        "countryID": 156,
        "cityID": 1527,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219632",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68819422,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Capelle aan den IJssel",
        "custom": null,
        "custom1": null,
        "custom2": "homeochromatism.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151636562",
        "ip": "2001:1c00:a2b:8c00:4d68:b930:84b3:59ec",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:33:54",
        "isSmartClick": "0",
        "email": "nanduca11@live.nl",
        "name": "Leandro Pereira Baptista",
        "firstName": "Leandro",
        "lastName": "Pereira Baptista",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12236",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274831682",
        "countryID": 156,
        "cityID": 8,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219614",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68818832,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Mol",
        "custom": null,
        "custom1": null,
        "custom2": "perfumeress.com",
        "custom3": "Dutch",
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151634555",
        "ip": "2a02:1810:151c:8f00:e8e6:9b81:a672:1160",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:28:49",
        "isSmartClick": "0",
        "email": "Mohamedlakrimi@hotmail.com",
        "name": "Mohamed Lakrimi",
        "firstName": "Mohamed",
        "lastName": "Lakrimi",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12235",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274830614",
        "countryID": 23,
        "cityID": 3228,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Major scandal: Immediate Edge refuses to comment on its employee Gert Verhulstt",
        "leadRequestOfferName": "Immediate Tine Embrechts",
        "leadRequestOfferWebsite": "Immediate Tine Embrechts",
        "brokerAutologinClick": "14219536",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68818662,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hellevoetsluis",
        "custom": null,
        "custom1": null,
        "custom2": "lemorao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151633928",
        "ip": "2a02:a45d:a268:0:b52d:44b3:a9be:3cfc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:27:10",
        "isSmartClick": "0",
        "email": "wpvermaat@gmail.com",
        "name": "Willem  Vermaat",
        "firstName": "Willem ",
        "lastName": "Vermaat",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12234",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "274830284",
        "countryID": 156,
        "cityID": 6526,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-01 13:11:01",
        "depositID": "20406464",
        "conversionID": "1214745",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219515",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68817563,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Naarden",
        "custom": null,
        "custom1": null,
        "custom2": "homeochromatism.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151629962",
        "ip": "2001:1c02:103:ee00:5d:c7e4:e5f8:2dce",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 11:19:29",
        "isSmartClick": "0",
        "email": "Noe@online.np",
        "name": "Gerard Noe",
        "firstName": "Gerard",
        "lastName": "Noe",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12233",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274828325",
        "countryID": 156,
        "cityID": 6810,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219410",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68814279,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zoetermeer",
        "custom": null,
        "custom1": null,
        "custom2": "homeochromatism.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151619657",
        "ip": "2001:1c00:1f1a:c100:bc09:4c32:69da:c62e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 10:52:54",
        "isSmartClick": "0",
        "email": "Ldflwr64@gmail.com",
        "name": "Ludo FLOWER",
        "firstName": "Ludo",
        "lastName": "FLOWER",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12232",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274822293",
        "countryID": 156,
        "cityID": 1565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14219032",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68813823,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "vesnioa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151618300",
        "ip": "89.205.131.52",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 10:49:03",
        "isSmartClick": "0",
        "email": "Erwin.samson@gmail.com",
        "name": "Erwin Samson",
        "firstName": "Erwin",
        "lastName": "Samson",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12231",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274821448",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14218964",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68813812,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "ranizo.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151618270",
        "ip": "85.149.69.166",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 10:48:58",
        "isSmartClick": "0",
        "email": "reus7@live.nl",
        "name": "Jan Reus",
        "firstName": "Jan",
        "lastName": "Reus",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12230",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "274821430",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-01 12:09:13",
        "depositID": "20406388",
        "conversionID": "1214691",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14218958",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68813529,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hoogerheide",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "151617471",
        "ip": "2001:1c03:470d:3f00:5d90:ffd8:9b45:8480",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 10:46:49",
        "isSmartClick": "0",
        "email": "rolandeilbracht@hotmail.com",
        "name": "H.J. Eilbracht",
        "firstName": "H.J.",
        "lastName": "Eilbracht",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12229",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274820929",
        "countryID": 156,
        "cityID": 17307,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14218920",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68807219,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amersfoort",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151599998",
        "ip": "2a02:a420:25f:919d:287:4988:8193:1996",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 10:04:43",
        "isSmartClick": "0",
        "email": "Privedenny@gmail.com",
        "name": "Hendrik Van het hul",
        "firstName": "Hendrik",
        "lastName": "Van het hul",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12228",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274810340",
        "countryID": 156,
        "cityID": 1494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14218306",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68806205,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: objectative.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151596924",
        "ip": "149.34.244.170",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 09:56:47",
        "isSmartClick": "0",
        "email": "test123yusdfewrwo@gmail.com",
        "name": "test123yusdfewrwo test123yusdfewrwo",
        "firstName": "test123yusdfewrwo",
        "lastName": "test123yusdfewrwo",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12227",
        "leadType": "1",
        "countryCode": "NL",
        "saleStatus": "Test",
        "saleStatusMapped": "Test",
        "clickID": "274808540",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14218189",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68803392,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "hydrocholecystis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151587007",
        "ip": "89.205.141.122",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 09:36:51",
        "isSmartClick": "0",
        "email": "Jordyvangemeren078@gmail.com",
        "name": "Jordy Van gemeren",
        "firstName": "Jordy",
        "lastName": "Van gemeren",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12226",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "274803343",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-01 10:55:49",
        "depositID": "20406265",
        "conversionID": "1214632",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14217831",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68801171,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Hilversum",
        "custom": null,
        "custom1": null,
        "custom2": "pneumonolithiasis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151576992",
        "ip": "2a02:a44f:e55e:1:d819:39e9:8c20:9957",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 09:19:20",
        "isSmartClick": "0",
        "email": "n.loenen15@gmail.com",
        "name": "Ernst Van drunen",
        "firstName": "Ernst",
        "lastName": "Van drunen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12225",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274798796",
        "countryID": 156,
        "cityID": 1556,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14217534",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68800734,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Soest",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "151575295",
        "ip": "84.241.207.63",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 09:16:19",
        "isSmartClick": "0",
        "email": "Vangilscleaning@gmail.com",
        "name": "Ruud Van gils",
        "firstName": "Ruud",
        "lastName": "Van gils",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12224",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274797837",
        "countryID": 156,
        "cityID": 6712,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14217469",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68800631,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zoetermeer",
        "custom": null,
        "custom1": null,
        "custom2": "hydrocholecystis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151574938",
        "ip": "89.205.133.185",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 09:15:51",
        "isSmartClick": "0",
        "email": "ronald@masware.nl",
        "name": "Ronald Maasse",
        "firstName": "Ronald",
        "lastName": "Maasse",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12223",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274797636",
        "countryID": 156,
        "cityID": 1565,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14217457",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68797512,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "'s-Hertogenbosch",
        "custom": null,
        "custom1": null,
        "custom2": "lanstro.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151566300",
        "ip": "2001:1c03:4e00:6600:6435:4ab2:90b7:9eb5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 09:00:43",
        "isSmartClick": "0",
        "email": "Keesvdhout@hotmail.com",
        "name": "Kees Van den hout",
        "firstName": "Kees",
        "lastName": "Van den hout",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12222",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274792515",
        "countryID": 156,
        "cityID": 2150,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14217224",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68796330,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Breda",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "151562234",
        "ip": "2a02:a445:e43e:1:7630:c5fd:dec1:fa12",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 08:53:47",
        "isSmartClick": "0",
        "email": "Erikhof1967@outlook.com",
        "name": "Erik Van t hof",
        "firstName": "Erik",
        "lastName": "Van t hof",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12221",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274790202",
        "countryID": 156,
        "cityID": 1526,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14217117",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68792023,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Heerlen",
        "custom": null,
        "custom1": null,
        "custom2": "momentaneity.com",
        "custom3": null,
        "custom4": "Pixl",
        "custom5": null,
        "leadRequestID": "151546452",
        "ip": "2001:1c05:2c11:5300:6360:dbf5:7d7b:5f4b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 08:25:44",
        "isSmartClick": "0",
        "email": "vanstraetene@gmail.com",
        "name": "Emil van straeten",
        "firstName": "Emil",
        "lastName": "van straeten",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12220",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274782039",
        "countryID": 156,
        "cityID": 1425,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14216632",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68789559,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "hydrocholecystis.com",
        "custom3": null,
        "custom4": "Golova",
        "custom5": null,
        "leadRequestID": "151536231",
        "ip": "2a02:a476:91f5:0:1096:5c14:3fdf:4848",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 08:11:27",
        "isSmartClick": "0",
        "email": "technoton@hetnet.nl",
        "name": "Tonny  Hagedoorn",
        "firstName": "Tonny ",
        "lastName": "Hagedoorn",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12219",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274777400",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14216292",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68788794,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amersfoort",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "151533330",
        "ip": "2a02:a44a:f4:1:9bc1:4408:6b36:1260",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 08:07:21",
        "isSmartClick": "0",
        "email": "tomtomtom770@hotmail.com",
        "name": "Tom Van Kleef ",
        "firstName": "Tom",
        "lastName": "Van Kleef ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12218",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274775980",
        "countryID": 156,
        "cityID": 1494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14216216",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68787741,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Rotterdam",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "151529802",
        "ip": "109.36.144.200",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 08:02:24",
        "isSmartClick": "0",
        "email": "haroldcoenen@hotmail.com",
        "name": "Harold  Coenen ",
        "firstName": "Harold ",
        "lastName": "Coenen ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12217",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274774189",
        "countryID": 156,
        "cityID": 423,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14216088",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68784583,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Creil",
        "custom": null,
        "custom1": null,
        "custom2": "ranizo.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151517525",
        "ip": "2001:9e0:a804:1c00:7527:6299:1308:da1a",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 07:42:10",
        "isSmartClick": "0",
        "email": "s.dudink@solcon.nl",
        "name": "Sjaak Dudink",
        "firstName": "Sjaak",
        "lastName": "Dudink",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12216",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "274768702",
        "countryID": 156,
        "cityID": 9909,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-01 10:14:20",
        "depositID": "20406207",
        "conversionID": "1214592",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215816",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68783807,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Haarlem",
        "custom": null,
        "custom1": null,
        "custom2": "valzria.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151514340",
        "ip": "143.176.194.189",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 07:35:35",
        "isSmartClick": "0",
        "email": "emielkepel@gmail.com",
        "name": "Emiel Keppel",
        "firstName": "Emiel",
        "lastName": "Keppel",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12215",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274767327",
        "countryID": 156,
        "cityID": 494,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215750",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68780230,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Sliedrecht",
        "custom": null,
        "custom1": null,
        "custom2": "romzia.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151496965",
        "ip": "84.241.193.160",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 06:57:29",
        "isSmartClick": "0",
        "email": "johangroen1979@gmail.com",
        "name": "Johan Groen",
        "firstName": "Johan",
        "lastName": "Groen",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12214",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "wrong language",
        "saleStatusMapped": "Language Barrier",
        "clickID": "274759427",
        "countryID": 156,
        "cityID": 89858,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215428",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68780075,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "West-Terschelling",
        "custom": null,
        "custom1": null,
        "custom2": "valzria.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151495949",
        "ip": "2a02:a44d:f8fb:1:2ed8:5b92:667d:92b5",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 06:55:14",
        "isSmartClick": "0",
        "email": "baukje58@gmail.com",
        "name": "B. Zandstra",
        "firstName": "B.",
        "lastName": "Zandstra",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12213",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274758972",
        "countryID": 156,
        "cityID": 50024,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215408",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68779255,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Sneek",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151490207",
        "ip": "2a02:a465:2640:0:1d06:496b:73f6:71ef",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 06:42:18",
        "isSmartClick": "0",
        "email": "a.de@telfort.nl",
        "name": "Anna de Boer",
        "firstName": "Anna",
        "lastName": "de Boer",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12212",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274756648",
        "countryID": 156,
        "cityID": 2361,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215357",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68778743,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "22d3041c7a",
        "affiliateID": "87911",
        "affiliateName": "Maximus",
        "affiliateEmail": "maximus@gmail2.com",
        "countryName": "United Kingdom",
        "cityName": null,
        "custom": null,
        "custom1": null,
        "custom2": null,
        "custom3": null,
        "custom4": null,
        "custom5": null,
        "leadRequestID": "151487397",
        "ip": "185.69.144.80",
        "userAgent": "GuzzleHttp/7",
        "platform": "Other",
        "browser": "Other",
        "signupDate": "2024-10-01 06:34:19",
        "isSmartClick": "0",
        "email": "testLead895937@mailinator.com",
        "name": "Test Tester",
        "firstName": "Test",
        "lastName": "Tester",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12211",
        "leadType": "1",
        "countryCode": "GB",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "274755446",
        "countryID": 233,
        "cityID": null,
        "affiliateManagerEmail": null,
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-01 07:43:10",
        "depositID": "manual",
        "conversionID": "1214484",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": "English (United Kingdom)",
        "localeCode": "en_GB",
        "stopSaleStatusUpdate": 1,
        "leadRequestComment": "Test",
        "leadRequestOfferName": "Test",
        "leadRequestOfferWebsite": "Test",
        "brokerAutologinClick": null,
        "brokerAutologinClickJavascriptEnabled": null,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68778095,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Klazienaveen",
        "custom": null,
        "custom1": null,
        "custom2": "knowledgewoven.com",
        "custom3": null,
        "custom4": "Coin",
        "custom5": null,
        "leadRequestID": "151484547",
        "ip": "2001:1c01:410d:8000:eca4:6478:5f81:719e",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 06:25:42",
        "isSmartClick": "0",
        "email": "albert.knorren49@g.mail.com",
        "name": "Albert Knorren",
        "firstName": "Albert",
        "lastName": "Knorren",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12210",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274754049",
        "countryID": 156,
        "cityID": 2480,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215264",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68776308,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Zuidoostbeemster",
        "custom": null,
        "custom1": null,
        "custom2": "vesnioa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151477598",
        "ip": "2001:1c04:509:eb00:b12d:1ad0:a3b3:dd3b",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 06:05:16",
        "isSmartClick": "0",
        "email": "R.leguijt@hotmail.com",
        "name": "Ron  Leguijt ",
        "firstName": "Ron ",
        "lastName": "Leguijt ",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12209",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274750549",
        "countryID": 156,
        "cityID": 35901,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215178",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68774222,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Breda",
        "custom": null,
        "custom1": null,
        "custom2": "monophonous.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151468918",
        "ip": "2a02:a443:5f56:1:4c6f:ae6d:ca50:10b8",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 05:40:41",
        "isSmartClick": "0",
        "email": "wjouwerkerk@hotmail.com",
        "name": "Walter ouwerkerk",
        "firstName": "Walter",
        "lastName": "ouwerkerk",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12208",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "deposit",
        "saleStatusMapped": "Converted",
        "clickID": "274746113",
        "countryID": 156,
        "cityID": 1526,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": "2024-10-01 08:29:03",
        "depositID": "20406032",
        "conversionID": "1214515",
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215096",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 1,
        "multiSignup": 0
    },
    {
        "traderID": 68773375,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Tiel",
        "custom": null,
        "custom1": null,
        "custom2": "lemorao.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151465390",
        "ip": "89.205.135.128",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 05:32:03",
        "isSmartClick": "0",
        "email": "tlmvdvenne@gmail.com",
        "name": "TLM van de Venne",
        "firstName": "TLM",
        "lastName": "van de Venne",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12207",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274744595",
        "countryID": 156,
        "cityID": 2288,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14215062",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68771790,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Amsterdam",
        "custom": null,
        "custom1": null,
        "custom2": "zanroa.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151457887",
        "ip": "2a02:a473:da95:0:c957:633:35:b8cc",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 05:14:57",
        "isSmartClick": "0",
        "email": "mkolff70@hotmail.com",
        "name": "Margreet  Kolff",
        "firstName": "Margreet ",
        "lastName": "Kolff",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12206",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "not interested",
        "saleStatusMapped": "No interest",
        "clickID": "274741213",
        "countryID": 156,
        "cityID": 93,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14214998",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68771359,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Netherlands",
        "cityName": "Stein",
        "custom": null,
        "custom1": null,
        "custom2": "ranizo.com",
        "custom3": null,
        "custom4": "Holy",
        "custom5": null,
        "leadRequestID": "151455971",
        "ip": "2a02:a450:ec7c:1:5e8:3b9f:af89:c634",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 05:10:28",
        "isSmartClick": "0",
        "email": "gschefman@hotmail.com",
        "name": "Gabrielle Schefman",
        "firstName": "Gabrielle",
        "lastName": "Schefman",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12205",
        "leadType": "0",
        "countryCode": "NL",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274740361",
        "countryID": 156,
        "cityID": 7113,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "Chantal Janzen regretted revealing the truth. But it was too late. The scandal erupted during a live broadcast when Chantal Janzen accidentally revealed her secret on the program.  ",
        "leadRequestOfferName": "Trade 2.0 Avapro",
        "leadRequestOfferWebsite": "Trade 2.0 Avapro",
        "brokerAutologinClick": "14214981",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    },
    {
        "traderID": 68764881,
        "campaignName": "General",
        "whitelabelID": "256",
        "affiliateHash": "e1ea92fd13",
        "affiliateID": "70791",
        "affiliateName": "Internal",
        "affiliateEmail": "internal@no3ma1ler67657587.op",
        "countryName": "Belgium",
        "cityName": "Brussels",
        "custom": null,
        "custom1": null,
        "custom2": "Offer: movementdeperseds.com",
        "custom3": "Dutch",
        "custom4": "Max",
        "custom5": null,
        "leadRequestID": "151427069",
        "ip": "178.51.124.30",
        "userAgent": null,
        "platform": null,
        "browser": null,
        "signupDate": "2024-10-01 02:46:36",
        "isSmartClick": "0",
        "email": "hamidoumalik0@gmail.com",
        "name": "malik hamidou",
        "firstName": "malik",
        "lastName": "hamidou",
        "brokerID": "25310",
        "brokerName": "PR",
        "customerID": "12204",
        "leadType": "0",
        "countryCode": "BE",
        "saleStatus": "no answer",
        "saleStatusMapped": "No Answer",
        "clickID": "274726023",
        "countryID": 23,
        "cityID": 175,
        "affiliateManagerEmail": "coinking@gmail2.com",
        "advertiserManagerEmail": null,
        "campaignHash": "f567cf719a",
        "FTDdate": null,
        "depositID": null,
        "conversionID": null,
        "projectAffiliateCommissionAmount": "0.00000000",
        "brokerPayoutAmount": "0.00000000",
        "failedRegistrationsCount": "0",
        "localeName": null,
        "localeCode": null,
        "stopSaleStatusUpdate": 0,
        "leadRequestComment": "No skills or knowledge are required. And I can prove it: give me your cell phone, I will register you on Bitcoineer, and at the end of the conversation you will see how much you can earn.",
        "leadRequestOfferName": "Bitcoineer (BE)",
        "leadRequestOfferWebsite": "Bitcoineer (BE)",
        "brokerAutologinClick": "14214632",
        "brokerAutologinClickJavascriptEnabled": 0,
        "hasFTD": 0,
        "multiSignup": 0
    }
];
  
  const AffiliateAnalytics = () => {
    const [expandedRows, setExpandedRows] = useState({});
    const isMobile = useMediaQuery('(max-width:600px)');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const rows = useMemo(() => processAffiliateData(data), [data]);
  
    const totalLeads = rows.reduce((acc, row) => acc + row.leads, 0);
    const totalDeposits = rows.reduce((acc, row) => acc + row.deposit, 0);
    const totalCr = (totalDeposits / totalLeads * 100).toFixed(2);
  
    const totalRow = {
      id: 'total', // Уникальный идентификатор для строки Total
      name: 'Total',
      leads: totalLeads,
      deposit: totalDeposits,
      cr: totalCr,
    };
  
    const columns = [
      {
        field: 'toggle',
        headerName: '',
        renderCell: (params) => {
          if (params.row.id === 'total') {
            return null; // Не отображаем кнопку раскрытия для строки Total
          }
          const isExpanded = expandedRows[params.row.id];
          return (
            <IconButton onClick={() => handleRowClick(params)} size="small">
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          );
        },
        width: 50,
        sortable: false, // Отключаем сортировку для этой колонки
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === 'total') return false; // Устанавливаем "Total" ниже
          if (params2.id === 'total') return false; 
          return v1.localeCompare(v2);
        },
      },
      {
        field: 'leads',
        headerName: 'Leads',
        flex: 1,
        sortComparator: (v1, v2, params1, params2) => {
          console.log(params1, params2)
          if (params1.id === 'total') return false; 
          if (params2.id === 'total') return false; 
          return v1 - v2;
        },
      },
      {
        field: 'deposit',
        headerName: 'Deposits',
        flex: 1,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === 'total') return false; 
          if (params2.id === 'total') return false; 
          return v1 - v2;
        },
      },
      {
        field: 'cr',
        headerName: 'CR (%)',
        flex: 1,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === 'total') return false;
          if (params2.id === 'total') return false; 
          return parseFloat(v1) - parseFloat(v2);
        },
      },
    ];
  
    const handleRowClick = (params) => {
      const id = params.row.id;
      setExpandedRows((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };
  
    // Chart для аффилиатов
    const renderAffiliateChart = () => {
      const chartData = {
        labels: rows.map((row) => row.name),
        datasets: [
          {
            label: 'Leads',
            data: rows.map((row) => row.leads),
            backgroundColor: '#36A2EB',
          },
          {
            label: 'Deposits',
            data: rows.map((row) => row.deposit),
            backgroundColor: '#FF6384',
          },
          {
            label: 'CR (%)',
            data: rows.map((row) => row.cr),
            backgroundColor: '#FFCE56',
          },
        ],
      };
  
      const options = {
        responsive: true,
        plugins: {
          legend: {
            display: true,
          },
        },
      };
  
      return <Bar data={chartData} options={options} />;
    };
  
    // Chart для Geo
    const renderGeoChart = (geoData) => {
      const chartData = {
        labels: Object.keys(geoData),
        datasets: [
          {
            data: Object.values(geoData).map((geo) => geo.leads),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
          },
        ],
      };
  
      return <Doughnut data={chartData} />;
    };
  
    return (
      <Box>
        <Typography variant="h4" align="center" gutterBottom sx={{ marginBottom: '20px', marginTop: '20px' }}>Affiliate Data</Typography>
        <DataGrid
          rows={[...rows.map((row, index) => ({ id: index, ...row })), totalRow]}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'MuiDataGrid-even-row' : ''
        }
        sx={dataGridStyles}
          getRowHeight={() => 52}
          components={{
            NoRowsOverlay: () => <div>No rows available</div>,
          }}
        />
  
        {/* Geo Data и Charts */}
        {rows.map((row, index) => (
          expandedRows[index] && (
            <Box key={index} sx={{ marginTop: '10px', backgroundColor: '#fff' }}>
              <Typography variant="h5" align="center" gutterBottom>Geo Data for {row.name}</Typography>
  
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row', // Flex для мобильных
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ width: isMobile ? '100%' : '60%' }}>
                  <DataGrid
                    rows={Object.values(row.geoData).map((geo, geoIndex) => ({
                      id: geoIndex,
                      countryCode: geo.countryCode,
                      leads: geo.leads,
                      deposit: geo.deposit,
                      cr: geo.cr,
                    }))}
                    columns={[
                      { field: 'countryCode', headerName: 'Country', flex: 1 },
                      { field: 'leads', headerName: 'Leads', flex: 1 },
                      { field: 'deposit', headerName: 'Deposits', flex: 1 },
                      { field: 'cr', headerName: 'CR (%)', flex: 1 },
                    ]}
                    pageSize={3}
                    rowsPerPageOptions={[3]}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'MuiDataGrid-even-row' : ''
                    }
                    sx={dataGridStyles}
                  />
                </Box>
  
                <Box sx={{ width: isMobile ? '100%' : '35%', marginTop: isMobile ? '20px' : '0' }}>
                  <Typography variant="h5" align="center" gutterBottom>Geo Leads Distribution</Typography>
                  {renderGeoChart(row.geoData)}
                </Box>
              </Box>
            </Box>
          )
        ))}
  
        {/* Chart для аффилиатов */}
        <Box sx={{ paddingBottom: '20px' }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ marginBottom: '20px', marginTop: '20px' }}>Affiliate Data Chart</Typography>
          {renderAffiliateChart()}
        </Box>
      </Box>
    );
  };
  
  export default AffiliateAnalytics;