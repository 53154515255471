// Utility function for processing affiliate data
export const processAffiliateData = (data) => {
    const affiliateMap = {};
  
    data.forEach((entry) => {
      const affiliateName = entry.affiliateName || 'unknown';
  
      if (!affiliateMap[affiliateName]) {
        affiliateMap[affiliateName] = {
          name: affiliateName,
          leads: 0,
          deposit: 0,
          cr: 0,
          geoData: {},
        };
      }
  
      affiliateMap[affiliateName].leads += 1;
      if (entry.depositID !== null) {
        affiliateMap[affiliateName].deposit += 1;
      }
  
      affiliateMap[affiliateName].cr = (
        (affiliateMap[affiliateName].deposit / affiliateMap[affiliateName].leads) * 100
      ).toFixed(2);
  
      const countryCode = entry.countryCode || 'unknown';
      if (!affiliateMap[affiliateName].geoData[countryCode]) {
        affiliateMap[affiliateName].geoData[countryCode] = {
          countryCode,
          leads: 0,
          deposit: 0,
          cr: 0,
        };
      }
  
      affiliateMap[affiliateName].geoData[countryCode].leads += 1;
      if (entry.depositID !== null) {
        affiliateMap[affiliateName].geoData[countryCode].deposit += 1;
      }
  
      affiliateMap[affiliateName].geoData[countryCode].cr = (
        (affiliateMap[affiliateName].geoData[countryCode].deposit / affiliateMap[affiliateName].geoData[countryCode].leads) * 100
      ).toFixed(2);
    });
  
    return Object.values(affiliateMap);
  };
  
  // Utility function for processing media buyer data
  export const processMediaBuyerData = (data) => {
    const mediaBuyersMap = {};
  
    data.forEach((entry, index) => {
      const mediaBuyerName = entry.custom4 || 'empty';
  
      if (!mediaBuyersMap[mediaBuyerName]) {
        mediaBuyersMap[mediaBuyerName] = {
          id: index,
          name: mediaBuyerName,
          leads: 0,
          deposit: 0,
          cr: 0,
        };
      }
  
      mediaBuyersMap[mediaBuyerName].leads += 1;
      if (entry.depositID !== null) {
        mediaBuyersMap[mediaBuyerName].deposit += 1;
      }
    });
  
    Object.keys(mediaBuyersMap).forEach((buyer) => {
      const leads = mediaBuyersMap[buyer].leads;
      const deposits = mediaBuyersMap[buyer].deposit;
      mediaBuyersMap[buyer].cr = leads > 0 ? ((deposits / leads) * 100).toFixed(2) : 0;
    });
  
    return Object.values(mediaBuyersMap);
  };
  